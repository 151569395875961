import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';

export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_LIST_LOADING = 'USER_LIST_LOADING';

export const onUserList = (data) => async (dispatch) => {
  dispatch({ type: USER_LIST_LOADING, payload: true });
  const islogin = typeof Storage !== 'undefined' && localStorage.getItem('islogin');
  let tokenid = '';
  if (islogin === 'true') {
    tokenid = localStorage.getItem('tokenId');
    await axios
      .post(AdminMicroRoutes.getalluser, data, { headers: { token: `${tokenid}` } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: USER_LIST_SUCCESS,
            payload: response.data.users,
          });
        } else {
          dispatch({
            type: USER_LIST_FAILURE,
            payload: response.data.status,
          });
        }
      });
  }
};
