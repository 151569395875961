import { CART_FUNCTION, CART_EXIST_CHECKER } from '../actions/cart/addtocart.action';

const initailState = {
  loading: false,
  status: 0,
};

export const userCartReducer = (state = initailState, action) => {
  switch (action.type) {
    case CART_FUNCTION:
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    default:
      return state;
  }
};
