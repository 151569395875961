import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { onUserList } from '../../actions/admin/Userlist.action';
import { FaUserEdit } from 'react-icons/fa';
import { HiUserAdd } from 'react-icons/hi';
import EditModal from './Modal/EditModal';
import AddNew from './AddNew';
import TableLoader from '../Admin/TableLoader';
import { getAllRoles } from '../../actions/admin/GetAllRoles.action';
import * as helpers from '../../helpers/config';
import dic from 'i18next';

const Main = () => {
  const dispatch = useDispatch();
  const { loading, users, error } = useSelector((state) => state.allusers);
  const [items, setItems] = useState(users);

  // Add New modal & Edit User Modal
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModel] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  const closeAddNewModal = () => {
    setShowAddModel(false);
  };

  const [selectData, setSelectData] = useState();

  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;
  const loggeddetails = helpers.LoggedUserDetails();
  const userEmailId = loggeddetails?.userEmailId;
  const userType = loggeddetails?.accessDetails?.role;

  useEffect(() => {
    const userlistdata = {
      userEmailId: userEmailId,
      userType: userType,
    };
    const userlist = {
      userEmailId: userEmailId,
    };
    dispatch(onUserList(userlistdata));
    dispatch(getAllRoles(userlist));
  }, []);

  // updated each time the users with new data
  useEffect(() => {
    setItems(users);
  }, [users]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items && items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items && items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  useEffect(() => {
    setItemOffset(0);
  }, [items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <Fragment>
      <div className="w-[inherit] mt-12 bg-white p-6">
        <div className="text-right">
          <button
            className="bg-gray-300 tracking-wider hover:bg-gray-400 text-gray-800 font-bold p-2 rounded inline-flex items-center"
            onClick={() => {
              setShowAddModel(true);
            }}
          >
            <HiUserAdd className="w-5 h-5 mr-2" />
            <span>{dic.t('Cakery-Create')}</span>
          </button>
        </div>
        <div className="container grid mx-auto mt-4">
          {loading ? (
            <>
              <TableLoader />
            </>
          ) : (
            <div className="w-full overflow-hidden rounded-lg shadow-md border-2 border-gray-300">
              <div className="w-full overflow-x-auto">
                <table className="w-full whitespace-no-wrap">
                  <thead className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                    <tr>
                      <td className="px-4 py-3 text-center">Jumeriah ID</td>
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Name')}</td>
                      {/* <td className="px-4 py-3 text-center">Phone</td> */}
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Email')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Date')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Status')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Action')}</td>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y text-gray-700">
                    {currentItems.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td className="px-4 py-3 text-center">
                            <span className="font-semibold text-black">{item.orgId}</span>
                          </td>
                          <td className="px-4 py-3 text-center">
                            <p className="text-sm font-semibold text-black">{`${item.firstName} ${item.lastName}`}</p>
                          </td>
                          {/* <td className="px-4 py-3 text-center">
                            <p className="text-sm font-semibold text-black">{item.phone}</p>
                          </td> */}
                          <td className="px-4 py-3 text-center">
                            <span className="text-sm font-semibold text-black">{item.emailId}</span>
                          </td>
                          <td className="px-4 py-3 text-center">
                            <span className="text-sm font-semibold text-black">
                              {moment(item.createdDate).format('DD-MM-YYYY')}
                            </span>
                          </td>
                          <td className="px-4 py-3 content-center text-center">
                            {item.userStatus === 'ACTIVE' ? (
                              <span className="inline-flex mx-auto px-2 text-xs font-bold leading-5 rounded-full  text-green-700 bg-green-300 dark:text-white dark:bg-purple-600">
                                {dic.t('Cakery-Active')}
                              </span>
                            ) : (
                              <span className="inline-flex mx-auto px-2 text-xs font-bold leading-5 rounded-full text-red-700 bg-red-300 dark:text-white dark:bg-lime-100">
                                {dic.t('Cakery-In Active')}
                              </span>
                            )}
                          </td>
                          <td className="px-4 py-3 text-center">
                            <span>
                              <button
                                className="text-sm mx-auto outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                  setShowModal(true);
                                  setSelectData(item);
                                }}
                              >
                                <FaUserEdit size="18" />
                              </button>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    {showModal && <EditModal closeModal={closeModal} data={selectData} />}
                    {showAddModal && <AddNew closeAddNewModal={closeAddNewModal} />}
                  </tbody>
                </table>
              </div>
              <div className="px-4 py-3 border-t dark:border-gray-700 bg-gray-50 text-gray-500 dark:text-gray-400 dark:bg-gray-800">
                <div className="flex flex-col justify-between text-xs sm:flex-row text-gray-600 dark:text-gray-400">
                  <span className="flex items-center font-semibold tracking-wide uppercase">
                    {/* {items.length > itemsPerPage && (
                      <span>
                        Showing {itemOffset + 1} - {itemOffset + itemsPerPage} of {items.length}
                      </span>
                    )} */}
                  </span>
                  <div className="flex mt-2 sm:mt-auto sm:justify-end">
                    {items && items.length > itemsPerPage && (
                      <ReactPaginate
                        containerClassName={'inline-flex items-center'}
                        pageLinkClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        breakLabel="..."
                        nextLabel={'Next'}
                        previousClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-600 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        nextClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-600 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel={'Previous'}
                        renderOnZeroPageCount={null}
                        activeClassName={
                          'text-gray-700 bg-white border border-gray-400 rounded-md hover:bg-gray-700 hover:text-white'
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Main;
