import axios from 'axios';
import { AdminMicroRoutes } from '../constants';
import { NIPAYMENT_STATUS } from './payment/payment.action';
import * as helpers from '../helpers/config';
import { onupdateorder } from './admin/UpdateOrder.action';

// export const GENERATE_REPORT = 'GENERATE_REPORT';

export const getNIPayStatusService =
  (data, isDraft = false) =>
  (dispatch) => {
    axios
      .post(AdminMicroRoutes.getNiPaymentStatus, { transactionNumber: data.transactionNumber })
      .then((response) => {
        console.log('RES >>', response.data.status);
        if (response.data.status) {
          const loggeddetails = helpers.LoggedUserDetails();
          const userEmailId = loggeddetails?.userEmailId;
          let sendupdateuser = {
            orderId: data.orderId,
            paymentStatus: 'PAID',
            userDTO: {
              userEmail: userEmailId,
            },
          };
          if (isDraft) {
            sendupdateuser.orderStatus = 'PLACED';
          }
          dispatch(onupdateorder(sendupdateuser));
        }

        dispatch({
          type: NIPAYMENT_STATUS,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: NIPAYMENT_STATUS,
          payload: false,
        });
      });
  };
