import {
  ORDER_HISTORY_SUCCESS,
  ORDER_HISTORY_FAILURE,
  ORDER_HISTORY_LOADING,
} from '../../actions/admin/UserHistory.action';

const initailState = {
  loading: false,
  orders: [],
  error: '',
};

export const getOrderHistoryReducer = (state = initailState, action) => {
  switch (action.type) {
    case ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
      };

    case ORDER_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ORDER_HISTORY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
