import React from 'react';
import { BsXCircleFill } from 'react-icons/bs';
import dic from 'i18next';

const CantAddCart = ({ checkModal, CantAddCartStatusUpdate }) => {
  return (
    <div>
      <div
        className={`fixed z-20 overflow-y-auto top-0 w-full left-0 ${
          checkModal === true ? '' : 'hidden'
        }`}
        id="servicemodal"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block  align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md  w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white sm:pb-4">
              {/* Body Start */}
              <div className="modal-body">
                <h4 className="text-lg text-center bg-gray-700 font-semibold tracking-wider py-3">
                  Info
                </h4>
                <div className="position-relative">
                  <BsXCircleFill
                    onClick={() => CantAddCartStatusUpdate()}
                    className="absolute right-[17px] top-[19px] cursor-pointer text-lg"
                  />
                </div>
                <div>
                  <p className="text-black font-semibold py-5 px-10 text-center">
                    This Product Belongs to Different Category Please Remove Current Product From
                    Cart.
                  </p>
                </div>
              </div>
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CantAddCart;
