import React, { useEffect, useState } from 'react';
import { BsDash, BsPlus } from 'react-icons/bs';

const CartControls = (props) => {
  const { qty, onValueChange } = props;

  const [quantity, setQuantity] = useState(qty || 1);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  useEffect(() => {
    onValueChange(quantity);
  }, [quantity]);

  return (
    <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md">
      <div className="flex items-center relative top-1	cursor-pointer" onClick={handleDecrement}>
        <BsDash className="text-white relative bottom-[0.2rem]" size={20} />
      </div>
      <div className="flex items-center text-white px-1 justify-center text-sm">{quantity}</div>
      <div
        className="flex items-center relative top-[0.2em] cursor-pointer"
        onClick={handleIncrement}
      >
        <BsPlus className="text-white relative bottom-[0.2rem]" size={20} />
      </div>
    </div>
  );
};

export default CartControls;
