import {
  ADMIN_ORDER_SUCCESS,
  ADMIN_ORDER_FAIL,
  LOADING_ADMIN_ORDER_WITHOUTNI,
  ADMIN_ORDER_DATA_DETAILS,
} from '../../actions/admin/AdminOrder.action';

const initailState = {
  loading: false,
  statusCode: '',
  message: '',
  orderDetails: {},
};

export const saveAdminOrder = (state = initailState, action) => {
  switch (action.type) {
    case LOADING_ADMIN_ORDER_WITHOUTNI:
      return {
        ...state,
        loading: action.payload,
      };
    case ADMIN_ORDER_SUCCESS:
      return {
        ...state,
        message: action.payload,
      };
    case ADMIN_ORDER_FAIL:
      return {
        ...state,
        message: action.payload,
      };
    case ADMIN_ORDER_DATA_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };

    default:
      return state;
  }
};
