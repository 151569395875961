import React, { Fragment } from 'react';
import CakeForm from './CakeForm';
import { useSelector } from 'react-redux';
import ProductCart from './ProductCart';
import { getOptionsList } from './utlis';

const OrderScreen = ({ cakeList, codes, FestivalCakes, supplies, pastries, customize }) => {
  const { cartItems } = useSelector((state) => state.callcenter);
  const FlavourOptions = getOptionsList(cakeList);
  const FestivalFlavourOptions = getOptionsList(FestivalCakes);
  const SuppliesOptions = [];
  const PastryOptions = getOptionsList(pastries);
  const AddonList = [];
  const CustomizeCakeOptions = getOptionsList(customize);
  supplies &&
    supplies.map((item) => {
      let optionName = item?.fields?.Title?.value;
      SuppliesOptions.push({
        label: optionName,
        value: optionName,
      });
    });
  return (
    <Fragment>
      <div className="grid grid-cols-1 p-4 sm:p-0 lg:grid-cols-12 gap-2">
        <div className="col-span-6">
          <CakeForm
            FlavourOptions={FlavourOptions}
            AddonList={AddonList}
            FestivalFlavourOptions={FestivalFlavourOptions}
            SuppliesOptions={SuppliesOptions}
            PastryOptions={PastryOptions}
            CustomizeCakeOptions={CustomizeCakeOptions}
          />
        </div>
        <div className="col-span-6">
          <ProductCart cartItems={cartItems} />
        </div>
      </div>
    </Fragment>
  );
};

export default OrderScreen;
