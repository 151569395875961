import React from 'react';
import leftarrow from '../../assets/icon/left-icon.png';

function LeftArrow(item) {
  return (
    <div className="relative">
      <img
        src={leftarrow}
        onClick={item.onClick}
        className="absolute z-10 xssm:top-[10.7rem] customsm:top-[192px] custommd:top-[140px] customlg:top-[160px] custom2xl:top-[192px] cursor-pointer left-[-40px] w-8"
      />
    </div>
  );
}

export default LeftArrow;
