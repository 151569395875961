import React, { Fragment, useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import * as Helpers from '../../../helpers/AdminHelpers';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';

const DatePickup = ({ setdpdate, dpdate, isFestival }) => {
  var Normaldate = new Date(); // Get current date
  var NormalNextdate = Helpers.getNextday(); // Get Next date
  var Festivaldate = new Date();
  Festivaldate.setDate(Festivaldate.getDate() + 2); // Add 2 days to current date
  return (
    <Fragment>
      <div className="relative overflow-x-auto border-2 border-gray-300 shadow-md w-full rounded sm:col-span-4">
        <div className="flex justify-center">
          <DayPicker
            mode="single"
            fromDate={Normaldate}
            selected={dpdate}
            onSelect={(e) => setdpdate(e)}
            styles={{
              caption_label: { zIndex: '0' },
              caption: { fontSize: '12px' },
              nav_button_next: { height: '30px', width: '30px' },
              nav_button_previous: { height: '30px', width: '30px' },
              button_reset: { fontSize: '14px' },
              cell: { height: '10px', width: '10px' },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DatePickup;
