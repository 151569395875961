import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { emailPattern, letters, passwordPattern, phonepattern } from '../../helpers/Patterns';
import { onUserResetPassword } from '../../actions/resetpassword.action';
import { CheckTokenValidation } from '../../actions/resetpassword.action';
import dic from 'i18next';

import { useSelector, useDispatch } from 'react-redux';
// import ResetExpireModal from '../ResetExpireModal';
import { RiLoader5Line } from 'react-icons/ri';
import ResetExpireModal from '../ResetExpireModal';
function ResetPassword() {
  const dispatch = useDispatch();
  const [modalStatus, setmodalStatus] = useState(false);
  const [getPasswordStatus, setPasswordStatus] = useState(false);
  const [getPasswordStatusSec, setPasswordStatusSec] = useState(false);
  const updateEyeStatus = (status) => {
    setPasswordStatus(status);
    var x = document.getElementById('user_password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };
  const updateEyeStatusSecond = (status) => {
    setPasswordStatusSec(status);
    var x = document.getElementById('user_confirm_password');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const { error, loading, statusCode, email, decryptstatuscode, loadingtoken , status} = useSelector(
    (state) => state.resetpassword
  );
  const [tokenEmail, setTokenEmail] = useState(email);
  const onSubmit = (data) => {
    let token = '' ;
    if(typeof window !== undefined){
      const params = new URLSearchParams(window.location.search);
      let resetToken = params.get('token');
      token = resetToken.replace(/ /g, '+');
    }
    const formdata = {
      email: tokenEmail,
      password: data.password,
    };
    dispatch(onUserResetPassword(formdata,encodeURI(token)));
    reset();
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let resetToken = params.get('token');
    if (resetToken) {
      const token = resetToken.replace(/ /g, '+');
      dispatch(CheckTokenValidation(encodeURI(token)));
    }
  }, []);
  useEffect(() => {
    setTokenEmail(email);
  }, [email]);

  useEffect(() => {
    if (decryptstatuscode !== undefined) {
      if (decryptstatuscode !== 200) {
        setmodalStatus(true);
      }
      if (decryptstatuscode === 200) {
        setmodalStatus(false);
      }
    }
  }, [decryptstatuscode]);
  return (
    <>
      <ResetExpireModal error={error || status} modalStatus={modalStatus || statusCode === 400} />
      <section className="">
        <div className="w-full sm:h-screen shadow-2xl overflow-hidden relative">
          <img
            className="absolute inset-0 h-2/5	  sm:h-full w-full object-cover"
            src="https://media.istockphoto.com/photos/cupcake-background-picture-id1211873244?b=1&k=20&m=1211873244&s=170667a&w=0&h=F_LxZ40UghcKXsxBvQWAF9W4woWqiq4SAdWSnHkYowI="
            alt=""
          />
          <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
          <div className="relative">
            <div className="py-3 sm:hidden">
              <br />
              <br />
              <br />
            </div>
            <div className="flex flex-col items-center justify-center py-8 mx-auto md:h-screen lg:py-0">
              <div className="w-full p-8 bg-black rounded-lg shadow md:mt-0 sm:max-w-lg px-5 text-white  sm:p-8">
                {statusCode === 200 ? (
                  <>
                    <p className="py-5 text-center">
                      {dic.t('Cakery-Your password has been changed')}...!
                    </p>
                    <div className="text-center">
                      <a href="/en/home">
                        <button
                          type="submit"
                          className="w-80 text-white bg-gray-700 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          {dic.t('Cakery-Return Home Page')}
                        </button>
                      </a>
                    </div>
                  </>
                ) : (
                  <form
                    className="mt-4 px-3 pt-1 pb-4 space-y-4 lg:mt-5 md:space-y-5"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <h2 className="mb-2 px-3 text-xl font-bold tracking-wider leading-tight  text-white text-center md:text-2xl">
                      Reset Password
                    </h2>
                    {decryptstatuscode === 200 ? (
                      <>
                        <div className="relative">
                          <label className="block mb-2 text-sm font-semibold text-white">
                            {dic.t('Cakery-New Password')}
                          </label>
                          <input
                            maxLength={15}
                            type="password"
                            name="password"
                            id="user_password"
                            placeholder=""
                            autoComplete="off"
                            className="border text-white text-sm rounded-lg bg-black outline-none block w-full p-2.5"
                            {...register('password', {
                              required: 'Password is Required',
                              pattern: {
                                value: passwordPattern,
                                message: 'Please enter a valid password',
                              },
                            })}
                          />
                          <span className="absolute cursor-pointer top-[40px] right-[9px]">
                            {getPasswordStatus === false ? (
                              <FaEye size={19} onClick={() => updateEyeStatus(true)} />
                            ) : (
                              <FaEyeSlash size={20} onClick={() => updateEyeStatus(false)} />
                            )}
                          </span>

                          {errors.password && errors.password.message && (
                            <span className=" mt-1 text-red-400 text-sm absolute">
                              {errors.password.message}
                            </span>
                          )}
                        </div>
                        <div className="pb-2 relative">
                          <label className="block mb-2 text-sm font-semibold text-white">
                            {dic.t('Cakery-Confirm Password')}
                          </label>
                          <input
                            maxLength={15}
                            type="password"
                            name="confirm-password"
                            id="user_confirm_password"
                            placeholder=""
                            autoComplete="off"
                            className="border text-white text-sm rounded-lg bg-black outline-none block w-full p-2.5"
                            {...register('confirmpassword', {
                              required: 'Confirm password is required',
                              validate: () => {
                                if (watch('password') !== watch('confirmpassword')) {
                                  return 'Your passwords do no match';
                                }
                              },
                            })}
                          />
                          <span className="absolute cursor-pointer top-[40px] right-[9px]">
                            {getPasswordStatusSec === false ? (
                              <FaEye size={19} onClick={() => updateEyeStatusSecond(true)} />
                            ) : (
                              <FaEyeSlash size={20} onClick={() => updateEyeStatusSecond(false)} />
                            )}
                          </span>

                          {errors.confirmpassword && errors.confirmpassword.message && (
                            <span className="mt-1 text-red-400 text-sm absolute">
                              {errors.confirmpassword.message}
                            </span>
                          )}
                        </div>
                        {loading === true ? (
                          <button
                            type="submit"
                            className="w-full text-white bg-gray-700 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                            disabled
                          >
                            <svg
                              className={'animate-spin -ml-1 mr-3 h-5 w-5 inline text-white'}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                            {dic.t('Cakery-Reset Password')}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="w-full text-white bg-gray-700 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
                          >
                            {dic.t('Cakery-Reset Password')}
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="text-center">
                          <svg
                            className={'animate-spin -ml-1 mr-3 h-5 w-5 mx-auto inline text-white'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </div>
                      </>
                    )}
                  </form>
                )}
              </div>
            </div>
            <div className="sm:hidden">
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
