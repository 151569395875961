import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { emailPattern } from '../../../helpers/Patterns';
import { colourStyles } from '../../../helpers/Patterns';
import Select from 'react-select';
import moment from 'moment';
import Loader from '../../Loader/Loader';
import { onupdateuser } from '../../../actions/admin/UpdateUser.action';
import * as helpers from '../../../helpers/config';
import dic from 'i18next';

const EditModalForm = ({ closeModal, editData }) => {
  const { updateloading } = useSelector((state) => state.allusers);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const loggeddetails = helpers.LoggedUserDetails();
  const userEmailId = loggeddetails?.userEmailId;
  const dispatch = useDispatch();
  const onSubmits = (data) => {
    const updatedata = {
      status: data.userStatus.value,
      updateEmailId: data.email,
      userEmailId: userEmailId,
      userId: editData.userId,
    };
    dispatch(onupdateuser(updatedata)).then(() => {
      reset();
      closeModal();
    });
  };
  const statusoptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
  ];
  return (
    <>
      {updateloading ? (
        <Loader />
      ) : (
        <div className="rounded px-8 pt-6 pb-8 w-full">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:text-white"
            onClick={() => {
              reset();
              closeModal();
            }}
          >
            <MdClose className="w-5 h-5" />
          </button>
          <div className="login-forms">
            <div className="grid grid-cols-12 items-center mb-5 sm:space-x-5">
              <div className="w-full col-span-2 text-center">
                <p className="mb-2 font-semibold text-gray-700">ID</p>
                <span>{editData.userId}</span>
              </div>
              <div className="w-full col-span-6 text-center">
                <p className="mb-2 font-semibold text-gray-700">{dic.t('Cakery-Name')}</p>
                <span>{`${editData.firstName} ${editData.lastName}`}</span>
              </div>
              <div className="w-full col-span-4 text-center">
                <p className="mb-2 font-semibold text-gray-700">{dic.t('Cakery-Date')}</p>
                <span>{moment(editData.createdDate).format('DD-MM-YYYY')}</span>
              </div>
            </div>
            <form className="pt-1 px-1" onSubmit={handleSubmit(onSubmits)}>
              {/* <ResponseShow success={statusCode} error={error} /> */}
              <div className="grid grid-cols-2 gap-4">
                <div className="relative z-0 w-full">
                  <p className="mb-2 font-semibold text-gray-700">Email</p>
                  <input
                    type="email"
                    defaultValue={editData.emailId}
                    className="block w-full pt-1 px-1.5 pb-1 text-sm text-gray-900 bg-transparent rounded-md border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                    placeholder={`${dic.t('Cakery-Email Address') + ' *'}`}
                    {...register('email', {
                      required: 'Email is Required',
                      pattern: {
                        value: emailPattern,
                        message: 'Please enter a valid email',
                      },
                    })}
                    autoComplete="off"
                  />
                  {errors.email && errors.email.message && (
                    <span className="text-red-400">{errors.email.message}</span>
                  )}
                </div>
                <div className="w-full">
                  <p className="mb-2 font-semibold text-gray-700">{dic.t('Cakery-Status')}</p>
                  <Controller
                    name="userStatus"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={statusoptions}
                        defaultValue={
                          editData.userStatus === 'ACTIVE'
                            ? { label: 'Active', value: 'ACTIVE' }
                            : { label: 'Inactive', value: 'INACTIVE' }
                        }
                        styles={colourStyles}
                      />
                    )}
                  />
                </div>
                <div className="text-center col-span-2">
                  <button
                    type="submit"
                    className="text-white my-2 bg-gray-700  hover:bg-gray-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center"
                  >
                    {dic.t('Cakery-Update')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EditModalForm;
