import axios from 'axios';
import { MicroRoutes } from '../constants';
import * as helpers from '../helpers/config';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const SET_FORGOT_LOADING = 'SET_FORGOT_LOADING';
export const CLEAR_FORGOT_STATE = 'CLEAR_FORGOT_STATE';

export const onUserForgotPassword = (data) => async (dispatch) => {
  dispatch({ type: SET_FORGOT_LOADING, payload: true });
  let payload = helpers.encryptePayload(data);
  await axios.post(MicroRoutes.forgot, payload).then((response) => {
    if (response?.data?.errorStatus) {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: response?.data?.message,
      });
    } else {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: 'Reset password link sent your mail...!',
      });
    }
  });
};

export const onClearForgotPasswordState = () => (dispatch) => {
  dispatch({
    type: CLEAR_FORGOT_STATE,
    payload: '',
  });
};
