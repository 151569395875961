import React, { Fragment } from 'react';
import { MdClose, MdPreview } from 'react-icons/md';
import moment from 'moment';
import dic from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import { getUploadedDocument } from '../../../actions/admin/DocumentUpload.action';

const OrderModal = ({ closeModal, data }) => {
  console.log('modal data', data);
  const Dispatch = useDispatch();
  const UploadDocument = (documentId) => {
    const payload = {
      documentIds: documentId,
    };
    Dispatch(getUploadedDocument(payload));
  };
  const orderTakenby =
    data && data.userDTO && Object.keys(data.userDTO).length > 0
      ? data.userDTO.firstName + ' ' + data.userDTO.lastName
      : 'Guest';
  const createJSON = (itemSuppliesNameNew, itemSuppliesPriceNew) => {
    var names = itemSuppliesNameNew.split(',');
    var prices = itemSuppliesPriceNew.split(',');
    var jsonArray = [];

    for (var i = 0; i < names.length; i++) {
      var jsonObject = {
        addonType: names[i],
        price: prices[i],
      };
      jsonArray.push(jsonObject);
    }

    return jsonArray;
  };

  return (
    <Fragment>
      <div className=" fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen"></span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100">
              <Fragment>
                <div className="shadow-md rounded p-4 w-full">
                  <div className="grid gap-2 mb-4 md:grid-cols-12 xl:grid-cols-12 mt-4">
                    <div className="col-span-12 mb-2">
                      <button
                        type="button"
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center dark:hover:text-white"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <MdClose className="w-5 h-5" />
                      </button>
                    </div>
                    <div className="col-span-12 sm:col-span-4 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white ">
                      <div className="p-2 flex items-center">
                        <div className="w-full h-46">
                          <div className="flex justify-between w-full mb-1">
                            <p className="text-xs  text-gray-800">{dic.t('Cakery-Order Status')}</p>
                            <p className="text-xs  text-gray-600">{data && data.orderStatus}</p>
                          </div>
                          <div className="flex justify-between w-full">
                            <p className="text-xs  text-gray-800">
                              {dic.t('Cakery-Payment Status')}
                            </p>
                            <p className="text-xs  text-gray-600">{data && data.paymentStatus}</p>
                          </div>
                          <p className="mb-2 mt-2 text-sm font-semibold text-gray-600 ">
                            {data?.deliveryType === 'PICKUP'
                              ? 'Delivery Type'
                              : dic.t('Cakery-Delivery Address')}
                          </p>
                          <p className="text-sm font-medium text-gray-700 ">
                            <p className="text-xs">
                              {data?.deliveryType === 'PICKUP'
                                ? 'Pickup'
                                : data?.deliveryAddress?.address +
                                  ', ' +
                                  data?.deliveryAddress?.region}
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 sm:col-span-8 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white ">
                      <div className="p-2 flex items-center">
                        <div className="w-full h-46">
                          {/* <h3 className="text-sm  font-semibold leading-5 text-gray-800">
                            {dic.t('Cakery-Order Summary')}
                          </h3> */}
                          <div className="flex justify-center items-center w-full space-y-1 flex-col border-gray-200">
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">{dic.t('Cakery-Order ID')}</p>
                              <p className="text-xs  text-gray-600">{data && data.orderId}</p>
                            </div>
                            {data && data.transactionNumber && (
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">Order Ref</p>
                                <p className="text-[0.55rem] sm:text-xs  text-gray-600">
                                  {data && data.transactionNumber}
                                </p>
                              </div>
                            )}
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">Customer Name</p>
                              <p className="text-xs  text-gray-600">{data && data.customerName}</p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">Customer Email</p>
                              <p className="text-xs  text-gray-600">{data && data.customerEmail}</p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">Customer Phone</p>
                              <p className="text-xs  text-gray-600">{data && data.phoneNumber}</p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">{dic.t('Cakery-Order Date')}</p>
                              <p className="text-xs  text-gray-600">
                                {data && moment(data.orderDate).format('DD/MM/YYYY')}
                              </p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {dic.t('Cakery-Order Taken By')}
                              </p>
                              <p className="text-xs  text-gray-600">{orderTakenby}</p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {data?.deliveryType === 'DELIVERY'
                                  ? dic.t('Cakery-Delivery Date')
                                  : dic.t('Cakery-Pickup Date')}
                              </p>
                              <p className="text-xs  text-gray-600">
                                {data && moment(data.dpDate).format('DD/MM/YYYY')}
                              </p>
                            </div>
                            <div className="flex justify-between w-full">
                              <p className="text-xs  text-gray-800">
                                {data?.deliveryType === 'DELIVERY'
                                  ? dic.t('Cakery-Delivery Time')
                                  : dic.t('Cakery-Pickup Time')}
                              </p>
                              <p className="text-xs  text-gray-600">{data && data.dpTime}</p>
                            </div>
                            {data && data.remarks !== '' && (
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">{dic.t('Cakery-Remarks')}</p>
                                <p className="text-xs  text-gray-600">{data && data.remarks}</p>
                              </div>
                            )}
                            {data && data.history !== '' && (
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">Status</p>
                                <p className="text-xs  text-gray-600">{data && data.history}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white mb-4">
                    <div className="flex items-center">
                      <div className="flex flex-col p-4 min-h-fit max-h-52 w-full space-y-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300">
                        {/* <h3 className="text-sm  font-semibold leading-5 text-gray-800">Items</h3> */}
                        {data.items.map((item, key) => {
                          console.log(item, 'ITEMS');
                          const itemSupplies =
                            item && item?.itemSuppliesName ? item.itemSuppliesName : '';
                          const itemSuppliesNameNew =
                            item && item?.itemSuppliesNameNew ? item.itemSuppliesNameNew : '';
                          return (
                            <div key={key}>
                              <ul role="list" className="divide-y divide-gray-200 ">
                                <li className="">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <img
                                        className="object-cover w-14 h-14 rounded-lg shadow-sm"
                                        src={item.itemImageUrl}
                                        alt="product"
                                      />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <div className="text-sm font-medium text-gray-900 truncate ">
                                        <p className="font-semibold">{item.itemName}</p>

                                        {item.cakeType === 'CUSTOMIZED' &&
                                          item.documentId &&
                                          item.documentId.length > 0 && (
                                            <MdPreview
                                              size="18"
                                              className="text-gray-500 inline-flex hover:cursor-pointer"
                                              onClick={() => UploadDocument(item.documentId)}
                                            ></MdPreview>
                                          )}
                                      </div>
                                      <div className="text-sm sm:font-medium">
                                        {item.itemDescription}
                                      </div>
                                      {item.addOns.length > 0 && (
                                        <>
                                          <h6 className="text-xs text-gray-600 font-semibold">
                                            Addons
                                          </h6>
                                          <ul className="festive-addons border-b pb-1 mb-2">
                                            {item.addOns.map((val, i) => (
                                              <li
                                                className="addon-item flex items-center text-sm text-muted font-medium space-x-3"
                                                key={i}
                                              >
                                                <h4 className="text-sm flex items-center">
                                                  <FaCheck className="h-3 w-3 text-green-500 mr-2" />
                                                  {val.addonName}
                                                </h4>
                                                <h4 className="">
                                                  {val.Qty} x {val.Price} {dic.t('Cakery-AED')}
                                                </h4>
                                              </li>
                                            ))}
                                          </ul>
                                        </>
                                      )}
                                      {item && item.itemSuppliesName && itemSupplies !== '' ? (
                                        <>
                                          <p className="text-xs text-gray-500 truncate">
                                            {`${item.itemSuppliesName}  ${item.itemSuppliesPrice} AED`}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          {itemSuppliesNameNew !== '' && (
                                            <div>
                                              <h6 className="text-xs text-gray-600 font-semibold">
                                                Supplies
                                              </h6>
                                              {createJSON(
                                                item.itemSuppliesNameNew,
                                                item.itemSuppliesPriceNew
                                              ).map((addon, index) => (
                                                <div className="flex" key={index}>
                                                  <p className="text-xs text-gray-500 truncate">
                                                    {addon.addonType}
                                                  </p>
                                                  <p className="text-xs text-gray-500 truncate">
                                                    &nbsp;&nbsp;{addon.price} AED
                                                  </p>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                        </>
                                      )}
                                      {item?.itemMessage !== '' && (
                                        <p className="text-xs text-gray-500 truncate ">
                                          ✉️ {item?.itemMessage}
                                        </p>
                                      )}
                                      {/* {item.cakeRemarks !== '' && (<div className="text-gray-600 text-sm">🗒️ {item.cakeRemarks}</div>)} */}
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                      <div className="flex font-bold text-gray-700 px-1 justify-center text-md">
                                        Qty {item.quantity}
                                      </div>
                                      <div className="col-span-1 py-auto text-center">
                                        <h2 className="font-bold tracking-widest text-gray-700">
                                          {dic.t('Cakery-AED')} {item.totalPrice}
                                        </h2>
                                      </div>
                                    </div>
                                    {/* <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                      {dic.t('Cakery-AED')} {item.totalPrice}
                                    </div> */}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white ">
                    <div className="flex items-center">
                      <div className="flex flex-col p-4 min-h-fit max-h-52 w-full space-y-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300">
                        {/* <h3 className="text-sm  font-semibold leading-5 text-gray-800">Items</h3> */}

                        <ul role="list" className="divide-y divide-gray-200 ">
                          <li className="">
                            <div className="flex items-center space-x-4 justify-between">
                              <div className="flex-shrink-0 text-gray-700 font-bold">
                                {dic.t('Cakery-Total Payment')}
                              </div>
                              <div className="grid grid-cols-1 sm:grid-cols-1 gap-6">
                                <div className="col-span-1 py-auto text-center">
                                  <h2 className="font-bold tracking-widest text-gray-700">
                                    {dic.t('Cakery-AED')} {data.totalPrice}
                                  </h2>
                                </div>
                              </div>
                              {/* <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                      {dic.t('Cakery-AED')} {item.totalPrice}
                                    </div> */}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderModal;
