import React, { Fragment, useEffect } from 'react';
import * as helpers from '../../helpers/config';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

const HeroBanner = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const Banners = props && props.fields && props.fields.Banners;
  const Title = props?.fields?.Title?.value;
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    fade: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'ease-in-out',
    pauseOnHover:false,
  };
  const ScrollDown = () => {
    window !== 'undefined' && window.scroll({ top: 600, behavior: 'smooth' });
  };

  useEffect(() => {
    const supportId = localStorage.getItem('supportId');
    const supportUrl = localStorage.getItem('supportUrl');
    if (supportId) {
      window.dataLayer.push({
        event: 'F5_blocking',
        page_name: '500 Epic Fail',
        page_url: supportUrl,
        support_id: supportId
      });
      // Clear localStorage items
      localStorage.removeItem('supportId');
      localStorage.removeItem('supportUrl');
      console.log(window.dataLayer, 'DATA_LAYER')
    }
  }, [])

  return (
    <Fragment>
      <div>
        <div>
          <Slider {...settings}>
            {Banners?.map((items, keys) => {
              const videourl = items?.fields?.Video?.value?.href;
              const imageurl = items?.fields?.Image?.value?.src;
              const mobileimageurl = items?.fields?.['Mobile Image']?.value?.src;
              const showvideo = items?.fields?.['Show Video']?.value;
              const description = items?.fields?.Description?.value;
              return (
                <div key={keys}>
                  <div className="w-full h-full absolute flex top-0 left-0 items-center">
                    <div
                      data-aos="fade-up"
                      data-duration="1000"
                      className="text-4xl sm:text-5xl font-medium capitalize ml-[3vh] sm:ml-[10vh] mt-[55vh] text-white z-10"
                    >
                      {Title}
                      <div
                        className="capitalize text-white z-10 text-[22px] md:text-3xl mt-3"
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></div>
                    </div>
                  </div>
                  <div className="absolute bottom-0 sm:bottom-4 text-sm left-[45vw] sm:left-[48vw] z-10 text-[#8f8f8f] text-center">
                    <div className="text-3xl animate-bounce">
                      <svg
                        className="w-8 h-8 inline hover:cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        onClick={ScrollDown}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </div>
                  </div>
                  {showvideo === true && isMobile && (
                    <div
                      className="w-screen h-screen"
                      style={{
                        backgroundImage: `url("${mobileimageurl}")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    >
                      <div id="responsiveVideoWrapper" className="relative pb-fluid-mobile-video">
                        <iframe
                          className="absolute top-0 left-0 w-full h-full"
                          src={`${videourl}?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=true&dnt=true&background=1`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  )}
                  {showvideo === true && !isTablet && !isMobile && (
                    <div
                      className="w-screen h-screen"
                      style={{
                        backgroundImage: `url("${imageurl}")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    >
                      <div id="responsiveVideoWrapper" className="relative pb-fluid-video">
                        <iframe
                          className="absolute top-0 left-0 w-full h-full"
                          src={`${videourl}?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=true&dnt=true&background=1`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  )}
                  {showvideo === false && (
                    <img
                      src={imageurl}
                      className="w-screen h-[75vh] lg:h-screen object-cover object-center"
                    />
                  )}
                  <div className="bg-gradient-to-t from-black via-black absolute inset-y-2/3 h-[25rem] w-full"></div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

export default HeroBanner;
