import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import * as session from '../../helpers/sessionHandler';
import { useSelector } from 'react-redux';
import { BsCheck2All } from 'react-icons/bs';
import { BiUserCircle, BiBell } from 'react-icons/bi';
import dic from 'i18next';

export default function LoginCheckout() {
  const [Login, SetLogin] = useState(false);
  const toggleModal = () => {
    document.getElementById('modal').classList.toggle('hidden');
  };
  const toggleModalCheck = () => {
    document.getElementById('modal').classList.toggle('hidden');
    document.getElementById('continueguest').click();
  };
  const login_status = helpers.CurrentLoginStatus();
  const islogin = localStorage.getItem('islogin');
  const Loggeduser = helpers.LoggedUserDetails();
  const gueststatus_redux = useSelector((state) => state.guest.status);
  const guestdetails_redux = useSelector((state) => state.guest.details);
  const GuestDetails = session.GetGuestDetails();
  const GuestStatus = localStorage.getItem('guestLoggedIn');
  useEffect(() => {
    if (login_status === 200) {
      SetLogin(!Login);
    } else {
      if (islogin === 'true') {
        SetLogin(!Login);
      }
    }
  }, [login_status, islogin]);
  return (
    <div className="sub-accordition">
      <div className="accord-title tracking-wider text-black font-bold p-4 bg-white">
        <span className="bg-black text-white text-xs font-semibold mr-2 px-2.5 py-1 rounded">
          1
        </span>
        {login_status === 200 || islogin === 'true' || GuestStatus === 'true' ? (
          <>
            {islogin === 'true' && 'LOGGED IN USER'}
            {GuestStatus === 'true' && 'LOGGED IN GUEST'}
            <BsCheck2All className="inline text-xl font-bold" />
            <div>
              <p className="font-bold p-0 m-0 relative left-8">
                {GuestStatus === 'true' && (
                  <>
                    {GuestDetails.email} | {GuestDetails.phone}
                  </>
                )}
                {islogin === 'true' && (
                  <>
                    {Loggeduser.firstName} {Loggeduser.lastName}
                  </>
                )}
              </p>
            </div>
          </>
        ) : (
          <>LOGIN</>
        )}
      </div>
      {login_status === 200 || islogin === 'true' || GuestStatus === 'true' ? (
        ''
      ) : (
        <div className="accord-description border border-white">
          <div className="grid grid-cols-1  md:grid-cols-1 gap-3">
            <div className="login-check text-center py-5">
              <p className="text-white text-center py-5">
                By Continuing, you agree to Creation Cakery{' '}
                <a
                  target={'_blank'}
                  href="https://www.jumeirah.com/en/jumeirah-group/terms-and-conditions"
                  className="underline underline-offset-1"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
              </p>
              <button
                onClick={toggleModal}
                className="text-white text-center mx-3  bg-gray-700 font-bold text-base rounded-lg text-md px-5 py-2.5 mr-2 mb-2 tracking-wider	"
              >
                {dic.t('Cakery-Login or Sign Up')}
              </button>
              <button
                onClick={toggleModalCheck}
                className="text-white text-center  mx-3 bg-gray-700 font-bold text-base rounded-lg text-md px-5 py-2.5 mr-2 mb-2 tracking-wider	"
              >
                Continue as Guest
              </button>
            </div>
            {/* <div className="secure-login text-white py-10">
              <p className="py-2">
                <BiUserCircle className="inline text-[1.5rem]" />{' '}
                {dic.t('Cakery-Safe and secure login')}
              </p>
              <p className="py-2">
                <BiBell className="inline text-[1.4rem]" />{' '}
                {dic.t('Cakery-Alerts and Recommendation')}
              </p>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
