import React, { Fragment } from 'react';
import CustomerForm from './CustomerForm';
import CustomerHistroy from './CustomerHistroy';

const CustomerScreen = ({ codes, isDisabled }) => {
  return (
    <Fragment>
      <div className="grid grid-cols-1 p-4 sm:p-0 lg:grid-cols-2 gap-4 relative">
        {!isDisabled && (
          <div className="absolute  inset-0 bg-opacity-50 opacity-25 bg-white z-20"></div>
        )}
        <CustomerForm codes={codes} />
        <CustomerHistroy />
      </div>
    </Fragment>
  );
};

export default CustomerScreen;
