import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import { BsXCircleFill } from 'react-icons/bs';
import moment from 'moment';
import dic from 'i18next';

function OrderView(props) {
  const { checkModal, item } = props;
  return (
    <div>
      <div
        className={`fixed z-20 overflow-y-auto  top-0 w-full left-0 ${
          checkModal === true ? '' : 'hidden'
        }`}
        id="servicemodal"
      >
        <div className="flex items-center  justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div className="inline-block  align-center bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-screen-sm sm:max-w-screen-sm lg:max-w-screen-lg w-full">
            <div className="bg-white">
              {/* Body Start */}
              <div className="modal-body">
                <h4 className="text-lg text-center bg-gray-700 text-white font-semibold tracking-wider py-3">
                  Order Detail
                </h4>
                <div className="position-relative">
                  <BsXCircleFill
                    onClick={() => props.setmodelItem(false)}
                    className="absolute right-[17px] top-[19px] text-white cursor-pointer text-lg"
                  />
                </div>
                <div
                  className={
                    'grid grid-cols-2 text-sm sm:text-base lg:grid-cols-4 gap-3 cursor-pointer px-5 py-5'
                  }
                >
                  <div className="product-details text-gray">
                    <p>
                      {dic.t('Cakery-Order ID')} : {item?.orderId}
                    </p>
                    <p>
                      {dic.t('Cakery-Order Date')} : {moment(item?.orderDate).format('YYYY-MM-DD')}
                    </p>
                    <p>
                      {dic.t('Cakery-Order Status')} : {item?.orderStatus}
                    </p>
                    <p>Type : {item?.deliveryType}</p>
                  </div>
                  {item?.deliveryType !== 'PICKUP' ? (
                    <div className="product-details text-gray">
                      <p> {dic.t('Cakery-Delivery Address')} : </p>
                      <p>
                        {item?.deliveryAddress?.makani}, {item?.deliveryAddress?.region},
                      </p>
                      <p>{item?.deliveryAddress?.address}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="product-details text-gray">
                    <p>
                      {item?.deliveryType === 'DELIVERY'
                        ? dic.t('Cakery-Delivery Date')
                        : 'Pickup Date'}
                      :{' '}
                    </p>
                    <p>{moment(item?.dpDate).format('YYYY-MM-DD')}</p>
                    <p>
                      {item?.deliveryType === 'DELIVERY'
                        ? dic.t('Cakery-Delivery Time')
                        : 'Pickup Time'}{' '}
                      : <br /> {item?.dpTime}
                    </p>
                  </div>
                  <div>
                    <p>
                      {dic.t('Cakery-Sub Total')}: {item?.subTotal}
                    </p>
                    <p>
                      {dic.t('Cakery-Delivery Charges')} : {item?.deliveryCharge}
                    </p>
                    <p>TotalPrice : {item?.totalPrice}</p>
                  </div>
                </div>
                {/* <div className="border-solid border-t-2 mx-5"></div> */}
                <div className="overflow-x-auto order-view mx-5 mb-2 h-[15rem] sm:h-[21rem]">
                  <div className="hidden sm:block">
                    <table className="w-full table-auto text-left text-white">
                      <thead className="md:text-sm  text-xs text-gray-700 border-gray-300 uppercase bg-white">
                        <tr className="border-t border-gray-400">
                          <th scope="col" className="px-10 sm:px-6 py-3">
                            {dic.t('Cakery-Product Image')}
                          </th>
                          <th scope="col" className="px-10 sm:px-6 py-3">
                            {dic.t('Cakery-Product Name')}
                          </th>
                          <th scope="col" className="px-10 sm:px-6 py-3">
                            {dic.t('Cakery-Quantity')}
                          </th>
                          <th scope="col" className="px-10 sm:px-6 py-3">
                            {dic.t('Cakery-Total Price')}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-700 border-gray-300">
                        {item.items.map((serviceitem, key) => {
                          return (
                            <tr
                              className="border-t border-gray-300  lg:text-base sm:text-md text-sm"
                              key={key}
                            >
                              <td scope="row" className="sm:px-6 py-4">
                                <img
                                  src={serviceitem?.itemImageUrl}
                                  className="object-cover h-28 sm:w-44 w-60"
                                />
                              </td>
                              <td className="px-6 text-gray-700 py-4">
                                {serviceitem?.itemName} <br /> {serviceitem?.itemDescription} <br />
                                {serviceitem?.addOns?.Price !== undefined && (
                                  <>
                                    {serviceitem?.addOns?.addonName +
                                      ' ' +
                                      serviceitem?.addOns?.Price +
                                      ' ' +
                                      serviceitem?.addOns?.Currency}
                                    <br />
                                  </>
                                )}
                                &nbsp;
                                {serviceitem?.value}
                              </td>
                              <td className="px-14 sm:px-6 py-4">{serviceitem?.quantity}</td>
                              <td className="px-10 sm:px-6 py-4">{serviceitem?.totalPrice}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* Small Device Started */}
                  <div className="small-device block sm:hidden">
                    <div className="">
                      <div className="flex h-full flex-col bg-white shadow-xl">
                        <div className="flex-1 overflow-y-auto sm:px-6">
                          <div className="mt-1">
                            <div className="flow-root">
                              <ul role="list" className="divide-y divide-gray-200">
                                {item.items.map((serviceitem, keys) => {
                                  const quantity = serviceitem.quantity;
                                  const price = serviceitem?.perItemPrice;
                                  const message = serviceitem?.itemMessage;
                                  const type = serviceitem && serviceitem.cakeType;
                                  var signatureBox = [];
                                  var macaroonBox = [];
                                  if (type === 'REGULAR') {
                                  } else if (type === 'SIGNATUREBOX') {
                                    var Temp = serviceitem?.signatureItems.replace(']', '');
                                    Temp = Temp.replace('[', '');
                                    signatureBox = Temp.split(' ');
                                  } else if (type === 'MACAROONBOX') {
                                    macaroonBox = JSON.parse(serviceitem.macaroonItems);
                                  }
                                  return (
                                    <li className="flex py-2" key={keys}>
                                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                        <img
                                          src={serviceitem.itemImageUrl}
                                          alt="Product Image"
                                          className="h-full w-full object-cover object-center"
                                        />
                                      </div>

                                      <div className="ml-4 flex flex-1 flex-col">
                                        <div>
                                          <div className="flex justify-between text-sm font-medium">
                                            <p className="text-base">
                                              <a href="javascript:;" className="text-black">
                                                {serviceitem.itemName}
                                              </a>
                                            </p>
                                          </div>
                                          <p className="mt-1 text-sm text-black">
                                            {serviceitem.itemDescription}
                                            <br />
                                            <span className="text-black">
                                              AED {parseInt(price * quantity)}
                                            </span>
                                            <span> | Qty {quantity}</span>
                                          </p>
                                          {signatureBox.length > 0 || macaroonBox.length > 0 ? (
                                            type === 'SIGNATUREBOX' ? (
                                              <span
                                                onClick={() => {
                                                  setmodalStatus(!modalStatus);
                                                  setItem(signatureBox);
                                                }}
                                                className="text-black text-xs cursor-pointer underline  underline-offset-1"
                                              >
                                                View Details
                                              </span>
                                            ) : (
                                              <span
                                                onClick={() => {
                                                  setmodalStatus(!modalStatus);
                                                  setItem(macaroonBox);
                                                }}
                                                className="text-black text-xs cursor-pointer underline  underline-offset-1"
                                              >
                                                View Details
                                              </span>
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Small Device End */}
                </div>
              </div>
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OrderView;
