/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import * as helpers from '../../helpers/config';
import { RevertLoginStatus } from '../../actions/login.action';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';
import { useToasts } from 'react-toast-notifications';
import dic from 'i18next';
import ViewDetail from '../ViewDetail';
import { FaRegMinusSquare } from 'react-icons/fa';

const AddToCart = (props) => {
  const proceed_checkout_link = props?.fields['Checkout CTA']?.value?.href;
  // const { cartItems } = props;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const sessionproducts = localStorage.getItem('cartproducts');
  const get_value = JSON.parse(sessionproducts);
  const [cartItems, setCartItems] = useState(get_value || []);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);
  var logoutstatus = useSelector((state) => state.login.logoutstatus);
  const [modalStatus, setmodalStatus] = useState(false);
  const [getItem, setItem] = useState();
  const smallScreenaddonpricelist = [];
  const bigScreenaddonpricelist = [];
  const onCartFunction = (
    items,
    serviceid,
    message,
    price,
    serves,
    productkey,
    servicekey,
    action,
    addonName
  ) => {
    var defaultValue = 1;
    const exist = cartItems?.find(
      (currentcart) => currentcart?.ItemDetails?.id === items?.ItemDetails?.id
    );
    if (exist) {
      const exist_service = exist?.cartaddedservice?.filter(
        (itemlist) => itemlist?.id === serviceid
      );
      const balance_service = exist?.cartaddedservice?.filter(
        (itemlist) => itemlist?.id !== serviceid
      );
      if (exist_service && exist_service?.length > 0) {
        var currentdata = [...cartItems];
        const services = currentdata[productkey]?.cartaddedservice;

        if (action === 'removeAddon') {
          services[servicekey] = {
            ...services[servicekey],
            addon: exist_service[0].addon.filter((addonFil) => addonFil.addonName !== addonName),
          };
          currentdata[productkey] = {
            ...currentdata[productkey],
            cartaddedservice: services,
          };
        } else if (action === 'plus') {
          if (exist_service[0].quantity.value < 50) {
            services[servicekey] = {
              ...services[servicekey],
              quantity: { value: exist_service[0].quantity.value + defaultValue },
            };
            currentdata[productkey] = {
              ...currentdata[productkey],
              cartaddedservice: services,
            };
          } else {
            addToast('Maximum quantity we are allowed in 50', { appearance: 'error' });
          }
        } else {
          const quantity = parseInt(exist_service[0].quantity.value) - parseInt(defaultValue);
          services[servicekey] = {
            ...services[servicekey],
            quantity: { value: exist_service[0].quantity.value - defaultValue },
          };
          currentdata[productkey] = {
            ...currentdata[productkey],
            cartaddedservice: services,
          };
          if (quantity === 0) {
            services.splice(servicekey, 1);
            const delete_zero_item = currentdata.filter(
              (item, keyitem) => item.cartaddedservice.filter((x) => x.length !== 0).length !== 0
            );
            currentdata = delete_zero_item;
          }
        }
        setCartItems(currentdata);
        dispatch(onCartAction(currentdata));
      }
    }
  };

  useEffect(() => {
    if (existcartstatus === true) {
      setCartItems(existcartdata);
      dispatch(onCartAction(existcartdata));
    }
  }, [existcartdata]);

  useEffect(() => {
    localStorage.setItem('cartproducts', JSON.stringify(cartItems));
    if (logoutstatus === true) {
      dispatch(RevertLoginStatus());
      localStorage.setItem('cartproducts', JSON.stringify([]));
      setCartItems([]);
    }
  });
  useEffect(() => {
    window !== 'undefined' && window.scroll(0, 500);
  }, []);
  return (
    <div
      className={`container-fluid pb-10 bg-black ${cartItems && cartItems.length > 1 && 'pb-10'} `}
    >
      <div className="lg:flex lg:flex-row ">
        {cartItems && cartItems.length > 0 ? (
          <>
            <div className="lg:basis-3/4 lg:px-10 pb-10 px-4 pt-8 lg:pt-0 lg:pl-[4rem] border-r-2 border-white-500 hidden sm:block">
              <div className="overflow-x-auto sm:rounded-lg">
                <table className="w-full  text-left text-white">
                  <thead className="md:text-sm text-xs text-white-700 uppercase bg-black">
                    <tr>
                      <th scope="col" className="px-12 py-3">
                        {dic.t('Cakery-Product Image')}
                      </th>
                      <th scope="col" className="px-14 sm:px-6 py-3">
                        {dic.t('Cakery-Product Name')}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        {dic.t('Cakery-Quantity')}
                      </th>
                      <th scope="col" className="px-6 sm:px-0 py-3">
                        {dic.t('Cakery-Total Price')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item, keys) => {
                      const title = item?.ItemDetails?.title;
                      const images = item?.ItemDetails?.image;
                      const productkey = keys;
                      return item.cartaddedservice.map((serviceItem, getkey) => {
                        console.log(serviceItem, 'ServiceItem');
                        const serviceid = serviceItem?.id;
                        const message = serviceItem?.message?.value;
                        const price = serviceItem?.price?.value;
                        const serves = serviceItem?.serves?.value;
                        const quantity = serviceItem?.quantity?.value;
                        const servicekey = getkey;
                        const addon = serviceItem && serviceItem.addon;
                        bigScreenaddonpricelist.push(
                          serviceItem?.addon?.Price === undefined ? 0 : serviceItem?.addon?.Price
                        );
                        return (
                          <tr className="border-t  lg:text-base sm:text-md text-sm" key={serviceid}>
                            <td scope="row" className="px-6 py-4">
                              <img src={images} className="object-cover h-28 w-44" />
                            </td>
                            <td className="px-6 py-4">
                              <ul
                                className={`cartItem  pb-2 mb-2 ${addon.length ? 'border-b' : ''}`}
                              >
                                <li className="flex justify-between">
                                  <div className="item">
                                    {title} <br />
                                    {serves} <br />
                                  </div>
                                  <div className="price">
                                    {helpers.currency}&nbsp;{price} <br />
                                  </div>
                                </li>
                              </ul>
                              <ul className="addons">
                                {addon.length ? (
                                  <h2 className="text-md font-semibold">Addons</h2>
                                ) : (
                                  ''
                                )}
                                {addon &&
                                  addon.map((val, i) => (
                                    <li className="addonList-item flex justify-between" key={i}>
                                      <div className="addon flex items-center">
                                        <FaRegMinusSquare
                                          className="mr-2 cursor-pointer"
                                          style={{ color: '#ed5e68' }}
                                          onClick={() =>
                                            onCartFunction(
                                              item,
                                              serviceid,
                                              message,
                                              price,
                                              serves,
                                              productkey,
                                              servicekey,
                                              'removeAddon',
                                              val.addonName
                                            )
                                          }
                                        />{' '}
                                        {val?.Qty} x {val.addonName}
                                      </div>
                                      <div className="price ml-2">
                                        {val.Currency} {val.Price}
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                              {serviceItem?.addon && serviceItem?.addon?.Price !== undefined && (
                                <>{serviceItem?.addon?.addonName}</>
                              )}
                              <br />
                              {serviceItem?.addon && serviceItem?.addon?.Price !== undefined && (
                                <>{helpers.currency}&nbsp;</>
                              )}
                              {serviceItem?.addon && serviceItem?.addon?.Price !== undefined && (
                                <>{serviceItem?.addon?.Price}</>
                              )}
                              <br />
                              {message ? `✉️ ${message}` : ''}
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex flex-row addtocartbox bg-gray-700 w-[5rem] py-0.5 rounded-md">
                                <div
                                  className="px-1 relative top-1	cursor-pointer text-center w-[29px]"
                                  onClick={() =>
                                    onCartFunction(
                                      item,
                                      serviceid,
                                      message,
                                      price,
                                      serves,
                                      productkey,
                                      servicekey,
                                      'minus',
                                      ''
                                    )
                                  }
                                >
                                  <Decrement />
                                </div>
                                <div className=" w-[24px] text-white text-center text-lg">
                                  {quantity}
                                </div>
                                <div
                                  className=" w-[26px] relative top-[0.2em] cursor-pointer"
                                  onClick={() =>
                                    onCartFunction(
                                      item,
                                      serviceid,
                                      message,
                                      price,
                                      serves,
                                      productkey,
                                      servicekey,
                                      'plus',
                                      ''
                                    )
                                  }
                                >
                                  <Increment />
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              {parseInt(price * quantity) +
                                (addon.length > 0
                                  ? addon.reduce(
                                      (total, addon) => total + (addon.Price * addon.Qty || 0),
                                      0
                                    )
                                  : 0)}
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Small Device Started */}
            <div className="small-device block sm:hidden">
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-black shadow-xl">
                  <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                    <div className="mt-8">
                      <div className="flow-root">
                        <ul role="list" className="-my-6 divide-y divide-gray-200">
                          {cartItems.map((item, keys) => {
                            const title = item?.ItemDetails?.title;
                            const images = item?.ItemDetails?.image;
                            const productkey = keys;
                            return item.cartaddedservice.map((serviceItem, getkey) => {
                              const serviceid = serviceItem?.id;
                              const message = serviceItem?.message?.value;
                              const price = serviceItem?.price?.value;
                              const serves = serviceItem?.serves?.value;
                              const quantity = serviceItem?.quantity?.value;
                              const servicekey = getkey;
                              const addon = serviceItem?.addon;
                              smallScreenaddonpricelist.push(
                                addon.length > 0
                                  ? addon.reduce(
                                      (total, addon) => total + (addon.Price * addon.Qty || 0),
                                      0
                                    )
                                  : 0
                              );
                              return (
                                <li className="flex py-6">
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <img
                                      src={images}
                                      alt="Product Image"
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div className="flex flex-2 justify-between text-sm font-medium">
                                      <p className="text-base">
                                        <a href="javascript:;" className="text-white">
                                          {title}
                                        </a>
                                      </p>
                                    </div>
                                    <p className="mt-1 text-sm text-white">
                                      {serves}
                                      <br />
                                      <span className="text-white">
                                        {helpers.currency}&nbsp;{price}
                                      </span>
                                      <span> | Qty {quantity}</span>
                                      <br />

                                      {serviceItem?.addon &&
                                        serviceItem?.addon?.Price !== undefined && (
                                          <>
                                            {serviceItem?.addon?.addonName}
                                            <br />
                                          </>
                                        )}
                                      {serviceItem?.addon &&
                                        serviceItem?.addon?.Price !== undefined && (
                                          <>{helpers.currency}&nbsp;</>
                                        )}
                                      {serviceItem?.addon &&
                                        serviceItem?.addon?.Price !== undefined && (
                                          <>{serviceItem?.addon?.Price}</>
                                        )}
                                      {message !== undefined ? (
                                        <>
                                          <br /> {message}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </p>
                                    <ul className="addons text-white text-sm">
                                      {addon.length ? (
                                        <h2 className="text-md font-semibold">Addons</h2>
                                      ) : (
                                        ''
                                      )}
                                      {addon &&
                                        addon.map((val, i) => (
                                          <li
                                            className="addonList-item flex justify-between"
                                            key={i}
                                          >
                                            <div className="addon">{val.addonName}</div>
                                            <div className="price ml-2">
                                              {val.Currency} {val.Price}
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                    <div className="flex my-2">
                                      <div className="flex flex-row mt-2 addtocartbox bg-gray-700 w-[5rem] py-0.5 rounded-md">
                                        <div
                                          className="px-1 relative top-1	cursor-pointer text-center w-[29px]"
                                          onClick={() =>
                                            onCartFunction(
                                              item,
                                              serviceid,
                                              message,
                                              price,
                                              serves,
                                              productkey,
                                              servicekey,
                                              'minus',
                                              ''
                                            )
                                          }
                                        >
                                          <Decrement />
                                        </div>
                                        <div className=" w-[24px] text-white text-center text-lg">
                                          {quantity}
                                        </div>
                                        <div
                                          className=" w-[26px] relative top-[0.2em] cursor-pointer"
                                          onClick={() =>
                                            onCartFunction(
                                              item,
                                              serviceid,
                                              message,
                                              price,
                                              serves,
                                              productkey,
                                              servicekey,
                                              'plus',
                                              ''
                                            )
                                          }
                                        >
                                          <Increment />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            });
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Small Device End */}
            <div className="lg:basis-1/5  basis-9/12 pt-8 lg:pt-0 px-10 lg:px-0 text-white lg:pl-10">
              <h5 className="font-bold pt-2 pb-3 uppercase xl:text-lg sm:text-md text-md">
                {dic.t('Cakery-Order Summary')}
              </h5>
              <p className="text-white text-xs">{dic.t('Cakery-Inclusive of Tax')}</p>
              <table className="w-full text-white">
                <tbody>
                  <tr className="text-left lg:text-base sm:text-md text-sm">
                    <th className="text-left font-medium py-2">{dic.t('Cakery-Sub Total')}</th>
                    <td className="text-center">
                      {parseInt(helpers.CartSubtotalSum(cartItems)) +
                        parseInt(helpers.ArraySum(smallScreenaddonpricelist))}
                    </td>
                  </tr>
                  <tr className="text-left">
                    <td colSpan="2" className="py-5">
                      <a href={proceed_checkout_link}>
                        <button className="w-full text-white bg-gray-700 font-bold text-base rounded-lg text-md px-5 py-2.5 mr-2 mb-2 tracking-wider	">
                          {dic.t('Cakery-Proceed to Checkout')}
                        </button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="basis-1/1 px-10 mx-auto  border-white-500 my-10 py-20">
            <p className="text-lg text-white py-5 text-center sm:pl-3">
              {' '}
              {dic.t('Cakery-No Cart Items')}
            </p>
            <div className="icons mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mb-6 text-gray-400 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </div>
          </div>
        )}
      </div>
      {modalStatus === true && (
        <ViewDetail modalStatus={modalStatus} setmodalStatus={setmodalStatus} getItem={getItem} />
      )}
    </div>
    // <Admin />
  );
};

export default AddToCart;
