import React, { Fragment, useState } from 'react';
import { HiOutlineKey } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { emailPattern, letters, passwordPattern, phonepattern } from '../../helpers/Patterns';
import { onUserResetPassword } from '../../actions/resetpassword.action';
import { useSelector, useDispatch } from 'react-redux';
import * as helpers from '../../helpers/config';
import dic from 'i18next';

function SecurityInformation() {
  const dispatch = useDispatch();
  const [status, SetStatus] = useState(false);
  const loggeddetails = helpers.LoggedUserDetails();

  // taking logged user token from storage
  const usertoken = loggeddetails?.token;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const { status: message , loading, statusCode , loadingstatus } = useSelector((state) => state.resetpassword);
  const onSubmits = (data) => {
    const SubmitData = {
      email: loggeddetails?.userEmailId,
      password: data.password,
    };
    dispatch(onUserResetPassword(SubmitData,usertoken));
    SetStatus(true);
    reset();
  };
  return (
    <Fragment>
      <div className="pt-12">
        <form className="pt-3 px-1" onSubmit={handleSubmit(onSubmits)}>
          {/* password section  */}
          <h3 className="text-lg inline  font-medium leading-6 lg:px-5 mb-4 text-white">
            <HiOutlineKey className="inline" />
            &nbsp;{dic.t('Cakery-Security Information')}
            <span className="text-xs inline pt-1 px-2">
              (We are recommened strong passwords,..like JohnDoe@1991)
            </span>
          </h3>
          {loading === true ? (
            <p className="text-white py-2">Loading...</p>
          ) : (
            <p className={`${ statusCode === 400 ? 'text-red-400 py-2 pl-5' :  'text-green-600 py-2 pl-5' }`}>{message}</p>
          )}
          <div className="grid grid-cols-12 ">
            <div className="col-span-12 lg:col-span-6 py-3 lg:px-5">
              <label htmlFor="password" className="block py-1 text-sm font-medium text-white">
                Password
              </label>
              <input
                type="text"
                className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder={dic.t('Cakery-Password')}
                name="user_password"
                id="user_password"
                {...register('password', {
                  required: 'Password is Required',
                  pattern: {
                    value: passwordPattern,
                    message: 'Please enter a valid password',
                  },
                })}
                autoComplete="off"
              />
              {errors.password && errors.password.message && (
                <span className=" mt-2 text-red-400">{errors.password.message}</span>
              )}
            </div>
            <div className="col-span-12 lg:col-span-6 py-3 lg:px-5">
              <label htmlFor="mobile-number" className="block py-1 text-sm font-medium text-white">
                Confirm Password
              </label>
              <input
                type="text"
                name="user_confirm_password"
                id="user_confirm_password"
                className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder={dic.t('Cakery-Confirm Password')}
                {...register('confirmpassword', {
                  required: 'Confim Password is Required',
                  validate: () => {
                    if (watch('password') !== watch('confirmpassword')) {
                      return 'Your passwords do no match';
                    }
                  },
                })}
                autoComplete="off"
              />
              {errors.confirmpassword && errors.confirmpassword.message && (
                <span className="mt-2 text-red-400">{errors.confirmpassword.message}</span>
              )}
            </div>
          </div>
          <div className="pt-10 lg:px-4 bg-transparent text-right">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-10 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-700"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default SecurityInformation;
