/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import * as helpers from '../../helpers/config';
import ProductService from '../ProductService/index';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsArrowRightCircleFill, BsInfoCircle } from 'react-icons/bs';
import LeftArrow from '../CustomArrow/LeftArrow';
import RightArrow from '../CustomArrow/RightArrow';
import SorryCart from '../SorryCart/index';
import { Link } from 'react-router-dom';
import ViewMore from '../ViewMore';
import { RevertLoginStatus } from '../../actions/login.action';
import { BsSearch, BsFilter } from 'react-icons/bs';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';
import dic from 'i18next';
import CantAddCart from '../CantAddCart';

function RegularCakes(props) {
  const all_cakes = props?.fields?.Cakes;
  const dispatch = useDispatch();
  const productitems = props?.fields?.Cakes;
  const [serviceUpdate, setServiceUpdate] = useState(false);
  const [productAddons, setproductAddons] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [getSorryStatus, setSorryModal] = useState(false);
  const [getViewMore, setViewMore] = useState(false);
  const [getDescription, SetDescription] = useState(false);
  const cartstatus = useSelector((state) => state.usercart.user_cart_status);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);

  const sessionproducts = localStorage.getItem('cartproducts');
  const get_value = JSON.parse(sessionproducts);
  const [cartItems, setCartItems] = useState(get_value || []);
  const [CantAddCartStatus, setCantAddCartStatusUpdate] = useState(false);
  var logoutstatus = useSelector((state) => state.login.logoutstatus);
  let isfest =
    cartItems.filter((item) => item?.cartaddedservice[0]?.type === 'FESTIVAL').length > 0
      ? true
      : false;
  let isSpecialCake =
      cartItems.filter((item) => item?.cartaddedservice[0]?.specialcake === true).length > 0
        ? true
        : false;
  const title = props?.productitems?.name;
  const productshow = props?.fields?.Cakes;
  const [cakeList, setCakeList] = useState(productshow);
  const [tempList, setTempList] = useState(productshow);
  const searchHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    if (e.target.value === '') {
      return setTempList(cakeList);
    }
    var filteredData = cakeList.filter((el) => {
      return el.fields['Cake Name'].fields?.Title?.value.toLowerCase().includes(lowerCase);
    });
    setTempList(filteredData);
  };
  const filterHandler = (e) => {
    document.getElementById('searchholder').value = '';
    var filtervalue = e.target.value;
    if (filtervalue === 'hightolow') {
      const Updateprice = cakeList.filter((items, key) => {
        return items.fields.Services.sort((a, b) =>
          a.fields.Price.value > b.fields.Price.value ? 1 : -1
        );
      });
      const updateHightoLow = Updateprice.sort((a, b) => {
        return a.fields.Services[0].fields.Price.value > b.fields.Services[0].fields.Price.value
          ? -1
          : 1;
      });
      setTempList(updateHightoLow);
    } else if (filtervalue === 'lowtohigh') {
      const Updateprice = cakeList.filter((items, key) => {
        return items.fields.Services.sort((a, b) =>
          a.fields.Price.value > b.fields.Price.value ? 1 : -1
        );
      });
      const updateLowtoHigh = Updateprice.sort((a, b) => {
        return a.fields.Services[0].fields.Price.value > b.fields.Services[0].fields.Price.value
          ? 1
          : -1;
      });
      setTempList(updateLowtoHigh);
    } else {
      setTempList(cakeList);
    }
  };
  const modalUpdateStatus = (items, addon) => {
    setServiceUpdate(items);
    setproductAddons(addon);
    closeModal();
  };
  const closeModal = () => {
    setServiceModal(!serviceModal);
  };
  const SorryCartStatusUpdate = () => {
    setSorryModal(!getSorryStatus);
  };
  const ViewMoreUpdate = (description, images, cakename) => {
    const detail = { description: description, images: images, cakename: cakename };
    SetDescription(detail);
    setViewMore(!getViewMore);
  };
  const onCartFunction = (items, serviceid, message, price, serves, addons, action) => {
    if (serviceid === '') {
      return false;
    }
    setServiceModal(!serviceModal);
    var defaultValue = 1;
    // Product id equal checking
    const exist = cartItems.find((currentcart) => currentcart?.ItemDetails?.id === items?.id);
    // if product id match it is with inner service id checking
    if (exist) {
      const exist_service = exist?.cartaddedservice?.filter(
        (itemlist) => itemlist?.id === serviceid
      );
      const balance_service = exist?.cartaddedservice.filter(
        (itemlist) => itemlist?.id !== serviceid
      );
      if (exist_service && exist_service?.length > 0) {
        // Exist service price quantity added here
        const previouscart = cartItems?.filter(
          (currentcart) => currentcart?.ItemDetails?.id !== items?.id
        );
        const itemImage = items?.fields['Cake Images'][0]?.fields?.Image?.value?.src;
        const itemTitle = items?.fields['Cake Name']?.fields?.Title?.value;
        const specialCake = items?.fields['Special Cake']?.value;
        const ItemDetails = {
          id: items?.id,
          image: itemImage,
          title: itemTitle,
        };
        var updateresponse = [
          ...previouscart,
          {
            ItemDetails: ItemDetails,
            cartaddedservice: [
              ...balance_service,
              {
                id: serviceid,
                quantity: { value: exist_service[0]?.quantity?.value + defaultValue },
                message: { value: message },
                price: { value: price },
                serves: { value: serves },
                addon: addons,
                specialcake: specialCake,
                type: 'REGULAR',
              },
            ],
          },
        ];
        setCartItems(updateresponse);
        dispatch(onCartAction(updateresponse));
      } else {
        const itemImage = items?.fields['Cake Images'][0]?.fields?.Image?.value?.src;
        const itemTitle = items?.fields['Cake Name']?.fields?.Title?.value;
        const specialCake = items?.fields['Special Cake']?.value;
        const ItemDetails = {
          id: items?.id,
          image: itemImage,
          title: itemTitle,
        };
        // Exist service price quantity intially added here
        const previouscart = cartItems?.filter(
          (currentcart) => currentcart?.ItemDetails?.id !== items?.id
        );
        var updateresponse = [
          ...previouscart,
          {
            ItemDetails: ItemDetails,
            cartaddedservice: [
              ...exist.cartaddedservice,
              {
                id: serviceid,
                quantity: { value: defaultValue },
                message: { value: message },
                price: { value: price },
                serves: { value: serves },
                addon: addons,
                specialcake: specialCake,
                type: 'REGULAR',
              },
            ],
          },
        ];
        setCartItems(updateresponse);
        dispatch(onCartAction(updateresponse));
      }
    } else {
      const itemImage = items?.fields['Cake Images'][0]?.fields?.Image?.value?.src;
      const itemTitle = items?.fields['Cake Name']?.fields?.Title?.value;
      const specialCake = items?.fields['Special Cake']?.value;
      const ItemDetails = {
        id: items?.id,
        image: itemImage,
        title: itemTitle,
      };
      // intial product add to cart
      var updateresponse = [
        ...cartItems,
        {
          ItemDetails: ItemDetails,
          cartaddedservice: [
            {
              id: serviceid,
              quantity: { value: defaultValue },
              message: { value: message },
              price: { value: price },
              serves: { value: serves },
              addon: addons,
              specialcake: specialCake,
              type: 'REGULAR',
            },
          ],
        },
      ];
      setCartItems(updateresponse);
      dispatch(onCartAction(updateresponse));
    }
  };
  useEffect(() => {
    if (existcartstatus === true) {
      setCartItems(existcartdata);
    }
  }, [existcartdata]);
  useEffect(() => {
    localStorage.setItem('cartproducts', JSON.stringify(cartItems));
    if (logoutstatus === true) {
      dispatch(RevertLoginStatus());
      localStorage.setItem('cartproducts', JSON.stringify([]));
      setCartItems([]);
    }
  });
  return (
    <div>
      <div className="bg-black text-white px-10 lg:pt-10 -z-10">
        <div className="container-fluid sm:px-6 md:m-0">
          <div className="grid  md:grid-cols-2 sm:grid-cols-1  px-6 py-10 lg:py-0">
            <div className="py-4 relative">
              <BsSearch
                size={14}
                border-radius={5}
                className="inline absolute top-[30px] left-[11px]"
              />
              <input
                type="text"
                id="searchholder"
                onChange={searchHandler}
                className="bg-black border-2 border-white-300 pl-8  placeholder:text-white tracking-wide text-sm rounded-lg block w-full md:w-64 lg:w-96 outline-none	 p-2.5 "
                placeholder={dic.t('Cakery-Search Cakes')}
                required
              />
            </div>
            <div className="text-right py-4 relative">
              <div className="desk-view">
                <BsFilter
                  size={19}
                  className="inline absolute left-[9px] sm:right-[13rem] sm:left-[unset]  md:right-[14rem] lg:right-[22rem] top-[26px]"
                />
              </div>
              <select
                onChange={filterHandler}
                className="bg-black border-2 ml-auto border-white-300 pl-8 placeholder:text-white tracking-wide text-sm rounded-lg block w-full md:w-64 lg:w-96 outline-none	 p-2.5"
              >
                <option value={'reset'}>{dic.t('Cakery-Select a Filter')}</option>
                <option value="hightolow">{dic.t('Cakery-High to Low')}</option>
                <option value="lowtohigh">{dic.t('Cakery-Low to High')}</option>
              </select>
            </div>
          </div>
          <div
            className={`${
              tempList && tempList.length > 0
                ? 'grid  2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1'
                : 'grid  grid-cols-1'
            }`}
          >
            {tempList && tempList.length > 0 ? (
              tempList.map((items, key) => {
                const exists = cartItems.filter((currentcart) => {
                  return currentcart?.ItemDetails?.id === items?.id;
                });
                var counts = 0;
                  exists.map((itemcheck) => {
                    itemcheck.cartaddedservice.map((serviceitem) => {
                      return (counts = counts + serviceitem?.quantity?.value);
                    });
                  });
                const pricelist = items.fields.Services;
                const findlowestprice = Math.min(
                  ...pricelist.map((item) => item.fields.Price.value)
                );
                const description = items?.fields?.Description?.value;
                const images = items?.fields['Cake Images'][0]?.fields?.Image?.value?.src;
                const allimages = items?.fields?.['Cake Images'];
                const addons = items?.fields?.Addons;
                const cakename = items?.fields['Cake Name'].fields?.Title?.value;
                const specialCake = items?.fields['Special Cake']?.value;

                return (
                  <div className="flex justify-center md:px-6 py-8 px-0" key={key}>
                    <div className="flex justify-center">
                      <div className="max-w-full bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <img
                          className="w-screen object-cover h-60   xl:h-64 2xl:h-72 rounded-t-lg"
                          src={images}
                          alt="product image"
                        />
                        <div className="px-5 py-4 border-t-2 border-gray-500">
                          <h5 className="sm:text-xl text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
                            {cakename}
                          </h5>

                          <div className="py-2">
                            <div className="grid grid-cols-2">
                              <div className="flex relative cursor-pointer justify-start">
                                <div className="sm:text-xl relative top-[6px] text-lg font-bold text-gray-900 dark:text-white">
                                  {helpers.currency} {findlowestprice}
                                  <div className="absolute top-[4px] left-[77px] sm:left-[90px]">
                                    <BsInfoCircle
                                      onClick={() =>
                                        ViewMoreUpdate(description, allimages, cakename)
                                      }
                                      className="text-gray-700  width-[20px] height-[16px]"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex relative	cursor-pointer justify-end">
                                {exists &&
                                exists[0] &&
                                exists[0].cartaddedservice &&
                                exists[0].cartaddedservice.length > 0 ? (
                                  <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md">
                                    <div
                                      className="flex relative top-1	cursor-pointer"
                                      onClick={SorryCartStatusUpdate}
                                    >
                                      <Decrement />
                                    </div>
                                    <div className="flex text-white px-1 justify-center text-lg">
                                      {counts}
                                    </div>
                                    <div
                                      className="flex relative top-[0.2em] cursor-pointer"
                                      onClick={() => {
                                        (isfest)
                                          ? setCantAddCartStatusUpdate(true)
                                          : modalUpdateStatus(items, addons);
                                      }}
                                    >
                                      <Increment />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="grid grid-cols-1 justify-items-end h-[36px]">
                                    <Link
                                      onClick={() => {
                                        (isfest || ((cartItems.length > 0) && (isSpecialCake !== specialCake)))
                                          ? setCantAddCartStatusUpdate(true)
                                          : modalUpdateStatus(items, addons);
                                      }}
                                      className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs lg:text-sm px-2 py-2 sm:px-2 lg:px-5  sm:py-2.5 text-center"
                                    >
                                        {dic.t('Cakery-Select Size')}
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="grid grid-cols-1  border-white-500 py-20 px-16">
                <p className="text-xl font-bold text-white py-5 m-auto sm:pl-3">
                  {dic.t('Cakery-Product Not Found')}
                </p>
                <div className="icons m-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mb-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
              </div>
            )}
            {serviceModal === true && (
              <>
                <ProductService
                  modalStatus={serviceModal}
                  modalUpdateStatus={modalUpdateStatus}
                  productAddons={productAddons}
                  servicelist={serviceUpdate}
                  onCartFunction={onCartFunction}
                  closeModal={closeModal}
                />
              </>
            )}
            {getSorryStatus === true && (
              <SorryCart
                SorryCartStatusUpdate={SorryCartStatusUpdate}
                checkModal={getSorryStatus}
              />
            )}
            {getViewMore === true && (
              <ViewMore
                getDescription={getDescription}
                ViewMoreUpdate={ViewMoreUpdate}
                getViewMore={getViewMore}
              />
            )}
            {CantAddCartStatus && (
              <CantAddCart
                checkModal={CantAddCartStatus}
                CantAddCartStatusUpdate={setCantAddCartStatusUpdate}
              />
            )}
            <div className="py-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegularCakes;
