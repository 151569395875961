import React, { Fragment, useState } from 'react';
import Main from './Main';
import LeftMenu from '../LeftMenu';
import ProgressBar from './ProgressBar';
import NavigationBar from './NavigationBar';

const CallCenter = (props) => {
  const [tab, setTabStatus] = useState(1);
  const [selectedRadio, setSelectedRadio] = useState(1);
  const updateRadio = (current) => {
    setSelectedRadio(current);
  };
  const updateTab = (current) => {
    setTabStatus(current);
  };
  const codes = props?.fields?.['Country Codes'];
  const cakes = props?.fields?.Cakes;
  const FestivalCakes = props?.fields?.['Festive Cakes'];
  const FestivalDeliveryCharges = props?.fields?.['Festive Delivery Charges'];
  const FestiveDeliveryTime = props?.fields?.['Festive Delivery Time'];
  const PickupTime = props?.fields?.['Pickup Time'];
  const deliverycharges = props?.fields?.['Delivery Charges'];
  const deliveryTime = props?.fields?.['Delivery Time'];
  const leftmenu = props?.fields?.['Left Menus'];
  const supplies = props?.fields?.Supplies;
  const pastries = props?.fields?.['French Pastries'];
  const customize = props?.fields?.['Customize Cakes'];
  return (
    <Fragment>
      <div className="flex w-full bg-[#1d1d1d]">
        <LeftMenu selectOption={3} leftmenu={leftmenu} />
        <div className="w-[inherit] mt-12 bg-white">
          {/* <ProgressBar tab={tab} updateTab={updateTab} /> */}
          <Main
            tab={tab}
            updateTab={updateTab}
            cakes={cakes}
            codes={codes}
            deliverycharges={deliverycharges}
            FestivalCakes={FestivalCakes}
            FestivalDeliveryCharges={FestivalDeliveryCharges}
            FestiveDeliveryTime={FestiveDeliveryTime}
            deliveryTime={deliveryTime}
            PickupTime={PickupTime}
            supplies={supplies}
            pastries={pastries}
            customize={customize}
          />
          {/* <NavigationBar tab={tab} updateTab={updateTab} /> */}
        </div>
      </div>
    </Fragment>
  );
};

export default CallCenter;
