import {
  SET_CART_DATA,
  REMOVE_CART_DATA,
  SET_CUSTOMER_DATA,
  SET_CUSTOMER_DELIVERY_DATA,
  EMPTY_CART,
  UPDATE_CUSTOMER_DATA_USER,
  REMOVE_ADDON_FROM_CART,
  UPDATE_CART_DATA,
  REMOVE_FESTIVE_ADDON_FROM_CART,
} from '../../actions/admin/CallCenter.action';
import * as Helpers from '../../helpers/AdminHelpers';

const initialState = {
  cartItems: [],
  customerInfo: {},
  customerDeliveryInfo: {
    dpdate: Helpers.getNextday(),
    dptime: '09:00am - 11:00am',
    deliverytype: 'DELIVERY',
    deliveryAddress: {},
  },
};

export const callCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPTY_CART:
      return {
        ...state,
        cartItems: [],
      };
    case SET_CART_DATA:
      if (state.cartItems.length > 0) {
        let cartItems = [...state.cartItems];
        let foundItem = cartItems.filter((item) => {
          const {
            cakeFlavour,
            cakeServings,
            cakeBerries,
            cakeMessage,
            cakeRemarks,
            cakeAddonName,
            cakeSuppliesName,
            cakeSuppliesPrice,
            cakeType,
            cakeFestiveAddons,
          } = item;
          if (
            cakeFlavour === action.payload.cakeFlavour &&
            cakeServings === action.payload.cakeServings &&
            cakeBerries === action.payload.cakeBerries &&
            cakeAddonName === action.payload.cakeAddonName &&
            // cakeMessage === action.payload.cakeMessage &&
            // cakeMessage !== action.payload.cakeMessage &&
            cakeRemarks === action.payload.cakeRemarks &&
            cakeSuppliesName === action.payload.cakeSuppliesName &&
            cakeSuppliesPrice === action.payload.cakeSuppliesPrice &&
            cakeFestiveAddons === action.payload.cakeFestiveAddons
          ) {
            console.log(
              item.cakeSuppliesPrice,
              item.cakeSuppliesName,
              cakeFestiveAddons,
              'cakeSuppliesPrice'
            );
            let tempCakeSuppliesPrice = item.cakeSuppliesPrice.reduce((acc, curr) => acc + curr, 0);
            let tempCakeFestiveAddonsPrice = item.cakeFestiveAddons
              ? item.cakeFestiveAddons.reduce((acc, curr) => acc + Number(curr.value * curr.Qty), 0)
              : 0;

            item.cakeQuantity = action.payload.cakeQuantity;
            item.totalPrice =
              item.cakeQuantity * item.cakePerItemPrice +
              Number(tempCakeSuppliesPrice) +
              Number(tempCakeFestiveAddonsPrice);
            return true;
          } else if (
            cakeFlavour === action.payload.cakeFlavour &&
            cakeType === action.payload.cakeType &&
            cakeMessage === action.payload.cakeMessage &&
            cakeServings === action.payload.cakeServings
          ) {
            console.log(item.cakeSuppliesPrice, item.cakeSuppliesName, 'cakeSuppliesPrice1');

            let dummyCakeSuppliesName = item.cakeSuppliesName;
            let dummyCakeSuppliesPrice = item.cakeSuppliesPrice;

            if (action.payload.cakeSuppliesName && action.payload.cakeSuppliesName.length > 0) {
              let selectedIndex = '';
              item.cakeSuppliesName.filter((filItem, index) => {
                if (filItem === action.payload.cakeSuppliesName[0]) {
                  selectedIndex = index;
                  return filItem;
                }
              });
              console.log(item.cakeSuppliesPrice, item.cakeSuppliesName, 'cakeSuppliesPrice2');

              if (selectedIndex !== '') {
                item.cakeSuppliesName.splice(selectedIndex, 1);
                item.cakeSuppliesPrice.splice(selectedIndex, 1);
                dummyCakeSuppliesName = item.cakeSuppliesName;
                dummyCakeSuppliesPrice = item.cakeSuppliesPrice;
              }
            }

            item.cakeServings = action.payload.cakeServings;
            item.cakeBerries = action.payload.cakeBerries;
            item.cakeAddonName = action.payload.cakeAddonName;
            item.cakeMessage = action.payload.cakeMessage;
            item.cakeRemarks = action.payload.cakeRemarks;
            item.cakeSuppliesName = [...dummyCakeSuppliesName, ...action.payload.cakeSuppliesName];
            item.cakeSuppliesPrice = [
              ...dummyCakeSuppliesPrice,
              ...action.payload.cakeSuppliesPrice,
            ];
            item.cakeFestiveAddons = action.payload.cakeFestiveAddons;

            let tempCakeSuppliesPrice = item.cakeSuppliesPrice.reduce((acc, curr) => acc + curr, 0);
            let tempCakeFestiveAddonsPrice = item.cakeFestiveAddons
              ? item.cakeFestiveAddons.reduce((acc, curr) => acc + Number(curr.value * curr.Qty), 0)
              : 0;

            item.totalPrice =
              item.cakeQuantity * item.cakePerItemPrice +
              Number(tempCakeSuppliesPrice) +
              Number(tempCakeFestiveAddonsPrice);

            return true;
          }
        });

        if (foundItem.length > 0) {
          return {
            ...state,
            cartItems: [...cartItems],
          };
        }
      }

      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };

    case REMOVE_CART_DATA:
      if (state.cartItems.length > 0) {
        let cartItems = [...state.cartItems];
        let foundItem = cartItems.filter((item) => {
          const {
            cakeFlavour,
            cakeServings,
            cakeBerries,
            cakeMessage,
            cakeRemarks,
            cakeQuantity,
            cakeAddonName,
            cakeSuppliesName,
            cakeSuppliesPrice,
            cakeType,
          } = item;
          if (
            cakeFlavour !== action.payload.cakeFlavour ||
            cakeServings !== action.payload.cakeServings ||
            // cakeBerries !== action.payload.cakeBerries ||
            // cakeAddonName !== action.payload.cakeAddonName ||
            cakeMessage !== action.payload.cakeMessage ||
            cakeRemarks !== action.payload.cakeRemarks ||
            cakeSuppliesName !== action.payload.cakeSuppliesName ||
            cakeSuppliesPrice !== action.payload.cakeSuppliesPrice
          ) {
            return true;
          } else if (
            cakeFlavour === action.payload.cakeFlavour &&
            cakeServings === action.payload.cakeServings &&
            cakeSuppliesPrice === action.payload.cakeSuppliesPrice &&
            cakeType === action.payload.cakeType
          ) {
            if (cakeQuantity >= 1) {
              let tempCakeSuppliesPrice = item.cakeSuppliesPrice.reduce(
                (acc, curr) => acc + curr,
                0
              );
              let tempCakeFestiveAddonsPrice = item.cakeFestiveAddons
                ? item.cakeFestiveAddons.reduce((acc, curr) => acc + Number(curr.value), 0)
                : 0;

              item.cakeQuantity = action.payload.cakeQuantity;
              item.totalPrice =
                item.cakeQuantity * item.cakePerItemPrice +
                Number(tempCakeSuppliesPrice) +
                Number(tempCakeFestiveAddonsPrice);
              return true;
            } else {
              return false;
            }
          }
        });

        return {
          ...state,
          cartItems: [...foundItem],
        };
      }

      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };

    case SET_CUSTOMER_DATA:
      return {
        ...state,
        customerInfo: action.payload,
      };

    case UPDATE_CUSTOMER_DATA_USER:
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          ...action.payload,
        },
      };

    case SET_CUSTOMER_DELIVERY_DATA:
      return {
        ...state,
        customerDeliveryInfo: {
          ...state.customerDeliveryInfo,
          ...action.payload,
        },
      };

    case REMOVE_ADDON_FROM_CART:
      const { ITEM, index } = action.payload;
      if (state.cartItems.length > 0) {
        let cartItems = [...state.cartItems];
        let foundItem = cartItems.find((item) => {
          const {
            cakeFlavour,
            cakeServings,
            cakeBerries,
            cakeMessage,
            cakeRemarks,
            cakeAddonName,
            cakeSuppliesName,
            cakeSuppliesPrice,
            cakeType,
          } = item;
          if (
            cakeFlavour === ITEM.cakeFlavour &&
            cakeServings === ITEM.cakeServings &&
            // cakeBerries === ITEM.cakeBerries &&
            // cakeAddonName === ITEM.cakeAddonName &&
            cakeMessage === ITEM.cakeMessage &&
            cakeRemarks === ITEM.cakeRemarks
            // &&
            // cakeSuppliesName === ITEM.cakeSuppliesName &&
            // cakeSuppliesPrice === ITEM.cakeSuppliesPrice
          ) {
            ITEM.cakeSuppliesName.forEach((addon, ind) => {
              let selectedIndex = '';
              cakeSuppliesName.filter((filItem, i) => {
                if (filItem === addon && ind === index) {
                  selectedIndex = index;
                  return filItem;
                }
              });
              if (selectedIndex !== '') {
                item.cakeSuppliesName.splice(selectedIndex, 1);
                item.cakeSuppliesPrice.splice(selectedIndex, 1);
              }
            });

            let tempCakeSuppliesPrice = item.cakeSuppliesPrice.reduce((acc, curr) => acc + curr, 0);
            let tempCakeFestiveAddonsPrice = item.cakeFestiveAddons
              ? item.cakeFestiveAddons.reduce((acc, curr) => acc + Number(curr.value * curr.Qty), 0)
              : 0;
            item.totalPrice =
              item.cakeQuantity * item.cakePerItemPrice +
              Number(tempCakeSuppliesPrice) +
              Number(tempCakeFestiveAddonsPrice);

            return true;
          }
        });
        console.log(foundItem, 'FOUND_ITEM');

        if (foundItem) {
          return {
            ...state,
            cartItems: [...cartItems],
          };
        }
      }

      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };

    case UPDATE_CART_DATA:
      state.cartItems.forEach((item, i) => {
        if (i === action.payload.index) {
          item.cakeMessage = action.payload.cakeMessage;
        }
      });
      return { ...state };

    case REMOVE_FESTIVE_ADDON_FROM_CART:
      const { FESTIVEITEM, festiveindex } = action.payload;
      if (state.cartItems.length > 0) {
        let cartItems = [...state.cartItems];
        let foundItem = cartItems.find((item) => {
          const {
            cakeFlavour,
            cakeServings,
            cakeBerries,
            cakeMessage,
            cakeRemarks,
            cakeAddonName,
            cakeSuppliesName,
            cakeSuppliesPrice,
            cakeType,
          } = item;

          if (
            cakeFlavour === FESTIVEITEM.cakeFlavour &&
            cakeServings === FESTIVEITEM.cakeServings &&
            // cakeBerries === FESTIVEITEM.cakeBerries &&
            // cakeAddonName === FESTIVEITEM.cakeAddonName &&
            cakeMessage === FESTIVEITEM.cakeMessage &&
            cakeRemarks === FESTIVEITEM.cakeRemarks
            // &&
            // cakeSuppliesName === FESTIVEITEM.cakeSuppliesName &&
            // cakeSuppliesPrice === FESTIVEITEM.cakeSuppliesPrice
          ) {
            FESTIVEITEM.cakeFestiveAddons.forEach((addon, ind) => {
              let selectedIndex = '';
              // cakeSuppliesName.filter((filItem, i) => {
              if (ind === festiveindex) {
                selectedIndex = festiveindex;
              }
              // });
              if (selectedIndex !== '') {
                item.cakeFestiveAddons.splice(selectedIndex, 1);
              }
            });
            let tempCakeSuppliesPrice = item.cakeSuppliesPrice.reduce((acc, curr) => acc + curr, 0);
            let tempCakeFestiveAddonsPrice = item.cakeFestiveAddons.reduce(
              (acc, curr) => acc + Number(curr.value * curr.Qty),
              0
            );
            item.totalPrice =
              item.cakeQuantity * item.cakePerItemPrice +
              Number(tempCakeSuppliesPrice) +
              Number(tempCakeFestiveAddonsPrice);

            return true;
          }
        });

        if (foundItem) {
          return {
            ...state,
            cartItems: [...cartItems],
          };
        }
      }

      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };

    default:
      return state;
  }
};
