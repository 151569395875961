import { React, useState, useEffect, useRef, useMemo } from 'react';
import { AiOutlineEye, AiOutlineSearch } from 'react-icons/ai';
import { TbShoppingCartX } from 'react-icons/tb';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { IconContext } from 'react-icons';
import { MdOutlineDeliveryDining, MdOutlineDoneOutline, MdPhonelinkRing } from 'react-icons/md';
import { GiCampCookingPot } from 'react-icons/gi';
import { BiCycling } from 'react-icons/bi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';
import { filterStyles } from '../../helpers/Patterns';
import KitchenModal from './Modal';
import ConfirmationModal from './ConfirmationModal';
import moment from 'moment';
import {
  TEMP_SAVE_ORDER,
  getAllOrders,
  GET_ORDER_LOADING,
} from '../../actions/admin/GetAllOrder.action';
import TableLoader from '../Admin/TableLoader';
import {
  filterOrders,
  FILTER_ORDER_LOADING,
  viewSingleOrders,
} from '../../actions/admin/FilterOrder.action';
import { removeError } from '../../actions/admin/RemoveError.action';
import { generateExcelReport } from '../../actions/report.action';
import * as helpers from '../../helpers/config';
import dic from 'i18next';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AdvanceSearchModal from '../KitchenDisplay/AdvanceSerachModal';
import { IoMdCalendar } from 'react-icons/io';
import ViewLoader from './videLoader';

const Main = ({ regionlist }) => {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const registerOptions = {
    // ...
    role: { required: 'Role is required' },
  };
  // fetching orders from redux state
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.allorders);
  const orders = useSelector((state) => state.allorders.orders);
  const tempOrders = useSelector((state) => state.allorders.tempOrders);
  const filterloading = useSelector((state) => state.filterorders.loading);
  const filteredorders = useSelector((state) => state.filterorders.orders);
  const PAGECOUNT = useSelector((state) => state.allorders.pagecount);
  const loggeddetails = helpers.LoggedUserDetails();
  const error = useSelector((state) => state.filterorders.error);
  const usertype = loggeddetails?.accessDetails?.role;
  const userEmailId = loggeddetails?.userEmailId;
  const today = new Date();
  const todayDate = new Date();
  const [date, setDateCurrent] = useState(todayDate.setDate(todayDate.getDate()));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [viewLoaderStatus, setViewLoaderStatus] = useState('');

  // state & function for view & close view more modal
  const [showModal, setShowModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showAdvanceSearchModal, setshowAdvanceSearchModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [confirmModalItem, setConfirmModalItem] = useState();
  const [confirmModalType, setConfirmModalType] = useState('');
  const closeModal = () => {
    setShowModal(false);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };
  const closeAdvanceSearchModal = () => {
    setshowAdvanceSearchModal(false);
  };
  const openAdvanceSearchModal = () => {
    setshowAdvanceSearchModal(true);
  };
  // react pagination states
  const CancelOrderExcluded = orders && orders.filter((item) => item.orderStatus !== 'CANCELLED');
  // const [items, setItems] = useState(CancelOrderExcluded);
  const [items, setItems] = useState(
    tempOrders && tempOrders.length > 0 ? tempOrders : CancelOrderExcluded
  );
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState(false);
  const itemsPerPage = 50;
  const [selectedFilterDrop, setSelectedFilterDrop] = useState(null);
  const [searchValue, setSearchValue] = useState();

  // filter data state
  const [orderStatus, setOrderStatus] = useState(null);
  const [deliveryType, setDeliveryType] = useState(null);
  const [selectData, setSelectData] = useState();
  const [startDeliveryDate, setStartDeliverydate] = useState(null);
  const [endDeliveryDate, setEndDeliveryDate] = useState(null);
  const [startOrderDate, setStartOrderdate] = useState(null);
  const [endOrderDate, setEndOrderDate] = useState(null);
  const [formData, updateFormData] = useState({
    searchKey: '',
    searchValue: '',
  });
  const [loadedData, setLoadedData] = useState(1);
  const [loadingData, setLoadingData] = useState(false);
  const labelRef = useRef();
  const valueRef = useRef();
  const [filter, setFilter] = useState({
    orderId: '',
    orderStatus: 'all',
    deliveryType: 'all',
    customerName: '',
    dateRange: null,
    paymentStatus: 'all',
  });

  // set intial items
  useEffect(() => {
    setItemOffset(0);
  }, [items]);

  // fetch all orders action call
  useEffect(() => {
    const userlistdata = {
      userEmailId: userEmailId,
      userType: usertype,
    };
    // dispatch(getAllOrders(userlistdata, pageNumber));
  }, []);

  // updated each time the orders with new data
  // useEffect(() => {
  //   // setItems(CancelOrderExcluded);
  //   if (
  //     tempOrders.length > 0 &&
  //     (orderStatus ||
  //       deliveryType ||
  //       searchValue ||
  //       startDeliveryDate ||
  //       endDeliveryDate ||
  //       startOrderDate ||
  //       endOrderDate
  //       )
  //   ) {
  //     setItems(tempOrders);
  //   } else {
  //     setItems(CancelOrderExcluded);
  //   }
  // }, [orders, tempOrders]);

  useEffect(() => {
    if (
      tempOrders &&
      tempOrders.length > 0 &&
      (orderStatus || deliveryType || searchValue || startDeliveryDate || endDeliveryDate)
    ) {
      setItems(tempOrders);
    } else {
      setItems(CancelOrderExcluded);
    }
  }, [
    orders,
    tempOrders,
    orderStatus,
    deliveryType,
    searchValue,
    startDeliveryDate,
    endDeliveryDate,
  ]);

  // updated each time the orders with filterred data
  useEffect(() => {
    setItems(filteredorders);
  }, [filteredorders]);

  useEffect(() => {
    if (items && items.length > 0) {
      // let data = items.slice(0, items.length > loadedData * 30 ? loadedData * 30 : items.length);
      // setCurrentItems(data);

      setCurrentItems(items);
      setLoadingData(false);
      console.log(loadingData, 'loADER kitchen dispaly');
    }
  }, [items, loadedData, tempOrders]);

  const handlePageClick = (event) => {
    let newpagenumber = event.selected;
    const userlistdata = {
      userEmailId: userEmailId,
      userType: usertype,
    };
    dispatch(getAllOrders(userlistdata, newpagenumber));
    setPageNumber(newpagenumber);
  };

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    const searchData = () => {
      const currentdate = new Date();
      updateFormData({
        ...formData,
        ['searchValue']:
          currentdate.getFullYear() +
          '-' +
          (currentdate.getMonth() + 1) +
          '-' +
          currentdate.getDate(),
      });
    };
    searchData();
  }, []);

  // filter handle change
  const FilterhandleChange = (e) => {
    if ((e && e.value === 'orderDate') || (e && e.value === 'deliveryDate')) {
      setSearchPlaceholder(true);
      setStartDate();
      setEndDate();
      if (endDate) {
        updateFormData({
          ...formData,
          ['searchValue']:
            moment(startDate).format('YYYY-MM-DD') + 'to' + moment(endDate).format('YYYY-MM-DD'),
        });
      } else {
        updateFormData({
          ...formData,
          ['searchValue']: moment(startDate).format('YYYY-MM-DD'),
        });
      }
    } else {
      setSearchPlaceholder(false);
    }
    e &&
      updateFormData({
        ...formData,
        ['searchKey']: e.value,
      });
    if (e && e.value === 'filterAll') {
      handleClear();
    }
    if (e && e.value !== '') {
      dispatch(removeError());
      setErrorMessage('');
    }
    dispatch({ type: TEMP_SAVE_ORDER, payload: [] });
    e && Object.keys(e).length > 0 && setSelectedFilterDrop(e.value);
  };
  const SearchhandleChange = (e) => {
    setSearchValue(e.target.value);
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
    dispatch(removeError());
    setErrorMessage('');
  };
  // handle search & clear search
  const onSubmits = () => {
    let SearchKey = '';
    if (searchValue) {
      let searchType = isNaN(Number(searchValue));
      if (!searchType) {
        if (searchValue.split('').length < 7) {
          SearchKey = 'order_id';
        } else {
          SearchKey = 'phoneNumber';
        }
      } else if (searchValue.includes('@')) {
        SearchKey = 'orderByEmail';
      } else {
        SearchKey = 'orderByName';
      }
      if (SearchKey) {
        let payload = {
          searchKey: SearchKey,
          searchValue: searchValue,
        };
        dispatch(filterOrders(payload));
        setStartDeliverydate(null);
        setEndDeliveryDate(null);
      }
    } else {
      const userlistdata = {
        userEmailId: userEmailId,
        userType: usertype,
      };
      dispatch(getAllOrders(userlistdata, pageNumber));
    }
  };

  const handleClear = () => {
    setItems(CancelOrderExcluded);
    valueRef.current.value = '';
    // labelRef.current.clearValue();
    dispatch(removeError());
    setErrorMessage('');
  };

  const prepareReportData = () => {
    const date = new Date();
    let filterTerm = formData.searchKey;
    let filterQuery = formData.searchValue;
    // let excelData = {
    //   generatedBy: loggeddetails?.firstName + ' ' + loggeddetails?.lastName,
    //   generatedDate:
    //     moment(date).format('DD\xa0MMM\xa0YYYY').toUpperCase() +
    //     ' ' +
    //     date.getHours() +
    //     ':' +
    //     ('0' + date.getMinutes()).slice(-2),
    //   searchKey: formData.searchKey === 'filterAll' ? '' : formData.searchKey,
    //   searchValue:
    //     formData.searchKey === '' || formData.searchKey === 'filterAll' ? '' : formData.searchValue,
    //   // searchByValue: filterTerm,
    //   // searchString: filterQuery.length > 0 ? filterQuery : '',
    // };
    if (startDeliveryDate && endDeliveryDate) {
      let excelData = {
        generatedBy: loggeddetails?.firstName + ' ' + loggeddetails?.lastName,
        generatedDate:
          moment(date).format('DD\xa0MMM\xa0YYYY').toUpperCase() +
          ' ' +
          date.getHours() +
          ':' +
          ('0' + date.getMinutes()).slice(-2),
        searchKey: 'deliveryDate',
        searchValue: `${convertDateFormat(startDeliveryDate)}to${convertDateFormat(
          endDeliveryDate
        )}`,
      };
      let reportName = `ORDER_REPORT_${moment(new Date()).format('DDMMMYYYY').toUpperCase()}`;
      dispatch(generateExcelReport(excelData, reportName, 'ORDERS'));
    } else {
      alert('Please select delivery date');
    }
  };
  // const onDateChange = (dates) => {
  //   const [start, end] = dates;
  //   if (selectedFilterDrop === 'orderDate') {
  //     const orderDate = orders.filter((ele) => {
  //       if (start && end) {
  //         const startDateInRange = moment(ele.orderDate).isAfter(moment(start));
  //         const endDateInRange = moment(ele.orderDate).isBefore(moment(end).add(1, 'days'));
  //         return startDateInRange && endDateInRange;
  //       } else if (start) {
  //         return moment(ele.orderDate).format('DD-MM-YYYY') === moment(start).format('DD-MM-YYYY');
  //       }
  //     });
  //     dispatch({ type: TEMP_SAVE_ORDER, payload: orderDate });
  //     console.log(orderDate, 'orderDate');
  //   }
  //   // delivery date
  //   if (selectedFilterDrop === 'deliveryType') {
  //     const deliveryDate = orders.filter((ele) => {
  //       if (start && end) {
  //         const startDateInRange = moment(ele.dpDate).isAfter(moment(start));
  //         const endDateInRange = moment(ele.dpDate).isBefore(moment(end).add(1, 'days'));
  //         return startDateInRange && endDateInRange;
  //       } else if (start) {
  //         return moment(ele.dpDate).format('DD-MM-YYYY') === moment(start).format('DD-MM-YYYY');
  //       }
  //     });
  //     dispatch({ type: TEMP_SAVE_ORDER, payload: deliveryDate });
  //     console.log(deliveryDate, 'deliveryDate');
  //   }
  //   setStartDate(start);
  //   setEndDate(end);
  //   updateFormData({
  //     ...formData,
  //     ['searchValue']: moment(start).format('YYYY-MM-DD') + 'to' + moment(end).format('YYYY-MM-DD'),
  //   });
  // };

  const afterAdvanceFilterClearFilter = () => {
    valueRef.current.value = '';
    labelRef.current.clearValue();
  };

  // const options = [
  //   { label: 'Delivery Date', value: 'deliveryDate' },
  //   { label: 'Order Date', value: 'orderDate' },
  //   { label: 'Order ID', value: 'order_id' },
  //   { label: 'Order Status', value: 'orderStatus' },
  //   { label: 'Delivery Type', value: 'deliveryType' },
  //   { label: 'Customer Name', value: 'orderByName' },
  //   { label: 'Phone Number', value: 'phoneNumber' },
  //   { label: 'Show All', value: 'filterAll' },
  // ];

  const deliveryTypeOption = [
    { name: 'All', value: 'all' },
    { name: 'Delivery', value: 'delivery' },
    { name: 'Pickup', value: 'pickup' },
  ];
  const paymentStatusOption = [
    { name: 'All', value: 'all' },
    { name: 'Paid', value: 'paid' },
    { name: 'Unpaid', value: 'Unpaid' },
  ];

  const orderOption = [
    { name: 'All', value: 'all' },
    { name: 'Delivered', value: 'delivered' },
    { name: 'Placed', value: 'placed' },
    { name: 'Cancelled', value: 'cancelled' },
    { name: 'Cooking', value: 'cooking' },
  ];

  const deliveryTypeOptions = [
    { name: 'All', value: 'all' },
    { name: 'Delivery', value: 'delivery' },
    { name: 'Pickup', value: 'pickup' },
  ];
  const paymentStatusOptions = [
    { name: 'All', value: 'all' },
    { name: 'Paid', value: 'paid' },
    { name: 'Unpaid', value: 'unpaid' },
  ];
  const orderStatusOptions = [
    { name: 'All', value: 'all' },
    { name: 'Delivered', value: 'delivered' },
    { name: 'Placed', value: 'placed' },
    { name: 'Cancelled', value: 'cancelled' },
    { name: 'Cooking', value: 'cooking' },
  ];
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };
  const filteredData = useMemo(() => {
    console.log('currentItems', currentItems);
    if (currentItems && currentItems.length > 0 && errorMessage === '') {
      return currentItems.filter((item) => {
        const { dateRange } = filter;
        return (
          item.orderId.toString().toLowerCase().includes(filter.orderId.toString().toLowerCase()) &&
          (filter.orderStatus === 'all' || item.orderStatus.toLowerCase() === filter.orderStatus) &&
          (filter.deliveryType === 'all' ||
            item.deliveryType.toLowerCase() === filter.deliveryType) &&
          item.customerName.toLowerCase().includes(filter.customerName.toLowerCase()) &&
          (!dateRange ||
            (Array.isArray(dateRange)
              ? item.deliveryDate >= dateRange[0] && item.deliveryDate <= dateRange[1]
              : item.deliveryDate.toISOString().slice(0, 10) === dateRange)) &&
          (filter.paymentStatus === 'all' ||
            item.paymentStatus.toLowerCase() === filter.paymentStatus) &&
          item.orderStatus !== 'DRAFT'
        );
      });
    }
  }, [currentItems, filter]);

  const handleDeliveryTypeSearch = (e) => {
    if (e.target.value.toLowerCase().includes('all')) {
      setDeliveryType(null);
    } else {
      setDeliveryType(e.target.value);
    }
  };

  const handleOrderStatusSearch = (e) => {
    if (e.target.value.toLowerCase().includes('all')) {
      setOrderStatus(null);
    } else {
      setOrderStatus(e.target.value);
    }
  };

  useEffect(() => {
    let filteredSearchResult = [];
    // Order date
    let filteredOrderDatesData = filteredSearchResult;
    console.log('filteredOrderDatesData', filteredOrderDatesData);
    // DropDowns
    if (orderStatus && deliveryType) {
      const filteredData =
        filteredOrderDatesData &&
        filteredOrderDatesData.length &&
        filteredOrderDatesData.filter(
          (order) =>
            order.orderStatus.toLowerCase().includes(orderStatus.toLowerCase()) &&
            order.deliveryType.toLowerCase().includes(deliveryType.toLowerCase())
        );
      if (filteredData.length > 0) {
        setErrorMessage('');
        dispatch({ type: TEMP_SAVE_ORDER, payload: filteredData });
      } else {
        setErrorMessage('error');
      }
      // setFilteredData(filteredData);
    } else if (orderStatus && deliveryType === null) {
      const filteredData =
        filteredOrderDatesData &&
        filteredOrderDatesData.length &&
        filteredOrderDatesData.filter((order) =>
          order.orderStatus.toLowerCase().includes(orderStatus.toLowerCase())
        );
      if (filteredData.length > 0) {
        setErrorMessage('');
        dispatch({ type: TEMP_SAVE_ORDER, payload: filteredData });
      } else {
        setErrorMessage('error');
      }
      // setFilteredData(filteredData);
    } else if (orderStatus === null && deliveryType) {
      const filteredData =
        filteredOrderDatesData &&
        filteredOrderDatesData.length &&
        filteredOrderDatesData.filter((order) =>
          order.deliveryType.toLowerCase().includes(deliveryType.toLowerCase())
        );
      console.log('filteredData 3', filteredData);
      if (filteredData.length > 0) {
        setErrorMessage('');
        dispatch({ type: TEMP_SAVE_ORDER, payload: filteredData });
      } else {
        setErrorMessage('error');
      }
      // setFilteredData(filteredData);
    } else if (orderStatus === null && deliveryType === null) {
      console.log('filteredData 4', filteredOrderDatesData);

      dispatch({ type: TEMP_SAVE_ORDER, payload: filteredOrderDatesData });
    }
  }, [
    orderStatus,
    deliveryType,
    orders,
    searchValue,
    startDeliveryDate,
    endDeliveryDate,
    startOrderDate,
    endOrderDate,
  ]);
  // const options = [
  //   { label: 'Delivery Date', value: 'deliveryDate' },
  //   { label: 'Order Date', value: 'orderDate' },
  //   { label: 'Order ID', value: 'order_id' },
  //   { label: 'Order Status', value: 'orderStatus' },
  //   { label: 'Delivery Type', value: 'deliveryType' },
  //   { label: 'Customer Name', value: 'orderByName' },
  //   { label: 'Phone Number', value: 'phoneNumber' },
  //   { label: 'Show All', value: 'filterAll' },
  // ];

  const convertDateFormat = (dateString) => {
    // Create a new Date object using the input date string
    var date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');

    // Return the formatted date string in the "YYYY-MM-DD" format
    return year + '-' + month + '-' + day;
  };

  useEffect(() => {
    if (currentItems && currentItems.length > 0) {
      dispatch({ type: GET_ORDER_LOADING, payload: false });
      // dispatch({ type: FILTER_ORDER_LOADING, payload: false });
    }
  }, [currentItems]);

  const handleScroll = () => {
    setLoadingData(true);
    setLoadedData((prev) => prev + 1);
  };
  return (
    <>
      <main className="w-[inherit] mt-12 p-4 sm:px-8  bg-white">
        <div className="container grid mx-auto">
          <div className="block md:flex justify-between mb-4">
            <div className="flex w-80">
              <form className="w-full" onSubmit={handleSubmit(onSubmits)}>
                <div className="w-full">
                  {/* <Controller
                    name="message"
                    control={control}
                    rules={registerOptions.message}
                    render={({ field: { onChange } }) => {
                      return (
                        <Select
                          options={options}
                          isClearable={false}
                          placeholder={`${dic.t('Cakery-Filter By') + ' *'}`}
                          onChange={(e) => {
                            FilterhandleChange(e);
                            onChange(e);
                          }}
                          styles={filterStyles}
                          ref={labelRef}
                          className="w-18 md:w-36 flex-shrink-0 z-10 inline-flex items-center text-sm font-medium text-left  bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                        />
                      );
                    }}
                  /> */}

                  <div className="relative w-full">
                    {/* {searchPlaceholder === true ? (
                    <ReactDatePicker
                      // selected={date}
                      value={date}
                      id="searchValue"
                      name="searchValue"
                      dateFormat="yyyy-MM-dd"
                      className="block p-2.5 w-full z-20 text-sm bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      onChange={(date) => {
                        onDateChange(date);
                        // setDateCurrent(date);
                        searchHandleDatePicker(date);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="YYYY-MM-DD"
                      maxDate={today.setDate(today.getDate() + 180)}
                      autoComplete="off"
                      selectsRange
                      ref={valueRef}
                    />
                  ) : ( */}
                    <input
                      type="text"
                      className="block p-2.5 w-full z-10 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-50 border-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      // placeholder={dic.t('Cakery-Search', 'Order ID / Name / Phone')}
                      placeholder="Order ID / Name / Phone / Email"
                      name="searchValue"
                      onChange={SearchhandleChange}
                      ref={valueRef}
                      maxLength={50}
                      autoComplete="off"
                    />
                    {/* )} */}
                    <button
                      type="submit"
                      disabled={
                        formData.searchKey === 'filterAll' ||
                        (searchPlaceholder && endDate === null)
                      }
                      className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-gray-700 rounded-r-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                    >
                      <AiOutlineSearch size="20" />
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {/* <AiFillFileExcel size="20" onClick={prepareReportData} /> */}
            <div className="grid grid-cols-1 gap-1">
              {/* <div className="mt-2 md:mt-0">
                <button
                  className="px-5 py-3 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-100"
                  onClick={openAdvanceSearchModal}
                >
                  {dic.t('Cakery-Advance Filter')}
                </button>
              </div> */}
              <div className="mt-2 md:mt-0">
                <button
                  type="submit"
                  className="px-5 py-3 text-xs font-medium text-white bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-100"
                  onClick={prepareReportData}
                >
                  {dic.t('Cakery-Download Report')}
                </button>
              </div>
            </div>
          </div>

          <div className="flex w-1/3 items-center bg-gray-100 p-3 rounded-lg space-x-4 mb-3">
            {/* Datepicker */}
            <div className="flex-1 w-1/3">
              <label htmlFor="datepicker" className="block text-xs font-bold text-gray-700">
                Delivery Date
              </label>
              <div className="relative flex items-center justify-end">
                <ReactDatePicker
                  value={date}
                  id="datepicker"
                  name="searchValue"
                  dateFormat="yyyy-MM-dd"
                  className="p-2.5 text-sm bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-full relative z-10"
                  onChange={(date) => {
                    // onDateChange(date);
                    // searchHandleDatePicker(date);
                    const [start, end] = date;

                    if (start !== null && end !== null) {
                      console.log('start date', convertDateFormat(start), convertDateFormat(end));
                      let payload = {
                        searchKey: 'deliveryDate',
                        searchValue: `${convertDateFormat(start)}to${convertDateFormat(end)}`,
                      };
                      dispatch(filterOrders(payload));
                    }

                    setStartDeliverydate(start);
                    setEndDeliveryDate(end);
                    setFilter({
                      orderId: '',
                      orderStatus: 'all',
                      deliveryType: 'all',
                      customerName: '',
                      dateRange: null,
                      paymentStatus: 'all',
                    });
                  }}
                  startDate={startDeliveryDate}
                  endDate={endDeliveryDate}
                  placeholderText="YYYY-MM-DD"
                  maxDate={today.setDate(today.getDate() + 180)}
                  autoComplete="off"
                  selectsRange
                  ref={valueRef}
                />
                <div className="absolute top-3  z-10" style={{ right: '10px' }}>
                  <IoMdCalendar className="text-gray-700" />
                </div>
              </div>
            </div>

            {/* order date */}
            {/* <div className="flex-1 w-1/3">
              <label htmlFor="datepicker" className="block text-xs font-bold text-gray-700">
                Order Date
              </label>
              <div className="relative flex items-center justify-end">
                <ReactDatePicker
                  value={date}
                  id="datepicker"
                  name="searchValue"
                  dateFormat="yyyy-MM-dd"
                  className="p-2.5 text-sm bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-full relative z-10"
                  onChange={(date) => {
                    // onDateChange(date);
                    // searchHandleDatePicker(date);
                    const [start, end] = date;
                    setStartOrderdate(start);
                    setEndOrderDate(end);
                  }}
                  startDate={startOrderDate}
                  endDate={endOrderDate}
                  placeholderText="YYYY-MM-DD"
                  maxDate={today.setDate(today.getDate() + 180)}
                  autoComplete="off"
                  selectsRange
                  ref={valueRef}
                />
                <div className="absolute top-3  z-20" style={{ right: '10px' }}>
                  <IoMdCalendar className="text-gray-700" />
                </div>
              </div>
            </div> */}
            {/* // order status */}
            {/* <div className="flex-1 w-1/6">
              <label htmlFor="dropdown1" className="block text-xs font-bold text-gray-700">
                Order Status
              </label>
              <select
                id="dropdown1"
                className="p-2.5 text-sm bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-full"
                value={orderStatus}
                onChange={handleOrderStatusSearch}
              >
                <option selected="true" disabled="disabled">
                  Select Order Status
                </option>
                {orderOption &&
                  orderOption.length > 0 &&
                  orderOption.map((val, i) => (
                    <option key={i} value={val.value}>
                      {val.name}
                    </option>
                  ))}
              </select>
            </div> */}

            {/* Dropdown 2 */}
            {/* //delivery type */}
            {/* <div className="flex-1 w-1/6">
              <label htmlFor="dropdown2" className="block text-xs font-bold text-gray-700">
                Delivery Type
              </label>
              <select
                id="dropdown2"
                className="p-2.5 text-sm bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-full"
                value={deliveryType}
                onChange={handleDeliveryTypeSearch}
              >
                <option selected="true" disabled="disabled">
                  Select Delivery Type
                </option>
                {deliveryTypeOption &&
                  deliveryTypeOption.length > 0 &&
                  deliveryTypeOption.map((val, i) => (
                    <option value={val.value} key={i}>
                      {val.name}
                    </option>
                  ))}
              </select>
            </div> */}
            {/* Payment Status */}
          </div>
          {loading || filterloading ? (
            <>
              <TableLoader />
            </>
          ) : (
            <div className="w-full overflow-hidden rounded-lg shadow-md border-2 border-gray-300">
              <div className="w-full">
                <table className="w-full whitespace-no-wrap">
                  <thead className="text-xs font-semibold tracking-wide text-left uppercase border-b bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:bg-gray-700">
                    <tr>
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Order ID')}</td>
                      <td className="px-2 py-2 text-center">Delivery Type</td>
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Created By')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Delivery Date')}</td>
                      <td className="px-4 py-3 text-center">{dic.t('Cakery-Delivery Status')}</td>
                      <td className="px-1 py-3 text-center">Payment Status</td>
                      <td className="px-1 py-3 text-center">View</td>
                    </tr>
                    <tr>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        <input
                          type="text"
                          name="orderId"
                          value={filter.orderId}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        />
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        <select
                          name="deliveryType"
                          value={filter.deliveryType}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        >
                          {deliveryTypeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      ></td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        {/* <input
                          type="text"
                          name="customerName"
                          value={filter.customerName}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        /> */}
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        {/* <DatePicker
                selected={filter.dateRange}
                onChange={() => handleDateChange(date)}
                placeholderText="Select Date Range"
                className="w-full p-1 rounded-lg border border-gray-300"
                isClearable
                selectsRange
              /> */}
                        <select
                          name="orderStatus"
                          value={filter.orderStatus}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        >
                          {orderStatusOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        <select
                          name="paymentStatus"
                          value={filter.paymentStatus}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        >
                          {paymentStatusOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      ></td>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y text-gray-700">
                    {filteredData && filteredData.length > 0 && errorMessage === '' ? (
                      filteredData.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">{item.orderId}</span>
                            </td>
                            <td className="px-4 py-3 text-center group">
                              <span className="font-semibold inline-flex uppercase rounded text-stone-600">
                                {item?.deliveryType === 'PICKUP' && (
                                  <>
                                    <RiShoppingBasketLine size="20" className=" text-gray-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      PICKUP
                                    </span>
                                  </>
                                )}
                                {item?.deliveryType === 'DELIVERY' && (
                                  <>
                                    <BiCycling size="20" className=" text-gray-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      DELIVERY
                                    </span>
                                  </>
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <p className="text-sm font-semibold">
                                {item.customerName
                                  ? item.customerName.includes('undefined')
                                    ? item.customerName.replace(/\bundefined\b/g, '').trim()
                                    : item.customerName
                                  : item.customerName}
                              </p>
                            </td>
                            {/* <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">
                                {moment(item.orderDate).format('DD-MM-YYYY')}
                              </span>
                            </td> */}
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">
                                {moment(item.dpDate).format('DD-MM-YYYY')}
                              </span>
                            </td>
                            {/* <td className="px-4 py-3 text-center">
                            <span className="text-sm font-semibold">
                              {moment(item.orderDate).format('YYYY-MM-DD')}
                            </span>
                          </td> */}
                            {/* <td className="px-4 py-3 text-center">
                            <span className="text-sm font-semibold">AED {item.totalPrice}</span>
                          </td> */}
                            {/* <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                {item.orderStatus === 'COOKING' && (
                                  <GiCampCookingPot
                                    size="20"
                                    className="hover:cursor-pointer text-amber-500"
                                  />
                                )}
                                {item.orderStatus === 'DISPATCH' && (
                                  <MdOutlineDeliveryDining
                                    size="20"
                                    className="hover:cursor-pointer text-red-500"
                                  />
                                )}
                                {item.orderStatus === 'DELIVERED' && (
                                  <MdOutlineDoneOutline
                                    size="20"
                                    className="hover:cursor-pointer text-green-600"
                                  />
                                )}
                              </span>
                            </td> */}
                            <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                <GiCampCookingPot
                                  size="20"
                                  className={` hover:cursor-pointer ${
                                    item.orderStatus === 'COOKING'
                                      ? 'text-amber-500'
                                      : 'text-gray-600'
                                  } `}
                                  onClick={() => {
                                    setConfirmModal(true);
                                    setConfirmModalType('COOKING');
                                    setConfirmModalItem(item);
                                  }}
                                />
                                <MdOutlineDeliveryDining
                                  size="25"
                                  className={` hover:cursor-pointer mx-4 ${
                                    item.orderStatus === 'DISPATCH'
                                      ? 'text-red-500'
                                      : 'text-gray-600'
                                  } `}
                                  onClick={() => {
                                    setConfirmModal(true);
                                    setConfirmModalType('DISPATCH');
                                    setConfirmModalItem(item);
                                  }}
                                />
                                <MdOutlineDoneOutline
                                  size="20"
                                  className={`hover:cursor-pointer ${
                                    item.orderStatus === 'DELIVERED'
                                      ? 'text-green-600'
                                      : 'text-gray-600'
                                  } `}
                                  onClick={() => {
                                    setConfirmModal(true);
                                    setConfirmModalType('DELIVERED');
                                    setConfirmModalItem(item);
                                  }}
                                />
                              </span>
                            </td>
                            <td className="px-4 py-3 content-center text-center">
                              {<p className="text-sm font-semibold">{item.paymentStatus}</p>}
                            </td>
                            {/* <td className="px-4 py-3 content-center  text-center">
                            <div className="flex justify-center">
                              {item.deliverystatus === 'Delivery' && (
                                <BiCycling size="20" className="text-gray-600" />
                              )}
                              {item.deliverystatus === 'PickUp' && (
                                <RiShoppingBasketLine size="20" className="text-gray-600" />
                              )}
                            </div>
                          </td> */}
                            <td className="px-4 py-3 text-center">
                              {viewLoaderStatus === item.orderId ? (
                                <ViewLoader />
                              ) : (
                                <button
                                  className="text-sm font-semibold px-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => {
                                    if (item.items) {
                                      setSelectData(item);
                                      setShowModal(true);
                                    } else {
                                      setViewLoaderStatus(item.orderId);
                                      let payload = {
                                        searchKey: 'order_id',
                                        searchValue: item.orderId,
                                      };
                                      dispatch(viewSingleOrders(payload))
                                        .then((res) => {
                                          if (res.length > 0) {
                                            setSelectData(res[0]);
                                            setShowModal(true);
                                          }
                                          setViewLoaderStatus('');
                                        })
                                        .catch((err) => {
                                          setViewLoaderStatus('');
                                        });
                                    }
                                  }}
                                >
                                  <AiOutlineEye size="18" />
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <div className="flex items-center justify-center  py-3">
                            {searchValue || endDeliveryDate ? (
                              <p className="text-md text-gray-900 text-center px-4 flex items-center">
                                {dic.t('Cakery-No Order History')} <TbShoppingCartX />
                              </p>
                            ) : (
                              <p className="text-md font-medium capitalize text-gray-900 text-center">
                                Apply filters to retrieve orders
                              </p>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                    {currentItems &&
                      currentItems.length > 0 &&
                      errorMessage === '' &&
                      items &&
                      items.length > loadedData * 30 && (
                        <tr>
                          <td colSpan={12}>
                            <div className="flex items-center justify-center text-2xl py-3">
                              <button
                                onClick={() => handleScroll()}
                                className="px-5 py-3 text-xs font-medium text-white  dark:focus:ring-gray-100 bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700"
                              >
                                Loading Data...
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    {showModal && <KitchenModal closeModal={closeModal} data={selectData} />}
                    {confirmModal && (
                      <ConfirmationModal
                        closeModal={closeConfirmModal}
                        confirmModalType={confirmModalType}
                        item={confirmModalItem}
                      />
                    )}
                    {showAdvanceSearchModal && (
                      <AdvanceSearchModal
                        closeModal={closeAdvanceSearchModal}
                        regionlist={regionlist}
                        afterAdvanceFilterClearFilter={afterAdvanceFilterClearFilter}
                      />
                    )}
                  </tbody>
                </table>
              </div>
              <div className="px-4 py-3 border-t dark:border-gray-700 bg-gray-50 text-gray-500 dark:text-gray-400 dark:bg-gray-600">
                <div className="flex flex-col justify-end text-xs sm:flex-row text-gray-600 dark:text-gray-400">
                  {/* <span className="flex items-center tracking-wide">
                  {flag && <span>Total: {flag.length}</span>}
                </span> */}
                  {/* <nav aria-label="Table navigation">
                    {items && items.length >= itemsPerPage && errorMessage === '' && (
                      <ReactPaginate
                        containerClassName={'inline-flex items-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        breakLabel="..."
                        nextLabel={'Next'}
                        previousClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        nextClassName={
                          'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-3 py-1 rounded-md text-xs text-gray-600  focus:outline-none border border-transparent active:text-white active:bg-gray-100 hover:bg-gray-100 focus:bg-gray-600 focus:text-white'
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={PAGECOUNT}
                        forcePage={pageNumber}
                        previousLabel={'Previous'}
                        renderOnZeroPageCount={null}
                        activeClassName={
                          'text-gray-700 bg-white border border-gray-400 rounded-md hover:bg-gray-700 hover:text-white'
                        }
                      />
                    )}
                  </nav> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};
export default Main;
