import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as helpers from '../../helpers/config';
import { FiLogIn, FiUser, FiLogOut } from 'react-icons/fi';
import { FaUserCircle } from 'react-icons/fa';
import { AiOutlineMenu, AiOutlineSolution } from 'react-icons/ai';
import { RiFileList2Line, RiShieldKeyholeFill } from 'react-icons/ri';
import { GiShoppingCart } from 'react-icons/gi';
import dic from 'i18next';

function MobileNav(props) {
  const carttotal = useSelector((state) => state.usercart.status);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);
  const successmessage = useSelector((state) => state.login.statuscode);

  const loggeddetails = helpers.LoggedUserDetails();

  return (
    <div>
      <a href="#" className="block sm:hidden ml-auto text-white absolute right-24 top-4">
        <div className="relative">
          {props.navigationLinks.map((item, key) => {
            const title = item?.fields?.['Page Title']?.value;
            const CTALink = item?.fields?.['Direct CTA Link']?.value?.href;
            const carticon = item?.fields?.['Menu Background']?.value?.src;
            return (
              <>
                {title === 'Add To Cart' ? (
                  <React.Fragment key={key}>
                    {carttotal > 0 ? (
                      <>
                        <a href={CTALink}>
                          <img src={carticon} className="img-fluid w-7" />
                          <span className="bg-gray-700 absolute text-white-800 text-xs font-semibold  bottom-[0.9rem] left-[0.5rem] pr-[0.55rem] pl-[0.45rem] py-[0.12rem] rounded-full ">
                            {carttotal}
                          </span>
                        </a>
                      </>
                    ) : (
                      <>
                        <div className="relative flex flex-col items-center group">
                          <img src={carticon} className="img-fluid w-7" />
                          <div className="absolute shadow-md w-56 my-4 right-[-25px]	top-2	hidden flex flex-col items-center  mb-6 group-hover:flex">
                            <div className="w-3 h-3 top-[7px] left-[70px] relative bg-white text-white rotate-45"></div>
                            <div className="bg-white text-black px-6 py-4">
                              <GiShoppingCart size={40} className="text-center mx-auto my-3" />
                              <p className="text-center font-bold text-lg capitalize py-1">
                                Your cart is empty..!
                              </p>
                              <p className="text-center font-bold text-sm capitalize py-1">
                                But it does not have to be.
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </div>
      </a>
      {/* Mobile Screen  */}
      {props.islogin === 'true' ? (
        <>
          <button
            type="button"
            className="flex absolute sm:hidden right-[3.5rem] top-4	 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
            id="user-menu-button"
            aria-expanded="false"
            data-dropdown-toggle="dropdown"
            onClick={props.setshowDropdowns}
          >
            <span className="sr-only hidden"></span>
            <FaUserCircle className="text-white" size={25} />
          </button>
          <div
            className={`${
              props.showDropdown ? 'block' : 'hidden'
            } z-50 my-4 text-base absolute sm:hidden right-8 top-12 list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 block`}
            id="dropdown"
            data-popper-placement="bottom"
          >
            <div className="py-3 bg-slate-700 text-white px-4">
              <FiUser className="inline" /> {loggeddetails?.firstName}
              {loggeddetails?.lastName}
            </div>
            <ul className="py-1" aria-labelledby="dropdown">
              {props?.profile_nav.map((item, key) => {
                return item?.fields['Page Title']?.value !== 'Profile' ? (
                  <li>
                    <a
                      href={item?.fields['Direct CTA Link']?.value.href}
                      className="flex py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <span className="text-gray-700 mr-2 mt-0.5">
                        {item?.fields['Page Title']?.value === 'Orders' ? (
                          <RiFileList2Line />
                        ) : item?.fields['Page Title']?.value === 'Consent' ? (
                          <AiOutlineSolution />
                        ) : (
                          <RiShieldKeyholeFill />
                        )}
                      </span>
                      {item?.fields['Page Title']?.value}
                    </a>
                  </li>
                ) : (
                  ''
                );
              })}
              <li onClick={props.Logout}>
                <a href="#" className="flex py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
                  <span className="text-gray-700 mr-2 mt-1">
                    <FiLogOut />
                  </span>
                  {dic.t('Cakery-Log Out')}
                </a>
              </li>
            </ul>
          </div>
        </>
      ) : (
        <>
          <a
            href="#"
            className="block sm:hidden ml-auto usertoggle text-white absolute right-14 top-4"
            onClick={props.toggleModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </>
      )}

      <button
        className=" text-3xl sm:hidden block top-3 relative  text-white"
        onClick={() => props.setShowNavs()}
        id="navIcon"
      >
        &#9776;
      </button>
    </div>
  );
}

export default MobileNav;
