export const getOptionsList = (FinalcakeList) => {
    let FlavourOptions = [];
    FinalcakeList && FinalcakeList.map((item) => {
      let cakename = item?.fields?.['Cake Name']?.fields?.Title?.value;
      FlavourOptions.push({
        label: cakename,
        value: {
          services: item?.fields?.Services,
          addons: item?.fields?.Addons,
          cakeImages: item?.fields?.['Cake Images'],
        },
      });
    });
    return FlavourOptions;
};
