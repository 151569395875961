import React, { Fragment } from 'react';
import { SiInstagram } from 'react-icons/si';

function Instagram() {
  return (
    <Fragment>
      <SiInstagram className="text-white relative left-[0.6rem] top-[0.55rem]" size={13} />
    </Fragment>
  );
}

export default Instagram;
