export const LoggedOrderDelivery = (OrderData, CashOnDelivery) => {
  let isTurkeyAvailabel = OrderData.orderProductDetail.filter((Data) =>
    Data.itemName.includes('Turkey')
  );
  return {
    cheque: 0,
    couponCode: OrderData.couponCode,
    currency: 'AED',
    cutlery: isTurkeyAvailabel.length > 0 ? 'true' : '',
    deliveryAddress: OrderData.delivery.address,
    deliveryCharge: Number(OrderData.charge),
    deliveryType: OrderData.delivery.deliverytype,
    discount: OrderData.discount,
    dpDate: OrderData.delivery.date,
    dpTime: OrderData.delivery.time,
    customerName: OrderData.Name,
    customerEmail: OrderData.Email,
    history: '',
    region: OrderData?.region,
    items: OrderData.orderProductDetail,
    outlet: '',
    paymentMode: CashOnDelivery ? 'OFFLINE' : 'ONLINE',
    paymentStatus: CashOnDelivery ? 'UNPAID' : 'PAID',
    phoneNumber: OrderData?.Phone,
    reasonForCancellation: '',
    remarks: OrderData.remarks,
    subTotal: Number(OrderData.subtotal),
    totalPrice: insertDecimal(parseInt(OrderData.total)),
    transactionNumber: OrderData.transaction_number,
    orderStatus: OrderData.orderStatus || '',
    userDTO: {
      userEmail: OrderData.Email,
      savedAddress: OrderData.savedAddress,
      userId: OrderData.userId,
      userType: 'END_USER',
    },
    vat: '',
  };
};

export const GuestOrderDelivery = (OrderData, CashOnDelivery) => {
  let isTurkeyAvailabel = OrderData.orderProductDetail.filter((Data) =>
    Data.itemName.includes('Turkey')
  );
  return {
    cheque: 0,
    couponCode: OrderData.couponCode,
    currency: 'AED',
    cutlery: isTurkeyAvailabel.length > 0 ? 'true' : '',
    deliveryAddress: OrderData.delivery.address,
    deliveryCharge: Number(OrderData.charge),
    deliveryType: OrderData.delivery.deliverytype,
    discount: OrderData.discount,
    dpDate: OrderData.delivery.date,
    dpTime: OrderData.delivery.time,
    customerName: OrderData.Name,
    customerEmail: OrderData.Email,
    history: '',
    region: OrderData.region,
    items: OrderData.orderProductDetail,
    outlet: '',
    paymentMode: CashOnDelivery ? 'OFFLINE' : 'ONLINE',
    paymentStatus: CashOnDelivery ? 'UNPAID' : 'PAID',
    phoneNumber: OrderData?.Phone,
    reasonForCancellation: '',
    remarks: OrderData.remarks,
    subTotal: Number(OrderData.subtotal),
    totalPrice: insertDecimal(parseInt(OrderData.total)),
    transactionNumber: OrderData.transaction_number,
    orderStatus: OrderData.orderStatus || '',
    userDTO: {
      userEmail: OrderData.Email,
      savedAddress: {},
      userType: 'GUEST_USER',
    },
    vat: '',
  };
};

export const Orderdate = () => {
  var MyDate = new Date();
  var MyDateString;
  MyDateString =
    MyDate.getFullYear() +
    '-' +
    ('0' + (MyDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + MyDate.getDate()).slice(-2);
  return MyDateString;
};
export const insertDecimal = (num) => {
  return Number((num / 100).toFixed(2));
};
