import React, { useEffect, useState } from 'react';
import { BsCheck2All } from 'react-icons/bs';
import { onBookPaymentStatus } from '../../actions/bookpayment.action';
import { onOrder, ORDER_FUNCTION } from '../../actions/order/order.action';
import { useDispatch, useSelector } from 'react-redux';
import * as helpers from '../../helpers/config';
import * as order from '../../helpers/order';
import { useToasts } from 'react-toast-notifications';
import { RiLoader5Line } from 'react-icons/ri';
import { onCartAction, onCartCount } from '../../actions/cart/addtocart.action';
import dic from 'i18next';
import { onSentEmail, ORDER_LOADING } from '../../actions/email/email.action';
import { FaCheck } from 'react-icons/fa';
import { onupdateorder } from '../../actions/admin/UpdateOrder.action';

function BookingConfirmation() {
  const cartproducts = localStorage.getItem('cartproducts');
  if (cartproducts !== null) {
    localStorage.setItem('getcartproduct', cartproducts);
  }
  const getorderproduct = JSON.parse(localStorage.getItem('getcartproduct'));
  if (getorderproduct !== null) {
    localStorage.removeItem('cartproducts');
  }
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [Status, setStaus] = useState('');
  const [OrderData, setOrderData] = useState(false);
  const [mailDetails, setMailDetails] = useState({});
  const params = new URLSearchParams(window.location.search);
  const payment_loading = useSelector((state) => state.bookpayment.payment_loading);
  const payment_message = useSelector((state) => state.bookpayment.payment_message);
  const orderloading = useSelector((state) => state.userorder.order_loading);
  const islogin = localStorage.getItem('islogin');
  const orderdetail = useSelector((state) => state.userorder.order_detail);
  const orderIdLoading = useSelector((state) => state.userorder.current_order_loading);

  const reference_id = params.get('ref');
  const addressresult = helpers.OrderAddress();
  const Loggeduser = helpers.LoggedUserDetails();
  const orderProductDetail = helpers.orderProductDetail();
  const GuestDetail = helpers.GuestDetail();
  const getSelectedAddress = helpers.getSelectedAddress();
  const addonpricelist = [];
  // Booking
  useEffect(() => {
    dispatch(onBookPaymentStatus(reference_id));
  }, [reference_id]);
  let orderInitId = localStorage.getItem('OrderInit');
  useEffect(() => {
    if (payment_message?._embedded?.payment[0].state === 'CAPTURED') {
      var deliveryCharge = payment_message.merchantDefinedData.deliveryCharge;
      const discount = payment_message.merchantDefinedData.discount;
      const couponCode = payment_message.merchantDefinedData.couponCode;
      var response = helpers.paymentBeforeValidate(deliveryCharge, discount);
      console.log('outside response.status', response.status);
      // orderInitId = orderInitId === null ? undefined : orderInitId;
      console.log('orderInitId', orderInitId);
      if (response.status === 'success') {
        var OrderData = {
          delivery: {
            address:
              response?.details?.deliveryOptions?.deliverytype === 'delivery'
                ? addressresult.deliveryaddress
                : {},
            deliverytype:
              response?.details?.deliveryOptions?.deliverytype === 'delivery'
                ? 'DELIVERY'
                : 'PICKUP',
            date: response?.details?.deliveryOptions?.deliverydate,
            time: response?.details?.deliveryOptions?.deliverytime,
          },
          discount: discount,
          couponCode: couponCode,
          remarks:
            response?.details?.deliveryOptions?.deliverytype === 'pickup'
              ? response?.details?.deliveryOptions?.remarks
              : '',
          region:
            response?.details?.deliveryOptions?.region !== undefined
              ? response?.details?.deliveryOptions?.region
              : '',
          charge: deliveryCharge,
          subtotal: response?.details?.subtotal,
          transaction_number: reference_id,
          orderId: orderInitId,
          deliveryOptions: response?.details?.deliveryOptions?.deliverytype,
          savedAddress: addressresult.savedAddress,
          orderProductDetail: orderProductDetail.order_product,
          total: response?.details?.total,
          Email: islogin === 'true' ? Loggeduser?.userEmailId : GuestDetail?.email,
          Phone: islogin === 'true' ? Loggeduser?.phoneNumber : GuestDetail?.phone,
          Name:
            islogin === 'true'
              ? Loggeduser?.firstName + ' ' + Loggeduser?.lastName
              : GuestDetail?.name,
          userId: islogin === 'true' ? Loggeduser?.userId : '',
        };
        const OrderObj =
          islogin === 'true'
            ? order.LoggedOrderDelivery(OrderData, false)
            : order.GuestOrderDelivery(OrderData, false);

        console.log('inside response.status', orderIdLoading);
        setMailDetails({
          EmailId: OrderData.Email,
          Phone: OrderData.Phone,
          subtotal: OrderData.subtotal,
          total: OrderData.total,
          Name: OrderData.Name,
        });

        console.log('orderdetail', orderdetail);
        if (orderdetail === false) {
          console.log('Reach to on order', orderInitId);
          // dispatch(onOrder(OrderObj, deliveryCharge, discount, mailDetails));
          const sendupdateorder = {
            orderId: Number(orderInitId),
            paymentStatus: 'PAID',
            orderStatus: 'PLACED',
            transactionNumber: reference_id,
          };
          dispatch({ type: ORDER_LOADING, payload: true });
          dispatch(onupdateorder(sendupdateorder)).then(() => {
            dispatch({ type: ORDER_LOADING, payload: false });
          });
        } else {
          // Mail Code goes here
          dispatch(
            onSentEmail(
              {
                EmailId: OrderData && OrderData.Email,
                Phone: OrderData && OrderData.Phone,
                subtotal: OrderData && OrderData.subtotal,
                total: OrderData && OrderData.total,
                Name: OrderData && OrderData.Name,
              },
              orderdetail?.OrderId,
              'BookingConfirmation local storage order id else'
            )
          );
        }

        setStaus(true);
      } else {
        // Mail Code goes here
        dispatch(
          onSentEmail(
            {
              EmailId: OrderData && OrderData.Email,
              Phone: OrderData && OrderData.Phone,
              subtotal: OrderData && OrderData.subtotal,
              total: OrderData && OrderData.total,
              Name: OrderData && OrderData.Name,
            },
            response.status,
            'Payment status response.status else'
          )
        );
        addToast(<p className="py-0.5">{dic.t('Cakery-Order Failed')}...!</p>, {
          appearance: 'error',
        });
        setStaus(!Status);
      }
    }
    setOrderData(OrderData);
  }, [payment_message]);
  useEffect(() => {
    if (orderloading === true) {
      localStorage.setItem('order_Id', orderInitId);
      setStaus(!Status);
    }
  }, [orderloading]);
  useEffect(() => {
    window !== 'undefined' && window.scroll(0, 500);
    dispatch({
      type: ORDER_FUNCTION,
      payload: false,
      loading: true,
    });
  }, []);
  return (
    <>
      {orderIdLoading === true ? (
        <div
          className="py-20 bg-black fixed left-0 right-0 bottom-0 h-screen z-[1000] flex-col flex justify-center items-center overflow-hidden"
          style={{ zIndex: '1000' }}
        >
          <RiLoader5Line className="w-12 h-12 mx-auto text-gray-200 animate-spin fill-white" />
          <h4 className="text-white text-center text-xl pt-2">{dic.t('Cakery-Loading')}</h4>
          <p className="text-white text-center py-2">
            {dic.t('Cakery-This May Take a Few Seconds')} <br /> We are creating your Order Please
            Wait ...
          </p>
        </div>
      ) : (
        <div className="container-fluid pb-10 bg-black ">
          <div className="py-14"></div>
          {payment_message?._embedded?.payment[0].state === 'CAPTURED' ? (
            orderdetail?.OrderId === null ? (
              <div className="py-20">
                {/* <RiLoader5Line className="w-12 h-12 mx-auto text-gray-200 animate-spin fill-white" />
                <h4 className="text-white text-center text-xl pt-2">{dic.t('Cakery-Loading')}</h4> */}
                <p
                  className="text-xl text-white text-center py-2"
                  dangerouslySetInnerHTML={{ __html: orderdetail.statusMessage }}
                ></p>
              </div>
            ) : (
              <div className="lg:flex lg:flex-row ">
                <div className="lg:basis-3/4 lg:px-10 px-4 pt-8 lg:pt-0 lg:pl-[4rem]  border-white-500">
                  <div className="main-accordition">
                    <div className="sub-accordition">
                      <div className="accord-title tracking-widest text-black font-bold p-4 bg-white">
                        <BsCheck2All className="inline text-xl font-bold" />{' '}
                        {dic.t('Cakery-Order Confirmed')}
                      </div>
                      <div className="accord-description border border-white py-4 px-5">
                        <div className="grid grid-cols-1  md:grid-cols-1">
                          <div>
                            <div className="grid grid-cols-1  md:grid-cols-3 text-sm">
                              <div className="order-basic">
                                <p className="text-white py-0.5">
                                  {dic.t('Cakery-Order ID')} :{' '}
                                  {orderInitId === null ? (
                                    orderInitId ? (
                                      orderInitId
                                    ) : (
                                      <>
                                        <RiLoader5Line className="w-3 h-3 text-gray-200 inline position-relative bottom-2 animate-spin fill-white" />
                                      </>
                                    )
                                  ) : (
                                    orderInitId
                                  )}
                                </p>
                                <p className="text-white py-0.5">
                                  {dic.t('Cakery-Order Date')} : {order.Orderdate()}
                                </p>
                                <p className="text-white py-0.5">
                                  {dic.t('Cakery-Order Status')} : Placed
                                </p>
                              </div>
                              <div>
                                <p className="py-3 capitalize text-white tracking-widest">
                                  Your order has been placed successfully.
                                </p>
                              </div>
                              <div className="order-delivery-address text-left sm:text-right">
                                {OrderData !== undefined && (
                                  <div>
                                    <p className="text-white">
                                      {dic.t('Cakery-Delivery Type')} :{' '}
                                      {OrderData?.delivery?.deliverytype === 'DELIVERY'
                                        ? 'Delivery'
                                        : 'Pickup'}
                                    </p>
                                  </div>
                                )}
                                {getSelectedAddress ? (
                                  <div>
                                    <p className="text-white">
                                      {dic.t('Cakery-Delivery Address')} :{' '}
                                    </p>
                                    <p className="text-white">{getSelectedAddress}</p>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <div className="pt-2 text-left sm:text-right">
                                  <p className="text-white ">
                                    {OrderData?.delivery?.deliverytype === 'DELIVERY'
                                      ? 'Delivery'
                                      : 'Pickup'}{' '}
                                    {dic.t('Cakery-Date and Time')} :
                                  </p>
                                  <p className="text-white">
                                    {OrderData !== undefined &&
                                      OrderData?.delivery?.date + ' , ' + OrderData?.delivery?.time}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 pt-5  md:grid-cols-1 gap-3">
                          <div className="">
                            <div className="overflow-x-auto sm:rounded-lg hidden sm:block">
                              <table className="w-full   text-left text-white">
                                {/* <thead className="md:text-sm text-xs text-white-700 uppercase bg-black">
                              <tr>
                                <th scope="col" className="px-12 py-3">
                                  {dic.t('Cakery-Product Image')}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  {dic.t('Cakery-Product Name')}
                                </th>
                                <th scope="col" className="px-1 py-3">
                                  {dic.t('Cakery-Quantity')}
                                </th>
                                <th scope="col" className="px-6 py-3">
                                  {dic.t('Cakery-Total Price')}
                                </th>
                              </tr>
                            </thead> */}
                                <tbody>
                                  {getorderproduct?.map((item, keys) => {
                                    const title = item?.ItemDetails?.title;
                                    const images = item?.ItemDetails?.image;

                                    return item.cartaddedservice.map((serviceitem, getkey) => {
                                      addonpricelist.push(helpers.AddonsTotalPrice(serviceitem));
                                      // addonPrice Comment
                                      // addonpricelist.push(
                                      //   serviceitem?.addon?.Price !== undefined
                                      //     ? serviceitem?.addon?.Price
                                      //     : 0
                                      // );
                                      return (
                                        <tr
                                          className="border-t mx-6  lg:text-base sm:text-md text-sm"
                                          key={serviceitem.id}
                                        >
                                          <td scope="row" className="py-4">
                                            <img src={images} className="-cover h-28 w-44" />
                                          </td>
                                          <td className="px-6 py-4">
                                            {title} <br /> {serviceitem.serves.value} <br />
                                            {helpers.currency}&nbsp;
                                            {serviceitem.price.value} <br />
                                            <ul className="addons">
                                              {serviceitem && serviceitem.addon.length ? (
                                                <h2 className="text-md font-semibold">Addons</h2>
                                              ) : (
                                                ''
                                              )}
                                              {serviceitem &&
                                                serviceitem.addon.map((val, i) => (
                                                  <li
                                                    className="addonList-item flex justify-between"
                                                    key={i}
                                                  >
                                                    <div className="addon flex items-center">
                                                      {' '}
                                                      <FaCheck className="h-3 w-3 text-green-500 mr-2" />
                                                      {val.Qty} x {val.addonName}
                                                    </div>
                                                    <div className="price ml-2">
                                                      {val.Currency} {val.Price}
                                                    </div>
                                                  </li>
                                                ))}
                                            </ul>
                                            {serviceitem?.addon &&
                                              serviceitem?.addon?.Price !== (undefined || 0) && (
                                                <>{serviceitem?.addon?.addonName}</>
                                              )}
                                            <br />
                                            {serviceitem?.addon &&
                                              serviceitem?.addon?.Price !== undefined && (
                                                <>{helpers.currency}&nbsp;</>
                                              )}
                                            {serviceitem?.addon &&
                                              serviceitem?.addon?.Price !== undefined && (
                                                <>{serviceitem?.addon?.Price}</>
                                              )}
                                            {serviceitem?.message?.value
                                              ? `✉️ ${serviceitem?.message?.value}`
                                              : ''}
                                          </td>
                                          <td className="px-6 py-4 align-baseline">
                                            {serviceitem.quantity.value}
                                          </td>
                                          <td className="px-12 py-4 align-baseline">
                                            {parseInt(
                                              serviceitem.price.value * serviceitem.quantity.value
                                            ) +
                                              parseInt(
                                                serviceitem?.addon?.Price !== undefined
                                                  ? serviceitem?.addon?.Price
                                                  : 0
                                              )}
                                          </td>
                                        </tr>
                                      );
                                    });
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div className="order-confirm text-right lg:px-5"></div>
                            {/* Small Device Started */}
                            <div className="small-device block sm:hidden">
                              <div className="pointer-events-auto">
                                <div className="flex h-full flex-col overflow-y-scroll bg-black shadow-xl">
                                  <div className="flex-1 overflow-y-auto sm:px-6">
                                    <div className="">
                                      <div className="flow-root">
                                        <ul role="list" className="divide-y divide-gray-200">
                                          {getorderproduct.map((item, keys) => {
                                            const title = item?.ItemDetails?.title;
                                            const images = item?.ItemDetails?.image;
                                            const productkey = keys;
                                            return item.cartaddedservice.map(
                                              (serviceitem, getkey) => {
                                                const serviceid = serviceitem?.id;
                                                const message = serviceitem?.message?.value;
                                                const price = serviceitem?.price?.value;
                                                const serves = serviceitem?.serves?.value;
                                                const quantity = serviceitem?.quantity?.value;
                                                const servicekey = getkey;
                                                const addon = serviceitem?.addon;
                                                addonpricelist.push(
                                                  helpers.AddonsTotalPrice(serviceitem)
                                                );
                                                // addonPrice Comment
                                                // addonpricelist.push(
                                                //   serviceitem?.addon?.Price !== undefined
                                                //     ? serviceitem?.addon?.Price
                                                //     : 0
                                                // );
                                                return (
                                                  <li className="flex pt-2 pb-1" key={keys}>
                                                    <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                      <img
                                                        src={images}
                                                        alt="Product Image"
                                                        className="h-full w-full object-cover object-center"
                                                      />
                                                    </div>

                                                    <div className="ml-4 flex flex-1 flex-col">
                                                      <div>
                                                        <div className="flex justify-between text-sm font-medium">
                                                          <p className="text-base">
                                                            <a
                                                              href="javascript:;"
                                                              className="text-white"
                                                            >
                                                              {title}
                                                            </a>
                                                          </p>
                                                        </div>
                                                        <p className="mt-1 text-sm text-white">
                                                          {serves}
                                                          <br />
                                                          <span className="text-white">
                                                            AED{' '}
                                                            {parseInt(price * quantity) +
                                                              parseInt(
                                                                addon?.Price !== undefined
                                                                  ? addon?.Price
                                                                  : 0
                                                              )}
                                                          </span>
                                                          <span> | Qty {quantity}</span>&nbsp;
                                                          <br />
                                                          {serviceitem?.addon &&
                                                            serviceitem?.addon?.Price !==
                                                              undefined && (
                                                              <>
                                                                {serviceitem?.addon?.addonName +
                                                                  ' ' +
                                                                  serviceitem?.addon?.Price +
                                                                  ' ' +
                                                                  serviceitem?.addon?.Currency}
                                                              </>
                                                            )}
                                                          {message !== undefined ? (
                                                            <>
                                                              <br /> {message}
                                                            </>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </li>
                                                );
                                              }
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Small Device End */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:basis-1/5  basis-9/12  lg:pt-0 px-4 lg:px-0 text-white lg:pl-5">
                  <div className="lg:sticky lg:self-baseline lg:top-[6rem] lg:border lg:px-5">
                    <h5 className="font-bold pt-2 pb-3 uppercase xl:text-lg sm:text-md text-md">
                      {dic.t('Cakery-Order Summary')}
                    </h5>
                    <p className="text-white text-xs">{dic.t('Cakery-Inclusive of Tax')}</p>
                    <table className="w-full text-white">
                      <tbody>
                        <tr className="text-left lg:text-base sm:text-md text-sm">
                          <th className="text-left font-medium py-2">Subtotal</th>
                          <td className="text-center">
                            {parseInt(helpers.CartSubtotalSum(getorderproduct)) +
                              parseInt(helpers.ArraySum(addonpricelist))}
                          </td>
                        </tr>
                        {OrderData !== undefined ? (
                          <tr className="text-left lg:text-base sm:text-md text-sm">
                            <th className="text-left font-medium py-2">
                              {dic.t('Cakery-Delivery Charges')}
                            </th>
                            <td className="text-center">{OrderData.charge}</td>
                          </tr>
                        ) : (
                          <></>
                        )}

                        <tr className="text-left xl:text-lg sm:text-md text-md border-t-2 border-white-500">
                          <th className="text-left ">{dic.t('Cakery-Total Amount')}</th>
                          <td className="text-center pt-3 pb-2">
                            {OrderData !== undefined ? order.insertDecimal(OrderData.total) : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          ) : (
            ''
          )}
          {payment_message?._embedded?.payment[0].state === 'FAILED' ? (
            <div className="lg:flex lg:flex-row ">
              <div className="lg:basis-full mb-20 lg:px-10 px-4 pt-8 lg:pt-0 lg:pl-[4rem]  border-white-500">
                <div className="main-accordition">
                  <div className="sub-accordition">
                    <div className="accord-title tracking-wider text-black font-bold p-4 bg-white">
                      <BsCheck2All className="inline text-xl font-bold" />{' '}
                      {dic.t('Cakery-Order Failed')}
                    </div>
                    <div className="accord-description border border-white py-4 px-5">
                      <div className="grid grid-cols-1  md:grid-cols-2">
                        <div>
                          <p className="text-white pb-1">
                            {dic.t('Cakery-Booking Failed Transaction')}.
                          </p>
                          <p className="text-white py-1">
                            {dic.t('Cakery-Please try again later')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
}

export default BookingConfirmation;
