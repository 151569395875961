import {
  SET_FORGOT_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  CLEAR_FORGOT_STATE,
} from '../actions/forgotpassword.action';

const initailState = {
  loading: false,
  statuscode: '',
  error: '',
};

export const userForgotPasswordReducer = (state = initailState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        statuscode: action.payload,
      };

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_FORGOT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case CLEAR_FORGOT_STATE:
      return {
        ...state,
        statuscode: action.payload,
        error: action.payload,
      };

    default:
      return state;
  }
};
