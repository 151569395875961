import { GUEST_FUNCTION } from '../actions/guest.action';

const initailState = {
  status: false,
  details: '',
  error: '',
};

export const GuestReducer = (state = initailState, action) => {
  switch (action.type) {
    case GUEST_FUNCTION:
      return {
        ...state,
        status: action.payloadstatus,
        details: action.payload,
      };
    default:
      return state;
  }
};
