import React, { Fragment, useEffect, useState } from 'react';
import DatePickup from './DatePickup';
import { useDispatch, useSelector } from 'react-redux';
import Delivery from './Delivery';
import { setCustomerDeliveryInfo } from '../../../actions/admin/CallCenter.action';

const DeliveryScreen = ({
  deliverycharges,
  FestivalDeliveryCharges,
  FestiveDeliveryTime,
  deliveryTime,
  PickupTime,
  isDisabled
}) => {
  const dispatch = useDispatch();
  console.log(isDisabled, 'isDisabled')
  const { customerDeliveryInfo, cartItems } = useSelector((state) => state.callcenter);
  const [dpdate, setdpdate] = useState(customerDeliveryInfo.dpdate);
  const [dptime, setdptime] = useState(customerDeliveryInfo.dptime);
  const [deliveryType, setdeliveryType] = useState(customerDeliveryInfo.deliverytype);
  let isfest = cartItems.filter((item) => item.cakeType === 'FESTIVAL').length > 0 ? true : false;
  useEffect(() => {
    const customerinfopayload = {
      dpdate: dpdate,
      dptime: dptime,
      deliverytype: deliveryType,
    };
    dispatch(setCustomerDeliveryInfo(customerinfopayload));
  }, [dpdate, dptime, deliveryType]);
  console.log('customerDeliveryInfo', customerDeliveryInfo);
  return (
    <Fragment>
      <div className="grid grid-cols-1 lg:grid-cols-12 p-4 sm:p-0 gap-2 relative">
        {!isDisabled && (
          <div className="absolute inset-0 bg-opacity-50 opacity-25 bg-white z-10"></div>
        )}
        <Delivery
          deliverycharges={deliverycharges}
          FestivalDeliveryCharges={FestivalDeliveryCharges}
          setdeliveryType={setdeliveryType}
          setdptime={setdptime}
          dptime={dptime}
          deliveryType={deliveryType}
          isFestival={isfest}
          FestiveDeliveryTime={FestiveDeliveryTime}
          deliveryTime={deliveryTime}
          PickupTime={PickupTime}
        />
        <DatePickup setdpdate={setdpdate} dpdate={dpdate} isFestival={isfest} />
      </div>
    </Fragment>
  );
};

export default DeliveryScreen;
