import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as helpers from '../../helpers/config';
import * as session from '../../helpers/sessionHandler';
import * as Helpers from '../../helpers/AdminHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { BsCheck2All } from 'react-icons/bs';
import { HiBadgeCheck } from 'react-icons/hi';
import { BiEditAlt } from 'react-icons/bi';
import { MdEditLocationAlt } from 'react-icons/md';
import GuestDeliveryForm from './GuestDeliveryForm';
import { onCheckoutRegion } from '../../actions/checkoutconfig.action';
import { useToasts } from 'react-toast-notifications';
import dic from 'i18next';
import { format, addMonths } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import { useMediaQuery } from 'react-responsive';
import AddAddress from '../AddAddress';
import EditModalForm from '../AddAddress/EditModal';
import { HiClock } from 'react-icons/hi';

export default function DeliveryAddress(props) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [Login, SetLogin] = useState(false);
  const toggleModal = () => {
    document.getElementById('modal').classList.toggle('hidden');
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();

  const [tempAddress, setTempAddress] = useState(false);
  const [getMakaniNumber, setMakaniNumber] = useState('');
  const [getGuestAddress, setGuestAddress] = useState(false);
  const [deliveryRegion, setdeliveryRegion] = useState('');
  const [deliveryTime, setDeliveryTime] = useState([]);
  const [dpdate, setdpdate] = useState(
    Helpers.is7pmcross() || props?.isFestival
      ? Helpers.getNexttoNextday()
      : props?.isSpecialCake
      ? Helpers.getNexttoNextday()
      : Helpers.getNextday()
  );
  const login_status = useSelector((state) => state.login.statuscode);
  const islogin = localStorage.getItem('islogin');
  const Loggeduser = helpers.LoggedUserDetails();
  const todayDate = new Date();
  const [date, setDate] = useState(todayDate.setDate(todayDate.getDate() + 1));
  const [deliveryType, setDeliveryType] = useState('delivery');
  const gueststatus_redux = useSelector((state) => state.guest.status);
  const guestdetails_redux = useSelector((state) => state.guest.details);
  const GuestDetails = session.GetGuestDetails();
  const GuestStatus = localStorage.getItem('guestLoggedIn');
  const deliverycharge = useSelector((state) => state.deliverycharge.ordercharge);

  // New Delivery Updates

  const [AddressModal, AddAddressModal] = useState(false);
  const [AddressList, SetAddressList] = useState(
    Loggeduser?.savedAddress !== undefined ? Loggeduser?.savedAddress : []
  );
  const [EditModal, SetEditModal] = useState(false);
  const [EditModalItem, SetModalItem] = useState();
  const [ClickAddressKey, setClickAddressKey] = useState();
  const [selectAddress, setselectAddress] = useState(0);
  const [selectTime, setSelectTime] = useState(false);
  const SelectAddress = (key, region) => {
    localStorage.setItem('selectAddress', key);
    CheckRegionListCharge(region);
  };

  const onGuestUpdate = (data) => {
    setGuestAddress(data);
  };

  const onDeliverySubmit = (data) => {
    addToast('Delivery/Pickup options have been updated...!', { appearance: 'success' });
    var NewDeliveryAddressExtra = '';
    const deliverydate = document.getElementById('deliverydate').value;
    if (deliveryType === 'pickup') {
      NewDeliveryAddressExtra = {
        deliverytype: deliveryType,
        deliverydate: deliverydate,
        deliverytime: data.deliverytime,
        remarks: data.remarks,
      };
    } else {
      NewDeliveryAddressExtra = {
        deliverytype: deliveryType,
        deliverydate: deliverydate,
        deliverytime: data.deliverytime,
      };
    }
    localStorage.setItem('NewDeliveryAddressExtra', JSON.stringify(NewDeliveryAddressExtra));
  };

  const onDeliveryAddressSubmit = (data) => {
    addToast('Delivery/Pickup address has been updated...!', { appearance: 'success' });
    const tempadd = AddressList;
    tempadd.push({
      makani: data.makani,
      address: data.address,
      region: data.region,
      deliverytime: data.deliverytime,
    });
    let OtherOrderDetails = {
      deliverytype: deliveryType,
      deliverydate:
        dpdate.getFullYear() +
        '-' +
        ('0' + (dpdate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + dpdate.getDate()).slice(-2),
      deliverytime: data.deliverytime,
    };
    UpdateDeliveryCharge(data.region);
    AddAddressModal(false);
    setTempAddress(tempadd);
    SetAddressList(tempadd);
    setselectAddress(parseInt(tempadd.length) - 1);
    localStorage.setItem('NewDeliveryAddress', JSON.stringify(tempadd));
    localStorage.setItem('NewDeliveryAddressExtra', JSON.stringify(OtherOrderDetails));
    localStorage.setItem('selectAddress', parseInt(tempadd.length) - 1);
  };

  const UpdateExtra = (deliverytime) => {
    if (deliverytime !== false) {
      let OtherOrderDetails = {
        deliverytype: 'delivery',
        deliverydate:
          dpdate.getFullYear() +
          '-' +
          ('0' + (dpdate.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + dpdate.getDate()).slice(-2),
        deliverytime: deliverytime,
      };
      localStorage.setItem('NewDeliveryAddressExtra', JSON.stringify(OtherOrderDetails));
    }
  };

  useEffect(() => {
    if (islogin && AddressList.length > 0 && localStorage && localStorage !== undefined) {
      UpdateExtra(
        AddressList[0]?.deliverytime !== undefined ? AddressList[0]?.deliverytime : false
      );
      localStorage.setItem('NewDeliveryAddress', JSON.stringify(AddressList));
    }
  }, []);

  const onDeliveryAddressUpdate = (data) => {
    AddressList[ClickAddressKey].address = data?.address;
    AddressList[ClickAddressKey].region = data?.region;
    AddressList[ClickAddressKey].deliverytime = data?.deliverytime;
    let OtherOrderDetails = {
      deliverytype: deliveryType,
      deliverydate:
        dpdate.getFullYear() +
        '-' +
        ('0' + (dpdate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + dpdate.getDate()).slice(-2),
      deliverytime: data.deliverytime,
    };
    localStorage.setItem('NewDeliveryAddressExtra', JSON.stringify(OtherOrderDetails));
    SetEditModal(false);
    setselectAddress(ClickAddressKey);
    SetAddressList(AddressList);
    UpdateDeliveryCharge(data?.region);
    localStorage.setItem('selectAddress', ClickAddressKey);
    localStorage.setItem('NewDeliveryAddress', JSON.stringify(AddressList));
  };

  const DeliveryDateUpdate = (data) => {
    const updateDate = JSON.parse(
      localStorage?.getItem('NewDeliveryAddressExtra') !== undefined
        ? localStorage?.getItem('NewDeliveryAddressExtra')
        : []
    );
    if (updateDate !== null) {
      const deliverydate =
        data.getFullYear() +
        '-' +
        ('0' + (data.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + data.getDate()).slice(-2);
      const newupdate = { ...updateDate, deliverydate };
      localStorage?.setItem('NewDeliveryAddressExtra', JSON.stringify(newupdate));
    }
  };

  const UpdatePickup = (time) => {
    let OtherOrderDetails = {
      deliverytype: deliveryType,
      deliverydate:
        dpdate.getFullYear() +
        '-' +
        ('0' + (dpdate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + dpdate.getDate()).slice(-2),
      deliverytime: time,
    };
    setSelectTime(time);
    localStorage.setItem('NewDeliveryAddressExtra', JSON.stringify(OtherOrderDetails));
  };

  const handleCheckNumber = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setMakaniNumber(value);
  };

  const UpdateDeliveryCharge = (region) => {
    var regionlist = props?.deliveryTimeRegion;
    const getprice = regionlist.filter(
      (item, key) =>
        item?.fields?.Region?.fields?.Region?.value?.toLowerCase() === region.toLowerCase()
    );
    const price = region === '' ? 0 : getprice[0]?.fields?.Price?.value;
    dispatch(onCheckoutRegion(price, 'delivery'));
  };

  const CheckRegionListCharge = (region) => {
    const setTemp = region.toLowerCase();
    const getregiontime = props?.deliveryTimeRegion.filter((item) => {
      if (setTemp === item?.fields?.Region?.fields?.Region?.value?.toLowerCase()) {
        return item;
      }
      if (setTemp.toLowerCase() === item?.fields?.Region?.fields?.Region?.value?.toLowerCase()) {
        return item;
      }
    });
    setDeliveryTime(getregiontime);
    setdeliveryRegion(region);
  };

  useEffect(() => {
    if (login_status === 200) {
      SetLogin(!Login);
    } else {
      if (islogin === 'true') {
        SetLogin(!Login);
      }
    }
  }, [login_status, islogin]);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    AddressList.length > 0 ? document.getElementById('intial_click').click() : '';
  }, []);

  const HandleNote = Helpers.isNightTimeUAE();
  // const HandleNightOrMorning = Helpers.isNightOrMorningTimeUAE();
  console.log(Helpers.isNightTimeUAE(), 'UAE');
  return (
    <div className="sub-accordition my-3">
      <div className="accord-title tracking-wider text-black uppercase font-bold p-4 bg-white">
        <span className="bg-black text-white text-xs font-semibold mr-2 px-2.5 py-1 rounded">
          2
        </span>
        {dic.t('Cakery-Delivery Options')}
        {login_status === 200 || islogin === 'true' || GuestStatus === 'true' ? (
          <>
            <BsCheck2All className="inline text-xl font-bold" />
          </>
        ) : (
          <></>
        )}
      </div>
      {GuestStatus === 'true' && (
        <div className="accord-description border border-white">
          {HandleNote && (
            <p className="font-bold text-white text-md px-6 py-5">
              Note : Our store is currently closed, and orders for today and next day cannot be
              processed at the moment. We appreciate your understanding
            </p>
          )}

          <div className="grid grid-cols-1  sm:grid-cols-2">
            <p></p>
          </div>
          <GuestDeliveryForm
            setDeliveryType={setDeliveryType}
            UpdateDeliveryCharge={UpdateDeliveryCharge}
            setselectAddress={setselectAddress}
            UpdateExtra={UpdateExtra}
            AddressList={AddressList}
            SetEditModal={SetEditModal}
            SetModalItem={SetModalItem}
            setClickAddressKey={setClickAddressKey}
            selectAddress={selectAddress}
            AddAddressModal={AddAddressModal}
            setdpdate={setdpdate}
            DeliveryDateUpdate={DeliveryDateUpdate}
            deliveryType={deliveryType}
            pickupTime={props?.pickupTime}
            UpdatePickup={UpdatePickup}
            AddressModal={AddressModal}
            deliveryTime={deliveryTime}
            EditModal={EditModal}
            deliveryTimeRegion={props?.deliveryTimeRegion}
            CheckRegionListCharge={CheckRegionListCharge}
            onDeliveryAddressSubmit={onDeliveryAddressSubmit}
            onDeliveryAddressUpdate={onDeliveryAddressUpdate}
            isMobile={isMobile}
            dpdate={dpdate}
            isSpecialCake={props?.isSpecialCake}
            selectTime={selectTime}
            EditModalItem={EditModalItem}
            isFestival={props?.isFestival}
          />
        </div>
      )}
      {login_status === 200 || islogin === 'true' ? (
        <div className="accord-description border  border-white">
          {HandleNote && (
            <p className="font-bold text-white text-md px-6 py-5">
              Note : Our store is currently closed, and orders for today and next day cannot be
              processed at the moment. We appreciate your understanding
            </p>
          )}
          <div className="grid grid-cols-2 px-6 py-5">
            <div
              id="intial_click"
              onClick={() => {
                setDeliveryType('delivery');
                UpdateDeliveryCharge(AddressList.length > 0 ? AddressList[0]?.region : '');
                setselectAddress(0);
                localStorage.setItem('selectAddress', 0);
                localStorage.removeItem('NewDeliveryAddressExtra');
                UpdateExtra(
                  AddressList[0]?.deliverytime !== undefined ? AddressList[0]?.deliverytime : false
                );
              }}
              className={` uppercase font-bold text-center border mr-2 py-3 cursor-pointer ${
                deliveryType === 'delivery' ? 'bg-white text-black' : 'text-white'
              }`}
            >
              Delivery
            </div>
            <div
              onClick={() => {
                localStorage.removeItem('NewDeliveryAddressExtra');
                setDeliveryType('pickup');
                dispatch(onCheckoutRegion(0, 'pickup'));
                setTimeout(() => {
                  document.getElementById('defaulttime').click();
                }, 0);
              }}
              className={`uppercase font-bold text-center border ml-2 py-3 cursor-pointer ${
                deliveryType === 'pickup' ? 'bg-white text-black' : 'text-white'
              }`}
            >
              Pickup
            </div>
          </div>

          {deliveryType === 'delivery' && (
            <>
              <p className=" px-6 py-5 text-white text-center">Please Select Delivery Address</p>
              {AddressList.length > 0 ? (
                <div
                  className={`${isMobile === true ? 'grid' : ''} grid-cols-1 sm:flex sm:flex-row`}
                >
                  <div className={`${isMobile === true ? 'grid' : ''} grid-cols-1 sm:basis-8/12`}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 px-5 mt-1 pb-5">
                      {AddressList.length > 0 ? (
                        AddressList.map((item, key) => {
                          return (
                            <div
                              key={key}
                              onClick={() => {
                                setselectAddress(key);
                                localStorage.setItem('selectAddress', key);
                                UpdateDeliveryCharge(item?.region);
                              }}
                              className="block p-4 rounded-lg cursor-pointer relative border mb-3 mx-2 shadow-lg bg-white max-w-sm"
                            >
                              {selectAddress === key && (
                                <HiBadgeCheck
                                  className="absolute top-[2px] right-[2px] text-green-600"
                                  size={23}
                                />
                              )}
                              <div className="text-black font-bold text-sm">
                                <p className="w-9/12" style={{ lineBreak: 'anywhere' }}>
                                  {item?.address}
                                </p>
                                <p>{item?.region}</p>
                                <p>{item?.deliverytime}</p>
                                <p
                                  onClick={() => {
                                    SetEditModal(true);
                                    SetModalItem(item);
                                    setClickAddressKey(key);
                                  }}
                                  className="underline text-sm pt-1 relative cursor-pointer inline-block underline-offset-3"
                                >
                                  <BiEditAlt className="cursor-pointer inline" size={18} />
                                  Edit Address
                                </p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      <div className="block p-4 rounded-lg relative border mb-3 mx-2 shadow-lg bg-white max-w-sm">
                        <div className="text-white text-center text-sm py-2">
                          <MdEditLocationAlt className="mx-auto text-black" size={20} />
                          <span className="block sm:hidden">
                            <br />
                          </span>
                          <button
                            onClick={() => AddAddressModal(true)}
                            className=" text-white text-center bg-gray-700 font-bold rounded-lg sm:mt-4 text-sm px-5 py-2 tracking-wider	"
                          >
                            Add New Address
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      isMobile === true ? 'grid' : ''
                    } grid-cols-1 sm:basis-4/12 px-6 sm:px-2 sm:pr-5`}
                  >
                    <div className="bg-white rounded-md sm:py-1 mb-4">
                      <DayPicker
                        mode="single"
                        required
                        fromDate={
                          Helpers.is7pmcross() || props?.isFestival
                            ? Helpers.getNexttoNextday()
                            : props?.isSpecialCake
                            ? Helpers.getNexttoNextday()
                            : Helpers.getNextday()
                        }
                        toDate={addMonths(new Date(), 3)}
                        selected={dpdate}
                        onSelect={(e) => {
                          setdpdate(e);
                          DeliveryDateUpdate(e);
                        }}
                        styles={{
                          caption_label: { zIndex: '0' },
                          caption: { fontSize: '12px' },
                          nav_button_next: { height: '30px', width: '30px' },
                          nav_button_previous: { height: '30px', width: '30px' },
                          button_reset: { fontSize: '14px' },
                          cell: { height: '10px', width: '10px' },
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={'grid grid-cols-1 px-5'}>
                    <div className="block p-4 rounded-lg relative  mb-3 mx-2 shadow-lg">
                      <div className="text-white text-center text-sm py-1 sm:py-10">
                        <MdEditLocationAlt className="mx-auto text-white" size={25} />
                        <span className="block sm:hidden">
                          <br />
                        </span>
                        <button
                          onClick={() => AddAddressModal(true)}
                          className=" text-white text-center bg-gray-700 font-bold rounded-lg sm:mt-4 text-sm px-5 py-2 tracking-wider	"
                        >
                          Add New Address
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {deliveryType === 'pickup' && (
            <>
              <div className={`${isMobile === true ? 'grid' : ''} grid-cols-1 sm:flex sm:flex-row`}>
                <div className={`${isMobile === true ? 'grid' : ''} grid-cols-1 sm:basis-8/12`}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 px-5 mt-1 pb-5">
                    {props?.pickupTime.map((item, value) => {
                      return (
                        <div
                          id={value === 0 ? 'defaulttime' : 'np'}
                          key={value}
                          onClick={(e) => {
                            UpdatePickup(item?.fields?.Title?.value);
                            dispatch(onCheckoutRegion(0, 'pickup'));
                          }}
                          className="block p-3.5 rounded-lg cursor-pointer relative border mb-3 mx-2 shadow-lg bg-white max-w-sm"
                        >
                          {item?.fields?.Title?.value === selectTime && (
                            <HiBadgeCheck
                              className="absolute top-[2px] right-[2px] text-green-600"
                              size={23}
                            />
                          )}
                          <div className="text-black font-bold text-sm">
                            <p className="text-md uppercase" style={{ lineBreak: 'anywhere' }}>
                              <HiClock size={22} className="inline-block mb-1" />{' '}
                              {item?.fields?.Title?.value}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`${
                    isMobile === true ? 'grid' : ''
                  } grid-cols-1 sm:basis-4/12 px-6 sm:px-2 sm:pr-5`}
                >
                  <div className="bg-white rounded-md sm:py-1 mb-4">
                    <DayPicker
                      mode="single"
                      required
                      fromDate={
                        Helpers.is7pmcross() || props?.isFestival
                          ? Helpers.getNexttoNextday()
                          : props?.isSpecialCake
                          ? Helpers.getNexttoNextday()
                          : Helpers.getNextday()
                      }
                      toDate={addMonths(new Date(), 3)}
                      selected={dpdate}
                      onSelect={(e) => {
                        setdpdate(e);
                        DeliveryDateUpdate(e);
                      }}
                      styles={{
                        caption_label: { zIndex: '0' },
                        caption: { fontSize: '12px' },
                        nav_button_next: { height: '30px', width: '30px' },
                        nav_button_previous: { height: '30px', width: '30px' },
                        button_reset: { fontSize: '14px' },
                        cell: { height: '10px', width: '10px' },
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {AddressModal === true && (
            <AddAddress
              modalStatus={AddressModal}
              AddAddressModal={AddAddressModal}
              deliveryTimeRegion={props?.deliveryTimeRegion}
              CheckRegionListCharge={CheckRegionListCharge}
              deliveryTime={deliveryTime}
              onDeliveryAddressSubmit={onDeliveryAddressSubmit}
            />
          )}
          {EditModal === true && (
            <EditModalForm
              modalStatus={EditModal}
              SetEditModal={SetEditModal}
              deliveryTimeRegion={props?.deliveryTimeRegion}
              CheckRegionListCharge={CheckRegionListCharge}
              deliveryTime={deliveryTime}
              EditModalItem={EditModalItem}
              onDeliveryAddressUpdate={onDeliveryAddressUpdate}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
