import React, { useState } from 'react';
import LeftButton from '../UserProfile/LeftButton';
import Order from '../UserProfile/Order';

function Orders() {
  return (
    <div className="bg-black p-6 pb-14">
      <div className="sm:mt-0">
        <div className="grid grid-cols-1	 sm:flex sm:flex-row">
          <div className="basis-full	 md:mt-0">
            <div className="shadow overflow-hidden sm:rounded">
              <div className="px-4 py-5 sm:p-6">
                <Order />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;
