import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import { useSelector } from 'react-redux';
import * as session from '../../helpers/sessionHandler';
import { BsCheck2All } from 'react-icons/bs';
import ViewDetail from '../ViewDetail';
import dic from 'i18next';
import { FaCheck } from 'react-icons/fa';

export default function OrderSummary() {
  const [Login, SetLogin] = useState(false);
  const toggleModal = () => {
    document.getElementById('modal').classList.toggle('hidden');
  };
  const login_status = helpers.CurrentLoginStatus();
  const islogin = localStorage.getItem('islogin');
  const gueststatus_redux = useSelector((state) => state.guest.status);
  const guestdetails_redux = useSelector((state) => state.guest.details);
  const GuestDetails = session.GetGuestDetails();
  const GuestStatus = localStorage.getItem('guestLoggedIn');
  const [modalStatus, setmodalStatus] = useState(false);
  const [getItem, setItem] = useState();
  const cart = localStorage.getItem('cartproducts');
  const jsoncart = JSON.parse(cart);
  const [cartItems, setCartItems] = useState(jsoncart || []);
  const addonpricelist = [];
  useEffect(() => {
    if (login_status === 200) {
      SetLogin(!Login);
    } else {
      if (islogin === 'true') {
        SetLogin(!Login);
      }
    }
  }, [login_status, islogin]);
  return (
    <div className="sub-accordition my-3">
      <div className="accord-title tracking-wider text-black font-bold p-4 bg-white">
        <span className="bg-black text-white text-xs font-semibold mr-2 px-2.5 py-1 rounded">
          3
        </span>
        ORDER SUMMARY{' '}
        {login_status === 200 ||
          islogin === 'true' ||
          (GuestStatus === 'true' && (
            <>
              <BsCheck2All className="inline text-xl font-bold" />
            </>
          ))}
      </div>
      {login_status === 200 || islogin === 'true' || GuestStatus === 'true' ? (
        <div className="accord-description border border-white py-4 px-5">
          <div className="">
            {cartItems && cartItems.length > 0 ? (
              <>
                <div className="">
                  <div className="overflow-x-auto sm:rounded-lg hidden sm:block">
                    <table className="w-full  text-left text-white">
                      <thead className="md:text-sm text-xs text-white-700 uppercase bg-black">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            {dic.t('Cakery-Product Image')}
                          </th>
                          <th scope="col" className="px-4 py-3">
                            {dic.t('Cakery-Product Name')}
                          </th>
                          <th scope="col" className="px-4 py-3">
                            {dic.t('Cakery-Quantity')}
                          </th>
                          <th scope="col" className="px-6 py-3">
                            {dic.t('Cakery-Total Price')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((item, keys) => {
                          const cakename = item?.ItemDetails?.title;
                          const images = item?.ItemDetails?.image;
                          return item.cartaddedservice.map((serviceItem, getkey) => {
                            let addonpricelist = [];
                            addonpricelist.push(helpers.AddonsTotalPrice(serviceItem));
                            // const signatureBox = serviceItem && serviceItem.signatureBox;
                            // const macaroonBox = serviceItem && serviceItem.macaroonBox;
                            const type = serviceItem && serviceItem.type;
                            return (
                              <tr
                                className={'border-t  lg:text-base sm:text-md text-sm'}
                                key={serviceItem.id}
                              >
                                <td scope="row" className="w-[80px]  sm:w-auto">
                                  <img src={images} className="object-cover my-3 h-28 w-44" />
                                </td>
                                <td className="px-2 sm:px-6 sm:py-4">
                                  {cakename} <br /> {serviceItem.serves.value} <br />
                                  {serviceItem.addon.length > 0 && (
                                    <ul className="festive-addons border-b pb-1 mb-2">
                                      <h4 className="text-muted text-sm font-semibold mt-2">
                                        Addons
                                      </h4>
                                      {serviceItem &&
                                        serviceItem.addon.length > 0 &&
                                        serviceItem.addon.map((val, i) => (
                                          <li
                                            className="addon-item flex items-center text-sm text-muted font-medium space-x-3"
                                            key={i}
                                          >
                                            <h4 className="text-sm flex items-center">
                                              <FaCheck className="h-3 w-3 text-green-500 mr-2" />
                                              {val.Qty} x {val.addonName}
                                            </h4>
                                            <h4 className="">
                                              {val.Price} {dic.t('Cakery-AED')}
                                            </h4>
                                          </li>
                                        ))}
                                    </ul>
                                  )}
                                  {helpers.currency}&nbsp;
                                  {serviceItem.price.value} <br />
                                  {serviceItem?.addon &&
                                    serviceItem?.addon?.Price !== (undefined || 0) && (
                                      <>{serviceItem?.addon?.addonName}</>
                                    )}
                                  <br />
                                  {serviceItem?.addon &&
                                    serviceItem?.addon?.Price !== undefined && (
                                      <>{helpers.currency}&nbsp;</>
                                    )}
                                  {serviceItem?.addon &&
                                    serviceItem?.addon?.Price !== undefined && (
                                      <>{serviceItem?.addon?.Price}</>
                                    )}
                                  {serviceItem?.message?.value
                                    ? ` ✉️ ${serviceItem?.message?.value}`
                                    : ''}
                                  {/* {signatureBox.length > 0 || macaroonBox.length ? (
                                    type === 'SIGNATUREBOX' ? (
                                      <span
                                        onClick={() => {
                                          setmodalStatus(!modalStatus);
                                          setItem(signatureBox);
                                        }}
                                        className="text-white text-xs cursor-pointer underline  underline-offset-1"
                                      >
                                        View Details
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() => {
                                          setmodalStatus(!modalStatus);
                                          setItem(macaroonBox);
                                        }}
                                        className="text-white text-xs cursor-pointer underline  underline-offset-1"
                                      >
                                        View Details
                                      </span>
                                    )
                                  ) : (
                                    <></>
                                  )} */}
                                </td>
                                <td className="px-3 sm:px-12 sm:py-4">
                                  {serviceItem.quantity.value}
                                </td>
                                <td className="px-2 sm:px-12 sm:py-4">
                                  {parseInt(serviceItem.price.value * serviceItem.quantity.value) +
                                    parseInt(helpers.ArraySum(addonpricelist))}
                                </td>
                              </tr>
                            );
                          });
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* Small Device Started */}
                  <div className="small-device block sm:hidden">
                    <div className="pointer-events-auto">
                      <div className="flex h-full flex-col overflow-y-scroll bg-black shadow-xl">
                        <div className="flex-1 overflow-y-auto px-4 sm:px-6">
                          <div className="">
                            <div className="flow-root">
                              <ul role="list" className="divide-y divide-gray-200">
                                {cartItems.map((item, keys) => {
                                  const title = item?.ItemDetails?.title;
                                  const images = item?.ItemDetails?.image;
                                  const productkey = keys;
                                  return item.cartaddedservice.map((serviceItem, getkey) => {
                                    const serviceid = serviceItem?.id;
                                    const message = serviceItem?.message?.value;
                                    const price = serviceItem?.price?.value;
                                    const serves = serviceItem?.serves?.value;
                                    const quantity = serviceItem?.quantity?.value;
                                    const servicekey = getkey;
                                    const addon = serviceItem?.addon;
                                    addonpricelist.push(helpers.AddonsTotalPrice(serviceItem));
                                    // addonPrice Comment
                                    // addonpricelist.push(
                                    //   serviceItem?.addon?.Price !== undefined
                                    //     ? serviceItem?.addon?.Price
                                    //     : 0
                                    // );
                                    // const signatureBox = serviceItem && serviceItem.signatureBox;
                                    // const macaroonBox = serviceItem && serviceItem.macaroonBox;
                                    const type = serviceItem && serviceItem.type;

                                    return (
                                      <li className="flex py-6" key={keys}>
                                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                          <img
                                            src={images}
                                            alt="Product Image"
                                            className="h-full w-full object-cover object-center"
                                          />
                                        </div>

                                        <div className="ml-4 flex flex-1 flex-col">
                                          <div>
                                            <div className="flex justify-between text-sm font-medium">
                                              <p className="text-base">
                                                <a href="javascript:;" className="text-white">
                                                  {title}
                                                </a>
                                              </p>
                                            </div>
                                            <p className="mt-1 text-sm text-white">
                                              {serves}
                                              <br />
                                              <span className="text-white">
                                                AED{' '}
                                                {parseInt(price * quantity) +
                                                  parseInt(
                                                    addon?.Price !== undefined ? addon?.Price : 0
                                                  )}
                                              </span>
                                              <span> | Qty {quantity}</span>
                                              {serviceItem?.addon &&
                                                serviceItem?.addon?.Price !== undefined && (
                                                  <>
                                                    {serviceItem?.addon?.addonName +
                                                      ' ' +
                                                      serviceItem?.addon?.Price +
                                                      ' ' +
                                                      serviceItem?.addon?.Currency}
                                                    <br />
                                                  </>
                                                )}
                                              {message !== undefined ? (
                                                <>
                                                  <br /> ✉️ {message}
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </p>
                                            {/* {signatureBox.length > 0 || macaroonBox.length ? (
                                              type === 'SIGNATUREBOX' ? (
                                                <span
                                                  onClick={() => {
                                                    setmodalStatus(!modalStatus);
                                                    setItem(signatureBox);
                                                  }}
                                                  className="text-white text-xs cursor-pointer underline  underline-offset-1"
                                                >
                                                  View Details
                                                </span>
                                              ) : (
                                                <span
                                                  onClick={() => {
                                                    setmodalStatus(!modalStatus);
                                                    setItem(macaroonBox);
                                                  }}
                                                  className="text-white text-xs cursor-pointer underline  underline-offset-1"
                                                >
                                                  View Details
                                                </span>
                                              )
                                            ) : (
                                              <></>
                                            )} */}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  });
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Small Device End */}
                </div>
              </>
            ) : (
              <div className="basis-1/1 px-10 mx-auto  border-white-500 py-20">
                <p className="text-lg text-white py-5 text-center sm:pl-3">
                  {' '}
                  {dic.t('Cakery-No Cart Items')}
                </p>
                <div className="icons mx-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mb-6 text-white mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      {modalStatus === true && (
        <ViewDetail modalStatus={modalStatus} setmodalStatus={setmodalStatus} getItem={getItem} />
      )}
    </div>
  );
}
