export const CART_FUNCTION = 'CART_FUNCTION';
export const CARTADD_FUNCTION = 'CARTADD_FUNCTION';
export const CART_EXIST_CHECKER = 'CART_EXIST_CHECKER';
import axios from 'axios';
import { MicroRoutes } from '../../constants';
import * as session from '../../helpers/sessionHandler';
import { encryptePayload } from '../../helpers/config';

export const onCartAction = (data) => async (dispatch) => {
  let count = 0;
  if (data.length > 0) {
    const cartdata = data.filter((item, key) => {
      item.cartaddedservice.filter((item, key) => {
        count = count + 1;
      });
    });
    dispatch({ type: CART_FUNCTION, payload: count });
  } else {
    dispatch({ type: CART_FUNCTION, payload: 0 });
  }
  dispatch({ type: CART_FUNCTION, payload: count });
  const islogin = session.getLoginStatus();
  if (islogin === 'true' && count > 0) {
    let tokenid = localStorage.getItem('tokenId');
    const { userId } = session.LoggedUserDetails();
    var UpdateCartDetails = '';
    if (userId !== null) {
      UpdateCartDetails = {
        cartJson: {
          data,
        },
        remarks: '',
        userDTO: {
          userId: userId,
        },
      };
      let encryptPayload = encryptePayload(UpdateCartDetails);
      await axios
        .post(MicroRoutes.addtocart, encryptPayload, { headers: { token: `${tokenid}` } })
        .then(async (response) => {
          localStorage.setItem('usercartid', 1);
        })
        .catch((error) => {
          console.log('error while add to cart', error);
        });
    }
  }
};

export const onCartCount = (data) => async (dispatch) => {
  let count = 0;
  const cartdata = data.filter((item, key) => {
    item.cartaddedservice.filter((item, key) => {
      count = count + 1;
    });
  });
  dispatch({ type: CART_FUNCTION, payload: count });
};
