import React from 'react';
import phone from '../../assets/cakes/phone.png';
import whatsapp from '../../assets/cakes/whatsapp.png';
import Facebook from '../CommonIcon/Facebook';
import Google from '../CommonIcon/Google';
import Instagram from '../CommonIcon/Instagram';
import Twitter from '../CommonIcon/Twitter';

const PageFooter = (props) => {
  const quick_links = props?.fields['Quick Links'];
  const social_links = props?.fields['Social Links'];
  const address = props?.fields?.Address?.value;
  const copyrights = props?.fields?.Copyrights?.value;
  const logo = props?.fields?.Logo?.value?.src;

  // social_links[0].fields['SOCIAL_MEDIA_LINKS'].value.href
  return (
    <footer className="bg-[#1d1d1d] text-white -z-10 border-t-2 border-gray-400">
      <div className="container px-6 pt-6 md:m-auto">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
          <div className="mb-0">
            {/* <h5 className="uppercase font-bold mb-2.5">SOCIAL MEDIA LINKS</h5> */}
            <div className="flex justify-center mb-4">
              <img src={logo} className="w-56 h-20 rounded-lg" alt="" />
            </div>
            <div className="mb-4 flex justify-center text-center">
              <div dangerouslySetInnerHTML={{ __html: address }} />
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex justify-center mb-2">
              <a
                href={social_links[0]?.fields['Social Media Link']?.value?.href}
                target={'_blank'}
                type="button"
                className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1"
                rel="noreferrer"
              >
                <Facebook />
              </a>
              <a
                href={social_links[1]?.fields['Social Media Link']?.value?.href}
                target={'_blank'}
                type="button"
                className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1"
                rel="noreferrer"
              >
                <Instagram />
              </a>
            </div>
            <ul className=" flex flex-col justify-center list-none text-center text-[18px] mb-0">
              {quick_links?.map((item, key) => {
                const title = item?.fields['Link Name']?.value;
                const link = item?.fields.Link?.value?.href;
                return (
                  <li className="py-1" key={key}>
                    <a
                      target={
                        title === 'Terms & Conditions' || title === 'Privacy Policy' ? '_blank' : ''
                      }
                      href={link}
                      className="text-white"
                      rel="noreferrer"
                    >
                      {title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: copyrights }} />
    </footer>
  );
};

export default PageFooter;
