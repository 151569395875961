export const SET_CART_DATA = 'SET_CART_DATA';
export const REMOVE_CART_DATA = 'REMOVE_CART_DATA';

export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';

export const SET_CUSTOMER_DELIVERY_DATA = 'SET_CUSTOMER_DELIVERY_DATA';

export const EMPTY_CART = 'EMPTY_CART';
export const UPDATE_CART_DATA = 'UPDATE_CART_DATA';

export const UPDATE_CUSTOMER_DATA_USER = 'UPDATE_CUSTOMER_DATA_USER';
export const REMOVE_ADDON_FROM_CART = 'REMOVE_ADDON_FROM_CART';

export const REMOVE_FESTIVE_ADDON_FROM_CART = 'REMOVE_FESTIVE_ADDON_FROM_CART';

// order screen actions
export const setCenterCart = (data) => (dispatch) => {
  dispatch({ type: SET_CART_DATA, payload: data });
};

export const removeCenterCart = (data) => (dispatch) => {
  dispatch({ type: REMOVE_CART_DATA, payload: data });
};
export const removeAddonFromCart = (data) => (dispatch) => {
  dispatch({ type: REMOVE_ADDON_FROM_CART, payload: data });
};

export const removeFestiveAddonFromCart = (data) => (dispatch) => {
  dispatch({ type: REMOVE_FESTIVE_ADDON_FROM_CART, payload: data });
};

export const emptyCenterCart = (data) => (dispatch) => {
  dispatch({ type: EMPTY_CART, payload: data });
};

// customer screen actions
export const setCustomerInfo = (data) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_DATA, payload: data });
};

// customer info update after user history
export const setCustomerInfoAfterUpdateHistory = (data) => (dispatch) => {
  console.log(data, 'customerInfo');
  dispatch({ type: UPDATE_CUSTOMER_DATA_USER, payload: data });
};

// Delivery screen actions
export const setCustomerDeliveryInfo = (data) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_DELIVERY_DATA, payload: data });
};

// update cake message dynamically
export const setCakeMessage = (data) => (dispatch) => {
  dispatch({ type: UPDATE_CART_DATA, payload: data });
};
