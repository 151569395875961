export const ORDER_FUNCTION = 'ORDER_FUNCTION';
export const ORDER_LOADING = 'ORDER_LOADING';
import axios from 'axios';
import { MicroRoutes, OrdersMicroRoute } from '../../constants';
import * as helpers from '../../helpers/config';
import { modifyPayload } from '../../helpers/modifyPayload';
import { onSentEmail } from '../email/email.action';

export const onOrder = (data, deliverycharge, discount, mailDetails) => async (dispatch) => {
  const getOrderObject = {
    data: data,
    deliverycharge: deliverycharge,
    Discount: discount,
    isAdminScreen: false,
  };
  let encryptPayload = helpers.encryptePayload(getOrderObject);
  dispatch({ type: ORDER_LOADING, payload: true });
  const islogin = localStorage.getItem('islogin');
  let tokenid = '';
  if (islogin === 'true') {
    tokenid = localStorage.getItem('tokenId');
  } else {
    await axios
      .post(MicroRoutes.guestUserToken)
      .then(async (response) => {
        tokenid = response.data.guestUserToken;
      })
      .catch((error) => {
        console.log('error while guest user token', error);
      });
  }
  return await axios
    .post(MicroRoutes.ordercreate + '?token=' + tokenid, encryptPayload)
    .then(async (response) => {
      dispatch({ type: ORDER_LOADING, payload: false });
      if (response.data.orderId) {
        if (data.orderStatus === 'DRAFT') {
          localStorage.setItem('OrderInit', response.data.orderId);
        } else {
          localStorage.removeItem('OrderInit');
        }

        dispatch({
          type: ORDER_FUNCTION,
          loading: true,
          payload: {
            status: 200,
            OrderId: response.data.orderId,
            statusMessage: 'Order has been Created Successfully',
          },
        });
        return response.data.orderId;
      } else {
        dispatch({
          type: ORDER_FUNCTION,
          loading: true,
          payload: {
            status: 400,
            OrderId: null,
            statusMessage:
              'We regret to inform you that your Order could not be processed.<br/> Please contact us on <a href="tel:526801334">526801334</a> or Email us at <a href="mailto:CreationsCakery@jumeirah.com">CreationsCakery@jumeirah.com</a>',
          },
        });
        return null;
      }
    })
    .catch((error) => {
      console.log('error while order', error);

      // Mail Code goes here
      dispatch(onSentEmail(mailDetails, error, 'onOrder catch'));
      return null;
    });
};
