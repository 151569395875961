const MicroEndPoint = process.env.NODE_ENV === 'development' ? 'http://localhost:9001' : '';
const ServiceEndPoint = process.env.REACT_APP_SERVICES_ENDPOINT;
export const EmailServiceURL = `${MicroEndPoint}/services/send-email`;
export const FrontEndURL =
  process.env.NODE_ENV === 'development' ? 'https://uat.creationscakery.com' : 'https://creationscakery.com';
export const ServicesEndpoint =
  process.env.NODE_ENV === 'development'
    ? 'https://servicesuat.jumeirah.com'
    : 'https://services.jumeirah.com';
export const OCPAPIMSUBKEY = process.env.NODE_ENV === 'development' ? '656ffb30619c456da95f7e80d19c593f' : '8537c314c33842d99fc76f12c0f0fcd2';
export const CurrentEnvironment = 'PROD';
export const reactAppSecretScan = '16KKymy#90!#8WU$d@28$@%';
export const MicroRoutes = {
  register: `${MicroEndPoint}/cakery/register`,
  login: `${MicroEndPoint}/cakery/login-user`,
  forgot: `${MicroEndPoint}/cakery/forgotPassword`,
  guestUserToken: `${MicroEndPoint}/cakery/guest-user-token`,
  decryptToken: `${MicroEndPoint}/cakery/decrypt-token`,
  update: `${MicroEndPoint}/cakery/updatePassword`,
  getProfile: `${MicroEndPoint}/cakery/getProfile`,
  updateProfile: `${MicroEndPoint}/cakery/updateProfile`,
  addtocart: `${MicroEndPoint}/cakery/add-Cart`,
  ordercreate: `${MicroEndPoint}/cakery/ordercreate`,
  paymentorder: `${MicroEndPoint}/cakery/paymentorder`,
  paymentstatus: `${MicroEndPoint}/cakery/getpaymentstatus`,
  viewOrderbyCustId: `${MicroEndPoint}/cakery/viewOrderbyCustId`,
  CartUserId: `${MicroEndPoint}/cakery/cart-userid`,
  ContactUs: `${MicroEndPoint}/cakery/contactus`,
  createInvoiceOrder: `${MicroEndPoint}/cakery/invoice`,
  ResendInvoiceOrder: `${MicroEndPoint}/cakery/resend-invoice`,
  onSentEmail: `${MicroEndPoint}/cakery/onSentEmail`,
  onSentEmailTurkey: `${MicroEndPoint}/cakery/onSentEmailTurkey`
};

export const OrdersMicroRoute = {
  createOrder: `${MicroEndPoint}/orders/createOrder`,
  getAllOrders: `${MicroEndPoint}/orders/getAllOrders`,

};

export const EndUserRoutes = {
  redirectUrl: `${FrontEndURL}/en/order-confirmation`,
};

export const AdminMicroRoutes = {
  getalluser: `${MicroEndPoint}/cakery/all-users-for-admin`,
  getallroles: `${MicroEndPoint}/cakery/all-roles`,
  getdashboard: `${MicroEndPoint}/cakery/dashboard-details`,
  updateuser: `${MicroEndPoint}/cakery/update-user`,
  updateorder: `${MicroEndPoint}/cakery/update-order`,
  getorderhistory: `${MicroEndPoint}/cakery/user-order-history`,
  getallorders: `${MicroEndPoint}/cakery/all-orders`,
  filterorders: `${MicroEndPoint}/cakery/filter-orders`,
  advancefilterorders: `${MicroEndPoint}/cakery/advance-filter-orders`,
  getdocument: `${MicroEndPoint}/cakery/get-documents`,
  uploaddocument: `${ServicesEndpoint}/cakeryservice/1.0/hospitality/cakery/document-upload`,
  orderReport: `${MicroEndPoint}/cakeryreport/order-report`,
  kitchenReport: `${MicroEndPoint}/cakeryreport/kicthen-report`,
  getNiPaymentStatus:`${MicroEndPoint}/cakeryreport/getNiPaymentStatus`
};

export const PaymentMicroRoutes = {
  createorder: `${MicroEndPoint}/orders`,
  createInvoiceOrder: `${MicroEndPoint}/invoice`,
  ResendInvoiceOrder: `${MicroEndPoint}/cakery/resend-invoice`
};

export const USER_END_PERMISSION = {
  home: 'HOME',
  regularCakes: 'REGULAR',
  customizeCake: 'CUSTOMIZE',
  dashboard: 'DASHBOARD',
  callcenter: 'CALL_CENTER',
  cart: 'CART',
  kitchendisplay: 'KITCHEN_DISPLAY',
  festiveMenu: 'FESTIVE_MENU',
};

export const ADMIN_PERMISSION = {
  dashboard: 'DASHBOARD',
  orders: 'ORDERS',
  kitchenDisplay: 'KITCHEN_DISPLAY',
  draftOrders:'DRAFT_ORDERS',
  callcenter: 'CALL_CENTER',
  employess: 'EMPLOYEES',
};

export const SITECORE_TABS = {
  HOME: 'Home',
  REGULAR_CAKES: 'Regular Cakes',
  CUSTOMIZE_CAKE: 'Customize Cake',
  DASHBOARD: 'Dashboard',
  CALLCENTER: 'Call Center',
  KITCHEN_DISPLAY: 'Kitchen Display',
  ADDTOCART: 'Add To Cart',
  FESTIVEMENU: 'Festive Menu',
  FRECH_PASTRY: 'French Pastry',
  SPECIALCAKES: 'Special Cakes'
};

export const ADMIN_TABS = {
  DASHBOARD: 'Dashboard',
  ORDERS: 'Orders',
  KITCHEN_DISPLAY: 'Kitchen Display',
  DRAFT_ORDERS:'Draft Orders',
  CALL_CENTER: 'Call Center',
  EMPLOYEES: 'Employees',
};

export const DEFALUT_PERMISSION = ['HOME', 'REGULAR', 'CUSTOMIZE', 'CART', 'FESTIVE_MENU'];

