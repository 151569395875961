import React, { Fragment, useState } from 'react';
import { IconContext } from 'react-icons';
import { BiCycling } from 'react-icons/bi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import dic from 'i18next';
import { BsCardList } from 'react-icons/bs';
import { BsCardChecklist } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { SELECTED_DELIVERY_TYPE } from '../../../actions/admin/GetAllOrder.action';

const FilterOrderTypes = ({ setdeliveryType, deliveryType }) => {
  const dispatch = useDispatch()
  return (
    <Fragment>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
        <div
          className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${deliveryType === 'ALL' ? 'border-gray-500' : 'border-gray-200'
            } hover:cursor-pointer`}
          onClick={() => {
            dispatch({ type: SELECTED_DELIVERY_TYPE, payload: 'ALL' })
            setdeliveryType('ALL')
          }}
        >
          <div className="flex">
            <div className="flex items-center">
              <IconContext.Provider
                value={{
                  className: `inline-flex ${deliveryType === 'ALL' && 'text-gray-800'}`,
                }}
              >
                <BsCardList size={20} />
              </IconContext.Provider>
            </div>
            <div className="ml-2 text-xl">
              <label htmlFor="helper-checkbox" className="font-medium text-gray-900">
                ALL
              </label>
            </div>
          </div>
        </div> <div
          className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
            deliveryType === 'DELIVERY' ? 'border-gray-500' : 'border-gray-200'
          } hover:cursor-pointer`}
          onClick={() => {
            dispatch({ type: SELECTED_DELIVERY_TYPE, payload: 'DELIVERY' })
            setdeliveryType('DELIVERY')
          }}
        >
          <div className="flex">
            <div className="flex items-center">
              <IconContext.Provider
                value={{
                  className: `inline-flex ${deliveryType === 'DELIVERY' && 'text-gray-800'}`,
                }}
              >
                <BiCycling size={20} />
              </IconContext.Provider>
            </div>
            <div className="ml-2 text-xl">
              <label htmlFor="helper-checkbox" className="font-medium text-gray-900">
                {dic.t('Cakery-Delivery')}
              </label>
            </div>
          </div>
        </div>
        <div
          className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
            deliveryType === 'PICKUP' ? 'border-gray-500' : 'border-gray-200'
          } hover:cursor-pointer`}
          onClick={() => {
            dispatch({ type: SELECTED_DELIVERY_TYPE, payload: 'PICKUP' })
            setdeliveryType('PICKUP')
          }}
        >
          <div className="flex">
            <div className="flex items-center">
              <IconContext.Provider
                value={{
                  className: `inline-flex ${deliveryType === 'PICKUP' && 'text-gray-800'}`,
                }}
              >
                <RiShoppingBasketLine size={20} />
              </IconContext.Provider>
            </div>
            <div className="ml-2 text-xl">
              <label htmlFor="helper-checkbox" className="font-medium text-gray-900 ">
                {dic.t('Cakery-Pick Up')}
              </label>
            </div>
          </div>
        </div>

      </div>
    </Fragment>
  );
};

export default FilterOrderTypes;
