import React, { Fragment, useState } from 'react';
import DeliveryTime from './DeliveryTime';
import DeliveryType from './DeliveryType';
import OrderType from './OrderType';
import PickUpType from './PickUpType';

const Delivery = ({
  deliverycharges,
  setdeliveryType,
  setdptime,
  dptime,
  deliveryType,
  FestiveDeliveryTime,
  deliveryTime,
  PickupTime,
  isFestival,
  FestivalDeliveryCharges,
}) => {
  return (
    <Fragment>
      <div className="relative overflow-x-auto border-2 border-gray-300 shadow-md rounded p-4 sm:col-span-8">
        <OrderType setdeliveryType={setdeliveryType} deliveryType={deliveryType} />
        {deliveryType === 'PICKUP' && (
          <DeliveryTime
            setdptime={setdptime}
            dptime={dptime}
            FestiveDeliveryTime={FestiveDeliveryTime}
            deliveryTime={deliveryTime}
            isFestival={isFestival}
            PickupTime={PickupTime}
          />
        )}

        {deliveryType === 'DELIVERY' && (
          <DeliveryType
            deliverycharges={deliverycharges}
            isFestival={isFestival}
            FestivalDeliveryCharges={FestivalDeliveryCharges}
            deliveryTime={deliveryTime}
            setdptime={setdptime}
          />
        )}
        {deliveryType === 'PICKUP' && <PickUpType />}
      </div>
    </Fragment>
  );
};

export default Delivery;
