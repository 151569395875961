export const ORDER_FUNCTION = 'ORDER_FUNCTION';
export const ORDER_LOADING = 'ORDER_LOADING';
import axios from 'axios';
import { MicroRoutes } from '../../constants';
import * as helpers from '../../helpers/config';

export const onSentEmail = (data, error, triggeredFrom) => async (dispatch) => {
  await axios
    .post(MicroRoutes.onSentEmail, { data: data, error: error, TriggeredFrom: triggeredFrom })
    .then((val) => {
      console.log('Email Triggered success', val);
      return val;
    })
    .catch((error) => {
      console.log('Email Triggered Error', error);
      return error;
    });
};

export const onSentEmailTurkey = (data) => async (dispatch) => {
  // .post(MicroRoutes.onSentEmailTurkey, { data: data })
  await axios
    .post(MicroRoutes.onSentEmail, { data: data })
    .then((val) => {
      console.log('Email Triggered success', val);
      return val;
    })
    .catch((error) => {
      console.log('Email Triggered Error', error);
      return error;
    });
};
