import React, { Fragment } from 'react';
import { MdClose } from 'react-icons/md';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import dic from 'i18next';

const EditAddress = ({ setEditModal, regionlist }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ defaultValues: { region: '' } });
  const onSubmits = (data) => {
    const deliveryAddress = {
      region: data.region.label,
      makani: data.makahani,
      address: data.address,
      deliveryPrice: Number(data.region.value),
    };
    dispatch(setCustomerDeliveryInfo({ deliveryAddress }));
    console.log('deliveryAddress', deliveryAddress);
    reset();
    setEditModal(false);
  };
  return (
    <Fragment>
      <div className=" fixed z-10 overflow-y-auto top-0 w-full left-0" id="EditAddress">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block  align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100 rounded px-8 pt-6 pb-8 w-full">
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:text-white"
                onClick={() => setEditModal(false)}
              >
                <MdClose className="w-5 h-5" />
              </button>
              <h1 className="block text-black-700 text-xl font-bold mb-4">
                {dic.t('Cakery-Add Address')}
              </h1>
              <form className="pt-1 px-1" onSubmit={handleSubmit(onSubmits)}>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Controller
                      name="region"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            options={regionlist}
                            placeholder={`${dic.t('Cakery-Region') + ' *'}`}
                            styles={colourStyles}
                            className="placeholder:tracking-wider"
                            maxMenuHeight={100}
                          />
                        );
                      }}
                    />
                    {errors.region && errors.region.type === 'required' ? (
                      <span className="text-red-400">{dic.t('Cakery-Region is Required')}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="relative z-0 w-full">
                    <input
                      type="text"
                      className="block w-full placeholder:tracking-wider pt-1 px-1.5 pb-1 text-sm text-gray-900 bg-transparent rounded-md border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                      placeholder={dic.t('Cakery-Makani No')}
                      {...register('makahani')}
                      autoComplete="off"
                    />
                    {errors.makahani && errors.makahani.message && (
                      <span className="text-red-400">{errors.makahani.message}</span>
                    )}
                  </div>
                  <div className="w-full col-span-2">
                    <input
                      type="text"
                      className="block w-full pt-1 px-1.5 pb-1 text-sm text-gray-900 bg-transparent rounded-md border border-gray-400 appearance-none h-10"
                      placeholder={`${dic.t('Cakery-Detailed Address') + ' *'}`}
                      {...register('address', {
                        required: 'Address is Required',
                      })}
                      autoComplete="off"
                    />
                    {errors.address && errors.address.message && (
                      <span className="text-red-400">{errors.address.message}</span>
                    )}
                  </div>
                  <div className="text-center col-span-2">
                    <button
                      type="submit"
                      className="text-white my-2 bg-gray-700  hover:bg-gray-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditAddress;
