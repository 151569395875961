import React, { useState, Fragment } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import {
  CallCenterCountryCodeStyles,
  CountryCodeStyles,
  emailPattern,
  lettersspace,
  passwordPattern,
  phonepattern,
} from '../../../helpers/Patterns';
import * as helpers from '../../../helpers/config';
import { colourStyles } from '../../../helpers/Patterns';
import { GiCook } from 'react-icons/gi';
import { RiCustomerService2Fill, RiAdminFill } from 'react-icons/ri';
import { IconContext } from 'react-icons/lib';
import { onuserhistory } from '../../../actions/admin/UserHistory.action';
import {
  setCustomerInfo,
  setCustomerInfoAfterUpdateHistory,
} from '../../../actions/admin/CallCenter.action';
import dic from 'i18next';
import { useEffect } from 'react';

const CustomerForm = (props) => {
  let countrycodeoptions = [];
  const { orders } = useSelector((state) => state.orderhistory);
  props &&
    props.codes.map((item) => {
      let phonemaxlength = item?.fields?.['Phone Number Maximum Length']?.value;
      let phoneminlength = item?.fields?.['Phone Number Minimum Length']?.value;
      let name = item?.fields?.Title?.value;
      let code = item?.fields?.['Country Code']?.value;
      countrycodeoptions.push({
        label: code + ' - ' + name,
        value: {
          name: name,
          code: code,
          max: phonemaxlength,
          min: phoneminlength,
        },
      });
    });
  const loggeddetails = helpers.LoggedUserDetails();
  const userEmailId = loggeddetails?.userEmailId;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    setValue,
  } = useForm();
  const { customerInfo } = useSelector((state) => state.callcenter);
  const [getContact, setContact] = useState(
    customerInfo
      ? customerInfo.CustomerPhoneNumber &&
          customerInfo.CustomerPhoneNumber.replace(/\D/g, '').slice(-10)
      : ''
  );
  const [phonemin, setPhoneMin] = useState();
  const [phonemax, setPhoneMax] = useState();
  const [orderFlag, setOrderFlag] = useState(false);
  const handleCheckNumber = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setContact(value);
  };
  useEffect(() => {
    if (orders && orders.length > 0 && orderFlag) {
      let Names = orders && orders[0].customerName;
      let Email = orders && orders[0].customerEmail;
      let Status = orders && orders[0].history;
      let Remarks = '';
      let firstName = Names;
      let lastName = '';
      setValue('firstname', firstName);
      setValue('lastname', lastName);
      setValue('email', Email);
      setValue('Status', Status);
      setValue('Remarks', Remarks);

      const customerdataafter = {
        CustomerEmail: Email,
        CustomerFirstName: firstName,
        CustomerLastName: lastName,
        StatusOfGuest: Status,
        Remarks: Remarks
      };
      dispatch(setCustomerInfoAfterUpdateHistory(customerdataafter));
    }
  }, [orders]);


  const onSubmits = (data) => {
    let customerEmail = '';
    // let countrycode = '';
    if (data.email === '') {
      customerEmail = userEmailId;
    } else {
      customerEmail = data.email;
    }
    // if (data.code) {
    //   countrycode = data.code.value.code;
    // }
    const custdata = {
      CustomerPhoneNumber: data.phone,
      CustomerEmail: customerEmail,
      CustomerFirstName: data.firstname,
      CustomerLastName: data.lastname,
      StatusOfGuest: data.Status,
      Remarks: data.Remarks,
    };
    const payload = {
      searchKey: 'phoneNumber',
      searchValue: data.phone,
    };
    dispatch(onuserhistory(payload))
    dispatch(setCustomerInfo(custdata));
    setOrderFlag(true);
  };
  const onCountryCodeChange = (e) => {
    setContact('');
    setPhoneMin(e.value.min);
    setPhoneMax(e.value.max);
  };
  let selectCountryvalue = [];
  if (customerInfo?.customerCountryCode?.label !== undefined) {
    selectCountryvalue = {
      label:
        customerInfo && customerInfo.customerCountryCode && customerInfo.customerCountryCode.label,
      value: {
        name:
          customerInfo &&
          customerInfo.customerCountryCode &&
          customerInfo.customerCountryCode.value &&
          customerInfo.customerCountryCode.value.name,
        code:
          customerInfo &&
          customerInfo.customerCountryCode &&
          customerInfo.customerCountryCode.value &&
          customerInfo.customerCountryCode.value.code,
        max:
          customerInfo &&
          customerInfo.customerCountryCode &&
          customerInfo.customerCountryCode.value &&
          customerInfo.customerCountryCode.value.max,
        min:
          customerInfo &&
          customerInfo.customerCountryCode &&
          customerInfo.customerCountryCode.value &&
          customerInfo.customerCountryCode.value.min,
      },
    };
  } else {
    selectCountryvalue = {
      label: '+971 - United Arab Emirates',
      value: {
        name: '+971',
        code: '+971',
        max: '7',
        min: 'United Arab Emirates',
      },
    };
  }

  return (
    <Fragment>
      <div className="rounded shadow-md border-2 border-gray-300 p-4 w-full">
        <form className="grid grid-cols-12 gap-2" onSubmit={handleSubmit(onSubmits)}>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              name="user_name"
              maxLength={20}
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder={dic.t('Cakery-Full Name')}
              defaultValue={customerInfo && customerInfo.CustomerFirstName}
              {...register('firstname', {
                pattern: {
                  value: lettersspace,
                  message: 'Please enter a valid customer name',
                },
              })}
              autoComplete="off"
              onChange={(e) => dispatch(setCustomerInfoAfterUpdateHistory({ CustomerFirstName: e.target.value }))}
            />
            {errors.firstname && errors.firstname.message && (
              <span className=" mt-4 text-xs text-red-400">{errors.firstname.message}</span>
            )}
          </div>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder={dic.t('Cakery-Phone Number')}
              // defaultValue={}
              {...register('phone', {
                required: 'Phone is Required',
                maxLength: phonemax,
                minLength: phonemin,
              })}
              value={getContact}
              // maxLength={15}
              onChange={handleCheckNumber}
              autoComplete="off"
            />
            {errors.phone && errors.phone.message && (
              <span className="text-xs mt-4 text-red-400">{errors.phone.message}</span>
            )}
            {errors.phone && errors.phone.type === 'maxLength' && (
              <span className="text-red-400 text-sm">Maximum Length Exceed</span>
            )}
            {errors.phone && errors.phone.type === 'minLength' && (
              <span className="text-red-400 text-sm">Minimum Length Not Reached</span>
            )}
          </div>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder={dic.t('Cakery-Email')}
              defaultValue={customerInfo && customerInfo.CustomerEmail}
              {...register('email', {
                pattern: {
                  value: emailPattern,
                  message: 'Please enter a valid email',
                },
              })}
              autoComplete="off"
              onChange={(e) => dispatch(setCustomerInfoAfterUpdateHistory({ CustomerEmail: e.target.value }))}
            />
            {errors.email && errors.email.message && (
              <span className="mt-4 text-xs text-red-400">{errors.email.message}</span>
            )}
          </div>
          <div className="w-full mb-3 col-span-6">
            <input
              type="text"
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder={dic.t('Cakery-Status of Guest')}
              maxLength={200}
              defaultValue={customerInfo && customerInfo.StatusofGuest}
              {...register('Status', {
                pattern: {
                  value: lettersspace,
                  message: 'Please enter a valid text',
                },
              })}
              autoComplete="off"
              onChange={(e) => dispatch(setCustomerInfoAfterUpdateHistory({ StatusOfGuest: e.target.value }))}
            />
          </div>
          <div className="w-full mb-3 col-span-12">
            <input
              type="text"
              className="border border-gray-300 p-2 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder={dic.t('Cakery-Remarks')}
              maxLength={200}
              // defaultValue={customerInfo && customerInfo.Remarks}
              {...register('Remarks')}
              autoComplete="off"
              onChange={(e) => dispatch(setCustomerInfoAfterUpdateHistory({ Remarks: e.target.value }))}

            />
          </div>
          <div className="col-span-12 text-right">
            <button
              type="submit"
              className="text-white my-2 bg-gray-700 tracking-wider hover:bg-gray-500 font-medium rounded-lg text-md w-full px-5 py-2 text-center"
            >
              {dic.t('Cakery-Submit')}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default CustomerForm;
