import React, { Fragment } from 'react';
import { BsPlus } from 'react-icons/bs';

function Increment() {
  return (
    <Fragment>
      <BsPlus className="text-white relative bottom-[0.2rem]" size={26} />
    </Fragment>
  );
}

export default Increment;
