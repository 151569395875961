import {
  USER_LIST_LOADING,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE,
} from '../../actions/admin/Userlist.action';

import { UPDATE_USER_SUCCESS, UPDATE_USER_LOADING } from '../../actions/admin/UpdateUser.action';

const initailState = {
  loading: false,
  users: [],
  error: '',
  updateloading: false,
  updateMessage: '',
};

export const allUserReducer = (state = initailState, action) => {
  switch (action.type) {
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_LIST_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case UPDATE_USER_SUCCESS:
      const UpdateUserIndex = state.users.findIndex(
        (users) => users.userId === action.payload.userData.userId
      );
      const newusers = [...state.users];
      newusers[UpdateUserIndex] = action.payload.userData;
      return {
        ...state,
        users: newusers,
        updateMessage: action.payload.message,
      };

    case UPDATE_USER_LOADING:
      return {
        ...state,
        updateloading: action.payload,
      };
    default:
      return state;
  }
};
