/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import { BsXCircleFill } from 'react-icons/bs';
import Info from '../CommonIcon/Info';
import dic from 'i18next';
import CartControls from '../CartControls';

function ProductService(props) {
  const { modalStatus, servicelist, productAddons } = props;
  const messagePlaceholder = (
    servicelist?.fields?.Message?.value ?? dic.t('Cakery-Message To Put On The Cake')
  ).trim();
  const [getServes, setServes] = useState();
  const [getCheckRadion, setCheckRadio] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [Addon, setAddon] = useState('');
  const service = servicelist?.fields?.Services;
  const [selectedValues, setSelectedValues] = useState([]);
  const checkRadionValidate = (serviceid) => {
    setShowMessage(false);
    setCheckRadio(serviceid);
  };
  // const AddingAddon = (name, price, currency) => {
  //   const list = {
  //     addonName: name,
  //     Price: Number(price),
  //     Currency: currency,
  //   };
  //   setAddon(list);
  // };
  const closeModal = () => {
    setShowMessage(false);
    props.closeModal();
    document.getElementById('message').value = '';
  };
  const callingCartTrigger = () => {
    window.dataLayer.push({
      event: 'add_to_cart',
      page_url: window.location.href,
    });
    if (getCheckRadion === '') {
      setShowMessage(true);
      return false;
    }
    const serviceid = getCheckRadion;
    const message = document.querySelector('.messagebox').value;
    const price = document.querySelector('.service-checkbox:checked').id;
    const serves = getServes;
    props.onCartFunction(servicelist, serviceid, message, price, serves, selectedValues, 'plus');
    setCheckRadio('');
    document.getElementById('message').value = '';
  };
  const handleCheckboxChange = (addonName, addonPrice, currency) => {
    const selectedValuesArray = Array.isArray(selectedValues) ? selectedValues : [];
    const isOptionSelected = selectedValuesArray.some((value) => value.addonName === addonName);
    if (isOptionSelected) {
      const newSelectedValues = selectedValuesArray.filter(
        (value) => value.addonName !== addonName
      );
      setSelectedValues(newSelectedValues);
    } else {
      const newSelectedValues = [
        ...selectedValuesArray,
        {
          addonName: addonName,
          Price: Number(addonPrice),
          Currency: currency,
          Qty: 1,
        },
      ];
      setSelectedValues(newSelectedValues);
    }
  };

  const filterselectedValues = (val) => {
    let temp = selectedValues?.filter((value) => value.addonName === val);
    return temp[0];
  };

  const addonQtychange = (val, quantity) => {
    let newData = [];
    selectedValues.map((value) => {
      if (value.addonName === val) {
        newData.push({
          addonName: value.addonName,
          Price: Number(value.Price),
          Currency: value.Currency,
          Qty: quantity,
        });
      } else {
        newData.push(value);
      }
    });
    setSelectedValues(newData);
  };

  const handleInput = (event) => {
    let inputValue = event.target.value;
    console.log(inputValue, 'InputValue');
    const englishAndNumbers = /^[a-zA-Z0-9 ]*$/; // Regex for English letters and numbers

    if (!englishAndNumbers.test(inputValue)) {
      // If the input contains characters other than English letters and numbers, remove them
      inputValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, '');
    }
    event.target.value = inputValue;
  };
  return (
    <React.Fragment>
      <div
        className={`fixed z-20 overflow-y-auto top-0 w-full left-0 ${
          modalStatus === true ? '' : 'hidden'
        }`}
        id="servicemodal"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block  align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg  w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white sm:pb-4">
              <div className="modal-body">
                <h4 className="text-lg text-center bg-gray-700 font-semibold tracking-wider py-3">
                  {dic.t('Cakery-Customize Your Order')}
                </h4>
                <div className="position-relative">
                  <BsXCircleFill
                    onClick={() => closeModal()}
                    className="absolute right-[17px] top-[19px] cursor-pointer text-lg"
                  />
                </div>
                <div className="text-black px-5 pt-2">
                  <table className="table-auto w-full mb-3 ">
                    <thead className="text-base border-b-2 border-gray-300  font-bold">
                      <tr>
                        <th className="pb-2 px-3">{dic.t('Cakery-Serves')}</th>
                        <th className="pb-2">{dic.t('Cakery-Price')}</th>
                        <th className="pb-2"></th>
                      </tr>
                    </thead>
                    <tbody className="text-sm">
                      {service &&
                        service.map((serviceitem, keys) => {
                          const servicetopeople =
                            serviceitem &&
                            serviceitem.fields['Service To People'] &&
                            serviceitem.fields['Service To People'].fields &&
                            serviceitem.fields['Service To People'].fields.Title &&
                            serviceitem.fields['Service To People'].fields.Title.value;
                          const price =
                            serviceitem && serviceitem.fields && serviceitem.fields.Price.value;
                          const serviceid = serviceitem.id;
                          return (
                            <tr key={keys}>
                              <td
                                className="pt-3 px-1 sm:px-3 servicetopeople"
                                id={servicetopeople}
                              >
                                {servicetopeople}
                              </td>
                              <td className="text-xs sm:text-sm">
                                {helpers.currency} {price}
                              </td>
                              <td className="">
                                <div className="flex items-center">
                                  <input
                                    value={serviceid}
                                    id={price}
                                    type="radio"
                                    name="radioList"
                                    onChange={() => setServes(servicetopeople)}
                                    onClick={() => checkRadionValidate(serviceid)}
                                    className="w-4 h-4   service-checkbox cursor-pointer"
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {showMessage === true && (
                    <div
                      className="flex p-4 mb-1 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800"
                      role="alert"
                    >
                      <Info />
                      <div className="inline">
                        <span className="font-medium pt-3 px-2">
                          {dic.t('Cakery-Select a serve from the serves list')}
                        </span>
                      </div>
                    </div>
                  )}
                  {productAddons.length > 0 && (
                    <>
                      <p className="border-b-gray-200 font-bold border-b-2 px-3"></p>
                      <div className="my-2 h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300">
                        {productAddons.map((item, keys) => {
                          const charges = item && item.fields && item.fields.Charges;
                          const addonName =
                            item && item.fields && item.fields.Title && item.fields.Title.value;
                          return (
                            <div
                              className="addons px-3 flex justify-between space-x-4 border-t-2-gray"
                              key={keys}
                            >
                              <div className="each-addon-title flex-1">
                                <p className="font-normal text-sm">{addonName}</p>
                              </div>
                              <div className="quantity-control">
                                {selectedValues &&
                                  selectedValues.some((value) => value.addonName === addonName) && (
                                    <CartControls
                                      qty={filterselectedValues(addonName).Qty}
                                      onValueChange={(quantity) => {
                                        addonQtychange(addonName, quantity);
                                      }}
                                    />
                                  )}
                              </div>
                              <div className="each-addon-price pb-2">
                                <div className="flex">
                                  {charges.length > 0 &&
                                    charges.map((chargeItem, keyvalue) => {
                                      const currencyCode = dic.t('Cakery-AED');
                                      const addonPrice =
                                        chargeItem &&
                                        chargeItem.fields &&
                                        chargeItem.fields.Price &&
                                        chargeItem.fields.Price.value;
                                      return (
                                        <React.Fragment key={keyvalue}>
                                          <p className="px-2 col-span-9 sm:col-span-8">
                                            {chargeItem.name}
                                          </p>
                                          <div className="text-xs py-1 sm:py-0 sm:text-sm col-span-3 sm:col-span-4">
                                            <input
                                              type="checkbox"
                                              name={addonName.toLowerCase().split(' ').join('-')}
                                              onChange={() =>
                                                handleCheckboxChange(
                                                  addonName,
                                                  addonPrice,
                                                  currencyCode
                                                )
                                              }
                                              checked={
                                                selectedValues &&
                                                selectedValues.some(
                                                  (value) => value.addonName === addonName
                                                )
                                              }
                                              className="align-middle cursor-pointer"
                                            />
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}

                  <div className="message-box px-2 pt-1">
                    <textarea
                      id="message"
                      rows="2"
                      maxLength={50}
                      className="block p-2.5 messagebox w-full text-sm text-black bg-gray-50 rounded-lg border border-gray-500 outline-none"
                      placeholder={messagePlaceholder}
                      onInput={handleInput}
                      style={{ fontFamily: 'jumeirah-font2' }}
                      dir="auto"
                    ></textarea>
                    <span className="font-bold text-sm">
                      Note: Please provide messages in English only.
                    </span>
                  </div>
                  <div className="pt-3 pb-5 sm:pb-0 text-base  text-center">
                    <button
                      onClick={() => callingCartTrigger(servicelist)}
                      className={
                        'text-white  bg-gray-700 font-bold   tracking-wider  lg:mr-1 rounded-lg text-sm px-5 sm:mr-1 mr-2 sm:px-6 py-2 text-center'
                      }
                    >
                      {dic.t('Cakery-Add')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default ProductService;
