import React, { Fragment, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { emailPattern } from '../../../helpers/Patterns';
import { colourStyles } from '../../../helpers/Patterns';
import Select from 'react-select';
import { setCustomerDeliveryInfo } from '../../../actions/admin/CallCenter.action';
// import TextareaAutosize from 'react-textarea-autosize';
import dic from 'i18next';

const DeliveryAddress = ({
  setAddModel,
  regionlist,
  deliverycharges,
  setdptime,
  isFestival,
  FestivalDeliveryCharges,
  deliverytimelist,
}) => {
  const dispatch = useDispatch();
  let deliverytimeList = [];
  const { deliveryAddress } = useSelector((state) => state.callcenter.customerDeliveryInfo);
  const [deliveryTime, setDeliveryTime] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ defaultValues: { region: '' } });
  const onSubmits = (data) => {
    const deliveryAddress = {
      region: data.region.label,
      makani: data.makahani,
      address: data.address,
      deliveryPrice: Number(data.region.value),
    };
    dispatch(setCustomerDeliveryInfo({ deliveryAddress }));
    // reset();
    // setAddModel(false);
  };
  const checkRegion = (e) => {
    let getregiontime = [];
    if (isFestival) {
      getregiontime = FestivalDeliveryCharges.filter(
        (item) =>
          e.label.toLowerCase() === item?.fields?.Region?.fields?.Region?.value.toLowerCase()
      );
    } else {
      getregiontime = deliverycharges.filter(
        (item) =>
          e.label.toLowerCase() === item?.fields?.Region?.fields?.Region?.value.toLowerCase()
      );
    }

    if (getregiontime.length > 0) {
      const call_center_time = getregiontime[0]?.fields?.Region?.fields?.Timings;
      call_center_time.map((items, key) => {
        const time = items.fields.Title.value;
        deliverytimeList.push({
          label: time,
          value: time,
        });
      });
      setDeliveryTime(deliverytimeList);
      setValue('deliverytime', null);
    }
  };
  const checkDeliveryTime = (e) => {
    setdptime(e.value);
  };
  return (
    <Fragment>
      {/* Body Start */}
      <div className=" rounded p-4  w-full z-10">
        {/* <h1 className="block text-black-700 text-xl font-bold mb-4">
          {dic.t('Cakery-Add Address')}
        </h1> */}
        <form className="pt-1 px-1" onSubmit={handleSubmit(onSubmits)}>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <Controller
                name="region"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={regionlist}
                    placeholder={`${dic.t('Cakery-Region') + ' *'}`}
                    value={value}
                    styles={colourStyles}
                    onChange={(e) => {
                      checkRegion(e);
                      onChange(e);
                    }}
                    maxMenuHeight={140}
                  />
                )}
              />
              {errors.region && errors.region.type === 'required' ? (
                <span className="text-red-400">{dic.t('Cakery-Region is Required')}</span>
              ) : (
                <></>
              )}
            </div>
            <div className="relative z-0 w-full col-span-2">
              <Controller
                name="deliverytime"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={deliverytimelist}
                    placeholder={`${dic.t('Cakery-Delivery Time') + ' *'}`}
                    value={value}
                    styles={colourStyles}
                    onChange={(e) => {
                      checkDeliveryTime(e);
                      onChange(e);
                    }}
                  />
                )}
              />
              {errors.deliverytime && errors.deliverytime.type === 'required' ? (
                <span className="text-red-400 text-xs">
                  {dic.t('Cakery-Delivery Time is Required')}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="w-full col-span-2">
              <input
                type="text"
                className="block w-full placeholder:tracking-wider pt-1 px-1.5 pb-1 text-sm text-gray-900 bg-transparent rounded-md border border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Makani No')}
                {...register('makahani')}
                defaultValue={deliveryAddress.makani}
                autoComplete="off"
              />
              {errors.makahani && errors.makahani.message && (
                <span className="text-red-400 text-xs">{errors.makahani.message}</span>
              )}
            </div>
            <div className="w-full col-span-2">
              <input
                type="text"
                className="block w-full pt-1 px-1.5 pb-1 text-sm text-gray-900 bg-transparent rounded-md border border-gray-400 appearance-none h-10"
                placeholder={`${dic.t('Cakery-Detailed Address') + ' *'}`}
                {...register('address', {
                  required: 'Address is Required',
                })}
                defaultValue={deliveryAddress.address}
                autoComplete="off"
              />
              {errors.address && errors.address.message && (
                <span className="text-red-400 text-xs">{errors.address.message}</span>
              )}
            </div>
            <div className="text-start col-span-2">
              <button
                type="submit"
                className="text-white my-2 bg-gray-700  hover:bg-gray-500 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center"
              >
                ADD
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* Body End */}
    </Fragment>
  );
};

export default DeliveryAddress;
