import React, { Fragment } from 'react';

function ResponseShow(props) {
  return (
    <Fragment>
      {props.success !== '' ? (
        <p className="text-center mt-1 mb-2 text-[#4caf50]">{props.success}</p>
      ) : (
        <></>
      )}
      {props.error !== '' ? (
        <p className="text-center mt-1 mb-2 text-red-500">{props.error}</p>
      ) : (
        <></>
      )}
    </Fragment>
  );
}

export default ResponseShow;
