import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { onContactUs } from '../../actions/contactus';
import { EmailServiceURL } from '../../constants';
import { RiLoader5Line } from 'react-icons/ri';
import axios from 'axios';
import dic from 'i18next';

function ContactUs() {
  const dispatch = useDispatch();
  const [getContact, setContact] = useState('');
  const { loading, status } = useSelector((state) => state.contactus);

  const handleCheckNumber = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setContact(value);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    dispatch(onContactUs(data));
  };
  return (
    <div className="pt-1">
      <div className="bg-black text-gray-100 px-8 pt-1 pb-20">
        <div className="max-w-screen-xl mt-14 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto text-gray-900 rounded-lg shadow-lg">
          <div className="flex flex-col text-white justify-between">
            <div className="pt-4">
              <h2 className="text-4xl lg:text-5xl font-bold leading-tight">
                {dic.t('Cakery-We Want To Hear From You')}
              </h2>
              <p className="py-5">{dic.t('Cakery-Jumeirah Beach Hotel ground level')}</p>
              <div className="py-5">
                <p className="font-bold">Creations Cakery</p>
                <p>
                  Ground floor, Talise Fitness, Jumeirah Beach Hotel <br />
                  Jumeirah Street
                  <br />
                  Dubai, UAE
                </p>
              </div>
            </div>
            <div className="mt-5 text-center"></div>
          </div>
          <div className="">
            <form className="px-1" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid text-white grid-cols-1">
                {status === 200 && (
                  <p className="px-5 py-2 capitalize text-gray-500	border-gray-500 border mx-5">
                    thanks for contacting us...!
                  </p>
                )}

                <div className="py-2 px-5">
                  <label className="block mb-1 text-sm font-bold">
                    {dic.t('Cakery-First Name')}
                  </label>
                  <input
                    type="text"
                    className="border text-black text-sm rounded-lg bg-gray-300 outline-none block w-full p-[0.7rem]"
                    placeholder={dic.t('Cakery-First Name')}
                    {...register('firstName', {
                      maxLength: {
                        value: 15,
                        message: 'Maximum 35 characters only allowed',
                      },
                      minLength: {
                        value: 3,
                        message: 'Enter at least 4 characters',
                      },
                      required: 'First Name is required',
                    })}
                    autoComplete="off"
                  />
                  {errors.firstName && errors.firstName.message && (
                    <p className="relative">
                      <span className="text-red-400 text-sm right-0 absolute">
                        {errors.firstName.message}
                      </span>
                    </p>
                  )}
                </div>
                <div className="py-2 px-5">
                  <label className="block mb-1 text-sm font-bold">
                    {dic.t('Cakery-Last Name')}
                  </label>
                  <input
                    type="text"
                    className="border text-black text-sm rounded-lg bg-gray-300 outline-none block w-full p-[0.7rem]"
                    placeholder={dic.t('Cakery-Last Name')}
                    {...register('lastName', {
                      maxLength: {
                        value: 15,
                        message: 'Maximum 35 characters only allowed',
                      },
                      minLength: {
                        value: 3,
                        message: 'Enter at least 4 characters',
                      },
                      required: 'Last Name is required',
                    })}
                    autoComplete="off"
                  />
                  {errors.lastName && errors.lastName.message && (
                    <p className="relative">
                      <span className="text-red-400 text-sm right-0 absolute">
                        {errors.lastName.message}
                      </span>
                    </p>
                  )}
                </div>
                <div className="py-1 px-5">
                  <label className="block mb-2 text-sm font-bold">{dic.t('Cakery-Email')}</label>
                  <input
                    type="text"
                    className="border text-black text-sm rounded-lg bg-gray-300 outline-none block w-full p-[0.7rem]"
                    placeholder={dic.t('Cakery-Email')}
                    {...register('email', {
                      maxLength: {
                        value: 35,
                        message: 'Maximum 35 characters only allowed',
                      },
                      minLength: {
                        value: 4,
                        message: 'Enter at least 4 characters',
                      },
                      required: 'Email is required',
                    })}
                    autoComplete="off"
                  />
                  {errors.email && errors.email.message && (
                    <p className="relative">
                      <span className="text-red-400 text-sm right-0 absolute">
                        {errors.email.message}
                      </span>
                    </p>
                  )}
                </div>
                <div className="py-1 px-5">
                  <label className="block mb-2 text-sm font-bold">
                    {dic.t('Cakery-Mobile Number')}
                  </label>
                  <input
                    type="text"
                    className="border text-black text-sm rounded-lg bg-gray-300 outline-none block w-full p-[0.7rem]"
                    placeholder={dic.t('Cakery-Mobile Number')}
                    minLength="10"
                    maxLength="10"
                    {...register('MobileNumber', {
                      required: 'MobileNumber is required',
                    })}
                    value={getContact}
                    onChange={handleCheckNumber}
                    autoComplete="off"
                  />
                  {errors.MobileNumber && errors.MobileNumber.message && (
                    <p className="relative">
                      <span className="text-red-400 text-sm right-0 absolute">
                        {errors.MobileNumber.message}
                      </span>
                    </p>
                  )}
                </div>
                <div className="py-1 px-5">
                  <label className="block mb-2 text-sm font-bold">{dic.t('Cakery-Message')}</label>
                  <textarea
                    className="border text-black text-sm rounded-lg bg-gray-300 outline-none block w-full p-[0.7rem]"
                    placeholder={dic.t('Cakery-Message')}
                    maxLength={100}
                    minLength={10}
                    rows={3}
                    {...register('message', {
                      required: 'Message is required',
                    })}
                    autoComplete="off"
                  ></textarea>
                  {errors.message && errors.message.message && (
                    <p className="relative">
                      <span className="text-red-400 text-sm right-0 absolute">
                        {errors.message.message}
                      </span>
                    </p>
                  )}
                </div>
                <div className="px-5 pt-8 pb-3">
                  {loading === true ? (
                    <button
                      type="submit"
                      disabled
                      className="w-full text-white bg-gray-700 font-semibold text-sm rounded-lg text-sm px-10 py-3 mb-2 tracking-wider"
                    >
                      <RiLoader5Line className="w-6 h-6 inline mx-auto text-gray-200 animate-spin dark:text-white fill-white" />
                      &emsp; Loading
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="w-full text-white bg-gray-700 font-semibold text-sm rounded-lg text-sm px-10 py-3 mb-2 tracking-wider"
                    >
                      {dic.t('Cakery-Submit')}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
