import React, { Fragment } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';

function Info() {
  return (
    <Fragment>
      <BsFillInfoCircleFill className="inline" size={18} />
    </Fragment>
  );
}

export default Info;
