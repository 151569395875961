import React, { Fragment } from 'react';
import SummaryLeft from './SummaryLeft';
import SummaryRight from './SummaryRight';

const SummaryScreen = () => {
  return (
    <Fragment>
      <div className="grid grid-cols-1 sm:grid-cols-12 p-4 sm:p-0 gap-2">
        <SummaryLeft />
        <SummaryRight />
      </div>
    </Fragment>
  );
};

export default SummaryScreen;
