import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import TableLoader from '../Admin/TableLoader';
import dic from 'i18next';
import { AiOutlineSearch } from 'react-icons/ai';

const CustomerTable = () => {
  const { loading, users, error } = useSelector((state) => state.allusers);
  const [items, setItems] = useState(users);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [searchValue, setSearchValue] = useState('');
  const [loadedData, setLoadedData] = useState(1);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setItems(users);
  }, [users]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  useEffect(() => {
    setItemOffset(0);
  }, [items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const searchFilter = (item) => {
    const name = `${item.firstName} ${item.lastName}`.toLowerCase();
    const email = item.emailId.toLowerCase();
    return name.includes(searchValue) || email.includes(searchValue);
  };

  const SearchHandleChange = (e) => {
    const searchInput = e.target.value.toLowerCase();
    setSearchValue(searchInput);
    setItems(users.filter(searchFilter));
    setItemOffset(0);
  };
  useEffect(() => {
    if (items && items.length > 0) {
      let data = items.slice(0, items.length > loadedData * 10 ? loadedData * 10 : items.length);
      setCurrentItems(data);
      setLoadingData(false);
      console.log(loadingData, 'loADER');
    }
  }, [items, loadedData]);
  const handleScroll = () => {
    setLoadingData(true);
    setLoadedData((prev) => prev + 1);
  };
  return (
    <Fragment>
      <div className="pb-4 md:m-0">
        {loading ? (
          <TableLoader />
        ) : (
          <Fragment>
            <div className="breadcrumb flex items-center">
              {/* <p className="text-xl mr-1 font-semibold">{dic.t('Cakery-Customer List')}</p> */}
            </div>
              <div
                className={'w-full overflow-hidden rounded-lg shadow-md border-2 border-gray-300'}
              >
                <div className="w-full overflow-x-auto">
                  <div className="bg-gray-50 w-full p-4">
                    <div className="relative w-80">
                      <input
                        type="text"
                        className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-500 border-1 border focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search by Name / Email "
                        name="searchValue"
                        onChange={SearchHandleChange}
                        autoComplete="off"
                      />
                      <button
                        type="submit"
                        className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white rounded-r-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                      >
                        <AiOutlineSearch size="20" />
                      </button>
                    </div>
                  </div>
                  <table className="w-full whitespace-no-wrap w-full">
                    <thead className="text-xs font-semibold tracking-wide text-left text-gray-500 capitalize border-b bg-gray-50">
                      <tr>
                        {/* <td className="px-4 py-3">Customer ID</td> */}
                        <td className="border px-4 py-3 tracking-widest">
                          {dic.t('Cakery-Customer Name')}
                        </td>
                        {/* <td className="px-4 py-3 text-center">Phone</td> */}
                        <td className="border px-4 py-3 tracking-widest">Customer Email</td>
                        <td className="border px-4 py-3 tracking-widest">Marketing Consent</td>
                        <td className="border px-4 py-3 tracking-widest">Consent</td>
                        <td className="border px-4 py-3 tracking-widest">Created Date</td>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y  text-gray-700 ">
                      {currentItems &&
                        currentItems.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">{`${item.firstName} ${item.lastName}`}</p>
                              </td>
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black"> {item.emailId}</p>
                              </td>
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">
                                  {item.marketingConsent ? 'Yes' : 'No'}
                                </p>
                              </td>
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">
                                  {item.consent ? 'Yes' : 'No'}
                                </p>
                              </td>
                              <td className="border px-4 py-3">
                                <p className="text-sm font-semibold text-black">
                                  {moment(item.createdDate).format('YYYY-MM-DD')}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      {currentItems &&
                        currentItems.length > 0 &&
                        items &&
                        items.length > loadedData * 10 && (
                          <tr>
                            <td colSpan={12}>
                              <div className="flex items-center justify-center text-2xl py-3">
                                <button
                                  onClick={() => handleScroll()}
                                  className="px-5 py-3 text-xs font-medium text-white  dark:focus:ring-gray-100 bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700"
                                >
                                  Load More
                                </button>
                              </div>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default CustomerTable;
