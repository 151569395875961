import axios from 'axios';
import { AdminMicroRoutes, OCPAPIMSUBKEY } from '../../constants';

export const DOCUMENT_UPLOAD_SUCCESS = 'DOCUMENT_UPLOAD_SUCCESS';
export const DOCUMENT_UPLOAD_FAILURE = 'DOCUMENT_UPLOAD_FAILURE';
export const DOCUMENT_UPLOAD_LOADING = 'DOCUMENT_UPLOAD_LOADING';
export const GET_DOCUMENT_LOADING = 'GET_DOCUMENT_LOADING';
export const SET_UPLOADED_DOCUMENT_ID = 'SET_UPLOADED_DOCUMENT_ID';

export const DoumentUpload = (formData, callback, file, key) => async (dispatch) => {
  dispatch({ type: DOCUMENT_UPLOAD_LOADING, payload: true });
  await axios
    .post(AdminMicroRoutes.uploaddocument, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        'Ocp-Apim-Subscription-Key': OCPAPIMSUBKEY,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DOCUMENT_UPLOAD_SUCCESS,
          payload: response && response.data,
        });
        callback(response.data, file, key);
      } else {
        dispatch({
          type: DOCUMENT_UPLOAD_FAILURE,
          payload: response && response.data,
        });
        // callback(response.data, file, key);
      }
    })
    .catch((err) => {
      dispatch({ type: DOCUMENT_UPLOAD_LOADING, payload: false });
      dispatch({
        type: DOCUMENT_UPLOAD_FAILURE,
        payload: err && err.error,
      });
      console.log('error', err);
    });
};

export const getUploadedDocument = (data) => async (dispatch) => {
  dispatch({ type: GET_DOCUMENT_LOADING, payload: true });
  console.log(data, 'img');
  await axios
    .post(AdminMicroRoutes.getdocument, data)
    .then((val) => val.data)
    .then((response) => {
      console.log('response', response);
      if (response !== '') {
        fetch('data:image/jpeg;base64,' + response.documents[0].documentData)
          .then((res) => res.blob())
          .then((blob) => {
            if (navigator.msSaveBlob) {
              // For ie and Edge
              return navigator.msSaveBlob(
                blob,
                `${response.documents[0].documentName}.${response.documents[0].documentType}`
              );
            } else {
              let blobURL = window.URL.createObjectURL(blob);
              let tempLink = document.createElement('a');
              tempLink.href = blobURL;
              tempLink.setAttribute(
                'download',
                `${response.documents[0].documentName}.${response.documents[0].documentType}`
              );
              tempLink.click();
              return true;
            }
          });
      } else {
      }
      dispatch({ type: GET_DOCUMENT_LOADING, payload: false });
    })
    .catch((err) => {
      dispatch({ type: GET_DOCUMENT_LOADING, payload: false });
      console.log('Error', err);
    });
};

export const setUploadedDocumentId = (data) => (dispatch) => {
  dispatch({
    type: SET_UPLOADED_DOCUMENT_ID,
    payload: data,
  });
};
