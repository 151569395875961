import React, { Fragment } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdMobileFriendly, MdPreview } from 'react-icons/md';
import { GiCampCookingPot } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import dic from 'i18next';
import moment from 'moment';
import { getUploadedDocument } from '../../../actions/admin/DocumentUpload.action';
import { FaCheck } from 'react-icons/fa';
const OrderConfirmModal = ({ closeModal, totalAmount }) => {
  const dispatch = useDispatch();
  const { loading, message, orderDetails } = useSelector((state) => state.adminorder);
  const cartItems = useSelector((state) => state.callcenter.cartItems);
  const deliveryInfo = useSelector((state) => state.callcenter.customerDeliveryInfo);
  const customerInfo = useSelector((state) => state.callcenter.customerInfo);
  const orderId = useSelector((state) => state.adminorder.orderDetails.orderId);

  const UploadDocument = (documentId) => {
    const payload = {
      documentIds: documentId,
    };
    dispatch(getUploadedDocument(payload));
  };
  return (
    <Fragment>
      <div className="fixed z-10 overflow-x-hidden overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen" />
          <div className="inline-block align-center overflow-x-hidden overflow-y-auto bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:h-[calc(100%-3rem)]">
            <div className="bg-gray-100 shadow-md rounded p-3 w-full">
              <div className="p-2 text-center">
                {loading ? (
                  <div className="text-center py-6">
                    <svg
                      role="status"
                      className="inline w-8 h-8 mr-2 text-slate-200 animate-spin fill-slate-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="visually-hidden ml-2 text-gray-900">
                      Please Wait We are Confirming Your Order ...
                    </span>
                  </div>
                ) : (
                  <>
                    {/* <MdMobileFriendly className="mx-auto mb-4 text-gray-400 w-14 h-14" /> */}
                    {message === 'success' &&
                    orderDetails &&
                    Object.keys(orderDetails).length > 0 &&
                    orderDetails.orderId &&
                    orderDetails.status === true ? (
                      <>
                        <h3 className="mb-5 text-lg font-normal text-gray-500">
                          {orderDetails['additional info']
                            ? orderDetails['additional info']
                            : 'Your Order is Placed Successfully'}
                        </h3>
                        <div className="p-2 flex items-center">
                          <div className="w-full h-46">
                            <div className="flex justify-center items-center w-full space-y-1 flex-col border-gray-200">
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">{dic.t('Cakery-Order ID')}</p>
                                <p className="text-xs  text-gray-600">{orderId && orderId}</p>
                              </div>
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">Customer Name</p>
                                <p className="text-xs  text-gray-600">
                                  {customerInfo.CustomerFirstName}
                                </p>
                              </div>
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">Customer Email</p>
                                <p className="text-xs  text-gray-600">
                                  {customerInfo.CustomerEmail}
                                </p>
                              </div>
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">Customer Phone</p>
                                <p className="text-xs  text-gray-600">
                                  {customerInfo.CustomerPhoneNumber}
                                </p>
                              </div>
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">
                                  {deliveryInfo?.deliveryType === 'DELIVERY'
                                    ? dic.t('Cakery-Delivery Date')
                                    : dic.t('Cakery-Pickup Date')}
                                </p>
                                <p className="text-xs  text-gray-600">
                                  {deliveryInfo.dpdate.getDate()}/
                                  {deliveryInfo.dpdate.getMonth() + 1}/
                                  {deliveryInfo.dpdate.getFullYear()}
                                </p>
                              </div>
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">
                                  {' '}
                                  {deliveryInfo?.deliveryType === 'DELIVERY'
                                    ? dic.t('Cakery-Delivery Time')
                                    : dic.t('Cakery-Pickup Time')}
                                </p>
                                <p className="text-xs  text-gray-600">
                                  {deliveryInfo && deliveryInfo.dptime}
                                </p>
                              </div>
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">Delivery Type</p>
                                <p className="text-xs  text-gray-600">
                                  {deliveryInfo.deliverytype}
                                </p>
                              </div>
                              {deliveryInfo.deliverytype === 'DELIVERY' && (
                                <div className="flex justify-between w-full">
                                  <p className="text-xs  text-gray-800">Address</p>
                                  <p className="text-xs  text-gray-600">
                                    {deliveryInfo.deliveryAddress.makani +
                                      ',' +
                                      deliveryInfo.deliveryAddress.address +
                                      ',' +
                                      deliveryInfo.deliveryAddress.region}
                                  </p>
                                </div>
                              )}
                              <div className="flex justify-between w-full">
                                <p className="text-xs  text-gray-800">Remarks</p>
                                <p className="text-xs  text-gray-600">{customerInfo.Remarks}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-12 rounded-lg shadow-md border-2 border-gray-200 overflow-hidden bg-white ">
                          <div className="min-w-0 rounded-lg  border-2 overflow-hidden">
                            <div className="flex items-center">
                              <div className="flex flex-col p-2 min-h-fit max-h-52 w-full space-y-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300">
                                {/* <h3 className="text-sm  font-semibold leading-5 text-gray-800">Items</h3> */}
                                {cartItems.length > 0 &&
                                  cartItems.map((item, key) => {
                                    console.log(item, 'itemSupplies');
                                    const itemSupplies =
                                      item && item?.cakeSuppliesName ? item.cakeSuppliesName : '';
                                    const cakeSuppliesNameNew =
                                      item && item?.cakeSuppliesNameNew
                                        ? item.cakeSuppliesNameNew
                                        : '';
                                    return (
                                      <div key={key}>
                                        <ul role="list" className="divide-y divide-gray-200 ">
                                          <li className="py-1">
                                            <div className="flex items-center space-x-4">
                                              <div className="flex-1 min-w-0 text-left">
                                                <p className="text-sm font-medium text-gray-900 truncate ">
                                                  {item.itemName}
                                                  {item.cakeType === 'CUSTOMIZED' &&
                                                    item.documentId &&
                                                    item.documentId.length > 0 && (
                                                      <MdPreview
                                                        size="18"
                                                        className="text-gray-500 inline-flex hover:cursor-pointer"
                                                        onClick={() =>
                                                          UploadDocument(item.documentId)
                                                        }
                                                      ></MdPreview>
                                                    )}
                                                </p>
                                                <div className="text-sm sm:font-medium">
                                                  {item.cakeFlavour}
                                                </div>
                                                <div className="text-xs xs:font-medium">
                                                  {item.cakeServings}
                                                </div>
                                                {item.cakeFestiveAddons.length > 0 && (
                                                  <ul className="festive-addons border-b pb-1 mb-2">
                                                    <h4 className="text-gray-600 text-xs font-semibold mt-2">
                                                      Addons
                                                    </h4>
                                                    {item &&
                                                      item.cakeFestiveAddons.length > 0 &&
                                                      item.cakeFestiveAddons.map((val, i) => (
                                                        <li
                                                          className="addon-item flex items-center text-sm text-muted font-medium space-x-3"
                                                          key={i}
                                                        >
                                                          <h4 className="text-xs flex items-center">
                                                            <FaCheck className="h-3 w-3 text-green-500 mr-2" />
                                                            {val.Qty} x {val.label}
                                                          </h4>
                                                          <h4 className="">
                                                            {val.value} {dic.t('Cakery-AED')}
                                                          </h4>
                                                        </li>
                                                      ))}
                                                  </ul>
                                                )}
                                                {item &&
                                                item.cakeSuppliesName &&
                                                item.cakeSuppliesName.length > 0 &&
                                                itemSupplies &&
                                                itemSupplies.length > 0 !== '' ? (
                                                  <>
                                                    <h4 className="text-gray-600 text-xs font-semibold mt-2">
                                                      Supplies
                                                    </h4>
                                                    <div className="">
                                                      {item.cakeSuppliesName.map((val, i) => (
                                                        <div
                                                          key={i}
                                                          className="flex items-center space-x-2"
                                                        >
                                                          <p className="text-xs text-gray-500 truncate">
                                                            {val} :
                                                          </p>
                                                          {item.cakeSuppliesPrice[i] && (
                                                            <p className="text-xs text-gray-500">{`${item.cakeSuppliesPrice[i]} AED`}</p>
                                                          )}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    {cakeSuppliesNameNew !== '' &&
                                                      createJSON(
                                                        item.cakeSuppliesNameNew,
                                                        item.cakeSuppliesPriceNew
                                                      ).map((supplies, index) => {
                                                        return (
                                                          <div className="flex" key={index}>
                                                            <p className="text-xs text-gray-500 truncate">
                                                              {supplies.addonType}
                                                            </p>
                                                            <p className="text-xs text-gray-500 truncate">
                                                              {supplies.price
                                                                ? `${supplies.price} AED`
                                                                : ''}
                                                            </p>
                                                          </div>
                                                        );
                                                      })}
                                                  </>
                                                )}

                                                {item?.itemMessage !== '' && (
                                                  <p className="text-xs text-gray-500 truncate ">
                                                    {item?.cakeMessage
                                                      ? `✉️ ${item?.cakeMessage}`
                                                      : ''}
                                                  </p>
                                                )}
                                                {/* {item.cakeRemarks !== '' && (<div className="text-gray-600 text-sm">🗒️ {item.cakeRemarks}</div>)} */}
                                              </div>
                                              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                                <div className="flex font-bold text-gray-700 px-1 justify-center text-sm">
                                                  Qty {item.cakeQuantity}
                                                </div>
                                                <div className="col-span-1 py-auto text-center">
                                                  <h2 className="font-bold text-sm tracking-widest text-gray-700">
                                                    {dic.t('Cakery-AED')} {item.totalPrice}
                                                  </h2>
                                                </div>
                                              </div>
                                              {/* <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                                      {dic.t('Cakery-AED')} {item.totalPrice}
                                    </div> */}
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                            <ul role="list" className="divide-y divide-gray-200 px-2 ">
                              <li className="">
                                <div className="flex items-center space-x-4 justify-between">
                                  <h6 className="flex-shrink-0 text-sm text-gray-700 font-bold">
                                    {dic.t('Cakery-Total Payment')}
                                  </h6>
                                  <div className="grid grid-cols-1 sm:grid-cols-1 gap-6">
                                    <div className="col-span-1 py-auto text-center">
                                      <h6 className="font-bold text-sm tracking-widest text-gray-700">
                                        {dic.t('Cakery-AED')} {totalAmount}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <a href="/en/admin/call-center">
                          <button
                            data-modal-toggle="popup-modal"
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                            className="text-white bg-gray-700 hover:bg-gray-500 hover:text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                          >
                            Close
                          </button>
                        </a>
                      </>
                    ) : (
                      <>
                        <h3 className="mb-5 text-lg font-normal text-gray-500">
                          Something Went Wrong Please Try Again
                        </h3>
                        <a href="/en/admin/call-center">
                          {' '}
                          <button
                            data-modal-toggle="popup-modal"
                            type="button"
                            onClick={() => {
                              closeModal();
                            }}
                            className="text-white bg-gray-700 hover:bg-gray-500 hover:text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                          >
                            Please Try Again
                          </button>
                        </a>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderConfirmModal;
