import { CART_LIST, CART_LIST_STATUS_UPDATE } from '../actions/cart/cartlist.action';

const initailState = {
  existcartstatus: false,
  existcartdata: false,
};

export const userCartListReducer = (state = initailState, action) => {
  switch (action.type) {
    case CART_LIST:
      return {
        ...state,
        existcartstatus: action.existcartstatus,
        existcartdata: action.existcartdata,
      };

    case CART_LIST_STATUS_UPDATE:
      return {
        ...state,
        existcartstatus: false,
        existcartdata: false,
      };

    default:
      return state;
  }
};
