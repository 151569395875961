import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import { MdOutlineDelete } from 'react-icons/md';
import {
  removeAddonFromCart,
  removeCenterCart,
  setCakeMessage,
  setCenterCart,
  removeFestiveAddonFromCart,
} from '../../../actions/admin/CallCenter.action';
import dic from 'i18next';

const ProductCart = ({ cartItems }) => {
  const dispatch = useDispatch();
  const [TotalPrice, setTotalPrice] = useState(0);
  // let TotalPrice = 0;
  const [finalcartItems, setfinalcartItems] = useState(cartItems);
  useEffect(() => {
    setfinalcartItems(cartItems);
  }, [cartItems]);
  useEffect(() => {
    let Price = 0;
    finalcartItems.map((item) => {
      Price = Price + item.totalPrice;
    });
    setTotalPrice(Price);
    // console.log('Price', Price);
  }, [finalcartItems]);
  const addmore = (cakedata) => {
    cakedata.cakeQuantity = cakedata.cakeQuantity + 1;
    dispatch(setCenterCart(cakedata));
  };
  const removeitem = (cakedata) => {
    cakedata.cakeQuantity = cakedata.cakeQuantity - 1;
    dispatch(removeCenterCart(cakedata));
  };
  const handQtyChange = (e, item) => {
    if (e.target.value >= 1 && e.target.value <= 99) {
      item.cakeQuantity = Number(e.target.value);
      dispatch(setCenterCart(item));
    }
  };

  const handleDeleteAddons = (item, i) => {
    dispatch(removeAddonFromCart({ ITEM: item, index: i }));
  };
  const handleDeleteFestiveAddons = (item, i) => {
    dispatch(removeFestiveAddonFromCart({ FESTIVEITEM: item, festiveindex: i }));
  };

  const handleDeleteCake = (item) => {
    item.cakeQuantity = 0;
    dispatch(removeCenterCart(item));
  };

  const handleCakeMessage = (event, index) => {
    let cakeMessage = event.target.value;
    dispatch(setCakeMessage({ cakeMessage, index }));
  };
  console.log(cartItems, 'CartItems');
  return (
    <Fragment>
      {finalcartItems && finalcartItems.length > 0 ? (
        <div className="flex flex-col justify-center">
          <div className="container flex flex-col w-full items-center overflow-y-auto h-full sm:h-72 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-thumb-full scrollbar-track-rounded-full scrollbar-track-gray-300 bg-white border-2 border-gray-200 shadow">
            <ul className="flex flex-col w-full divide divide-y">
              {finalcartItems.map((item, index) => {
                const itemSupplies = item && item?.cakeSuppliesName ? item.cakeSuppliesName : [];
                const itemSuppliesPrice = item ? item.cakeSuppliesPrice : [];
                const itemAddons = item && item.cakeFestiveAddons ? item.cakeFestiveAddons : [];
                return (
                  <li className="flex flex-row items-center" key={index}>
                    <div className="cursor-pointer flex flex-1 items-center py-2 px-4">
                      <div className="flex flex-col justify-center  mr-2 self-start mb-auto">
                        <img
                          alt="cake"
                          src={item.cakeImage}
                          className="mx-auto object-cover rounded h-16 w-20"
                        />
                      </div>
                      <div className="flex-1 pl-1 mr-5">
                        <div className="text-sm sm:font-medium">{item.cakeFlavour}</div>
                        <div className="text-gray-600 text-xs">{item.cakeServings}</div>
                        {/* {item.cakeBerries !== 0 && (
                          <div className="text-gray-600 text-xs">
                            {` ${item.cakeAddonName} : AED ${item.cakeBerries}`}
                          </div>
                        )}
                        {item.cakeBerries !== 0 && (
                          <div className="text-gray-600 text-xs">
                            {` ${item.cakeAddonName} : AED ${item.cakeBerries}`}
                          </div>
                        )} */}
                        {itemAddons && itemAddons.length > 0 ? (
                          <div>
                            <h6 className="text-xs text-gray-600 font-semibold">Addons</h6>
                            {itemAddons.length > 0 || itemAddons !== ''
                              ? itemAddons.map((items, i) => (
                                  <div className="flex justify-between" key={i}>
                                    <div className="text-gray-600 text-xs flex-2">
                                      {`${items.Qty} x ${items.label} : ${items.value}`}
                                    </div>
                                    <div className="text-gray-600 text-xs flex-1">
                                      <MdOutlineDelete
                                        className="h-4 w-4"
                                        style={{ color: '#ed5e68' }}
                                        onClick={() => handleDeleteFestiveAddons(item, i)}
                                      />
                                    </div>
                                  </div>
                                ))
                              : ''}
                          </div>
                        ) : (
                          ''
                        )}
                        {itemSupplies.length > 0 ? (
                          <h6 className="text-xs text-gray-600 font-semibold">Supplies</h6>
                        ) : (
                          ''
                        )}
                        {itemSupplies.length > 0 || itemSupplies !== ''
                          ? itemSupplies.map((items, i) => (
                              <div className="flex justify-between" key={i}>
                                <div className="text-gray-600 text-xs w-3/5">
                                  {`${items} : ${itemSuppliesPrice[i]}`}
                                  {''}
                                </div>
                                <div className="text-gray-600 text-xs w-3/5">
                                  <MdOutlineDelete
                                    className="h-4 w-4"
                                    style={{ color: '#ed5e68' }}
                                    onClick={() => handleDeleteAddons(item, i)}
                                  />
                                </div>
                              </div>
                            ))
                          : ''}
                        {item.cakeMessage !== '' && (
                          <p className="cursor-auto text-xs text-gray-500 truncate ">
                            ✉️{' '}
                            <input
                              type="text"
                              className="px-1 border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600"
                              defaultValue={item.cakeMessage}
                              // value={item.cakeMessage}
                              name="cakeMessage"
                              onChange={(event) => handleCakeMessage(event, index)}
                            />
                          </p>
                        )}
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <div className="col-span-1">
                          <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-2 py-1 rounded-md">
                            <div
                              className="flex relative  cursor-pointer"
                              onClick={() => removeitem(item)}
                            >
                              <IconContext.Provider value={{ className: 'text-white' }}>
                                <BiMinusCircle size={20} />
                              </IconContext.Provider>
                            </div>
                            <div className="flex text-white  justify-center text-lg">
                              <input
                                type="text"
                                name="cakeQuantity"
                                id="cakeQuantity"
                                value={item.cakeQuantity}
                                onChange={(e) => handQtyChange(e, item)}
                                className="text-center bg-transparent appearance-none border-none text-white text-sm w-5 focus:outline-none"
                                onWheelCapture={(e) => e.target.blur()}
                                min={1}
                              />
                            </div>
                            <div
                              className="flex relative 	cursor-pointer"
                              onClick={() => addmore(item)}
                            >
                              <IconContext.Provider value={{ className: 'text-white' }}>
                                <BiPlusCircle size={20} />
                              </IconContext.Provider>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="py-auto text-center flex">
                            <h2 className="font-bold tracking-widest text-gray-700">
                              {dic.t('Cakery-AED')} {item.cakePerItemPrice}
                            </h2>
                            <MdOutlineDelete
                              className="h-5 w-6"
                              style={{ color: '#ed5e68' }}
                              onClick={() => handleDeleteCake(item)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="w-full text-right font-bold tracking-widest text-gray-700 my-auto flex justify-between px-4 h-10 border-2 border-gray-200 bg-gray-200 shadow mt-2">
            <div className="my-auto">{dic.t('Cakery-Total')}</div>
            <div className="my-auto">
              {dic.t('Cakery-AED')} {TotalPrice}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative overflow-x-auto shadow p-4 border-2 broder-gray-400 h-full flex flex-col justify-center">
          <h2 className="text-2xl font-medium text-center">
            {dic.t('Cakery-There is nothing here')}
          </h2>
          <p className="mt-4 text-sm text-gray-500 text-center">{dic.t('Cakery-Add New Cake')}</p>
          <h2 className="text-2xl font-medium text-center mt-4">🛒</h2>
        </div>
      )}
    </Fragment>
  );
};

export default ProductCart;
