import { MicroRoutes } from '../constants';
import axios from 'axios';
export const BOOK_PAYMENT = 'BOOK_PAYMENT';
export const onBookPaymentStatus = (data) => async (dispatch) => {
  dispatch({ type: BOOK_PAYMENT, loading: false });
  await axios
    .get(`${MicroRoutes.paymentstatus + `?token=${data}`}`)
    .then((response) => {
      dispatch({ type: BOOK_PAYMENT, loading: true, message: response.data });
    })
    .catch((error) => {
      dispatch({ type: BOOK_PAYMENT, loading: false, message: error.response });
    });
};
