import React, { Fragment } from 'react';
import { SiTwitter } from 'react-icons/si';

function Twitter() {
  return (
    <Fragment>
      <SiTwitter className="text-white relative left-2 top-2" size={16} />
    </Fragment>
  );
}

export default Twitter;
