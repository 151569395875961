import React, { Fragment, useState } from 'react';
import { MdClose, MdPreview } from 'react-icons/md';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment';
import dic from 'i18next';
import { CallCenterCountryCodeStyles } from '../../../helpers/Patterns';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FilterOrderTypes from './FilterOrderTypes';
import { useDispatch, useSelector } from 'react-redux';
import { advancefilterOrders } from '../../../actions/admin/FilterOrder.action';
import { SAVE_FILTER_DATA, TEMP_SAVE_ORDER } from '../../../actions/admin/GetAllOrder.action';
import { useEffect } from 'react';

const AdvanceSearchModal = ({ closeModal, regionlist, afterAdvanceFilterClearFilter }) => {
  const dispatch = useDispatch();
  let RegionList = [];
  regionlist &&
    regionlist.map((item) => {
      let regionname = item?.fields?.Region?.value;
      RegionList.push({ label: regionname, value: regionname });
    });
  const selectedDeliveryType = useSelector((state) => state.allorders.selectedDeliveryType);
  const [deliveryTYPE, setdeliveryTYPE] = useState(selectedDeliveryType);
  const orders = useSelector((state) => state.allorders.orders);
  const tempOrders = useSelector((state) => state.allorders.tempOrders);
  const filterData = useSelector((state) => state.allorders.filterData);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dataItems, setDataItems] = useState({
    custname: filterData.custname ? filterData.custname : '', // Set default value for the 'custname' field
    custemail: filterData.custemail ? filterData.custemail : '',
    custphone: filterData.custphone ? filterData.custphone : '',
    orderid: filterData.orderid ? filterData.orderid : '',
  });
  console.log();
  const [region, setregion] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      custname: filterData.custname ? filterData.custname : '', // Set default value for the 'custname' field
      custemail: filterData.custemail ? filterData.custemail : '',
      custphone: filterData.custphone ? filterData.custphone : '',
      orderid: filterData.orderid ? filterData.orderid : '',
    },
  });
  const onSubmits = (data) => {
    let newenddate = data && data.enddate && moment(data.enddate).format('YYYY-MM-DD');
    let newstartdate = data && data.fromdate && moment(data.fromdate).format('YYYY-MM-DD');
    let region = data && data.region && data.region.value;
    let newPayload = { ...data, enddate: newenddate, fromdate: newstartdate, region: region };
    const payload = {
      customerName: data.custname ? data.custname : dataItems.custname,
      customerEmail: data.custemail ? data.custname : dataItems.custname,
      phoneNumber: data.custphone ? data.custphone : dataItems.custphone,
      deliveryType: deliveryTYPE,
      orderId: data.orderid ? data.orderid : dataItems.orderid,
      region: data.region,
      fromDate: newPayload.fromdate,
      toDate: newPayload.enddate,
      region: newPayload.region,
    };

    dispatch(advancefilterOrders(payload)).then(() => {
      afterAdvanceFilterClearFilter();
      closeModal();
    });
  };
  const onStartDateChange = (start) => {
    setStartDate(start);
  };
  const onEndDateChange = (end) => {
    setEndDate(end);
  };
  const onRegionChange = (region) => {
    setregion(region);
  };
  const regionoptions = [
    { label: 'Ajman', value: 'ajman' },
    { label: 'Dubai', value: 'dubai' },
  ];
  const handleChangeInputs = (e) => {
    setDataItems({ ...dataItems, [e.target.name]: e.target.value.trim('') });

    let filter = { ...dataItems, [e.target.name]: e.target.value.trim('') };

    let filterCustomerName = [];
    // if (e.target.name === 'custname') {
    if (filter.custname) {
      filterCustomerName =
        orders.length > 0 &&
        orders.filter((orderr) =>
          String(orderr.customerName.toLowerCase()).includes(filter.custname.toLowerCase())
        );
    } else {
      filterCustomerName = orders;
    }
    // }

    let filterCustomerEmail = [];

    // if (e.target.name === 'custemail') {
    if (filter.custemail) {
      filterCustomerEmail =
        filterCustomerName.length > 0 &&
        filterCustomerName.filter((orderr) =>
          String(orderr.customerEmail.toLowerCase()).includes(filter.custemail)
        );
    } else {
      filterCustomerEmail = filterCustomerName;
    }
    // }

    let filterCustomerPhone = [];

    // if (e.target.name === 'custphone') {
    if (filter.custphone) {
      filterCustomerPhone =
        filterCustomerEmail.length > 0 &&
        filterCustomerEmail.filter((orderr) =>
          String(orderr.phoneNumber.toLowerCase()).includes(filter.custphone)
        );
    } else {
      filterCustomerPhone = filterCustomerEmail;
    }
    // }

    let filterOrderId = [];

    // if (e.target.name === 'orderid') {
    if (filter.orderid) {
      filterOrderId =
        filterCustomerPhone.length > 0 &&
        filterCustomerPhone.filter((orderr) => String(orderr.orderId).includes(filter.orderid));
    } else {
      filterOrderId = filterCustomerPhone;
    }
    // }

    dispatch({ type: TEMP_SAVE_ORDER, payload: filterOrderId });
  };

  useEffect(() => {
    if (dataItems && Object.keys(dataItems).length > 0) {
      dispatch({ type: SAVE_FILTER_DATA, payload: dataItems });
    } else {
      dispatch({ type: SAVE_FILTER_DATA, payload: {} });
    }
  }, [dataItems]);
  return (
    <Fragment>
      <div className=" fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen"></span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100">
              <Fragment>
                <div className="shadow-md rounded p-4 w-full">
                  <div className="grid gap-2 mb-4 md:grid-cols-12 xl:grid-cols-12 mt-4">
                    <div className="col-span-12 mb-2">
                      <label className="text-center flex justify-center">SEARCH ORDER</label>
                      <button
                        type="button"
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center dark:hover:text-white"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <MdClose className="w-5 h-5" />
                      </button>
                    </div>
                    <form
                      className="col-span-12 grid grid-cols-12 gap-2"
                      onSubmit={handleSubmit(onSubmits)}
                    >
                      {/* Type of Order */}
                      <div className="w-full mb-3 col-span-12">
                        <FilterOrderTypes
                          setdeliveryType={setdeliveryTYPE}
                          deliveryType={deliveryTYPE}
                        />
                      </div>
                      {/* Start Date Selection */}
                      <div className="w-full mb-3 col-span-6">
                        <Controller
                          name="fromdate"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <ReactDatePicker
                                selected={startDate}
                                value={value}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select From Date"
                                // autoComplete="off"
                                onChange={(date) => {
                                  onChange(date);
                                  onStartDateChange(date);
                                }}
                                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg  border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                              />
                            );
                          }}
                        />
                      </div>
                      {/* End Date Selection */}
                      <div className="w-full mb-3 col-span-6">
                        <Controller
                          name="enddate"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <ReactDatePicker
                                selected={endDate}
                                value={value}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select End Date"
                                autoComplete="off"
                                onChange={(date) => {
                                  onChange(date);
                                  onEndDateChange(date);
                                }}
                                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg  border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                              />
                            );
                          }}
                        />
                      </div>
                      {/* Customer Information */}
                      <div className="w-full mb-3 col-span-6">
                        <input
                          type="text"
                          maxLength={200}
                          className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder={'Customer Name'}
                          {...register('custname')}
                          autoComplete="off"
                          onChange={handleChangeInputs}
                        />
                      </div>
                      <div className="w-full mb-3 col-span-6">
                        <input
                          type="text"
                          maxLength={200}
                          className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder={'Customer Email'}
                          {...register('custemail')}
                          autoComplete="off"
                          onChange={handleChangeInputs}
                        />
                      </div>
                      <div className="w-full mb-3 col-span-6">
                        <input
                          type="text"
                          maxLength={200}
                          className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder={'Customer Phone No.'}
                          {...register('custphone')}
                          autoComplete="off"
                          onChange={handleChangeInputs}
                        />
                      </div>
                      {/* Order Info */}
                      <div className="w-full mb-3 col-span-6">
                        <input
                          type="text"
                          maxLength={200}
                          className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder={'Order ID'}
                          {...register('orderid')}
                          autoComplete="off"
                          onChange={handleChangeInputs}
                        />
                      </div>
                      {/* Region List */}
                      <div className="w-full mb-3 col-span-12">
                        <Controller
                          name="region"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                options={RegionList}
                                value={value}
                                maxMenuHeight={100}
                                placeholder={`${'Region'}`}
                                styles={CallCenterCountryCodeStyles}
                                onChange={(e) => {
                                  onRegionChange(e);
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      {/* Submit Button */}
                      <div className="w-full col-span-12">
                        <button
                          type="submit"
                          className="text-white my-2 bg-gray-700 tracking-wider hover:bg-gray-500 font-medium rounded-lg text-md w-full px-5 py-2 text-center"
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdvanceSearchModal;
