import React, { Fragment } from 'react';
import CustomerScreen from './CustomerScreen';
import DeliveryScreen from './DeliveryScreen';
import OrderScreen from './OrderScreen';
import SummaryScreen from './SummaryScreen';
import { useSelector } from 'react-redux';

const Main = ({
  tab,
  updateTab,
  cakes,
  deliverycharges,
  codes,
  FestivalCakes,
  FestivalDeliveryCharges,
  FestiveDeliveryTime,
  deliveryTime,
  PickupTime,
  supplies,
  pastries,
  customize,
}) => {
  const orderItem = useSelector((state) => state.callcenter.cartItems);
  const customerInfo = useSelector((state) => state.callcenter.customerInfo);
  const customerDelivery = useSelector((state) => state.callcenter.customerDeliveryInfo);
  let customerDisabled = orderItem.length > 0 ? true : false;
  let deliveryDisabled = Object.keys(customerInfo).length > 0 ? true : false;
  let summaryDisabled = customerDisabled && deliveryDisabled
  console.log(orderItem.length > 0, Object.keys(customerInfo).length > 0, Object.keys(customerDelivery).length > 0, 'TAB')

  return (
    <Fragment>
      <div className="w-[inherit] pb-4 sm:px-8 bg-white">
        {/* {tab === 1 && <OrderScreen cakeList={cakes} FestivalCakes={FestivalCakes} supplies={supplies} pastries={pastries} customize={customize}/>}
        {tab === 2 && <CustomerScreen codes={codes} />}
        {tab === 3 && (
          <DeliveryScreen
            deliverycharges={deliverycharges}
            FestivalDeliveryCharges={FestivalDeliveryCharges}
            deliveryTime={deliveryTime}
            PickupTime={PickupTime}
            FestiveDeliveryTime={FestiveDeliveryTime}
          />
        )}
        {tab === 4 && <SummaryScreen />} */}
        <div className="order-screen my-4">
          <h2 className="font-bold text-md mb-2">1. Order Item</h2>
          <OrderScreen cakeList={cakes} FestivalCakes={FestivalCakes} supplies={supplies} pastries={pastries} customize={customize} />
        </div>
        <div className={`customer-screen my-4 relative ${!customerDisabled ? 'pointer-events-none filter opacity-50' : ''}`}>
          <h2 className="font-bold text-md mb-2">2. Customer Info</h2>
          <CustomerScreen codes={codes} isDisabled={customerDisabled} />
        </div>
        <div className={`delivery-screen order-screen my-4 relative ${!deliveryDisabled ? 'pointer-events-none filter opacity-50' : ''}`}>
          <h2 className="font-bold text-md mb-2">3. Choose Delivery</h2>
          <DeliveryScreen
            isDisabled={deliveryDisabled}
            deliverycharges={deliverycharges}
            FestivalDeliveryCharges={FestivalDeliveryCharges}
            deliveryTime={deliveryTime}
            PickupTime={PickupTime}
            FestiveDeliveryTime={FestiveDeliveryTime}
          />
        </div>
        {summaryDisabled &&
          <div className="summary-screen my-4">
            <h2 className="font-bold text-md mb-2">4. Order Summary</h2>
            <SummaryScreen />
          </div>
        }
      </div>
    </Fragment>
  );
};

export default Main;
