import React, { Fragment } from 'react';
import { BsDash } from 'react-icons/bs';

function Decrement() {
  return (
    <Fragment>
      <BsDash className="text-white relative bottom-[0.2rem]" size={25} />
    </Fragment>
  );
}

export default Decrement;
