import { SUCESSS, FAILURE, LOADING } from '../actions/contactus';

const initailState = {
  loading: false,
  status: 0,
};

export const userContactUs = (state = initailState, action) => {
  switch (action.type) {
    case SUCESSS:
      return {
        ...state,
        loading: false,
        status: action.status,
      };
    case FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};
