import {
  UPDATE_PASSWORD,
  CLEAR_UPDATE_STATE,
  DECRYPT_STATUS,
  DECRYPT_STATUS_REVERT,
} from '../actions/resetpassword.action';

const initailState = {
  loading: false,
  statuscode: '',
  status: '',
  error: '',
  email: '',
  loadingtoken: false,
};

export const userResetPasswordReducer = (state = initailState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD:
      return {
        ...state,
        loading: action.loadingstatus,
        statusCode: action.statusCode,
        status: action.payload,
      };

    case DECRYPT_STATUS:
      return {
        ...state,
        decryptstatuscode: action.status,
        loadingtoken: action.loadingtoken,
        error: action.error,
        email: action.email,
      };

    case DECRYPT_STATUS_REVERT:
      return {
        ...state,
        decryptstatuscode: '',
        loadingtoken: '',
        error: '',
        email: '',
      };

    case CLEAR_UPDATE_STATE:
      return {
        ...state,
        statuscode: action.payload,
        status: action.payload,
      };

    default:
      return state;
  }
};
