import axios from 'axios';
import { AdminMicroRoutes } from '../constants';
export const GENERATE_REPORT = 'GENERATE_REPORT';

export const generateExcelReport = (data, reportName, reportType) => (dispatch) => {
  let tokenid = localStorage.getItem('tokenId');
  console.log(reportName, data, reportType, 'reportName')
  dispatch({
    type: GENERATE_REPORT,
  });
  let url = '';
  switch (reportType) {
    case 'ORDERS':
      url = AdminMicroRoutes.orderReport;
      break;
    case 'KITCHEN':
      url = AdminMicroRoutes.kitchenReport;
      break;
    default:
      console.log('error');
  }
  axios
    .post(url, data, { headers: { token: `${tokenid}` } })
    .then((val) => val.data)
    .then((response) => {
      if (response !== '') {
        fetch('data:image/png;base64,' + response)
          .then((res) => res.blob())
          .then((blob) => {
            if (navigator.msSaveBlob) {
              console.log(blob, 'blobIf')
              return navigator.msSaveBlob(blob, `${reportName}.xlsx`);
            } else {
              console.log(blob, 'blobElse')
              let blobURL = window.URL.createObjectURL(blob);
              let tempLink = document.createElement('a');
              tempLink.href = blobURL;
              tempLink.setAttribute('download', `${reportName}.xlsx`);
              tempLink.click();
              return true;
            }
          });
        dispatch({
          type: GENERATE_REPORT,
        });
        return true;
      } else {
        return dispatch({
          type: GENERATE_REPORT,
        });
      }
    })
    .catch((err) => {
      return dispatch({
        type: GENERATE_REPORT,
      });
    });
};
