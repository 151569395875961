import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';
import { GET_ORDER_SUCCESS, TEMP_SAVE_ORDER } from './GetAllOrder.action';

export const FILTER_ORDER_SUCCESS = 'FILTER_ORDER_SUCCESS';
export const FILTER_ORDER_FAILURE = 'FILTER_ORDER_FAILURE';
export const FILTER_ORDER_LOADING = 'FILTER_ORDER_LOADING';
export const REMOVE_FILTER_ERROR = 'REMOVE_FILTER_ERROR';

export const filterOrders = (data) => async (dispatch) => {
  dispatch({ type: FILTER_ORDER_LOADING, payload: true });
  let tokenid = localStorage.getItem('tokenId');
  await axios
    .post(AdminMicroRoutes.filterorders, data, { headers: { token: `${tokenid}` } })
    .then((response) => {
      if (response?.data?.errorStatus) {
        console.log('response', response);
        dispatch({
          type: FILTER_ORDER_FAILURE,
          payload: response.data,
        });
      } else {
        dispatch({
          type: FILTER_ORDER_SUCCESS,
          payload: response.data.order,
        });
        dispatch({
          type: GET_ORDER_SUCCESS,
          payload: response.data.order,
          status: response.status,
        });
        dispatch({ type: TEMP_SAVE_ORDER, payload: response.data.order });
        dispatch({ type: FILTER_ORDER_LOADING, payload: false });
      }
    })
    .catch((error) => {
      dispatch({ type: FILTER_ORDER_FAILURE, payload: error.message });
      console.log('error', error);
    });
};

export const advancefilterOrders = (data) => async (dispatch) => {
  dispatch({ type: FILTER_ORDER_LOADING, payload: true });
  let tokenid = localStorage.getItem('tokenId');
  await axios
    .post(AdminMicroRoutes.advancefilterorders, data, { headers: { token: `${tokenid}` } })
    .then((response) => {
      if (response?.data?.errorStatus) {
        console.log('response', response);
        dispatch({
          type: FILTER_ORDER_FAILURE,
          payload: response.data,
        });
      } else {
        dispatch({ type: REMOVE_FILTER_ERROR });
        dispatch({
          type: FILTER_ORDER_SUCCESS,
          payload: response.data && response.data.filteredData,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: FILTER_ORDER_FAILURE, payload: error.message });
      console.log('error', error);
    });
};

export const viewSingleOrders = (data) => async (dispatch) => {
  const myPromise = new Promise((resolve, reject) => {
    // dispatch({ type: FILTER_ORDER_LOADING, payload: true });
    let tokenid = localStorage.getItem('tokenId');
    axios
      .post(AdminMicroRoutes.filterorders, data, { headers: { token: `${tokenid}` } })
      .then((response) => {
        if (response?.data?.errorStatus) {
          console.log('response', response);
          reject({});
        } else {
          resolve(response.data.order);
          // dispatch({
          //   type: FILTER_ORDER_SUCCESS,
          //   payload: response.data.order,
          // });
          // dispatch({
          //   type: GET_ORDER_SUCCESS,
          //   payload: response.data.order,
          //   status: response.status,
          // });
          // dispatch({ type: TEMP_SAVE_ORDER, payload: response.data.order });
          // dispatch({ type: FILTER_ORDER_LOADING, payload: false });
        }
      })
      .catch((error) => {
        reject({});
        console.log('error', error);
      });
  });
  return myPromise;
};
