import {
  GET_ROLES_LOADING,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
} from '../../actions/admin/GetAllRoles.action';

const initailState = {
  loading: false,
  roles: [],
  error: '',
};

export const getAllRolesReducer = (state = initailState, action) => {
  switch (action.type) {
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };

    case GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_ROLES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
