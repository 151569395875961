import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { MdOutlinePayments } from 'react-icons/md';
import Loader from '../../Loader/Loader';
import dic from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NIPAYMENT_STATUS } from '../../../actions/payment/payment.action';

const PaymentStatusModal = ({ closeModal }) => {
  const { nipayment_status } = useSelector((state) => state.payment);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className=" fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen"></span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100 shadow-md rounded p-4 w-full">
              <div className="p-6 text-center">
                {nipayment_status === null ? (
                  <>
                    <Loader />
                    <h3 className="mb-5 text-lg font-normal text-gray-500">
                      Wait While We fetch your details
                    </h3>
                  </>
                ) : (
                  <>
                    <MdOutlinePayments className="mx-auto mb-4 text-gray-400 w-14 h-14" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500">
                      {nipayment_status.payment_state
                        ? `Payment status : ${nipayment_status.payment_state}`
                        : 'Payment failed'}
                    </h3>
                    <button
                      data-modal-toggle="popup-modal"
                      type="button"
                      onClick={() => {
                        closeModal();
                        dispatch({
                          type: NIPAYMENT_STATUS,
                          payload: null,
                        });
                      }}
                      className="text-white bg-gray-700 hover:bg-gray-500 hover:text-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 capitalize"
                    >
                      close
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentStatusModal;
