import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ForgotModal from './ForgotModal';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import ContinueGuest from './ContinueGuest';
function HeaderModal(props) {
  const gueststatus = useSelector((state) => state.guest.status);
  const guestsessionstatus = localStorage.getItem('guestLoggedIn');
  const [TabsStatus, SetTabStatus] = useState(
    (guestsessionstatus || gueststatus) === 'true' ? 4 : 1
  );
  const state = useSelector((state) => state);
  const toggleModal = () => {
    document.getElementById('modal').classList.toggle('hidden');
    if ((guestsessionstatus || gueststatus) === 'true') {
      SetTabStatus(4);
    } else {
      SetTabStatus(1);
    }
  };

  const TabStatusAction = (status) => {
    SetTabStatus(status);
  };

  return (
    <>
      <div className=" fixed z-20 overflow-y-auto top-0 w-full left-0 hidden" id="modal">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100">
              {/* Body Start */}
              {TabsStatus === 1 && (
                <LoginModal toggleModal={toggleModal} TabStatusAction={TabStatusAction} />
              )}
              {TabsStatus === 2 && (
                <RegisterModal
                  codes={props.codes}
                  TabStatusAction={TabStatusAction}
                  toggleModal={toggleModal}
                />
              )}
              {TabsStatus === 3 && (
                <ForgotModal TabStatusAction={TabStatusAction} toggleModal={toggleModal} />
              )}
              {TabsStatus === 4 && (
                <ContinueGuest TabStatusAction={TabStatusAction} toggleModal={toggleModal} />
              )}
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderModal;
