import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { MdClose } from 'react-icons/md';
import moment from 'moment';
import { IoCloseCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { CallCenterCountryCodeStyles } from '../../../helpers/Patterns';
import { useForm, Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../Loader/Loader';
import { useToasts } from 'react-toast-notifications';
import { onupdateorder } from '../../../actions/admin/UpdateOrder.action';
import * as helpers from '../../../helpers/config';
import dic from 'i18next';

const CommentsModal = ({ closeModal, item }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { updateloading, statusCode } = useSelector((state) => state.allorders);
  // state values for Delivery Date & Delivery Time or Pickup Date & Pickup Time
  const [dpdate, setdpdate] = useState(item.dpDate);
  const [dptime, setdptime] = useState();
  // taking logged user data from storage
  const loggeddetails = helpers.LoggedUserDetails();
  const usertype = loggeddetails?.accessDetails?.role;
  const userEmailId = loggeddetails?.userEmailId;
  const userlistdata = {
    userEmailId: userEmailId,
    userType: usertype,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();
  const onSubmits = (data) => {
    const sendupdateuser = {
      orderId: item.orderId,
      remarks: data.remark,
      dpDate: data.newdpdate,
      dpTime: data.newdptime.value,
      customerEmail: data.email,
      userDTO: {
        userEmail: userEmailId,
      },
    };
    dispatch(onupdateorder(sendupdateuser)).then(() => {
      closeModal();
      addToast(<p className="py-0.5">{dic.t('Cakery-Order Status Changed Successfully')}</p>, {
        appearance: 'success',
      });
    });
    console.log('data', sendupdateuser);
  };
  useEffect(() => {
    setValue('remark', item.remarks);
    setValue('newdptime', { label: item.dpTime, value: item.dpTime });
    setValue('newdpdate', moment(item.dpDate).format('YYYY-MM-DD'));
    setValue('email', item.customerEmail);
  }, []);
  const ondptimechange = (e) => {
    setdptime(e.value);
  };
  // const dpTimeList = [{ label: '09:00am - 11:00am', value: '09:00am - 11:00am' }];

  const dpTimeList = [
    {
      label: '09:00am - 11:00am',
      value: '09:00am - 11:00am',
    },
    {
      label: '11:00am -1:00pm',
      value: '11:00am -1:00pm',
    },
    {
      label: '1:00pm - 3:00pm',
      value: '1:00pm - 3:00pm',
    },
    {
      label: '3:00pm - 5:00pm',
      value: '3:00pm - 5:00pm',
    },
    {
      label: '5:00pm - 7:00pm',
      value: '5:00pm - 7:00pm',
    },
  ];
  return (
    <Fragment>
      <div className=" fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen"></span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100">
              <Fragment>
                <div className="shadow-md rounded p-4 w-full">
                  <div className="grid gap-2 mb-4 md:grid-cols-12 xl:grid-cols-12 mt-4">
                    <div className="col-span-12 mb-2">
                      <label className="text-center flex justify-center">UPDATE ORDER</label>
                      <button
                        type="button"
                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center dark:hover:text-white"
                        onClick={() => {
                          closeModal();
                          reset();
                        }}
                      >
                        <MdClose className="w-5 h-5" />
                      </button>
                    </div>
                    <div className="col-span-12 overflow-hidden">
                      <div className="p-2 flex items-center">
                        {updateloading ? (
                          <div className="mx-auto">
                            <Loader />
                          </div>
                        ) : (
                          <div className="w-full">
                            <form
                              className="grid grid-cols-12 gap-2"
                              onSubmit={handleSubmit(onSubmits)}
                            >
                              {/* Delivery & Pickup Date */}
                              <div className="w-full mb-1 col-span-12">
                                <Controller
                                  name="newdpdate"
                                  control={control}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <ReactDatePicker
                                        selected={dpdate}
                                        value={value}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText={
                                          item.deliveryType === 'PICKUP'
                                            ? 'Pickup Date'
                                            : 'Delivery Date'
                                        }
                                        autoComplete="off"
                                        onChange={(date) => {
                                          onChange(date);
                                          setdpdate(date);
                                        }}
                                        className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg  border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                      />
                                    );
                                  }}
                                />
                              </div>
                              {/* Delivery & Pickup Time */}
                              <div className="w-full mb-1 col-span-12">
                                <Controller
                                  name="newdptime"
                                  control={control}
                                  // rules={{ required: true }}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      options={dpTimeList}
                                      placeholder={
                                        item.deliveryType === 'PICKUP'
                                          ? 'Pickup Time'
                                          : 'Delivery Time'
                                      }
                                      value={value}
                                      styles={CallCenterCountryCodeStyles}
                                      onChange={(e) => {
                                        ondptimechange(e);
                                        onChange(e);
                                      }}
                                      maxMenuHeight={140}
                                    />
                                  )}
                                />
                              </div>
                              {/* Update email  */}
                              <div className="w-full mb-1 col-span-12">
                                <input
                                  type="text"
                                  name="email"
                                  defaultValue={'prince.patel@gmail.com'}
                                  className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder={'Update email'}
                                  {...register('email', {
                                    required: 'Email is Required',
                                  })}
                                />
                                {errors.email && errors.email.message && (
                                  <span className=" mt-4 text-xs text-red-400">
                                    {errors.email.message}
                                  </span>
                                )}
                              </div>
                              {/* Remarks/Comments */}
                              <div className="w-full mb-1 col-span-12">
                                <input
                                  type="text"
                                  name="remark"
                                  maxLength={200}
                                  className="border-0 px-3 py-3 tracking-wider placeholder-gray-500 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  placeholder={'Additional Comments'}
                                  {...register('remark', {
                                    required: 'Remarks is Required',
                                  })}
                                  autoComplete="off"
                                />
                                {errors.remark && errors.remark.message && (
                                  <span className=" mt-4 text-xs text-red-400">
                                    {errors.remark.message}
                                  </span>
                                )}
                              </div>

                              <div className="col-span-12 text-right">
                                <button
                                  type="submit"
                                  className="text-white my-2 bg-gray-700 tracking-wider hover:bg-gray-500 font-medium rounded-lg text-md w-full px-5 py-2 text-center"
                                >
                                  Update
                                </button>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CommentsModal;
