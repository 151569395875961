/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import { BsXCircleFill } from 'react-icons/bs';
import Slider from 'react-slick';

function ViewMore({ getViewMore, ViewMoreUpdate, getDescription }) {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  const { images, description, cakename } = getDescription;
  return (
    <div>
      <div
        className={`fixed z-20 overflow-y-auto top-0 w-full left-0 ${
          getViewMore === true ? '' : 'hidden'
        } `}
        id="viewmoremodal"
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block  align-center bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-screen-sm sm:max-w-screen-sm lg:max-w-[80%] xl:max-w-screen-[32rem] 2xl:max-w-[70%] w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white">
              {/* Body Start */}
              <div className="modal-body">
                <h4 className="text-lg text-center bg-gray-700 font-semibold tracking-wider py-3">
                  {cakename}
                </h4>
                <div className="position-relative">
                  <BsXCircleFill
                    className="absolute right-[17px] top-[19px] cursor-pointer text-lg"
                    onClick={() => ViewMoreUpdate()}
                  />
                </div>
                <div className="grid grid-cols-1  lg:grid-cols-2 ">
                  <div>
                    <Slider {...settings}>
                      {images.map((items) => {
                        const image = items?.fields?.Image?.value?.src;
                        return (
                          <div className="w-full h-[35vh] lg:h-[70vh] 2xl:h-[60vh] overflow-hidden relative">
                            <img
                              className="absolute inset-0 lg:h-full	w-full object-cover"
                              src={image}
                              alt="cake"
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                  <div>
                    <div className="text-center py-3 overflow-y-auto max-h-[35vh] sm:max-h-[35vh] md:max-h-full  px-14">
                      <p className="text-black" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Body End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ViewMore;
