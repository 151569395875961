import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';

export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_LOADING = 'UPDATE_ORDER_LOADING';

export const onupdateorder = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_ORDER_LOADING, payload: true });
  let tokenid = localStorage.getItem('tokenId');
  await axios
    .post(AdminMicroRoutes.updateorder, data, { headers: { token: `${tokenid}` } })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: UPDATE_ORDER_SUCCESS,
          payload: response.data,
        });
        dispatch({ type: UPDATE_ORDER_LOADING, payload: false });
      }
    });
};
