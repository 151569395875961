import React, { Fragment } from 'react';
import { TiSocialFacebook } from 'react-icons/ti';

function Facebook() {
  return (
    <Fragment>
      <TiSocialFacebook className="text-white relative top-1 left-[0.2rem]" size={25} />
    </Fragment>
  );
}

export default Facebook;
