import {
  FILTER_ORDER_LOADING,
  FILTER_ORDER_SUCCESS,
  FILTER_ORDER_FAILURE,
} from '../../actions/admin/FilterOrder.action';
import { REMOVE_FILTER_ERROR } from '../../actions/admin/RemoveError.action';

const initailState = {
  loading: false,
  orders: [],
  error: '',
};

export const filterOrdersReducer = (state = initailState, action) => {
  switch (action.type) {
    case FILTER_ORDER_SUCCESS:
      return {
        ...state,
        // loading: false,
        orders: action.payload,
      };

    case FILTER_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

    case FILTER_ORDER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case REMOVE_FILTER_ERROR:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};
