import {
  SET_LOGIN_LOADING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  CLEAR_LOGIN_STATE,
  REVERT_LOGIN_STATE,
  CLEAR_ERROR,
} from '../actions/login.action';

const initailState = {
  loading: false,
  statuscode: '',
  error: '',
  logoutstatus: false,
};

export const userLoginReducer = (state = initailState, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        statuscode: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: '',
      };

    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_LOGIN_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CLEAR_LOGIN_STATE:
      return {
        ...state,
        logoutstatus: action.logoutstatus,
        statuscode: '',
      };
    case REVERT_LOGIN_STATE:
      return {
        ...state,
        logoutstatus: false,
      };
    default:
      return state;
  }
};
