import React, { Fragment } from 'react';
import { IoCloseCircle } from 'react-icons/io5';

const CheckoutNoteModal = ({ closeModal, proccedPayment }) => {
  return (
    <Fragment>
      <div className=" fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <span className="flex h-screen justify-center items-center sm:inline-block sm:align-middle sm:h-screen"></span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-100 shadow-md rounded p-4 w-full">
              <div className="text-center py-3">
                <span className="visually-hidden ml-2 text-gray-700">
                  <b>Note: </b>Please keep the browser open until you receive an order ID after
                  successfully payment. For any issue contact us on whatsapp{' '}
                  <a href="tel:+971526801334">526801334</a>
                </span>
                <p className="mt-3">
                  <b>Please wait, we are redirecting you...</b>
                </p>
              </div>
              <div className="text-center ">
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                  className="text-white bg-gray-700 hover:bg-gray-500 hover:text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Cancel
                </button>
                {/* <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={() => {
                    proccedPayment();
                  }}
                  className="text-white bg-gray-700 hover:bg-gray-500 hover:text-gray-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Okay
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutNoteModal;
