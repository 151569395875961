import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import { reactAppSecretScan } from '../constants';

export const HomeUrl = 'https://dxp-hospitality-dev-rg-sit-467300-cm.azurewebsites.net';
export const currency = 'AED';
export const ContentType = process.env.REACT_APP_CONTENTTYPE;
export const OCP_Subscription_Key = process.env.REACT_APP_AUTHOCPAPIMSUBKEY;
export const APP_Authorization = process.env.REACT_APP_AUTHORIZATION;

export const CartSubtotalSum = (cartItems) => {
  var serviceprice = [];
  cartItems?.map((product) => {
    serviceprice?.push(
      product?.cartaddedservice?.reduce(function (tot, arr) {
        return parseInt(tot) + parseInt(arr?.price?.value * arr?.quantity?.value);
      }, 0)
    );
  });
  return serviceprice.reduce(function (a, b) {
    return a + b;
  }, 0);
};

export const ArraySum = (response) => {
  if (response.length > 0) {
    return response.reduce((a, b) => parseInt(a) + parseInt(b));
  } else {
    return 0;
  }
};

export const AddonsTotalPrice = (serviceitem) => {
  // cartItems.map((item, keys) => {
  // item.cartaddedservice.map((serviceitem, getkey) => {

  if (serviceitem?.addon && serviceitem?.addon?.length > 0) {
    let addonSum = serviceitem?.addon?.reduce(
      (acc, curr) => acc + Number(curr.Price * curr.Qty),
      0
    );
    return addonSum;
  } else {
    return 0;
  }
  // });
  // })
};

export const orderProductDetail = () => {
  const items = [];
  const addonpricelist = [];
  const cartItems =
    localStorage.getItem('cartproducts') === null
      ? localStorage.getItem('getcartproduct')
      : localStorage.getItem('cartproducts');
  const cartresponse = JSON.parse(cartItems);
  const updateProduct =
    cartresponse &&
    cartresponse.length > 0 &&
    cartresponse.map((item, keys) => {
      item.cartaddedservice.map((serviceitem) => {
        const addons = serviceitem.addon !== undefined ? serviceitem.addon : {};
        addonpricelist.push(AddonsTotalPrice(serviceitem));
        // addonPrice Comment
        // addonpricelist.push(addons?.Price !== undefined ? addons?.Price : 0);
        items.push({
          cakeType: serviceitem?.type,
          itemMessage: serviceitem?.message?.value,
          itemDescription: serviceitem?.serves?.value,
          itemImageUrl: item?.ItemDetails?.image,
          itemName: item?.ItemDetails?.title,
          itemServiceId: serviceitem?.id,
          itemCakeId: item?.ItemDetails?.id,
          perItemPrice: Number(serviceitem?.price?.value),
          quantity: serviceitem?.quantity?.value,
          totalPrice:
            (parseInt(ArraySum(addonpricelist)) + parseInt(serviceitem?.price?.value)) *
            parseInt(serviceitem?.quantity?.value),
          addOns: addons,
        });
      });
    });
  return {
    total: parseInt(CartSubtotalSum(cartresponse)) + parseInt(ArraySum(addonpricelist)),
    order_product: items,
  };
};

export const TotalProductSum = () => {
  const getOrderTotal = orderProductDetail();
  return getOrderTotal?.total;
};

export const paymentBeforeValidate = (deliverycharge, Discount) => {
  const Loggeduser = LoggedUserDetails();
  const cartItems = localStorage.getItem('cartproducts');
  const subtotal = parseInt(TotalProductSum() - Discount);
  const total =
    deliverycharge !== false
      ? (parseInt(subtotal) + parseInt(deliverycharge)) * 100
      : parseInt(subtotal) * 100;
  const islogin = localStorage.getItem('islogin');
  var GuestObj = '';
  var AddressObj = '';
  var address = '';
  const deliveryOptions = JSON.parse(localStorage?.getItem('NewDeliveryAddressExtra'));
  if (deliveryOptions === (undefined || null)) {
    return { status: 'error', description: 'Please fill the delivery/pickup options' };
  }
  if (islogin === 'true') {
    const selectAddress = localStorage.getItem('selectAddress');
    if (deliveryOptions?.deliverytype === 'delivery') {
      if (selectAddress === undefined || selectAddress === null) {
        return { status: 'error', description: 'Please select a address' };
      }
    }
    const Loggeduser = LoggedUserDetails();
    address = getSelectedAddress();
  } else {
    const guestDetail = JSON.parse(localStorage.getItem('GuestDetail'));
    let AddressObj = {};
    if (deliveryOptions?.deliverytype === 'delivery') {
      const guestaddress = JSON.parse(localStorage.getItem('NewDeliveryAddress'));
      AddressObj =
        guestaddress[0]?.makani + ' ' + guestaddress[0]?.region + ' ' + guestaddress[0]?.address;
    }
    GuestObj = {
      userEmailId: guestDetail?.email,
      phone: guestDetail?.phone,
      firstName: guestDetail?.name,
      lastName: guestDetail?.name,
    };
  }
  return {
    status: 'success',
    details: {
      total: total,
      subtotal: subtotal,
      deliveryOptions: deliveryOptions,
      Loggeduser: islogin === 'true' ? Loggeduser : GuestObj,
      address: islogin === 'true' ? address : AddressObj,
      cartItems: cartItems,
    },
  };
};

export const OrderAddress = () => {
  const islogin = localStorage.getItem('islogin');
  const DeliveryDetails = JSON.parse(localStorage.getItem('NewDeliveryAddressExtra'));
  let response = '';
  if (islogin === 'true') {
    var deliveryaddress = {};
    const selectAddress = localStorage.getItem('selectAddress');
    const Loggeduser = LoggedUserDetails();
    if (DeliveryDetails?.deliverytype === 'delivery') {
      var savedAddress = [];
      const updateaddress = localStorage.getItem('NewDeliveryAddress');
      var checkaddress = JSON.parse(updateaddress);
      const extractdelivery = checkaddress.filter(
        (ietm, key) => parseInt(key) === parseInt(selectAddress)
      );
      deliveryaddress = {
        region: extractdelivery[0]?.region,
        makani: extractdelivery[0]?.makani,
        address: extractdelivery[0]?.address,
      };
      response = { deliveryaddress: deliveryaddress, savedAddress: checkaddress };
    } else {
      response = { deliveryaddress: deliveryaddress, savedAddress: Loggeduser?.savedAddress };
    }
  } else {
    let guestaddress = {
      region: '',
      makani: '',
      address: '',
    };
    if (DeliveryDetails?.deliverytype === 'delivery') {
      const guesttempaddress = JSON.parse(localStorage.getItem('NewDeliveryAddress'));
      guestaddress = {
        region: guesttempaddress[0]?.region,
        makani: guesttempaddress[0]?.makani,
        address: guesttempaddress[0]?.address,
      };
    }
    response = { deliveryaddress: guestaddress, savedAddress: [] };
  }
  return response;
};

export const getSelectedAddress = () => {
  const selectAddress = localStorage.getItem('selectAddress');
  const DeliveryDetails = JSON.parse(localStorage.getItem('NewDeliveryAddressExtra'));
  const Loggeduser = LoggedUserDetails();
  const islogin = localStorage.getItem('islogin');
  if (DeliveryDetails?.deliverytype === 'delivery') {
    var deliveryaddress = {};
    var address = '';
    if (islogin === 'true') {
      if (selectAddress !== undefined) {
        const updateaddress = localStorage.getItem('NewDeliveryAddress');
        const checkaddress = JSON.parse(updateaddress);
        const response = checkaddress.filter(
          (ietm, key) => parseInt(key) === parseInt(selectAddress)
        );
        // Saved Address
        deliveryaddress = {
          region: response[0]?.region,
          makani: response[0]?.makani,
          address: response[0]?.address,
        };
        address = response[0]?.makani + ' ' + response[0]?.region + ' , ' + response[0]?.address;
      }
    } else {
      if (selectAddress !== undefined) {
        const updateaddress = localStorage.getItem('NewDeliveryAddress');
        const checkaddress = JSON.parse(updateaddress);
        const response = checkaddress.filter(
          (ietm, key) => parseInt(key) === parseInt(selectAddress)
        );
        // Saved Address
        deliveryaddress = {
          region: response[0]?.region,
          makani: response[0]?.makani,
          address: response[0]?.address,
        };
        address = response[0]?.makani + ' ' + response[0]?.region + ' , ' + response[0]?.address;
      }
    }
  } else {
    address = '';
  }

  return address;
};

export const currentCartItems = () => {
  const products = localStorage.getItem('cartproducts');
  const response = JSON.parse(products);
  return response;
};

export const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'transperant',
    color: '#ffffff',
    borderRadius: 5,
    minHeight: '42px',
    height: '42px',
    borderColor: '#abb8bd',
    '&:hover': { borderColor: '#abb8bd' },
    boxShadow: 'none',
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  dropdownIndicator: (base) => ({
    ...base,
    position: 'relative',
    top: '0px',
    color: '#abb8bd',
  }),
  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      position: 'relative',
      top: '0px',
    };
  },
  input: (base) => ({
    ...base,
    color: 'white',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'white',
  }),
  menuList: (defaultStyles) => {
    return {
      ...defaultStyles,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '0px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '0px',
      },
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? 'gray' : 'white',
      color: state.isSelected ? 'black' : 'black',
    };
  },
};

export const CurrentLoginStatus = () => {
  return useSelector((state) => state.login.statuscode);
};

export const SessionStore = (keyname, response) => {
  return localStorage.setItem(keyname, response);
};

export const JsonStringfySession = (keyname, response) => {
  return localStorage.setItem(keyname, JSON.stringify(response));
};

export const LoggedUserDetails = () => {
  const getdetail = localStorage.getItem('LoggedUserDetails');
  const response = JSON.parse(getdetail);
  return response;
};

export const GuestDetail = () => {
  return JSON.parse(localStorage.getItem('GuestDetail'));
};

export const encryptePayload = (body, withOutPayload) => {
  // Encrypt Payload
  let bodyString = withOutPayload ? body : JSON.stringify(body);
  let payload = '';
  if (bodyString) {
    payload = CryptoJS.AES.encrypt(bodyString, reactAppSecretScan).toString();
  }
  return { payload: payload };
};

export const decryptePayload = (payload) => {
  // Decrypt Payload
  return CryptoJS.AES.decrypt(payload, reactAppSecretScan).toString(CryptoJS.enc.Utf8);
};
