import React, { Fragment, useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { colourStyles } from '../../../helpers/Patterns';
import * as helpers from '../../../helpers/config';
import { setCenterCart, emptyCenterCart } from '../../../actions/admin/CallCenter.action';
import dic from 'i18next';
import { DoumentUpload, setUploadedDocumentId } from '../../../actions/admin/DocumentUpload.action';
import DocumentUploadLoader from './DocumentUploadLoader';
import { SelectBox } from './SelectBox';
import CartControls from '../../CartControls';

const CakeForm = ({
  FlavourOptions,
  FestivalFlavourOptions,
  FestivalDeliveryCharges,
  SuppliesOptions,
  PastryOptions,
  CustomizeCakeOptions,
}) => {
  const { cartItems } = useSelector((state) => state.callcenter);
  const { loading } = useSelector((state) => state.documentupload);
  const Dispatch = useDispatch();
  let servingsOptions = [];
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();
  const MAX_ALLOWED_SIZE = 3000000; // 3 MB in bytes
  const [perItemPrice, setItemPrice] = useState(0);
  const [orderType, setOrderType] = useState(
    cartItems && cartItems.length > 0 ? cartItems[0] && cartItems[0].cakeType : 'REGULAR'
  );
  const [addonlist, setaddonlist] = useState([]);
  const [custPrice, setCustPrice] = useState();
  const [suppliesPrice, setsuppliesPrice] = useState();
  const [servingOption, setServingsOption] = useState([]);
  const [cakeimage, setCakeImage] = useState('');
  const [addonberryprice, setAddonberryprice] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [pastryQty, setPastryQty] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);

  // useEffect to when ordertype change make cart empty
  // useEffect(() => {
  //   debugger;
  //   Dispatch(emptyCenterCart(''));
  // }, [orderType]);
  const documentupload = useSelector((state) => state.documentupload);
  const onSubmits = (data) => {
    if (orderType === 'CUSTOMIZED' && uploadedFiles.length > 0) {
      uploadDocumentToServer(uploadedFiles, data);
    }

    let suppliesPrice = data.suppliesprice ? [Number(data.suppliesprice)] : [];

    const cakedata = {
      cakeImage: cakeimage,
      cakeSuppliesPrice: suppliesPrice,
      cakeSuppliesName: data.supplies ? [data.supplies.value] : [],
      cakeType: orderType,
      totalPrice: Number(
        orderType === 'FESTIVAL'
          ? Number(perItemPrice) +
              ((data.addons &&
                data.addons.length &&
                Number(
                  data.addons.reduce((acc, curr) => acc + Number(curr.value * curr.Qty), 0)
                )) ||
                0) +
              (suppliesPrice.length > 0 ? Number(suppliesPrice) : 0)
          : orderType === 'CUSTOMIZED'
          ? Number(data.Price) + (suppliesPrice.length > 0 ? Number(suppliesPrice) : 0)
          : orderType === 'PASTRY'
          ? Number(servingOption[0].value) * data.pastryQty
          : Number(perItemPrice) + (suppliesPrice.length > 0 ? Number(suppliesPrice) : 0)
      ),
      cakePerItemPrice: Number(
        orderType === 'CUSTOMIZED'
          ? Number(data.Price)
          : orderType === 'PASTRY'
          ? Number(servingOption[0].value) * data.pastryQty
          : Number(perItemPrice)
      ),
      cakeQuantity: 1,
      cakeFlavour: data.flavour.label,
      cakeMessage: data.message,
      cakeServings: data.pastryQty ? `${data.pastryQty} Person` : data.servings.label,
      cakeRemarks: data.remarks,
      cakeBerries: Number(addonberryprice),
      cakeAddonName: orderType === 'FESTIVAL' && data.addons !== null ? data.addons.label : '',
      cakeFestiveAddons: orderType === 'FESTIVAL' && data.addons !== null ? data.addons : '',
    };
    if (cakedata.cakeSuppliesName.length > 0 && cakedata.cakeSuppliesPrice.length === 0) {
      alert('Please add price');
    } else if (cakedata.cakeSuppliesPrice.length > 0 && cakedata.cakeSuppliesName.length === 0) {
      alert('Please add Addon name');
    } else {
      Dispatch(setCenterCart(cakedata));
    }
  };
  const onFlavourChange = (e) => {
    let services = e.value.services;
    let addons = e.value.addons;
    let addonoptions = [];
    let image = e.value.cakeImages[0]?.fields?.Image?.value?.src;
    services.map((item) => {
      servingsOptions.push({
        label: item?.fields?.['Service To People']?.fields?.Title?.value,
        value: item?.fields?.Price?.value,
      });
    });
    addons.map((item) => {
      addonoptions.push({
        label: item?.fields?.Title?.value,
        value: item?.fields?.Charges[0]?.fields?.Price?.value,
      });
    });
    setaddonlist([...addonoptions]);
    setServingsOption(servingsOptions);
    setCakeImage(`${image}`);
    if (orderType === 'FESTIVAL') {
      setValue('addons', null);
      setAddonberryprice(0);
    }
  };
  const onServingChange = (e) => {
    setItemPrice(e.value);
  };
  const onAddOnChange = (e) => {
    setAddonberryprice(e.value);
    setSelectedValues(e);
  };
  const handleCheckNumber = (e) => {
    const value = e.target.value.replace(/[^\d]+/g, '');
    setCustPrice(value);
  };
  const handleCheckSuppliesPrice = (e) => {
    const value = e.target.value.replace(/[^\d]+/g, '');
    setsuppliesPrice(value);
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    if (files && files[0] && files[0].size > MAX_ALLOWED_SIZE) {
      setFileLimit(true);
    } else {
      setUploadedFiles(files);
      setFileLimit(false);
    }
    return true;
  };
  const uploadDocumentToServer = (docs, payloadData) => {
    const formData = new FormData();
    // Form append not working
    if (docs && docs.length !== 0) {
      formData.append('file', docs[0]); // upload one only, check in the doc if its array
      Dispatch(DoumentUpload(formData, uploadDocCallback, payloadData, docs[0].name));
    }
  };

  const uploadDocCallback = (resp, payloadData, fileName) => {
    if (resp && resp.documentId !== '' && resp.documentId !== null) {
      const oldDocumentId =
        documentupload && documentupload.submitedDocumentID
          ? documentupload.submitedDocumentID
          : [];
      oldDocumentId.push({ fielId: parseInt(resp.documentId), fileName: fileName });
      setUploadedDocumentId(oldDocumentId);
    }
  };

  const handleCheckboxChange = (addonName, addonPrice, currency) => {
    const selectedValuesArray = Array.isArray(selectedValues) ? selectedValues : [];
    const isOptionSelected = selectedValuesArray.some((value) => value.label === addonName);
    if (isOptionSelected) {
      const newSelectedValues = selectedValuesArray.filter((value) => value.label !== addonName);
      setSelectedValues(newSelectedValues);
    } else {
      const newSelectedValues = [
        ...selectedValuesArray,
        {
          label: addonName, // Use addonName here instead of label
          value: Number(addonPrice),
          Currency: currency,
          Qty: 1,
        },
      ];
      setSelectedValues(newSelectedValues);
    }
  };

  const filterselectedValues = (val) => {
    let temp = selectedValues?.filter((value) => value.label === val.label);
    return temp[0];
  };

  const addonQtychange = (val, quantity) => {
    let newData = [];
    selectedValues.map((value) => {
      if (value.label === val.label) {
        newData.push({
          label: value.label, // Use addonName here instead of label
          value: Number(value.value),
          Currency: value.Currency,
          Qty: quantity,
        });
      } else {
        newData.push(value);
      }
    });
    setSelectedValues(newData);
  };
  return (
    <Fragment>
      <div className={'border-2 text-black shadow px-4 pt-4 border-gray-200 sm:h-full'}>
        <fieldset className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div
            htmlFor="Regular"
            className={`block cursor-pointer rounded-lg border border-gray-300 p-2 text-sm font-medium shadow-sm hover:border-gray-200 ${
              orderType === 'REGULAR' ? 'border-blue-500 ring-1 ring-blue-500' : ''
            }`}
            onClick={() => {
              // Dispatch(emptyCenterCart(''));
              setOrderType('REGULAR');
              setValue('flavour', null);
              setValue('servings', null);
              setaddonlist([]);
              setAddonberryprice(0);
            }}
          >
            <div className="flex items-center justify-between">
              <p className="text-gray-700 font-bold">REGULAR</p>

              <svg
                className={`h-5 w-5 text-blue-600 ${orderType === 'REGULAR' ? '' : 'hidden'}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div
            htmlFor="customize"
            className={`block cursor-pointer rounded-lg border border-gray-300 p-2 text-sm font-medium shadow-sm hover:border-gray-200 ${
              orderType === 'CUSTOMIZED' ? 'border-blue-500 ring-1 ring-blue-500' : ''
            }`}
            onClick={() => {
              Dispatch(emptyCenterCart(''));
              setOrderType('CUSTOMIZED');
              setValue('flavour', null);
              setValue('servings', null);
              setaddonlist([]);
              setAddonberryprice(0);
            }}
          >
            <div className="flex items-center justify-between">
              <p className="text-gray-700 font-bold">CUSTOMIZED</p>

              <svg
                className={`h-5 w-5 text-blue-600 ${orderType === 'CUSTOMIZED' ? '' : 'hidden'}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div
            htmlFor="festival"
            className={`block cursor-pointer rounded-lg border border-gray-300 p-2 text-sm font-medium shadow-sm hover:border-gray-200 ${
              orderType === 'FESTIVAL' ? 'border-blue-500 ring-1 ring-blue-500' : ''
            }`}
            onClick={() => {
              if (orderType !== 'FESTIVAL') {
                Dispatch(emptyCenterCart(''));
              }
              setOrderType('FESTIVAL');
              setValue('flavour', null);
              setValue('servings', null);
              setaddonlist([]);
              setAddonberryprice(0);
            }}
          >
            <div className="flex items-center justify-between">
              <p className="text-gray-700 font-bold">FESTIVAL</p>

              <svg
                className={`h-5 w-5 text-blue-600 ${orderType === 'FESTIVAL' ? '' : 'hidden'}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div
            htmlFor="PASTRY"
            className={`block cursor-pointer rounded-lg border border-gray-300 p-2 text-sm font-medium shadow-sm hover:border-gray-200 ${
              orderType === 'PASTRY' ? 'border-blue-500 ring-1 ring-blue-500' : ''
            }`}
            onClick={() => {
              // Dispatch(emptyCenterCart(''));
              setOrderType('PASTRY');
              setValue('flavour', null);
              setValue('servings', null);
              setaddonlist([]);
              setAddonberryprice(0);
            }}
          >
            <div className="flex items-center justify-between">
              <p className="text-gray-700 font-bold">PASTRY</p>

              <svg
                className={`h-5 w-5 text-blue-600 ${orderType === 'PASTRY' ? '' : 'hidden'}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </fieldset>
        {orderType === 'REGULAR' && (
          <form className="grid grid-cols-12 gap-2 mt-4" onSubmit={handleSubmit(onSubmits)}>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="flavour"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      options={FlavourOptions}
                      value={value}
                      placeholder={`${dic.t('Cakery-Flavors') + ' *'}`}
                      styles={colourStyles}
                      onChange={(e) => {
                        onFlavourChange(e);
                        onChange(e);
                        setValue('servings', null);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="servings"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={servingOption}
                    value={value}
                    placeholder={`${dic.t('Cakery-Servings') + ' *'}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onServingChange(e);
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="supplies"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={SuppliesOptions}
                    value={value}
                    placeholder={`${dic.t('Cakery-addsupplies')}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <input
                type="text"
                className="appearance-none block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Price')}
                {...register('suppliesprice')}
                onChange={handleCheckSuppliesPrice}
                value={suppliesPrice}
                maxLength={5}
                autoComplete="off"
              />
            </div>
            <div className="mb-2 col-span-12">
              <input
                type="text"
                maxLength={'40'}
                className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Message To Put On The Cake')}
                {...register('message')}
                autoComplete="off"
              />
            </div>
            {/* <div className="mb-2 col-span-12 sm:col-span-6">
              <input
                type="text"
                className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Remarks')}
                {...register('remarks')}
                autoComplete="off"
              />
            </div> */}
            {/* {addonlist && addonlist.length > 0 && (
              <div className="mb-2 col-span-12 sm:col-span-12">
                <h2 className="text-md font-semibold">{dic.t('Cakery-Addons')}</h2>
                <div className="addons">
                  {addonlist.length > 0 &&
                    addonlist.map((val, i) => {
                      console.log(val, 'VAlues ADDONS');
                      return (
                        <React.Fragment key={i}>
                          <div className="addon-items flex items-center justify-between space-x-4 mb-2">
                            <input
                              type="checkbox"
                              name={val.label.toLowerCase().split(' ').join('-')}
                              onChange={() => {
                                handleCheckboxChange(val.label, val.value, dic.t('Cakery-AED'));
                                onChange([
                                  ...selectedValues,
                                  { label: val.label, value: val.value },
                                ]);
                              }}
                              checked={
                                selectedValues &&
                                selectedValues.some((value) => value.label === val.label)
                              }
                              className="align-middle"
                            />
                            <div className="title flex-1">{val.label}</div>
                            <div className="quantity">
                              {selectedValues &&
                                selectedValues.some((value) => value.label === val.label) && (
                                  <CartControls
                                    qty={filterselectedValues(val).Qty}
                                    onValueChange={(quantity) => {
                                      addonQtychange(val, quantity);
                                      onChange([
                                        ...selectedValues,
                                        {
                                          label: val.label,
                                          value: val.value,
                                          Currency: dic.t('Cakery-AED'),
                                          Qty: quantity,
                                        },
                                      ]);
                                    }}
                                  />
                                )}
                            </div>
                            <div className="price">
                              {val.value} {dic.t('Cakery-AED')}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            )} */}
            {/* {addonlist && addonlist.length > 0 && (
              <div className="mb-2 col-span-12 sm:col-span-12">
                <div className="flex justify-between mb-2">
                  <h2>{dic.t('Cakery-Addons')}</h2>
                  <strong
                    className="inline-flex items-center hover:cursor-pointer border border-red-500 text-red-500 border-current uppercase p-1.5 rounded-full text-[10px] tracking-widest"
                    onClick={() => {
                      setAddonberryprice(0);
                    }}
                  >
                    {dic.t('Cakery-Remove')}
                  </strong>
                </div>
                <div className="grid grid-cols-1 gap-2">
                  {addonlist.length > 0 &&
                    addonlist.map((item, key) => {
                      let price = item?.fields?.Price?.value;
                      return (
                        <div
                          className="col-span-1"
                          key={key}
                          onClick={() => {
                            setAddonberryprice(price);
                          }}
                        >
                          <label
                            className={`block p-1 text-sm text-center font-medium transition-colors border border-gray-300 rounded-lg shadow-sm cursor-pointer ${
                              addonberryprice === price
                                ? 'border-gray-700 bg-gray-600 text-white'
                                : ''
                            }`}
                          >
                            <span>
                              {dic.t('Cakery-AED')} {price}{' '}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            )} */}
            <div className="mb-2 col-span-12 sm:col-span-12">
              <button
                type="submit"
                className="text-white bg-gray-700 col-span-6 hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
              >
                {dic.t('Cakery-Add')}
              </button>
            </div>
          </form>
        )}
        {orderType === 'CUSTOMIZED' && (
          <form className="grid grid-cols-12 gap-2 mt-4" onSubmit={handleSubmit(onSubmits)}>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="flavour"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      options={CustomizeCakeOptions}
                      value={value}
                      placeholder={`${dic.t('Cakery-Flavors') + ' *'}`}
                      styles={colourStyles}
                      onChange={(e) => {
                        onFlavourChange(e);
                        onChange(e);
                        setValue('servings', null);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="servings"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={servingOption}
                    value={value}
                    placeholder={`${dic.t('Cakery-Servings') + ' *'}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onServingChange(e);
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2 col-span-12">
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer bg-gray-50 focus:outline-none"
                aria-describedby="file_input_help"
                id="file_input"
                accept="application/pdf, image/jpeg, image/jpg"
                type="file"
                onChange={handleFileEvent}
              />
              <p className="mt-1 text-xs text-gray-500" id="file_input_help">
                PDF or JPEG (MAX. 3MB).{' '}
                {fileLimit && <span className="text-xs text-red-600">File Size Exceeded</span>}
              </p>
            </div>
            <div className="mb-2 col-span-12">
              <input
                type="text"
                className="appearance-none block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Price')}
                {...register('Price')}
                onChange={handleCheckNumber}
                value={custPrice}
                maxLength={5}
                autoComplete="off"
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="supplies"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={SuppliesOptions}
                    value={value}
                    placeholder={`${dic.t('Cakery-addsupplies')}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <input
                type="text"
                className="appearance-none block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Price')}
                {...register('suppliesprice')}
                onChange={handleCheckSuppliesPrice}
                value={suppliesPrice}
                maxLength={5}
                autoComplete="off"
              />
            </div>
            <div className="mb-2 col-span-12">
              <input
                type="text"
                maxLength={'40'}
                className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Message To Put On The Cake')}
                {...register('message')}
                autoComplete="off"
              />
            </div>
            {/* <div className="mb-2 col-span-12 sm:col-span-6">
              <input
                type="text"
                className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Remarks')}
                {...register('remarks')}
                autoComplete="off"
              />
            </div> */}
            {addonlist && addonlist.length > 0 && (
              <div className="mb-2 col-span-12 sm:col-span-12">
                <div className="flex justify-between mb-2">
                  <h2>{dic.t('Cakery-Berries')}</h2>
                  <strong
                    className="inline-flex items-center hover:cursor-pointer border border-red-500 text-red-500 border-current uppercase p-1.5 rounded-full text-[10px] tracking-widest"
                    onClick={() => {
                      setAddonberryprice(0);
                    }}
                  >
                    {dic.t('Cakery-Remove')}
                  </strong>
                </div>
                <div className="grid grid-cols-6 gap-2">
                  {addonlist.length > 0 &&
                    addonlist.map((item, key) => {
                      let price = item?.fields?.Price?.value;
                      return (
                        <div
                          className="col-span-1"
                          key={key}
                          onClick={() => {
                            setAddonberryprice(price);
                          }}
                        >
                          <label
                            className={`block p-1 text-sm text-center font-medium transition-colors border border-gray-300 rounded-lg shadow-sm cursor-pointer ${
                              addonberryprice === price
                                ? 'border-gray-500 bg-gray-600 text-white'
                                : ''
                            }`}
                          >
                            <span>
                              {dic.t('Cakery-AED')} {price}{' '}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            <div className="mb-2 col-span-12 sm:col-span-12">
              <button
                disabled={fileLimit}
                type="submit"
                className="text-white bg-gray-700 col-span-6 hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
              >
                {dic.t('Cakery-Add')}
              </button>
            </div>
          </form>
        )}
        {orderType === 'FESTIVAL' && (
          <form className="grid grid-cols-12 gap-2 mt-4" onSubmit={handleSubmit(onSubmits)}>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="flavour"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      options={FestivalFlavourOptions}
                      value={value}
                      placeholder={`${dic.t('Cakery-Flavors') + ' *'}`}
                      styles={colourStyles}
                      onChange={(e) => {
                        onFlavourChange(e);
                        onChange(e);
                        setValue('servings', null);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="servings"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={servingOption}
                    value={value}
                    placeholder={`${dic.t('Cakery-Servings') + ' *'}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onServingChange(e);
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="supplies"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={SuppliesOptions}
                    value={value}
                    placeholder={`${dic.t('Cakery-addsupplies')}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <input
                type="text"
                className="appearance-none block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Price')}
                {...register('suppliesprice')}
                onChange={handleCheckSuppliesPrice}
                value={suppliesPrice}
                maxLength={5}
                autoComplete="off"
              />
            </div>
            <div className="mb-2 col-span-12">
              <input
                type="text"
                maxLength={'40'}
                className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Message To Put On The Cake')}
                {...register('message')}
                autoComplete="off"
              />
            </div>
            {/* <div className="mb-2 col-span-12 sm:col-span-6">
              <input
                type="text"
                className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Remarks')}
                {...register('remarks')}
                autoComplete="off"
              />
            </div> */}
            {addonlist && addonlist.length > 0 && (
              <div className="mb-2 col-span-12 sm:col-span-12">
                <div className="flex justify-between mb-2">
                  <h2>{dic.t('Cakery-Addons')}</h2>
                  {/* <strong
                    className="inline-flex items-center hover:cursor-pointer border border-red-500 text-red-500 border-current uppercase p-1.5 rounded-full text-[10px] tracking-widest"
                    onClick={() => {
                      setAddonberryprice(0);
                      setValue('addons', null);
                    }}
                  >
                    {dic.t('Cakery-Remove')}
                  </strong> */}
                </div>
                <div className="mb-2 col-span-12 sm:col-span-6">
                  <div className="addons">
                    <Controller
                      name="addons"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <div>
                          {addonlist.length > 0 &&
                            addonlist.map((val, i) => {
                              console.log(val, 'VAlues ADDONS');
                              return (
                                <React.Fragment key={i}>
                                  <div className="addon-items flex items-center justify-between space-x-4 mb-2">
                                    <input
                                      type="checkbox"
                                      name={val.label.toLowerCase().split(' ').join('-')}
                                      onChange={(e) => {
                                        handleCheckboxChange(
                                          val.label,
                                          val.value,
                                          dic.t('Cakery-AED')
                                        );
                                        onChange([
                                          ...selectedValues,
                                          {
                                            label: val.label,
                                            value: Number(val.value),
                                            Currency: dic.t('Cakery-AED'),
                                            Qty: 1,
                                          },
                                        ]);
                                      }}
                                      checked={
                                        selectedValues &&
                                        selectedValues.some((value) => value.label === val.label)
                                      }
                                      className="align-middle"
                                    />
                                    <div className="title flex-1">{val.label}</div>
                                    <div className="quantity">
                                      {selectedValues &&
                                        selectedValues.some(
                                          (value) => value.label === val.label
                                        ) && (
                                          <CartControls
                                            qty={filterselectedValues(val).Qty}
                                            onValueChange={(quantity) => {
                                              addonQtychange(val, quantity);
                                              onChange([
                                                ...selectedValues.filter(
                                                  (value) => value.label !== val.label
                                                ),
                                                {
                                                  label: val.label,
                                                  value: Number(val.value),
                                                  Currency: dic.t('Cakery-AED'),
                                                  Qty: quantity,
                                                },
                                              ]);
                                            }}
                                          />
                                        )}
                                    </div>
                                    <div className="price">
                                      {val.value} {dic.t('Cakery-AED')}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      )}
                    />
                  </div>
                  {/* {addonlist.length > 0 && (
                    <Controller
                      name="addons"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <SelectBox
                          className="block font-medium py-[4px] px-2 w-full text-sm text-gray-400 bg-transparent rounded-md  border border-gray-400  dark:placeholder-gray-400 placeholder-gray-400 dark:text-gray-600  dark:focus:border-primary-color focus:outline-none focus:ring-0 focus:border-primary-color"
                          onChange={(e) => {
                            onAddOnChange(e);
                            onChange(e);
                            console.log('eeee', e);
                          }}
                          selectedValues={selectedValues}
                          options={addonlist}
                          placeholder={`${dic.t('Cakery-Addons') + ' *'}`}
                          value={value}
                        />
                      )}
                    />
                  )} */}
                </div>
              </div>
            )}
            <div className="mb-2 col-span-12 sm:col-span-12">
              <button
                type="submit"
                className="text-white bg-gray-700 col-span-6 hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
              >
                {dic.t('Cakery-Add')}
              </button>
            </div>
          </form>
        )}
        {orderType === 'PASTRY' && (
          <form className="grid grid-cols-12 gap-2 mt-4" onSubmit={handleSubmit(onSubmits)}>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="flavour"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      options={PastryOptions}
                      value={value}
                      placeholder={`${dic.t('Cakery-Flavors') + ' *'}`}
                      styles={colourStyles}
                      onChange={(e) => {
                        onFlavourChange(e);
                        onChange(e);
                        setValue('servings', null);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              {/* <Controller
                name="servings"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={servingOption}
                    value={value}
                    placeholder={`${dic.t('Cakery-Servings') + ' *'}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onServingChange(e);
                      onChange(e);
                    }}
                  />
                )}
              /> */}
              <input
                type="text"
                maxLength={'40'}
                className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder="Add PASTRY Qty"
                // placeholder={dic.t('Cakery-Message To Put On The Cake')}
                // onChange={(e) => {
                //   console.log('sdfsdfds', e);
                //   // setItemPrice(e.target.value);
                //   setPastryQty(e.target.value);
                // }}
                {...register('pastryQty')}
                autoComplete="off"
                // value={pastryQty}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <Controller
                name="supplies"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={SuppliesOptions}
                    value={value}
                    placeholder={`${dic.t('Cakery-addsupplies')}`}
                    styles={colourStyles}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2 col-span-12 sm:col-span-6">
              <input
                type="text"
                className="appearance-none block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Price')}
                {...register('suppliesprice')}
                onChange={handleCheckSuppliesPrice}
                value={suppliesPrice}
                maxLength={5}
                autoComplete="off"
              />
            </div>
            <div className="mb-2 col-span-12">
              <input
                type="text"
                maxLength={'40'}
                className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                placeholder={dic.t('Cakery-Message To Put On The Cake')}
                {...register('message')}
                autoComplete="off"
              />
            </div>
            {/* <div className="mb-2 col-span-12 sm:col-span-6">
            <input
              type="text"
              className="block w-full px-2 py-1 text-sm text-gray-900 tracking-wider bg-transparent rounded-md border border-gray-400 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
              placeholder={dic.t('Cakery-Remarks')}
              {...register('remarks')}
              autoComplete="off"
            />
          </div> */}
            {addonlist && addonlist.length > 0 && (
              <div className="mb-2 col-span-12 sm:col-span-12">
                <div className="flex justify-between mb-2">
                  <h2>{dic.t('Cakery-Addons')}</h2>
                  <strong
                    className="inline-flex items-center hover:cursor-pointer border border-red-500 text-red-500 border-current uppercase p-1.5 rounded-full text-[10px] tracking-widest"
                    onClick={() => {
                      setAddonberryprice(0);
                    }}
                  >
                    {dic.t('Cakery-Remove')}
                  </strong>
                </div>
                <div className="grid grid-cols-1 gap-2">
                  {addonlist.length > 0 &&
                    addonlist.map((item, key) => {
                      let price = item?.fields?.Price?.value;
                      return (
                        <div
                          className="col-span-1"
                          key={key}
                          onClick={() => {
                            setAddonberryprice(price);
                          }}
                        >
                          <label
                            className={`block p-1 text-sm text-center font-medium transition-colors border border-gray-300 rounded-lg shadow-sm cursor-pointer ${
                              addonberryprice === price
                                ? 'border-gray-500 bg-gray-600 text-white'
                                : ''
                            }`}
                          >
                            <span>
                              {dic.t('Cakery-AED')} {price}{' '}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            <div className="mb-2 col-span-12 sm:col-span-12">
              <button
                type="submit"
                className="text-white bg-gray-700 col-span-6 hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
              >
                {dic.t('Cakery-Add')}
              </button>
            </div>
          </form>
        )}
      </div>
      {loading && <DocumentUploadLoader />}
    </Fragment>
  );
};

export default CakeForm;
