import React from 'react';
import leftarrow from '../../assets/icon/left-icon.png';

function LeftArrowNew(item) {
  return (
    <div className="relative">
      <img
        src={leftarrow}
        onClick={item.onClick}
        className="absolute z-10 xssm:top-[10.7rem] customsm:top-[140px] 2xl:top-[182px] cursor-pointer left-[-40px] w-8"
      />
    </div>
  );
}

export default LeftArrowNew;
