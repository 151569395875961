import {
  SET_REGESTER_LOADING,
  REGESTER_USER_SUCCESS,
  REGESTER_USER_FAILURE,
  CLEAR_REGESTER_STATE,
} from '../actions/registration.action';

const initailState = {
  loading: false,
  statuscode: '',
  error: '',
};

export const userRegistrationReducer = (state = initailState, action) => {
  switch (action.type) {
    case REGESTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        statuscode: action.payload,
      };

    case REGESTER_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_REGESTER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case CLEAR_REGESTER_STATE:
      return {
        ...state,
        statuscode: action.payload,
        error: action.payload,
      };

    default:
      return state;
  }
};
