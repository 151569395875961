import axios from 'axios';
import { MicroRoutes } from '../constants';

export const REGESTER_USER_SUCCESS = 'REGESTER_USER_SUCCESS';
export const REGESTER_USER_FAILURE = 'REGESTER_USER_FAILURE';
export const SET_REGESTER_LOADING = 'SET_REGESTER_LOADING';
export const CLEAR_REGESTER_STATE = 'CLEAR_REGESTER_STATE';

export const onUserRegistration = (data) => async (dispatch) => {
  dispatch({ type: SET_REGESTER_LOADING, payload: true });
  await axios
    .post(MicroRoutes.register, data)
    .then((response) => {
      if (response?.data?.errorStatus) {
        dispatch({
          type: REGESTER_USER_FAILURE,
          payload: response.data.message,
        });
      } else {
        dispatch({
          type: REGESTER_USER_SUCCESS,
          payload: response?.data?.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: REGESTER_USER_FAILURE,
        payload: error?.response?.data?.message,
      });
    });
};

export const onClearRegistrationState = () => (dispatch) => {
  dispatch({
    type: CLEAR_REGESTER_STATE,
    payload: '',
  });
};
