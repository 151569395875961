import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';

export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const GET_ORDER_LOADING = 'GET_ORDER_LOADING';
export const GET_ORDER_PAGECOUNT = 'GET_ORDER_PAGECOUNT';
export const TEMP_SAVE_ORDER = 'TEMP_SAVE_ORDER';
export const SAVE_FILTER_DATA = 'SAVE_FILTER_DATA';
export const SELECTED_DELIVERY_TYPE = 'SELECTED_DELIVERY_TYPE';

export const getAllOrders = (data, pagenumber) => async (dispatch) => {
  dispatch({ type: GET_ORDER_LOADING, payload: true });
  let tokenid = localStorage.getItem('tokenId');
  await axios
    .post(`${AdminMicroRoutes.getallorders}?pageNo=${pagenumber}&pageSize=${200}`, data, {
      headers: { token: `${tokenid}` },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: GET_ORDER_SUCCESS,
          payload: response.data.orders,
          status: response.status,
        });
        dispatch({
          type: GET_ORDER_PAGECOUNT,
          payload: response && response.data.pageCount,
          pageno: response && response.data.currentPageNo,
        });
      } else {
        dispatch({
          type: GET_ORDER_FAILURE,
          payload: response.data.status,
          status: response.status,
        });
      }
    })
    .catch((error) => {
      // dispatch({ type: GET_ORDER_LOADING, payload: false });
      console.log('error', error);
    });
};
