import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUserList } from '../../actions/admin/Userlist.action';
import * as helpers from '../../helpers/config';
import CustomerTable from './CustomerTable';

const Main = () => {
  const dispatch = useDispatch();
  const { loading, dashBoardDetails, error } = useSelector((state) => state.dashboard);
  // taking logged user data from storage
  const loggeddetails = helpers.LoggedUserDetails();
  const usertype = loggeddetails?.accessDetails?.role;
  const userEmailId = loggeddetails?.userEmailId;
  const userId = loggeddetails?.userId;
  useEffect(() => {
    const userlistdata = {
      userEmailId: userEmailId,
      userType: 'END_USER',
    };
    dispatch(onUserList(userlistdata));
    // const dashboarddata = {
    //   userEmailId: userEmailId,
    //   userId: userId,
    // };
    // dispatch(getDadshboard(dashboarddata));
  }, []);
  return (
    <Fragment>
      <main className="w-[inherit] mt-12 bg-white">
        <div className="grid px-6 mx-auto">
          <div className="grid grid-cols-3 sm:grid-cols-6 lg:grid-cols-12 mt-4"></div>
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0">
            <div className="disputed-orders mt-4">
              <h1 className="text-md capitalize font-semibold">Customer Records</h1>
              <CustomerTable />
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Main;
