import axios from 'axios';
import { MicroRoutes } from '../../constants';
import * as config from '../../helpers/config';
import { onupdateorder } from '../admin/UpdateOrder.action';
import * as Helpers from '../../helpers/AdminHelpers';
export const PAYMENT_STATUS = 'PAYMENT_STATUS';
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS';
export const INVOICE_CREATE_LOADING = 'INVOICE_CREATE_LOADING';
export const INVOICE_CREATE_FAIL = 'INVOICE_CREATE_FAIL';
export const NIPAYMENT_CREATE_SUCCESS = 'NIPAYMENT_CREATE_SUCCESS';
export const NIPAYMENT_CREATE_FAIL = 'NIPAYMENT_CREATE_FAIL';
export const NIPAYMENT_CREATE_LOADING = 'NIPAYMENT_CREATE_LOADING';
export const NIPAYMENT_STATUS = 'NIPAYMENT_STATUS';

export const onPayment =
  (data, cartproducts, deliverycharge, Discount, draft = false) =>
  async (dispatch) => {
    dispatch({ type: NIPAYMENT_CREATE_LOADING, payload: true });
    const getPaymentObject = {
      data: data,
      cartproducts: cartproducts,
      deliverycharge: deliverycharge,
      Discount: Discount,
    };
    let encryptPayload = config.encryptePayload(getPaymentObject);
    console.log(encryptPayload, getPaymentObject, 'ENCRYPTED PAYLOAD');
    await axios
      .post(MicroRoutes.paymentorder, encryptPayload)
      .then((response) => {
        // dispatch({ type: NIPAYMENT_CREATE_LOADING, payload: false });
        console.log(response, 'RESPONSE');
        if (draft) {
          localStorage.setItem('tempTransactionNo', response.data.reference);
          localStorage.setItem('tempNiUrl', response.data._links.payment.href);
        } else {
          dispatch({ type: NIPAYMENT_CREATE_LOADING, payload: false });
          localStorage.removeItem('tempTransactionNo');
          localStorage.removeItem('tempNiUrl');
          window.location.href = response.data._links.payment.href;
        }
      })
      .catch((error) => {
        dispatch({ type: NIPAYMENT_CREATE_LOADING, payload: false });
        return dispatch({
          type: PAYMENT_STATUS,
          loading: false,
          message: 'Payment gateway server down...!',
        });
      });
  };

export const onInvoicePayment =
  (data, updateOrderId = '') =>
  async (dispatch) => {
    console.log(data, 'DATA');
    dispatch({ type: INVOICE_CREATE_LOADING, payload: true });
    await axios
      .post(MicroRoutes.createInvoiceOrder, data)
      .then((response) => {
        console.log('response', response);
        dispatch({ type: INVOICE_CREATE_LOADING, payload: false });
        if (response.status === 201) {
          dispatch({
            type: INVOICE_CREATE_SUCCESS,
            payload: {
              message: 'Invoice Created',
              orderReference: response?.data?.orderReference,
              outlet: response?.data?.reference,
              // invoiceReferenceNumber: response?.data?.reference,
              status: 'success',
            },
          });
          if (updateOrderId) {
            const payload = {
              orderId: updateOrderId,
              transactionNumber: response?.data?.orderReference,
              outlet: response?.data?.reference,
              // invoiceReferenceNumber: response?.data?.reference,
            };
            dispatch(onupdateorder(payload));
          }
        } else {
          dispatch({
            type: INVOICE_CREATE_FAIL,
            status: 'fail',
            payload: response?.data?.errors[0]?.localizedMessage,
          });
        }
        return response?.data?.orderReference;
      })
      .catch((error) => {
        dispatch({ type: INVOICE_CREATE_LOADING, payload: false });
        dispatch({
          type: INVOICE_CREATE_FAIL,
          payload: error?.[0]?.localizedMessage,
        });
        console.log('errors', error);
      });
  };

export const onResendInvoicePayment =
  (data, item = {}) =>
  async (dispatch) => {
    console.log('onResendInvoicePayment', data);
    dispatch({ type: INVOICE_CREATE_LOADING, payload: true });
    await axios
      .post(MicroRoutes.ResendInvoiceOrder, data)
      .then((response) => {
        console.log('response', response);
        dispatch({ type: INVOICE_CREATE_LOADING, payload: false });
        if (response.status === 200) {
          if (response?.data && response?.data?.reference) {
            dispatch({
              type: INVOICE_CREATE_SUCCESS,
              payload: {
                message: 'Invoice Created',
                orderReference: response?.data?.orderReference,
                outlet: response?.data?.reference,
                // invoiceReferenceNumber: response?.data?.reference,
                status: 'success',
              },
            });
          } else {
            const InvoicePayload = Helpers.getInvoicePayloadForOrders(item);
            dispatch(onInvoicePayment(InvoicePayload));
          }
        } else {
          dispatch({
            type: INVOICE_CREATE_FAIL,
            status: 'fail',
            payload: response?.data?.errors[0]?.localizedMessage,
          });
        }
        return response?.data?.orderReference;
      })
      .catch((error) => {
        dispatch({ type: INVOICE_CREATE_LOADING, payload: false });
        dispatch({
          type: INVOICE_CREATE_FAIL,
          payload: error?.[0]?.localizedMessage,
        });
        console.log('errors', error);
      });
  };

export const onPaymentStatusRevert = (data) => async (dispatch) => {
  dispatch({
    type: PAYMENT_STATUS,
    message: false,
  });
};
