export const ADMIN_ORDER_SUCCESS = 'ADMIN_ORDER_SUCCESS';
export const ADMIN_ORDER_FAIL = 'ADMIN_ORDER_FAIL';
export const LOADING_ADMIN_ORDER_WITHOUTNI = 'LOADING_ADMIN_ORDER_WITHOUTNI';
export const ADMIN_ORDER_DATA_DETAILS = 'ADMIN_ORDER_DATA_DETAILS';
import axios from 'axios';
import { MicroRoutes, OrdersMicroRoute } from '../../constants';
import * as helpers from '../../helpers/config';
import { modifyPayload } from '../../helpers/modifyPayload';
import { onInvoicePayment } from '../payment/payment.action';

export const onAdminOrder =
  (data, InvoicePayload = {}) =>
  async (dispatch) => {
    console.log('Create Order Payload', data);

    data.items.forEach((item) => {
      if (item.itemSuppliesName.length > 0 && item.itemSuppliesPrice.length) {
        item.itemSuppliesNameNew = item.itemSuppliesName[0].join(',');
        item.itemSuppliesPriceNew = item.itemSuppliesPrice[0].join(',');
        item.itemSuppliesName = '';
        item.itemSuppliesPrice = '';
      }
    });
    const getPaymentObject = { data: data, isAdminScreen: true };
    let encryptPayload = helpers.encryptePayload(getPaymentObject);
    dispatch({
      type: LOADING_ADMIN_ORDER_WITHOUTNI,
      payload: true,
    });
    const islogin = localStorage.getItem('islogin');
    let tokenid = '';
    if (islogin === 'true') {
      tokenid = localStorage.getItem('tokenId');
      await axios
        .post(MicroRoutes.ordercreate + '?token=' + tokenid, encryptPayload)
        .then(async (response) => {
          console.log(response, 'ADMIN_ORDER_DATA_DETAILS');
          dispatch({
            type: LOADING_ADMIN_ORDER_WITHOUTNI,
            payload: false,
          });
          if (response && response.data && response.data.orderId) {
            dispatch({
              type: ADMIN_ORDER_SUCCESS,
              payload: 'success',
            });
            if (InvoicePayload) {
              dispatch(onInvoicePayment(InvoicePayload, response.data.orderId));
            }
            dispatch({
              type: ADMIN_ORDER_DATA_DETAILS,
              payload: response?.data,
            });
          } else if (response && response.data && response.data.message) {
            dispatch({
              type: ADMIN_ORDER_FAIL,
              payload: 'fail',
            });
          } else {
            dispatch({
              type: ADMIN_ORDER_FAIL,
              payload: 'fail',
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: LOADING_ADMIN_ORDER_WITHOUTNI,
            payload: false,
          });
          dispatch({
            type: ADMIN_ORDER_SUCCESS,
            payload: 'fail',
          });
          console.log('error while order', error);
        });
    }
  };
