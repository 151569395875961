import React, { Fragment } from 'react';
import LeftMenu from '../LeftMenu';
import Main from './Main';

const DraftOrders = (props) => {
  const leftmenu = props?.fields?.['Left Menus'];
  const regionlist = props?.fields?.Regions;
  return (
    <Fragment>
      <div className="flex w-full bg-[#1d1d1d]">
        <LeftMenu selectOption={2} leftmenu={leftmenu} />
        <Main regionlist={regionlist} />
      </div>
    </Fragment>
  );
};

export default DraftOrders;
