/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import * as helpers from '../../helpers/config';
import ProductService from '../ProductService/index';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsArrowRightCircleFill, BsInfoCircle } from 'react-icons/bs';
import LeftArrow from '../CustomArrow/LeftArrow';
import RightArrow from '../CustomArrow/RightArrow';
import SorryCart from '../SorryCart/index';
import { Link } from 'react-router-dom';
import ViewMore from '../ViewMore';
import { RevertLoginStatus } from '../../actions/login.action';
import Decrement from '../CommonIcon/Decrement';
import Increment from '../CommonIcon/Increment';
import dic from 'i18next';
import CantAddCart from '../CantAddCart';
import RightArrowNew from '../CustomArrow/RightArrowNew';
import LeftArrowNew from '../CustomArrow/LeftArrowNew';

function TopRecommendedCakes(props) {
  var dispatch = useDispatch();
  const { toprecommend, toptrending } = props;
  const [serviceUpdate, setServiceUpdate] = useState(false);
  const [productAddons, setproductAddons] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [getSorryStatus, setSorryModal] = useState(false);
  const [getViewMore, setViewMore] = useState(false);
  const [getDescription, SetDescription] = useState(false);
  const cartstatus = useSelector((state) => state.usercart.user_cart_status);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);

  const sessionproducts = localStorage.getItem('cartproducts');
  const get_value = JSON.parse(sessionproducts);
  var [cartItems, setCartItems] = useState(get_value || []);
  const [CantAddCartStatus, setCantAddCartStatusUpdate] = useState(false);
  var logoutstatus = useSelector((state) => state.login.logoutstatus);
  let isfest =
    cartItems.filter((item) => item?.cartaddedservice[0]?.type === 'FESTIVAL').length > 0
      ? true
      : false;
  let isSpecialCake =
      cartItems.filter((item) => item?.cartaddedservice[0]?.specialcake === true).length > 0
        ? true
        : false;
  const title = props?.toprecommend?.name;
  const modalUpdateStatus = (items, addon) => {
    setServiceUpdate(items);
    setproductAddons(addon);
    closeModal();
  };
  const closeModal = () => {
    setServiceModal(!serviceModal);
  };
  const SorryCartStatusUpdate = () => {
    setSorryModal(!getSorryStatus);
  };
  const ViewMoreUpdate = (description, images, cakename) => {
    const detail = { description: description, images: images, cakename: cakename };
    SetDescription(detail);
    setViewMore(!getViewMore);
  };
  const onCartFunction = (items, serviceid, message, price, serves, addons, action) => {
    if (serviceid === '') {
      return false;
    }
    setServiceModal(!serviceModal);

    var defaultValue = 1;
    // Product id equal checking
    const exist = cartItems.find((currentcart) => currentcart?.ItemDetails?.id === items?.id);
    // if product id match it is with inner service id checking
    if (exist) {
      const exist_service = exist?.cartaddedservice?.filter(
        (itemlist) => itemlist?.id === serviceid
      );
      const balance_service = exist?.cartaddedservice.filter(
        (itemlist) => itemlist?.id !== serviceid
      );
      if (exist_service && exist_service?.length > 0) {
        // Exist service price quantity added here
        const previouscart = cartItems?.filter(
          (currentcart) => currentcart?.ItemDetails?.id !== items?.id
        );
        const itemImage = items?.fields['Cake Images'][0]?.fields?.Image?.value?.src;
        const itemTitle = items?.fields['Cake Name']?.fields?.Title?.value;
        const specialCake = items?.fields['Special Cake']?.value;
        const ItemDetails = {
          id: items?.id,
          image: itemImage,
          title: itemTitle,
        };
        var updateresponse = [
          ...previouscart,
          {
            ItemDetails: ItemDetails,
            cartaddedservice: [
              ...balance_service,
              {
                id: serviceid,
                quantity: { value: exist_service[0]?.quantity?.value + defaultValue },
                message: { value: message },
                price: { value: price },
                serves: { value: serves },
                addon: addons,
                specialcake: specialCake,
                type: 'REGULAR',
              },
            ],
          },
        ];
        setCartItems(updateresponse);
        dispatch(onCartAction(updateresponse));
      } else {
        // Exist service price quantity intially added here
        const previouscart = cartItems?.filter(
          (currentcart) => currentcart?.ItemDetails?.id !== items?.id
        );
        const itemImage = items?.fields['Cake Images'][0]?.fields?.Image?.value?.src;
        const itemTitle = items?.fields['Cake Name']?.fields?.Title?.value;
        const specialCake = items?.fields['Special Cake']?.value;
        const ItemDetails = {
          id: items?.id,
          image: itemImage,
          title: itemTitle,
        };
        var updateresponse = [
          ...previouscart,
          {
            ItemDetails: ItemDetails,
            cartaddedservice: [
              ...exist.cartaddedservice,
              {
                id: serviceid,
                quantity: { value: defaultValue },
                message: { value: message },
                price: { value: price },
                serves: { value: serves },
                addon: addons,
                specialcake: specialCake,
                type: 'REGULAR',
              },
            ],
          },
        ];
        setCartItems(updateresponse);
        dispatch(onCartAction(updateresponse));
      }
    } else {
      const itemImage = items?.fields['Cake Images'][0]?.fields?.Image?.value?.src;
      const itemTitle = items?.fields['Cake Name']?.fields?.Title?.value;
      const specialCake = items?.fields['Special Cake']?.value;
      const ItemDetails = {
        id: items?.id,
        image: itemImage,
        title: itemTitle,
      };
      // intial product add to cart
      var updateresponse = [
        ...cartItems,
        {
          ItemDetails: ItemDetails,
          cartaddedservice: [
            {
              id: serviceid,
              quantity: { value: defaultValue },
              message: { value: message },
              price: { value: price },
              serves: { value: serves },
              addon: addons,
              specialcake: specialCake,
              type: 'REGULAR',
            },
          ],
        },
      ];
      setCartItems(updateresponse);
      dispatch(onCartAction(updateresponse));
    }
  };
  var toprecommendsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var toptrendingsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <LeftArrowNew />,
    nextArrow: <RightArrowNew />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (existcartstatus === true) {
      setCartItems(existcartdata);
    }
  }, [existcartdata]);
  useEffect(() => {
    localStorage.setItem('cartproducts', JSON.stringify(cartItems));
    if (logoutstatus === true) {
      dispatch(RevertLoginStatus());
      localStorage.setItem('cartproducts', JSON.stringify([]));
      setCartItems([]);
    }
  });
  return (
    <div className="bg-black text-white px-10 -z-10 pt-2">
      {/* Top Recommanded Starts Here */}
      <div className="container-fluid px-6 pt-6 md:m-0">
        <h4 className="text-center py-8 mb-5 text-xl md:text-3xl uppercase tracking-wider">
          {dic.t('Cakery-Top Recommended Cakes')}
        </h4>
        <div className="grid grid-cols-1 sm:grid-cols-1">
          <Slider {...toprecommendsettings}>
            {toprecommend !== undefined && toprecommend
              ? toprecommend.map((items, key) => {
                  const exists = cartItems.filter((currentcart) => {
                    return currentcart?.ItemDetails?.id === items.id;
                  });
                  var counts = 0;
                  exists.map((itemcheck) => {
                    itemcheck.cartaddedservice.map((serviceitem) => {
                      return (counts = counts + serviceitem?.quantity?.value);
                    });
                  });
                  const pricelist = items.fields.Services;
                  const findlowestprice = Math.min(
                    ...pricelist.map((item) => item.fields.Price.value)
                  );
                  const description = items?.fields?.Description?.value;
                  const images = items?.fields?.['Cake Images'][0]?.fields?.Image?.value?.src;
                  const allimages = items?.fields?.['Cake Images'];
                  const addons = items?.fields?.Addons;
                  const cakename = items?.fields['Cake Name'].fields?.Title?.value;
                  const specialCake = items?.fields['Special Cake']?.value;
                  return (
                    <div className="flex justify-center md:px-6 px-0" key={key}>
                      <div className="flex justify-center">
                        <div className="max-w-full bg-white relative overflow-hidden rounded-lg shadow-md">
                          <div className="absolute left-0 top-0 h-16 w-16 z-[9]">
                            <div className="absolute transform -rotate-45 bg-gray-500 text-center text-white font-semibold py-1 left-[-35px] top-[33px] w-[170px]">
                              Recommended
                            </div>
                          </div>
                          <img
                            className="w-screen object-cover h-60 hover:scale-125 ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg"
                            src={images}
                            alt="product image"
                          />
                          <div className="px-5 py-4 border-t-2 border-gray-500 relative z-[9] bg-white">
                            <h5 className="sm:text-xl text-lg font-semibold tracking-widest text-gray-900">
                              {cakename}
                            </h5>
                            <div className="py-2">
                              <div className="grid grid-cols-2">
                                <div className="flex relative cursor-pointer justify-start">
                                  <div className="sm:text-xl relative top-[6px] text-lg font-bold text-gray-900">
                                    {helpers.currency} {findlowestprice}
                                    <div className="absolute top-[4px] left-[77px] sm:left-[90px]">
                                      <BsInfoCircle
                                        onClick={() =>
                                          ViewMoreUpdate(description, allimages, cakename)
                                        }
                                        className="text-gray-700  width-[20px] height-[16px]"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex relative	cursor-pointer justify-end">
                                  {exists &&
                                  exists[0] &&
                                  exists[0].cartaddedservice &&
                                  exists[0].cartaddedservice.length > 0 ? (
                                    <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md">
                                      <div
                                        className="flex relative top-1	cursor-pointer"
                                        onClick={SorryCartStatusUpdate}
                                      >
                                        <Decrement />
                                      </div>
                                      <div className="flex text-white px-1 justify-center text-lg">
                                        {counts}
                                      </div>
                                      <div
                                        className="flex relative top-[0.2em] cursor-pointer"
                                        onClick={() => {
                                          isfest
                                            ? setCantAddCartStatusUpdate(true)
                                            : modalUpdateStatus(items, addons);
                                        }}
                                      >
                                        <Increment />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="grid grid-cols-1 justify-items-end h-[36px]">
                                      <Link
                                        onClick={() => {
                                          (isfest || ((cartItems.length > 0) && (isSpecialCake !== specialCake)))
                                            ? setCantAddCartStatusUpdate(true)
                                            : modalUpdateStatus(items, addons);
                                        }}
                                        className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs lg:text-sm px-2 py-2 sm:px-2 lg:px-5  sm:py-2.5 text-center   "
                                      >
                                          {dic.t('Cakery-Select Size')}
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
          </Slider>
        </div>
      </div>
      {/* Top Recommanded Starts Here */}
      {/* Top Trending Starts Here */}
      <div className="container-fluid px-6 pt-14 pb-20 md:m-0">
        <h4 className="text-center py-8 mb-5 text-xl md:text-3xl uppercase tracking-wider">
          {dic.t('Cakery-Top Trending Cakes')}
        </h4>
        <div className="grid grid-cols-1 sm:grid-cols-1">
          <Slider {...toptrendingsettings}>
            {toptrending !== undefined && toptrending
              ? toptrending.map((items, key) => {
                  const exists = cartItems.filter((currentcart) => {
                    return currentcart?.ItemDetails?.id === items.id;
                  });
                  var counts = 0;
                  exists.map((itemcheck) => {
                    itemcheck.cartaddedservice.map((serviceitem) => {
                      return (counts = counts + serviceitem?.quantity?.value);
                    });
                  });
                  const pricelist = items.fields.Services;
                  const findlowestprice = Math.min(
                    ...pricelist.map((item) => item.fields.Price.value)
                  );
                  const description = items?.fields?.Description?.value;
                  const images = items?.fields?.['Cake Images'][0]?.fields?.Image?.value?.src;
                  const allimages = items?.fields?.['Cake Images'];
                  const addons = items?.fields?.Addons;
                  const cakename = items?.fields['Cake Name'].fields?.Title?.value;
                  const specialCake = items?.fields['Special Cake']?.value;

                  return (
                    <div className="flex justify-center md:px-6 px-0" key={key}>
                      <div className="flex justify-center">
                        <div className="max-w-full bg-white relative overflow-hidden rounded-lg shadow-md">
                          <img
                            className="w-screen object-cover h-60 hover:scale-125 ease-in duration-500  xl:h-64 2xl:h-72 rounded-t-lg"
                            src={images}
                            alt="product image"
                          />
                          <div className="px-5 py-4 border-t-2 border-gray-500 relative z-[9] bg-white	">
                            <h5 className="text-md sm:text-xl font-semibold tracking-widest text-gray-900">
                              {cakename}
                            </h5>
                            <div className="py-2 ">
                              <div className="grid grid-cols-2">
                                <div className="flex relative cursor-pointer justify-start">
                                  <div className="sm:text-xl relative top-[6px] text-lg font-bold text-gray-900">
                                    {helpers.currency} {findlowestprice}
                                    <div className="absolute top-[4px] left-[77px] sm:left-[90px]">
                                      <BsInfoCircle
                                        onClick={() =>
                                          ViewMoreUpdate(description, allimages, cakename)
                                        }
                                        className="text-gray-700  width-[20px] height-[16px]"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex relative	cursor-pointer justify-end">
                                  {exists &&
                                  exists[0] &&
                                  exists[0].cartaddedservice &&
                                  exists[0].cartaddedservice.length > 0 ? (
                                    <div className="grid grid-cols-3 addtocartbox bg-gray-700 px-1 py-1 rounded-md">
                                      <div
                                        className="flex relative top-1	cursor-pointer"
                                        onClick={SorryCartStatusUpdate}
                                      >
                                        <Decrement />
                                      </div>
                                      <div className="flex text-white px-1 justify-center text-lg">
                                        {counts}
                                      </div>
                                      <div
                                        className="flex relative top-[0.2em] cursor-pointer"
                                        onClick={() => {
                                          isfest
                                            ? setCantAddCartStatusUpdate(true)
                                            : modalUpdateStatus(items, addons);
                                        }}
                                      >
                                        <Increment />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="grid grid-cols-1 justify-items-end h-[36px]">
                                      <Link
                                        onClick={() => {
                                          (isfest || ((cartItems.length > 0) && (isSpecialCake !== specialCake)))
                                            ? setCantAddCartStatusUpdate(true)
                                            : modalUpdateStatus(items, addons);
                                        }}
                                        className="text-white font-normal hover:bg-gray-700 bg-gray-600 uppercase rounded-lg text-xs lg:text-sm px-2 py-2 sm:px-5  sm:py-2.5 text-center   "
                                      >
                                          {dic.t('Cakery-Select Size')}
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ''}
          </Slider>
        </div>
      </div>
      {/* Top Trending Ends Here */}
      {serviceModal === true && (
        <>
          <ProductService
            modalStatus={serviceModal}
            modalUpdateStatus={modalUpdateStatus}
            productAddons={productAddons}
            servicelist={serviceUpdate}
            onCartFunction={onCartFunction}
            closeModal={closeModal}
          />
        </>
      )}
      {getSorryStatus === true && (
        <SorryCart SorryCartStatusUpdate={SorryCartStatusUpdate} checkModal={getSorryStatus} />
      )}
      {getViewMore === true && (
        <ViewMore
          getDescription={getDescription}
          ViewMoreUpdate={ViewMoreUpdate}
          getViewMore={getViewMore}
        />
      )}
      {CantAddCartStatus && (
        <CantAddCart
          checkModal={CantAddCartStatus}
          CantAddCartStatusUpdate={setCantAddCartStatusUpdate}
        />
      )}
    </div>
  );
}

export default TopRecommendedCakes;
