import {
  DASHBOARD_LOADING,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
} from '../../actions/admin/GetDashboard.action';

const initailState = {
  loading: false,
  dashBoardDetails: [],
  error: '',
};

export const getDashboardReducer = (state = initailState, action) => {
  switch (action.type) {
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashBoardDetails: action.payload,
      };

    case DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DASHBOARD_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
