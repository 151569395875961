import axios from 'axios';
import { AdminMicroRoutes } from '../../constants';

export const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';
export const ORDER_HISTORY_FAILURE = 'ORDER_HISTORY_FAILURE';
export const ORDER_HISTORY_LOADING = 'ORDER_HISTORY_LOADING';

export const onuserhistory = (data) => async (dispatch) => {
  dispatch({ type: ORDER_HISTORY_LOADING, payload: true });
  let tokenid = localStorage.getItem('tokenId');
  await axios
    .post(AdminMicroRoutes.getorderhistory, data, { headers: { token: `${tokenid}` } })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: ORDER_HISTORY_SUCCESS,
          payload: response.data.order,
        });
      } else {
        dispatch({
          type: ORDER_HISTORY_FAILURE,
          payload: response.data.status,
        });
      }
    });
};
