import moment from 'moment-timezone';

export const SetAdminCartData = (data) => {
  sessionStorage.setItem('admincakecartdata', JSON.stringify(data));
};

export const GetAdminCartData = (data) => {
  const getdata = sessionStorage.getItem('admincakecartdata', JSON.parse(data));
  return getdata;
};

export const isAuthorize = () => {
  const loggedin = sessionStorage.getItem('admincakecartdata', JSON.parse(data));
};

export const getNextday = () => {
  let tomarrow = new Date();
  tomarrow = new Date(tomarrow.setDate(tomarrow.getDate() + 1));
  return tomarrow;
};

export const getNexttoNextday = () => {
  let tomarrow = new Date();
  tomarrow = new Date(tomarrow.setDate(tomarrow.getDate() + 2));
  return tomarrow;
};

export const is7pmcross = () => {
  let today = new Date();
  let is7pm = today.getHours() >= 19;
  return is7pm;
};
export const getOrderPayload = (
  customerDeliveryInfo,
  cartItems,
  customerInfo,
  subTotalPrice,
  loggeddetails,
  TotalPayment
) => {
  let orderPayload = {};
  let items = [];
  let MyDateString = '';
  if (
    customerDeliveryInfo &&
    Object.keys(customerDeliveryInfo).length > 0 &&
    customerDeliveryInfo.dpdate
  ) {
    MyDateString =
      customerDeliveryInfo.dpdate.getFullYear() +
      '-' +
      ('0' + (customerDeliveryInfo.dpdate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + customerDeliveryInfo.dpdate.getDate()).slice(-2);
  } else {
    MyDateString = 'Date information not available';
  }
  let usertype = loggeddetails?.accessDetails?.role;
  let userEmailId = loggeddetails?.userEmailId;
  let userId = loggeddetails?.userId;
  cartItems.length > 0 &&
    cartItems.map((item) => {
      let festiveAddons = [];
      item.cakeFestiveAddons &&
        item.cakeFestiveAddons.length > 0 &&
        item.cakeFestiveAddons.map((val, i) => {
          festiveAddons.push({
            addonName: val.label,
            Price: Number(val.value),
            Currency: 'AED',
            Qty: val.Qty,
          });
        });
      items.push({
        cakeType: item.cakeType === 'CUSTOMIZE' ? 'CUSTOMIZED' : item.cakeType,
        addOns: festiveAddons,
        itemName: item.cakeFlavour,
        itemDescription: item.cakeServings,
        itemMessage: item.cakeMessage,
        itemImageUrl: item.cakeImage,
        perItemPrice: item.cakePerItemPrice,
        quantity: item.cakeQuantity,
        itemSuppliesName: [item.cakeSuppliesName],
        itemSuppliesPrice: [item.cakeSuppliesPrice],
        itemSuppliesNameNew: [item.cakeSuppliesName],
        itemSuppliesPriceNew: [item.cakeSuppliesPrice],
        totalPrice: item.totalPrice,
      });
    });
  let isTurkeyAvailabel = cartItems.filter((Data) => Data.cakeFlavour.includes('Turkey'));
  orderPayload = {
    cheque: 0,
    couponCode: '',
    currency: 'AED',
    cutlery: isTurkeyAvailabel.length > 0 ? 'true' : '',
    deliveryAddress: {
      region: customerDeliveryInfo.deliveryAddress.region,
      makani: customerDeliveryInfo.deliveryAddress.makani,
      address: customerDeliveryInfo.deliveryAddress.address,
    },
    deliveryCharge:
      customerDeliveryInfo.deliveryAddress.deliveryPrice !== undefined
        ? customerDeliveryInfo.deliveryAddress.deliveryPrice
        : '',
    deliveryType: customerDeliveryInfo.deliverytype,
    discount: '',
    dpDate: MyDateString,
    dpTime: customerDeliveryInfo.dptime,
    customerName:
      customerInfo.CustomerFirstName !== undefined ? customerInfo.CustomerFirstName : '',
    customerEmail: customerInfo.CustomerEmail,
    history: customerInfo.StatusOfGuest,
    region: '',
    items: items,
    outlet: '',
    paymentMode: 'OFFLINE',
    paymentStatus: 'UNPAID',
    phoneNumber: customerInfo.CustomerPhoneNumber,
    reasonForCancellation: '',
    remarks: customerInfo.Remarks,
    subTotal: subTotalPrice,
    totalPrice: TotalPayment,
    transactionNumber: '',
    // invoiceReferenceNumber: '',
    userDTO: {
      userEmail: userEmailId,
      savedAddress: [],
      userId: userId,
      userType: usertype,
    },
    vat: '',
  };
  return orderPayload;
};

export const getPaymentPayload = (
  TotalPayment,
  customerInfo,
  customerDeliveryInfo,
  redirectUrl
) => {
  const PaymentPayload = {
    action: 'SALE',
    amount: {
      currencyCode: 'AED',
      value: TotalPayment * 100,
    },
    emailAddress: customerInfo.CustomerEmail,
    language: 'en',
    billingAddress: {
      firstName: customerInfo.CustomerName,
      address1: customerDeliveryInfo.deliveryAddress.address,
    },
    merchantAttributes: {
      redirectUrl: redirectUrl,
      skipConfirmationPage: true,
    },
    merchantDefinedData: {
      deliveryCharge: customerDeliveryInfo.deliveryAddress.deliveryPrice,
    },
  };
  return PaymentPayload;
};

export const getInvoiceOrderPayload = (
  customerDeliveryInfo,
  cartItems,
  customerInfo,
  subTotalPrice,
  loggeddetails,
  TotalPayment,
  invoice_order_ref,
  invoice_ref
) => {
  let orderPayload = {};
  let items = [];
  var MyDateString;
  MyDateString =
    customerDeliveryInfo?.dpdate?.getFullYear() +
    '-' +
    ('0' + (customerDeliveryInfo?.dpdate?.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + customerDeliveryInfo?.dpdate?.getDate()).slice(-2);
  let usertype = loggeddetails?.accessDetails?.role;
  let userEmailId = loggeddetails?.userEmailId;
  let userId = loggeddetails?.userId;
  cartItems.length > 0 &&
    cartItems.map((item) => {
      let festiveAddons = [];
      item.cakeFestiveAddons &&
        item.cakeFestiveAddons.length > 0 &&
        item.cakeFestiveAddons.map((val, i) => {
          festiveAddons.push({
            addonName: val.label,
            Price: Number(val.value),
            Currency: 'AED',
            Qty: val.Qty,
          });
        });
      items.push({
        cakeType: item.cakeType === 'CUSTOMIZE' ? 'CUSTOMIZED' : item.cakeType,
        addOns: festiveAddons,
        itemName: item.cakeFlavour,
        itemDescription: item.cakeServings,
        itemMessage: item.cakeMessage,
        itemImageUrl: item.cakeImage,
        perItemPrice: item.cakePerItemPrice,
        itemSuppliesName: [item.cakeSuppliesName],
        itemSuppliesPrice: [item.cakeSuppliesPrice],
        quantity: item.cakeQuantity,
        totalPrice: item.totalPrice,
      });
    });
  let isTurkeyAvailabel = cartItems.filter((Data) => Data.cakeFlavour.includes('Turkey'));
  orderPayload = {
    cheque: 0,
    couponCode: '',
    currency: 'AED',
    cutlery: isTurkeyAvailabel.length > 0 ? 'true' : '',
    deliveryAddress: {
      region: customerDeliveryInfo.deliveryAddress.region,
      makani: customerDeliveryInfo.deliveryAddress.makani,
      address: customerDeliveryInfo.deliveryAddress.address,
    },
    deliveryCharge:
      customerDeliveryInfo.deliveryAddress.deliveryPrice !== undefined
        ? customerDeliveryInfo.deliveryAddress.deliveryPrice
        : '',
    deliveryType: customerDeliveryInfo.deliverytype,
    discount: '',
    dpDate: MyDateString,
    dpTime: customerDeliveryInfo.dptime,
    customerName:
      customerInfo.CustomerFirstName !== undefined ? customerInfo.CustomerFirstName : '',
    customerEmail: customerInfo.CustomerEmail,
    history: '',
    region: '',
    items: items,
    paymentMode: 'ONLINE',
    paymentStatus: 'UNPAID',
    phoneNumber: customerInfo.CustomerPhoneNumber,
    reasonForCancellation: '',
    remarks: customerInfo.Remarks,
    subTotal: subTotalPrice,
    totalPrice: TotalPayment,
    transactionNumber: invoice_order_ref !== undefined ? invoice_order_ref : '',
    // outlet: '',
    // invoiceReferenceNumber: invoice_ref !== undefined ? invoice_ref : '',
    outlet: invoice_ref !== undefined ? invoice_ref : '',
    userDTO: {
      userEmail: userEmailId,
      savedAddress: [],
      userId: userId,
      userType: usertype,
    },
    vat: '',
  };
  return orderPayload;
};
export const getInvoicePayloadForOrders = (item) => {
  let invoicePayload = {};
  let invoiceitems = [];
  let userFirstName = '';
  let userLastName = '';
  if (item.customerName) {
    let strArr = item.customerName
      .trim()
      .split(' ')
      .filter((val) => val !== '');
    if (strArr.length > 2) {
      userLastName = strArr.pop();
      userFirstName = strArr.join(' ');
    } else if (strArr.length === 2) {
      userLastName = strArr.pop();
      userFirstName = strArr.join(' ');
    } else {
      userFirstName = strArr[0];
      userLastName = '-';
    }
  }
  item &&
    item.items &&
    item.items.length > 0 &&
    item.items.map((item) => {
      invoiceitems.push({
        description: item.itemName,
        totalPrice: {
          currencyCode: 'AED',
          value: Number(item.totalPrice * 100),
        },
        quantity: Number(item.quantity),
      });
    });
  if (item.deliveryType === 'DELIVERY') {
    invoiceitems.push({
      description: 'Delivery Charge',
      totalPrice: {
        currencyCode: 'AED',
        value: Number(item.deliveryCharge * 100),
      },
      quantity: '1',
    });
  }
  let tomarrow = new Date();
  tomarrow = new Date(tomarrow.setDate(tomarrow.getDate() + 1));
  let TomarrowString =
    tomarrow.getFullYear() +
    '-' +
    ('0' + (tomarrow.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + tomarrow.getDate()).slice(-2);
  invoicePayload = {
    firstName: userFirstName,
    lastName: userLastName,
    email: item.customerEmail && item.customerEmail.trim(),
    bccEmail: window.location.href.includes('uat')
      ? [
          'lakshya.choudhary-c@jumeirah.com',
          'prince.patel-c@jumeirah.com',
          'anish.devaiah-v@jumeirah.com',
        ]
      : ['creationscakery@jumeirah.com'],
    transactionType: 'SALE',
    emailSubject: 'Invoice From Creations Cakery',
    invoiceExpiryDate: TomarrowString,
    items: invoiceitems,
    total: {
      currencyCode: 'AED',
      value: Number(Number(item.totalPrice) * 100),
    },
    message:
      'Thank you for shopping with Creations Cakery. Please visit the link provided below to pay your bill.',
  };
  return invoicePayload;
};
export const getInvoicePayload = (
  customerDeliveryInfo,
  cartItems,
  customerInfo,
  subTotalPrice,
  loggeddetails,
  TotalPayment
) => {
  let invoicePayload = {};
  let usertype = loggeddetails?.accessDetails?.role;
  let userEmailId = loggeddetails?.userEmailId;
  let userFirstName = '';
  let userLastName = '';
  if (customerInfo.CustomerFirstName) {
    let strArr = customerInfo.CustomerFirstName.trim()
      .split(' ')
      .filter((val) => val !== '');
    if (strArr.length > 2) {
      userLastName = strArr.pop();
      userFirstName = strArr.join(' ');
    } else if (strArr.length === 2) {
      userLastName = strArr.pop();
      userFirstName = strArr.join(' ');
    } else {
      userFirstName = strArr[0];
      userLastName = '-';
    }
  }
  let tomarrow = new Date();
  tomarrow = new Date(tomarrow.setDate(tomarrow.getDate() + 1));
  let TomarrowString =
    tomarrow.getFullYear() +
    '-' +
    ('0' + (tomarrow.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + tomarrow.getDate()).slice(-2);
  let userId = loggeddetails?.userId;
  let items = [];
  cartItems.length > 0 &&
    cartItems.map((item) => {
      items.push({
        description: item.cakeFlavour,
        totalPrice: {
          currencyCode: 'AED',
          value: Number(item.totalPrice * 100),
        },
        quantity: Number(item.cakeQuantity),
      });
    });
  invoicePayload = {
    firstName: userFirstName,
    lastName: userLastName,
    email: customerInfo.CustomerEmail && customerInfo.CustomerEmail.trim(),
    bccEmail: window.location.href.includes('uat')
      ? [
          'lakshya.choudhary-c@jumeirah.com',
          'prince.patel-c@jumeirah.com',
          'anish.devaiah-v@jumeirah.com',
        ]
      : ['creationscakery@jumeirah.com'],
    transactionType: 'SALE',
    emailSubject: 'Invoice From Creations Cakery',
    invoiceExpiryDate: TomarrowString,
    items: items,
    total: {
      currencyCode: 'AED',
      value: Number(TotalPayment * 100),
    },
    message:
      'Thank you for shopping with Creations Cakery. Please visit the link provided below to pay your bill.',
  };
  return invoicePayload;
};

export const getResentInvoicePayload = (item) => {
  let tomarrow = new Date();
  tomarrow = new Date(tomarrow.setDate(tomarrow.getDate() + 1));
  let TomarrowString =
    tomarrow.getFullYear() +
    '-' +
    ('0' + (tomarrow.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + tomarrow.getDate()).slice(-2);
  let resentInvoicePayload = {
    data: {
      email: item.customerEmail && item.customerEmail.trim(),
      // bccEmail: window.location.href.includes('uat')
      //   ? [
      //       'lakshya.choudhary-c@jumeirah.com',
      //       'prince.patel-c@jumeirah.com',
      //       'anish.devaiah-v@jumeirah.com',
      //     ]
      //   : ['creationscakery@jumeirah.com'],
      invoiceExpiryDate: TomarrowString,
    },
    invoiceRef: item.outlet,
  };
  return resentInvoicePayload;
};

export const getCurrentUAETime = () => {
  return moment().tz('Asia/Dubai');
};

export const isNightTimeUAE = () => {
  const currentDubaiTime = getCurrentUAETime();
  const currentHour = currentDubaiTime.hour();
  console.log('currentDubaiTime:', currentDubaiTime, 'currentDubaiHour:', currentHour);
  return currentHour >= 19 || currentHour < 10;
};

export const isNightOrMorningTimeUAE = () => {
  const currentHour = new Date(getCurrentUAETime()).getHours();
  if (currentHour >= 19 && currentHour <= 24) {
    return true;
  } else {
    return false;
  }
};
