import React, { Fragment } from 'react';
import loadingimage from '../../assets/Cakery-Loading.gif';

const PageLoading = () => {
  return (
    <Fragment>
      <div className="text-center m-auto">
        <img src={loadingimage} alt="cakery loading" className="m-auto" />
      </div>
    </Fragment>
  );
};

export default PageLoading;
