import React, { useEffect, useState } from 'react';
import * as helpers from '../../helpers/config';
import * as order from '../../helpers/order';
import * as session from '../../helpers/sessionHandler';
import { useSelector, useDispatch } from 'react-redux';
import { onOrder } from '../../actions/order/order.action';
import {
  NIPAYMENT_CREATE_LOADING,
  onPayment,
  onPaymentStatusRevert,
} from '../../actions/payment/payment.action';
import { BsShieldCheck, BsCheck2Circle, BsCheck2All } from 'react-icons/bs';
import { useToasts } from 'react-toast-notifications';
import { EndUserRoutes } from '../../constants';
import dic from 'i18next';
import CODModel from './CODModel';
import CODConfirmOrderModel from './CODConfirmOrderModel';
import NIPaymentModel from './NIPaymentModel';
import NiPaymentFailModel from './NIPaymentFailModel';
import CheckoutNoteModal from './CheckoutNoteModal';
import { onSentEmailTurkey } from '../../actions/email/email.action';
import {
  getCurrentUAETime,
  isNightOrMorningTimeUAE,
  isNightTimeUAE,
} from '../../helpers/AdminHelpers';
import moment from 'moment-timezone';

export default function Payment({ CouponCode, Discount }) {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const order_loading = useSelector((state) => state.userorder.order_loading);
  const [Login, SetLogin] = useState(false);
  const [sessionRefreshCount, setsessionRefreshCount] = useState(0);
  const [paynowstate, setpaynowstate] = useState(false);
  const toggleModal = () => {
    document.getElementById('modal').classList.toggle('hidden');
  };
  const login_status = helpers.CurrentLoginStatus();
  const islogin = localStorage.getItem('islogin');
  const cart = localStorage.getItem('cartproducts');
  const jsoncart = JSON.parse(cart);
  const GuestDetail = helpers.GuestDetail();
  // const addressresult = helpers.OrderAddress();
  const Loggeduser = helpers.LoggedUserDetails();
  const orderProductDetail = helpers.orderProductDetail();
  // const getSelectedAddress = helpers.getSelectedAddress();
  const [cartItems, setCartItems] = useState(jsoncart || []);
  const [cashOnDelivery, setCashOnDelivery] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);

  const gueststatus_redux = useSelector((state) => state.guest.status);
  const guestdetails_redux = useSelector((state) => state.guest.details);
  const GuestDetails = session.GetGuestDetails();
  const GuestStatus = localStorage.getItem('guestLoggedIn');
  const orderdetail = useSelector((state) => state.userorder.order_detail);
  const [mailDetails, setMailDetails] = useState({});
  const addressresult = helpers.OrderAddress();
  const deliverycharge = useSelector((state) => state.deliverycharge.ordercharge);
  const { payment_loading, payment_message, payment_error, nipayment_loading, nipayment_fail } =
    useSelector((state) => state.payment);
  const orderloading = useSelector((state) => state.userorder.current_order_loading);
  const showorderloading = useSelector((state) => state.userorder.order_loading);
  const orderAction = () => {
    addToast('Order has been Intialized', { appearance: 'info' });
    dispatch(onOrder());
    SetOrders(false);
  };
  const paymentAction = () => {
    if (cartItems?.length === 0) {
      addToast('Your cart is empty', { appearance: 'info' });
      return false;
    }
    const response = helpers.paymentBeforeValidate(deliverycharge, Discount);
    console.log(response, 'Response');
    let isTurkeyAvailabel = cartItems.filter((Data) => Data.ItemDetails.title.includes('Turkey'));
    if (isTurkeyAvailabel.length > 0) {
      dispatch(
        onSentEmailTurkey({
          EmailId: response?.details?.Loggeduser?.userEmailId,
          Phone: response?.details?.Loggeduser?.phone || '',
          Name: response?.details?.Loggeduser?.firstName,
          CartProducts: cartItems,
        })
      );
    }

    if (response.status === 'success') {
      const cartproducts = localStorage && localStorage.getItem('cartproducts');
      const getValidCartList = JSON.parse(cartproducts);

      var OrderData = {
        delivery: {
          address:
            response?.details?.deliveryOptions?.deliverytype === 'delivery'
              ? addressresult.deliveryaddress
              : {},
          deliverytype:
            response?.details?.deliveryOptions?.deliverytype === 'delivery' ? 'DELIVERY' : 'PICKUP',
          date: response?.details?.deliveryOptions?.deliverydate,
          time: response?.details?.deliveryOptions?.deliverytime,
        },
        discount: Discount,
        couponCode: CouponCode,
        remarks:
          response?.details?.deliveryOptions?.deliverytype === 'pickup'
            ? response?.details?.deliveryOptions?.remarks
            : '',
        region:
          response?.details?.deliveryOptions?.region !== undefined
            ? response?.details?.deliveryOptions?.region
            : '',
        charge: deliverycharge,
        subtotal: response?.details?.subtotal,
        transaction_number: 'DRAFT',
        orderStatus: 'DRAFT',
        deliveryOptions: response?.details?.deliveryOptions?.deliverytype,
        savedAddress: addressresult.savedAddress,
        orderProductDetail: orderProductDetail.order_product,
        total: response?.details?.total,
        Email: islogin === 'true' ? Loggeduser?.userEmailId : GuestDetail?.email,
        Phone: islogin === 'true' ? Loggeduser?.phoneNumber : GuestDetail?.phone,
        Name:
          islogin === 'true'
            ? Loggeduser?.firstName + ' ' + Loggeduser?.lastName
            : GuestDetail?.name,
        userId: islogin === 'true' ? Loggeduser?.userId : '',
      };
      const OrderObj =
        islogin === 'true'
          ? order.LoggedOrderDelivery(OrderData, true)
          : order.GuestOrderDelivery(OrderData, true);
      setMailDetails({
        EmailId: OrderData.Email,
        Phone: OrderData.Phone,
        subtotal: OrderData.subtotal,
        total: OrderData.total,
        Name: OrderData.Name,
      });
      if (orderdetail === false) {
        console.log(OrderObj, 'NEW ORDER PAYLOAD');
        console.log('Reach to on order', orderdetail?.OrderId);
        const createPaymentObject = {
          action: 'SALE',
          amount: {
            currencyCode: 'AED',
            value: response?.details?.total,
          },
          emailAddress: response?.details?.Loggeduser?.userEmailId,
          language: 'en',
          billingAddress: {
            firstName: response?.details?.Loggeduser?.firstName,
            lastName: response?.details?.Loggeduser?.lastName,
            address1:
              response?.details?.deliveryOptions?.deliverytype === 'delivery'
                ? response?.details?.address
                : '',
          },
          merchantAttributes: {
            redirectUrl: EndUserRoutes.redirectUrl,
            skipConfirmationPage: true,
          },
          merchantDefinedData: {
            deliveryCharge: deliverycharge,
            discount: Discount,
            couponCode: CouponCode,
          },
        };
        console.log(createPaymentObject, 'PAYMENT OBJECT');
        if (OrderObj && OrderObj.totalPrice && OrderObj.totalPrice > 0) {
          addToast('Order has been Initiated', { appearance: 'info' });
          dispatch(
            onPayment(createPaymentObject, getValidCartList, deliverycharge, Discount, true)
          ).then(() => {
            OrderObj.transactionNumber = localStorage.getItem('tempTransactionNo');
            dispatch(onOrder(OrderObj, deliverycharge, Discount, mailDetails))
              .then((resp) => {
                console.log('resp', resp);
                if (resp) {
                  dispatch({ type: NIPAYMENT_CREATE_LOADING, payload: false });
                  window.location.href = localStorage.getItem('tempNiUrl');
                } else {
                  addToast('Order is not created, Please try again', { appearance: 'error' });
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
              })
              .catch((error) => {
                // Handle any errors here
                addToast('Order is not created, Please try again', { appearance: 'error' });
                console.error('Order failed', error);
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              });
          });
        } else {
          addToast('Order can not be Initiated', { appearance: 'warning' });
          setShowCheckoutModal(false);
        }
      } else {
        addToast('Order can not be Initiated', { appearance: 'warning' });
        setShowCheckoutModal(false);
      }
    } else {
      addToast(<p className="py-0.5">{response?.description}</p>, {
        appearance: 'error',
      });
    }
  };
  const CashOnDelivery = () => {
    if (cartItems?.length === 0) {
      addToast('Your cart is empty', { appearance: 'info' });
      return false;
    }
    var response = helpers.paymentBeforeValidate(deliverycharge, Discount);
    if (response.status === 'success') {
      var OrderData = {
        delivery: {
          address:
            response?.details?.deliveryOptions?.deliverytype === 'delivery'
              ? addressresult.deliveryaddress
              : {},
          deliverytype:
            response?.details?.deliveryOptions?.deliverytype === 'delivery' ? 'DELIVERY' : 'PICKUP',
          date: response?.details?.deliveryOptions?.deliverydate,
          time: response?.details?.deliveryOptions?.deliverytime,
        },
        discount: Discount,
        couponCode: CouponCode,
        remarks:
          response?.details?.deliveryOptions?.deliverytype === 'pickup'
            ? response?.details?.deliveryOptions?.remarks
            : '',
        region:
          response?.details?.deliveryOptions?.region !== undefined
            ? response?.details?.deliveryOptions?.region
            : '',
        charge: deliverycharge,
        subtotal: response?.details?.subtotal,
        transaction_number: '',
        deliveryOptions: response?.details?.deliveryOptions?.deliverytype,
        savedAddress: addressresult.savedAddress,
        orderProductDetail: orderProductDetail.order_product,
        total: response?.details?.total,
        Email: islogin === 'true' ? Loggeduser?.userEmailId : GuestDetail?.email,
        Phone: islogin === 'true' ? Loggeduser?.phoneNumber : GuestDetail?.phone,
        Name:
          islogin === 'true'
            ? Loggeduser?.firstName + ' ' + Loggeduser?.lastName
            : GuestDetail?.name,
        userId: islogin === 'true' ? Loggeduser?.userId : '',
      };
      const OrderObj =
        islogin === 'true'
          ? order.LoggedOrderDelivery(OrderData, true)
          : order.GuestOrderDelivery(OrderData, true);
      if (localStorage.getItem('order_Id') === null) {
        dispatch(onOrder(OrderObj, deliverycharge, Discount)).then(() => {
          // console.log('OrderObj', OrderObj);
          // window.history(`${EndUserRoutes.redirectUrl}`);
        });
      }
    } else {
      addToast(<p className="py-0.5">{dic.t('Cakery-Order Failed')}...!</p>, {
        appearance: 'error',
      });
    }
  };
  const orderGuestAction = () => {
    addToast(<p className="py-0.5">{dic.t('Cakery-Guest account order disabled')}</p>, {
      appearance: 'error',
    });
  };
  useEffect(() => {
    if (payment_message !== false) {
      addToast(<p className="py-0.5">{payment_message}</p>, {
        appearance: 'error',
      });
      dispatch(onPaymentStatusRevert());
    }
  }, [payment_message]);
  useEffect(() => {
    if (sessionRefreshCount === 0) {
      // localStorage.removeItem('NewDeliveryAddress');
      // localStorage.removeItem('NewDeliveryAddressExtra');
      // localStorage.removeItem('selectAddress');
      setsessionRefreshCount(1);
    }
  }, [sessionRefreshCount]);
  useEffect(() => {
    if (login_status === 200) {
      SetLogin(!Login);
    } else {
      if (islogin === 'true') {
        SetLogin(!Login);
      }
    }
  }, [login_status, islogin]);

  const OnAcceptClick = (e) => {
    setpaynowstate(e.target.checked);
  };

  // const handlePayNow = () => {
  //   let currentDate = new Date();
  //   let currentUTCTime = currentDate.toISOString();
  //   let currentUAETime = new Date(currentUTCTime);
  //   currentUAETime.setHours(currentUAETime.getHours() + 4);
  //   let currentHour = currentUAETime.getHours();
  //   if (currentHour < 19) {
  //     paymentAction();
  //     setShowCheckoutModal(true);
  //   } else {
  //     const response = helpers.paymentBeforeValidate(deliverycharge, Discount);

  //     let d = response?.details?.deliveryOptions?.deliverydate;
  //     // let t = response?.details?.deliveryOptions?.deliverytime;

  //     if (
  //       new Date(d).getDate() === currentUAETime.getDate() + 1 ||
  //       new Date(d).getDate() === currentUAETime.getDate()
  //     ) {
  //       addToast(
  //         'Orders placed after 7:00 PM cannot be delivered tomorrow. Please order earlier to ensure timely delivery.',
  //         { appearance: 'error' }
  //       );
  //     } else {
  //       paymentAction();
  //       setShowCheckoutModal(true);
  //     }
  //   }
  // };

  // const today =new Date(getCurrentUAETime());
  // const inputDateObj = new Date('2024-04-09');
  // console.log(new Date(today.getTime()));
  // // Check if the input date is the current date or a day after today
  // if (inputDateObj >= today && inputDateObj <= new Date(today.getTime() + 86400000)) {
  //   console.log('Invalid date. Please enter a date in the future.');
  // }

  const handlePayNow = () => {
    const currentDateTime = getCurrentUAETime();
    const currentHour = currentDateTime.hour();
    console.log('Current Date/Time:', currentDateTime);
    console.log('Current Hour:', currentHour);
    const response = helpers.paymentBeforeValidate(deliverycharge, Discount);
    let deliveryDate = moment(response?.details?.deliveryOptions?.deliverydate).tz('Asia/Dubai');
    const nextDayCutoff = moment(currentDateTime).startOf('day').add(19, 'hours');
    const isNightTime = isNightTimeUAE();
    console.log('Delivery Date:', deliveryDate, nextDayCutoff, 'nextDayCutoff');
    console.log(
      'currentDateTime:',
      deliveryDate,
      nextDayCutoff,
      currentDateTime,
      deliveryDate >= currentDateTime
    );
    console.log(showCheckoutModal, 'showCheckoutModal');
    if (
      deliveryDate.isAfter(nextDayCutoff) ||
      (deliveryDate.isSame(nextDayCutoff, 'day') && currentHour >= 7)
    ) {
      // Allow ordering for future dates or tomorrow after 7:00 PM
      paymentAction();
      setShowCheckoutModal(true);
    } else {
      setShowCheckoutModal(false);
      addToast(
        'Orders can only be placed for future dates or tomorrow after 7:00 PM. Please select a valid delivery date.',
        { appearance: 'error' }
      );
    }
  };

  return (
    <>
      <div className="sub-accordition my-3">
        <div className="accord-title tracking-wider text-black font-bold p-4 bg-white">
          <span className="bg-black text-white text-xs font-semibold mr-2 px-2.5 py-1 rounded">
            4
          </span>
          PAYMENT{' '}
          {login_status === 200 ||
            islogin === 'true' ||
            (GuestStatus === 'true' && (
              <>
                <BsCheck2All className="inline text-xl font-bold" />
              </>
            ))}
        </div>
        {login_status === 200 || islogin === 'true' || GuestStatus === 'true' ? (
          <div className="accord-description border border-white py-4 px-5">
            {nipayment_loading && <NIPaymentModel />}
            {nipayment_fail && <NiPaymentFailModel />}
            {/* <fieldset className="grid grid-cols-2 gap-4">
            <div
              htmlFor="customize"
              className={`block cursor-pointer rounded-lg border border-gray-300 p-4 text-sm font-medium shadow-sm ${
                cashOnDelivery ? 'border-gray-700 ring-1 ring-gray-700' : ''
              }`}
              onClick={() => {
                setCashOnDelivery(true);
              }}
            >
              <div className="flex items-center justify-between">
                <p className="text-white font-bold">Cash On Delivery</p>

                <svg
                  className={`h-5 w-5 text-gray-700 ${cashOnDelivery ? '' : 'hidden'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div
              htmlFor="DeliveryStandard"
              className={`block cursor-pointer rounded-lg border border-gray-300 p-4 text-sm font-medium shadow-sm ${
                !cashOnDelivery ? 'border-gray-700 ring-1 ring-gray-700' : ''
              }`}
              onClick={() => {
                setCashOnDelivery(false);
              }}
            >
              <div className="flex items-center justify-between">
                <p className="text-white font-bold">Pay Now</p>

                <svg
                  className={`h-5 w-5 text-gray-700 ${!cashOnDelivery ? '' : 'hidden'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </fieldset> */}
            <div className="py-4">
              <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                <div className=" sm:text-left">
                  <div className="flex">
                    <div className="flex items-center h-5">
                      <input
                        id="helper-checkbox"
                        aria-describedby="helper-checkbox-text"
                        type="checkbox"
                        value=""
                        onClick={OnAcceptClick}
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                      />
                    </div>
                    <div className="ml-2 text-sm text-white">
                      <label htmlFor="helper-checkbox" className="font-medium">
                        I agree with the{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.jumeirah.com/en/jumeirah-group/terms-and-conditions"
                          className="text-gray-400 underline"
                        >
                          terms & conditions
                        </a>
                      </label>
                      <p id="helper-checkbox-text" className="text-xs font-normal">
                        {dic.t('Cakery-Secure Payment Partner')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {cashOnDelivery && paynowstate && (
              <>
                {orderloading ? (
                  <CODModel />
                ) : (
                  <>
                    {showorderloading ? (
                      <CODConfirmOrderModel />
                    ) : (
                      <div className="text-white sm:text-right mt-4">
                        <button
                          onClick={() => CashOnDelivery()}
                          className=" text-white w-36 text-center bg-gray-700 font-bold rounded-lg text-sm px-5 py-2  mb-2 tracking-wider	"
                        >
                          {dic.t('Cakery-Order Now')}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {!cashOnDelivery && paynowstate && (
              <div className="grid grid-cols-1  md:grid-cols-1 py-1 gap-3">
                <div className="text-white sm:text-right">
                  <button
                    onClick={handlePayNow}
                    className=" text-white w-36 text-center bg-gray-700 font-bold rounded-lg text-sm px-5 py-2  mb-2 tracking-wider	"
                  >
                    {dic.t('Cakery-Pay Now')}
                  </button>
                </div>
              </div>
            )}
            {showCheckoutModal && (
              <CheckoutNoteModal
                closeModal={() => {
                  setShowCheckoutModal(false);
                }}
                proccedPayment={() => {
                  setShowCheckoutModal(false);
                }}
              />
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
