import React, { Fragment, useState } from 'react';
import { IconContext } from 'react-icons';
import { BsClockFill } from 'react-icons/bs';
import dic from 'i18next';

const DeliveryTime = ({
  dptime,
  setdptime,
  isFestival,
  FestiveDeliveryTime,
  deliveryTime,
  PickupTime,
}) => {
  return (
    <Fragment>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 mt-4">
        {isFestival &&
          PickupTime.map((item, key) => {
            return (
              <div
                className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
                  dptime === item?.fields?.Title?.value ? 'border-gray-500' : 'border-gray-200'
                } hover:cursor-pointer`}
                onClick={() => setdptime(item?.fields?.Title?.value)}
                key={key}
              >
                <div className="flex">
                  <div className="flex items-center">
                    <IconContext.Provider
                      value={{
                        className: `inline-flex ${
                          dptime === item?.fields?.Title?.value && 'text-gray-800'
                        }`,
                      }}
                    >
                      <BsClockFill size={25} />
                    </IconContext.Provider>
                  </div>
                  <div className="ml-2">
                    <label htmlFor="helper-checkbox" className="font-normal text-sm text-gray-900">
                      {item?.fields?.Title?.value}
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        {!isFestival &&
          PickupTime.map((item, key) => {
            return (
              <div
                className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
                  dptime === item?.fields?.Title?.value ? 'border-gray-500' : 'border-gray-200'
                } hover:cursor-pointer`}
                onClick={() => setdptime(item?.fields?.Title?.value)}
                key={key}
              >
                <div className="flex">
                  <div className="flex items-center">
                    <IconContext.Provider
                      value={{
                        className: `inline-flex ${
                          dptime === item?.fields?.Title?.value && 'text-gray-800'
                        }`,
                      }}
                    >
                      <BsClockFill size={25} />
                    </IconContext.Provider>
                  </div>
                  <div className="ml-2">
                    <label htmlFor="helper-checkbox" className="font-normal text-sm text-gray-900">
                      {item?.fields?.Title?.value}
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        {/* <div
          className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
            dptime === '12:00pm - 03:00pm' ? 'border-gray-500' : 'border-gray-200'
          } hover:cursor-pointer`}
          onClick={() => setdptime('12:00pm - 03:00pm')}
        >
          <div className="flex">
            <div className="flex items-center">
              <IconContext.Provider
                value={{
                  className: `inline-flex ${dptime === '12:00pm - 03:00pm' && 'text-gray-800'}`,
                }}
              >
                <BsClockFill size={25} />
              </IconContext.Provider>
            </div>
            <div className="ml-2 text-lg">
              <label htmlFor="helper-checkbox" className="font-medium text-gray-900">
                12PM TO 3PM
              </label>
            </div>
          </div>
        </div>
        <div
          className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
            dptime === '03:00pm - 05:00pm' ? 'border-gray-500' : 'border-gray-200'
          } hover:cursor-pointer`}
          onClick={() => setdptime('03:00pm - 05:00pm')}
        >
          <div className="flex">
            <div className="flex items-center">
              <IconContext.Provider
                value={{
                  className: `inline-flex ${dptime === '03:00pm - 05:00pm' && 'text-gray-800'}`,
                }}
              >
                <BsClockFill size={25} />
              </IconContext.Provider>
            </div>
            <div className="ml-2 text-lg">
              <label
                htmlFor="helper-checkbox"
                className="font-medium text-gray-900 dark:text-gray-300"
              >
                3PM TO 5PM
              </label>
            </div>
          </div>
        </div>
        <div
          className={`border-2 text-gray-400 rounded-md shadow-md p-2 ${
            dptime === '05:00pm - 07:00pm' ? 'border-gray-500' : 'border-gray-200'
          } hover:cursor-pointer`}
          onClick={() => setdptime('05:00pm - 07:00pm')}
        >
          <div className="flex">
            <div className="flex items-center">
              <IconContext.Provider
                value={{
                  className: `inline-flex ${dptime === '05:00pm - 07:00pm' && 'text-gray-800'}`,
                }}
              >
                <BsClockFill size={25} />
              </IconContext.Provider>
            </div>
            <div className="ml-2 text-lg">
              <label
                htmlFor="helper-checkbox"
                className="font-medium text-gray-900 dark:text-gray-300"
              >
                5PM TO 7PM
              </label>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default DeliveryTime;
