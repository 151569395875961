import {
  GET_ORDER_LOADING,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDER_PAGECOUNT,
  TEMP_SAVE_ORDER,
  SAVE_FILTER_DATA,
  SELECTED_DELIVERY_TYPE,
} from '../../actions/admin/GetAllOrder.action';

import { UPDATE_ORDER_LOADING, UPDATE_ORDER_SUCCESS } from '../../actions/admin/UpdateOrder.action';

const initailState = {
  loading: false,
  orders: [],
  error: '',
  updateloading: false,
  updateMessage: '',
  statusCode: '',
  pagecount: '',
  pagenumber: 0,
  tempOrders: [],
  filterData: {},
  selectedDeliveryType: 'ALL',
};

export const getAllOrdersReducer = (state = initailState, action) => {
  switch (action.type) {
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        // loading: false,
        orders: action.payload,
        statusCode: action.status,
      };
    case GET_ORDER_PAGECOUNT:
      return {
        ...state,
        pagecount: action.payload,
        pagenumber: action.pageno,
      };
    case GET_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        statusCode: action.status,
      };

    case GET_ORDER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case UPDATE_ORDER_LOADING:
      return {
        ...state,
        updateloading: action.payload,
      };

    case UPDATE_ORDER_SUCCESS:
      const UpdatOrderIndex = state?.orders?.findIndex((order) => {
        return order?.orderId === action?.payload?.order?.orderId;
      });
      const neworders = [...state.orders];
      neworders[UpdatOrderIndex] = action.payload.order;
      return {
        ...state,
        orders: neworders,
        updateMessage: action.payload.message,
      };

    case TEMP_SAVE_ORDER:
      return {
        ...state,
        tempOrders: action.payload,
      };
    case SAVE_FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      };
    case SELECTED_DELIVERY_TYPE:
      return {
        ...state,
        selectedDeliveryType: action.payload,
      };
    default:
      return state;
  }
};
