import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Loader from '../Loader/Loader';
import ResponseShow from '../ResponseShow/ResponseShow';
import { GetGuestDetails } from '../../helpers/sessionHandler';
import { emailPattern, letters, phonepattern } from '../../helpers/Patterns';
import { onGuest, onGuestDisable } from '../../actions/guest.action';
import { BsXCircleFill } from 'react-icons/bs';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import dic from 'i18next';

function ContinueGuest(props) {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [getstatus, SetStatus] = useState(true);
  const [guestLoggedIn, setguestLoggedIn] = useState(localStorage.getItem('guestLoggedIn'));
  const guestdetails = GetGuestDetails();
  const gueststatus = useSelector((state) => state.guest.status);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmits = (data) => {
    const guestDetail = {
      email: data.email,
      phone: data.phone,
      name: data.name,
    };
    addToast('Welcome, ' + data.name, {
      appearance: 'success',
    });
    reset();
    dispatch(onGuest(guestDetail));
    onclose();
    onchangetab(4);
    SetStatus(!getstatus);
  };
  const onclose = () => {
    props.toggleModal();
  };
  const LogoutGuest = () => {
    localStorage.setItem('guestLoggedIn', false);
    onclose();
    addToast('Logged Out', {
      appearance: 'success',
    });
    onchangetab(1);
    SetStatus(!getstatus);
    window.location.href = '/en/home';
  };
  const onchangetab = (item) => {
    props.TabStatusAction(item);
  };
  useEffect(() => {
    setguestLoggedIn(localStorage.getItem('guestLoggedIn'));
    if (guestLoggedIn === 'false') {
      dispatch(onGuestDisable());
      SetStatus(!getstatus);
    }
  }, [gueststatus]);
  return (
    <>
      <>
        <div className="w-full">
          <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-slate-200 border-0">
            <div className="close-icon">
              {/* <BsXCircleFill
                onClick={onclose}
                className="text-xl text-slate-400 cursor-pointer absolute right-2 top-2"
              /> */}
              <button
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:text-white"
                onClick={onclose}
              >
                <MdClose className="w-5 h-5" />
              </button>
            </div>
            <div className="flex-auto px-4 lg:px-10 pb-5 pt-0">
              <div className="text-slate-600 pt-5  pb-1 lg:pt-10 lg:pb-3 text-center font-bold">
                <p className="text-lg uppercase">{dic.t('Cakery-Guest')}</p>
              </div>
              {guestLoggedIn === 'true' || gueststatus === 'true' ? (
                <>
                  <ul className="list-inside text-center py-6 font-bold">
                    <li className="py-1">
                      {dic.t('Cakery-Name')} : {guestdetails && guestdetails.name}
                    </li>
                    <li className="py-1">Email : {guestdetails && guestdetails.email}</li>
                    <li className="py-1">
                      {dic.t('Cakery-Phone')} : {guestdetails && guestdetails.phone}
                    </li>
                    <></>
                  </ul>
                  <div className="text-center mt-6 px-1">
                    <button
                      onClick={() => LogoutGuest()}
                      type="submit"
                      className="bg-slate-800 uppercase text-white active:bg-slate-600 text-sm tracking-widest px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    >
                      Logout Guest
                    </button>
                  </div>
                </>
              ) : (
                <form className="pt-1 px-1" onSubmit={handleSubmit(onSubmits)}>
                  <div className="relative w-full mb-3 px-1">
                    {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      {dic.t('Cakery-Name')}
                    </label> */}
                    <input
                      type="text"
                      name="user_name"
                      maxLength={20}
                      className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={dic.t('Cakery-Name')}
                      {...register('name', {
                        required: 'Name is Required',
                        pattern: {
                          value: letters,
                          message: 'Please enter a valid name',
                        },
                      })}
                      autoComplete="off"
                    />
                    {errors.name && errors.name.message && (
                      <span className=" mt-2 text-red-400">{errors.name.message}</span>
                    )}
                  </div>
                  <div className="relative w-full mb-3 px-1">
                    {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      Email
                    </label> */}
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={dic.t('Cakery-Email')}
                      {...register('email', {
                        required: 'Email is Required',
                        pattern: {
                          value: emailPattern,
                          message: 'Please enter a valid email',
                        },
                      })}
                      autoComplete="off"
                    />
                    {errors.email && errors.email.message && (
                      <span className="text-red-400">{errors.email.message}</span>
                    )}
                  </div>

                  <div className="relative w-full mb-3 px-1">
                    {/* <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                      {dic.t('Cakery-Phone Number')}
                    </label> */}
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder:text-slate-600 placeholder:tracking-widest text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder={dic.t('Cakery-Phone Number')}
                      {...register('phone', {
                        required: 'Phone is Required',
                        pattern: {
                          value: phonepattern,
                          message: 'Please enter a valid phone',
                        },
                      })}
                      autoComplete="off"
                    />
                    {errors.phone && errors.phone.message && (
                      <span className="text-red-400">{errors.phone.message}</span>
                    )}
                  </div>

                  <div className="text-center mt-6 px-1">
                    <button
                      type="submit"
                      className="bg-slate-800 text-white active:bg-slate-600 text-sm tracking-widest px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    >
                      {dic.t('Cakery-Continue Guest')}
                    </button>
                  </div>
                  <div className="flex flex-wrap justify-between font-bold mt-4 px-1">
                    <span
                      className="text-slate-600 cursor-pointer text-sm underline underline-offset-4"
                      onClick={() => onchangetab(1)}
                    >
                      {dic.t('Cakery-Already Have an Account')}
                    </span>

                    <span
                      className="text-slate-600 cursor-pointer text-sm underline underline-offset-4"
                      onClick={() => onchangetab(2)}
                    >
                      {dic.t('Cakery-Create Account')}
                    </span>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default ContinueGuest;
