import { React, useState, useEffect, useRef, useMemo } from 'react';
import { AiOutlineEye, AiOutlineSearch } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { IoMdCalendar } from 'react-icons/io';
import {
  MdOutlineDeliveryDining,
  MdOutlineDoneOutline,
  MdPhonelinkErase,
  MdMobileFriendly,
  MdSend,
  MdCancelScheduleSend,
  MdRefresh,
} from 'react-icons/md';
import { TbEdit, TbEditOff, TbShoppingCartX } from 'react-icons/tb';
import { BsBasketFill } from 'react-icons/bs';
import { VscFilePdf } from 'react-icons/vsc';
import { GiCampCookingPot } from 'react-icons/gi';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { filterStyles } from '../../helpers/Patterns';
import OrderModal from './Modal';
import moment from 'moment';
import { BiCycling } from 'react-icons/bi';
import { RiShoppingBasketLine } from 'react-icons/ri';
import {
  TEMP_SAVE_ORDER,
  getAllOrders,
  GET_ORDER_LOADING,
} from '../../actions/admin/GetAllOrder.action';
import TableLoader from '../Admin/TableLoader';
import {
  filterOrders,
  FILTER_ORDER_LOADING,
  viewSingleOrders,
} from '../../actions/admin/FilterOrder.action';
import { removeError } from '../../actions/admin/RemoveError.action';
import { generateExcelReport } from '../../actions/report.action';
import * as helpers from '../../helpers/config';
import * as Helpers from '../../helpers/AdminHelpers';
import CancelConfirmModal from './ConfirmationModal/CancelConfirmModal';
import ConfirmationModal from './ConfirmationModal';
import { IoCloseCircle } from 'react-icons/io5';
import { BiError } from 'react-icons/bi';
import dic from 'i18next';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CommentsModal from './CommentsModal';
import { onInvoicePayment, onResendInvoicePayment } from '../../actions/payment/payment.action';
import OrdersSendLinkModal from './OrdersSendLinkModal';
import AdvanceSearchModal from './AdvanceSerachModal';
import { onupdateorder } from '../../actions/admin/UpdateOrder.action';
import PaymentStatusModal from './PaymentStatusModal';
import { getNIPayStatusService } from '../../actions/getNIPayStatus.action';
import ViewLoader from './ViewLoader';

const Main = ({ regionlist }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  // fetching orders from redux state
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.allorders);
  const orders = useSelector((state) => state.allorders.orders);
  const tempOrders = useSelector((state) => state.allorders.tempOrders);
  // const PAGECOUNT = useSelector((state) => state.allorders.pagecount);
  const filterloading = useSelector((state) => state.filterorders.loading);
  const filteredorders = useSelector((state) => state.filterorders.orders);
  const error = useSelector((state) => state.filterorders.error);
  const { invoice_order_ref, invoice_ref } = useSelector((state) => state.payment);

  // taking logged user data from storage
  const loggeddetails = helpers.LoggedUserDetails();
  const usertype = loggeddetails?.accessDetails?.role;
  const userEmailId = loggeddetails?.userEmailId;
  // state & function for view & close view more modal
  const [showModal, setShowModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  // react pagination states
  const [items, setItems] = useState(tempOrders && tempOrders.length > 0 ? tempOrders : orders);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [commentsModalItem, setCommentsModalItem] = useState();
  const [confirmModalItem, setConfirmModalItem] = useState();
  const [cancelconfirmModalItem, setCancelConfirmModalItem] = useState();
  const [cancelConfirmModal, setCancelConfirmModal] = useState();
  const [showAdvanceSearchModal, setshowAdvanceSearchModal] = useState(false);
  const today = new Date();
  const todayDate = new Date();
  const [deliveryDate, setDeliveryDateCurrent] = useState(todayDate.setDate(todayDate.getDate()));
  const [endDate, setEndDate] = useState(null);
  const [sendLinkModal, setSendLinkModal] = useState(false);
  // selected Filter Drop
  const [selectedFilterDrop, setSelectedFilterDrop] = useState(null);
  const [searchValue, setSearchValue] = useState();
  const [selectData, setSelectData] = useState();
  const [orderStatus, setOrderStatus] = useState(null);
  const [deliveryType, setDeliveryType] = useState(null);

  // Dates State
  const [startDeliveryDate, setStartDeliverydate] = useState(null);
  const [endDeliveryDate, setEndDeliveryDate] = useState(null);
  const [startOrderDate, setStartOrderdate] = useState(null);
  const [endOrderDate, setEndOrderDate] = useState(null);
  const [formData, updateFormData] = useState({
    searchKey: '',
    searchValue: '',
  });
  const [loadedData, setLoadedData] = useState(1);
  const [loadingData, setLoadingData] = useState(false);
  const labelRef = useRef();
  const valueRef = useRef();
  const [paymentStatus, setPaymentStatusType] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);

  const [pdfgenerateLoaderStatus, setPdfgenerateLoaderStatus] = useState('');
  const [paymentLinkLoaderStatus, setPaymentLinkLoaderStatus] = useState('');
  const [viewLoaderStatus, setViewLoaderStatus] = useState('');

  const [filter, setFilter] = useState({
    orderId: '',
    orderStatus: 'all',
    deliveryType: 'all',
    customerName: '',
    dateRange: null,
    paymentStatus: 'all',
    onlyTurkey: false,
  });

  // set intial items
  useEffect(() => {
    setItemOffset(0);
  }, [items]);

  // fetch all orders action call
  useEffect(() => {
    const userlistdata = {
      userEmailId: userEmailId,
      userType: usertype,
    };
    // dispatch(getAllOrders(userlistdata, pageNumber));
  }, []);

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };
  const draftStatusChangedModal = () => {
    let payload = {
      searchKey: 'orderStatus',
      searchValue: 'DRAFT',
    };
    dispatch(filterOrders(payload));
  };
  const closeCancelConfirmModal = () => {
    setCancelConfirmModal(false);
  };
  const closeCommentsModal = () => {
    setShowCommentModal(false);
  };
  const closeAdvanceSearchModal = () => {
    setshowAdvanceSearchModal(false);
  };
  // updated each time the orders with filterred data
  useEffect(() => {
    setItems(filteredorders);
  }, [filteredorders]);

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    if (items && items.length > 0) {
      setCurrentItems(items);
      setLoadingData(false);
    }
  }, [items, loadedData, tempOrders]);

  useEffect(() => {
    if (currentItems && currentItems.length > 0) {
      dispatch({ type: GET_ORDER_LOADING, payload: false });
    }
  }, [currentItems]);

  const handleClear = () => {
    setItems(orders);
    valueRef.current.value = '';
    labelRef.current.clearValue();
    dispatch(removeError());
    setErrorMessage('');
  };
  const onpaymentclick = (item) => {
    setConfirmModal(true);
    setConfirmModalItem(item);
  };
  const oncancelclick = (item) => {
    setCancelConfirmModal(true);
    setCancelConfirmModalItem(item);
  };

  useEffect(() => {
    const searchData = () => {
      const e = new Date();
      updateFormData({
        ...formData,
        ['searchValue']:
          e.getFullYear() +
          '-' +
          ('0' + (e.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + e.getDate()).slice(-2),
      });
    };
    searchData();
  }, []);
  // code to update the order reference number

  useEffect(() => {
    if (invoice_order_ref !== '' && selectData) {
      const payload = {
        orderId: selectData.orderId,
        transactionNumber: invoice_order_ref,
        outlet: invoice_ref,
        // invoiceReferenceNumber: invoice_ref,
      };
      dispatch(onupdateorder(payload));
    }
  }, [invoice_order_ref]);

  const SearchhandleChange = (e) => {
    setSearchValue(e.target.value);
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
    dispatch(removeError());
    setErrorMessage('');
  };
  // handle search & clear search
  const onSubmits = () => {
    let SearchKey = 'order_id';
    if (searchValue) {
      let searchType = isNaN(Number(searchValue));
      console.log('searchType', searchType);
      if (!searchType) {
        if (searchValue.split('').length < 7) {
          SearchKey = 'order_id';
        } else {
          SearchKey = 'phoneNumber';
        }
      } else if (searchValue.includes('@')) {
        SearchKey = 'orderByEmail';
      } else {
        SearchKey = 'orderByName';
      }
      if (SearchKey) {
        let payload = {
          searchKey: SearchKey,
          searchValue: searchValue,
        };
        dispatch(filterOrders(payload));
        setStartDeliverydate(null);
        setEndDeliveryDate(null);
        setOrderStatus(null);
        setDeliveryType(null);
        setPaymentStatusType(null);

        setFilter({
          orderId: '',
          orderStatus: 'all',
          deliveryType: 'all',
          customerName: '',
          dateRange: null,
          paymentStatus: 'all',
        });
      }
    } else {
      const userlistdata = {
        userEmailId: userEmailId,
        userType: usertype,
      };
      dispatch(getAllOrders(userlistdata, pageNumber));
    }
  };

  const prepareReportData = () => {
    const date = new Date();
    let filterTerm = formData.searchKey;
    let filterQuery = formData.searchValue;

    if (startDeliveryDate && endDeliveryDate) {
      let excelData = {
        generatedBy: loggeddetails?.firstName + ' ' + loggeddetails?.lastName,
        generatedDate:
          moment(date).format('DD\xa0MMM\xa0YYYY').toUpperCase() +
          ' ' +
          date.getHours() +
          ':' +
          ('0' + date.getMinutes()).slice(-2),
        searchKey: 'deliveryDate',
        searchValue: `${convertDateFormat(startDeliveryDate)}to${convertDateFormat(
          endDeliveryDate
        )}`,
      };
      let reportName = `ORDER_REPORT_${moment(new Date()).format('DDMMMYYYY').toUpperCase()}`;
      dispatch(generateExcelReport(excelData, reportName, 'ORDERS'));
    } else {
      alert('Please select delivery date');
    }
  };

  const SendLink = (item) => {
    if (item.outlet && item.outlet !== '') {
      const ResentInvoicePayload = Helpers.getResentInvoicePayload(item);
      dispatch(onResendInvoicePayment(ResentInvoicePayload, item));
      setSendLinkModal(true);
    } else {
      const InvoicePayload = Helpers.getInvoicePayloadForOrders(item);
      dispatch(onInvoicePayment(InvoicePayload));
      setSendLinkModal(true);
    }
  };

  const afterAdvanceFilterClearFilter = () => {
    valueRef.current.value = '';
    labelRef.current.clearValue();
  };

  const closeSendLinkModal = () => {
    setSendLinkModal(false);
  };
  const onPdfGenrationClick = async (item) => {
    let options = {
      fileName: `OrderSlip${item.orderId}.pdf`,
      directory: 'Documents',
      base64: true,
    };
    const Table = item.items.map((item, key) => {
      const itemSupplies = item && item?.itemSuppliesName ? item.itemSuppliesName : '';
      const itemSuppliesPrice = item && item.itemSuppliesPrice;
      return `<tr key=${key}>
          <td>${item.itemName}</td>
          <td align="center">${item.quantity}</td>
          <td align="center">${item.perItemPrice}</td>
          <td align="center" style="letter-spacing: 2px;font-size: 8px;">${`${itemSupplies} (${itemSuppliesPrice})`}</td>
          <td align="center">${item.totalPrice}</td>
        </tr>`;
    });
    let Orderdate = moment(item.orderDate).format('DD/MM/YYYY');
    let deliveryDate = moment(item.dpDate).format('DD/MM/YYYY');
    let createdDate = moment().format('DD/MM/YYYY');
    let createdTime = moment().format('HH:mm');
    let htmltable = `<div style="padding: 20px; font-style: italic;font-size: 10px;letter-spacing: 2px;width:100%;">
      <p style="width:100%;" >Order&nbsp;ID&nbsp;-&nbsp;${item.orderId}</p>
      <p>Order&nbsp;Date&nbsp;-&nbsp;${Orderdate}</p>
      <p>Order&nbsp;Status&nbsp;-&nbsp;${item.orderStatus}</p>
      <p>${
        item?.deliveryType === 'PICKUP' ? 'Pickup' : 'Delivery'
      }&nbsp;Date&nbsp;-&nbsp;${deliveryDate}</p>
      <p style="background: #f2f2f2;padding-top: 5px;padding-bottom: 5px; font-style: italic;">CUSTOMER&nbsp;INFORMATION
      </p>
      <p>Name&nbsp;-&nbsp;${item.customerName.replace(/ /g, '&nbsp;')}</p>
      <p>Phone&nbsp;-&nbsp;${item.phoneNumber}</p>
      <p>Email&nbsp;-&nbsp;${item.customerEmail ? item.customerEmail : ''}</p>
      <p style="background: #f2f2f2;padding-top: 5px;padding-bottom: 5px; font-style: italic;">
                      ORDER&nbsp;INFORMATION
      </p>
      <table cellPadding="5" cellSpacing="0" style="border: 1px solid black;padding-top: 2px;padding-bottom: 2px;">
            <thead>
              <colgroup>
                <col width="40%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" span="1" style="" />
              </colgroup>
              <tr>
                <th align="left" className="col-item-name">
                  Item&nbsp;Name
                </th>
                <th align="center" className="col-quantity">
                  Quantity
                </th>
                <th align="center" className="col-price">
                  Price
                </th>
                <th align="center" className="col-price">
                  Supplies
                </th>
                <th align="center" className="col-price">
                  Total&nbsp;Price
                </th>
              </tr>
            </thead>
            <tbody>
              ${Table.join('')}
            </tbody>
            <tfoot>
              <td className="price_txt" scope="col" colSpan="4">
                Total&nbsp;Price
              </td>
              <td align="center">${item.totalPrice}</td>
            </tfoot>
            <tfoot>
              <td className="price_txt" scope="col" colSpan="4">
                Delivery&nbsp;Charge
              </td>
              <td align="center">${item.deliveryCharge}</td>
            </tfoot>
          </table>
          <table>
              ${
                item?.deliveryType === 'PICKUP'
                  ? ''
                  : `<tr style="">
                      <td style="">
                        <b>Delivery&nbsp;Address&nbsp;:&nbsp;</b>
                      </td>
                      <td style="" className="childcalss">
                        ${item?.deliveryAddress?.address.replace(/ /g, '&nbsp;')}
                      </td>
                    </tr>
                    <tr style="  ">
                      <td style="">
                        <b>Delivery&nbsp;Region&nbsp;:</b>
                      </td>
                      <td style="" className="childcalss">
                        ${item?.deliveryAddress?.region.replace(/ /g, '&nbsp;')}
                      </td>
                    </tr>`
              }
              <tr style="  ">
                <td style="">
                  <b>Payment&nbsp;Mode&nbsp;:&nbsp;</b>
                </td>
                <td style="" className="childcalss">
                  ${item.paymentMode}
                </td>
              </tr>
              <tr style="  ">
                <td style="">
                  <b>Payment&nbsp;Status&nbsp;:&nbsp;</b>
                </td>
                <td style="" className="childcalss">
                  ${item.paymentStatus}
                </td>
              </tr>
            </table>
            ${
              item.remarks
                ? `<p style="margin:0;">
              Comments&nbsp;:&nbsp;${item.remarks && item.remarks.replace(/ /g, '&nbsp;')}
              </p>`
                : ''
            }
            <p style="margin:0;">
              Created&nbsp;By&nbsp;:&nbsp;${
                Object.keys(item.userDTO).length === 0
                  ? item.userDTO.firstName.replace(/ /g, '&nbsp;')
                  : item.customerName.replace(/ /g, '&nbsp;')
              }
            </p>
            <p style="margin:0;">
              Created&nbsp;Date&nbsp;:&nbsp;${createdDate}
            </p>
            <p style="margin:0;">
              Created&nbsp;Time&nbsp;:&nbsp;${createdTime}
            </p>
     </div>`;
    const { jsPDF } = require('jspdf');
    const doc = new jsPDF('p', 'px', 'A4');
    await doc.html(htmltable, {
      callback: function (doc) {
        console.log('doc created', htmltable);
        doc.save(options.fileName);
      },
    });
  };
  const deliveryTypeOption = [
    { name: 'All', value: 'all' },
    { name: 'Delivery', value: 'delivery' },
    { name: 'Pickup', value: 'pickup' },
  ];
  const paymentStatusOption = [
    { name: 'All', value: 'all' },
    { name: 'Paid', value: 'paid' },
    { name: 'Unpaid', value: 'Unpaid' },
  ];

  const orderOption = [
    { name: 'All', value: 'all' },
    { name: 'Delivered', value: 'delivered' },
    { name: 'Placed', value: 'placed' },
    { name: 'Cancelled', value: 'cancelled' },
    { name: 'Cooking', value: 'cooking' },
  ];

  const deliveryTypeOptions = [
    { name: 'All', value: 'all' },
    { name: 'Delivery', value: 'delivery' },
    { name: 'Pickup', value: 'pickup' },
  ];

  const paymentStatusOptions = [
    { name: 'All', value: 'all' },
    { name: 'Paid', value: 'paid' },
    { name: 'Unpaid', value: 'unpaid' },
  ];

  const orderStatusOptions = [
    { name: 'All', value: 'all' },
    { name: 'Draft Order', value: 'draft' },
    { name: 'Delivered', value: 'delivered' },
    { name: 'Placed', value: 'placed' },
    { name: 'Cancelled', value: 'cancelled' },
    { name: 'Cooking', value: 'cooking' },
  ];

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  const handleFilterChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: checked }));
  };

  const filteredData = useMemo(() => {
    if (currentItems && currentItems.length > 0 && errorMessage === '') {
      return currentItems.filter((item) => {
        const { dateRange } = filter;
        var d = new Date();
        d.toLocaleString();
        d.setDate(d.getDate() - 3);
        if (item.dpDate >= new Date(d)) {
          console.log('item.deliveryDate', item.dpDate);
          return (
            item.orderId
              .toString()
              .toLowerCase()
              .includes(filter.orderId.toString().toLowerCase()) &&
            (filter.orderStatus === 'all' ||
              item.orderStatus.toLowerCase() === filter.orderStatus) &&
            (filter.deliveryType === 'all' ||
              item.deliveryType.toLowerCase() === filter.deliveryType) &&
            item.customerName.toLowerCase().includes(filter.customerName.toLowerCase()) &&
            (!dateRange ||
              (Array.isArray(dateRange)
                ? item.deliveryDate >= dateRange[0] && item.deliveryDate <= dateRange[1]
                : item.deliveryDate.toISOString().slice(0, 10) === dateRange)) &&
            (filter.paymentStatus === 'all' ||
              item.paymentStatus.toLowerCase() === filter.paymentStatus) &&
            (!filter.onlyTurkey || item.cutlery.toLowerCase() === 'true')
          );
        }
      });
    }
  }, [currentItems, filter]);

  const handlePaymentStatusSearch = (e) => {
    if (e.target.value.toLowerCase().includes('all')) {
      setPaymentStatusType(null);
    } else {
      setPaymentStatusType(e.target.value);
    }
  };
  const handleOrderStatusSearch = (e) => {
    if (e.target.value.toLowerCase().includes('all')) {
      setOrderStatus(null);
    } else {
      setOrderStatus(e.target.value);
    }
  };

  // updated each time the orders with new data
  useEffect(() => {
    if (
      tempOrders &&
      tempOrders.length > 0 &&
      (orderStatus ||
        deliveryType ||
        searchValue ||
        startDeliveryDate ||
        endDeliveryDate ||
        paymentStatus)
    ) {
      setItems(tempOrders);
    } else {
      setItems(orders);
    }
  }, [
    orders,
    tempOrders,
    orderStatus,
    deliveryType,
    searchValue,
    startDeliveryDate,
    endDeliveryDate,
  ]);
  useEffect(() => {
    let payload = {
      searchKey: 'orderStatus',
      searchValue: 'DRAFT',
    };
    dispatch(filterOrders(payload));
  }, []);

  useEffect(() => {
    let filteredSearchResult = [];
    let filteredOrderDatesData = filteredSearchResult;
    console.log('filteredOrderDatesData', paymentStatus, filteredOrderDatesData);

    let filteredValues = filteredOrderDatesData;
    if (orderStatus) {
      const filteredData =
        filteredValues &&
        filteredValues.length &&
        filteredValues.filter((order) =>
          order.orderStatus.toLowerCase().includes(orderStatus.toLowerCase())
        );
      console.log('filteredData 1', filteredData);
      if (filteredData.length > 0) {
        setErrorMessage('');
        filteredValues = filteredData;
        // dispatch({ type: TEMP_SAVE_ORDER, payload: filteredData });
      } else {
        filteredValues = filteredData;
        setErrorMessage('error');
      }
    }

    if (deliveryType) {
      const filteredData =
        filteredValues &&
        filteredValues.length &&
        filteredValues.filter((order) =>
          order.deliveryType.toLowerCase().includes(deliveryType.toLowerCase())
        );
      console.log('filteredData 2', filteredData);
      if (filteredData.length > 0) {
        setErrorMessage('');
        filteredValues = filteredData;

        // dispatch({ type: TEMP_SAVE_ORDER, payload: filteredData });
      } else {
        filteredValues = filteredData;

        setErrorMessage('error');
      }
    }

    if (paymentStatus) {
      if (paymentStatus.toLowerCase() === 'all') {
        console.log('filteredData 3', filteredValues);
        // dispatch({ type: TEMP_SAVE_ORDER, payload: filteredValues });
      } else {
        const filteredData =
          filteredValues &&
          filteredValues.length &&
          filteredValues.filter(
            (order) => order.paymentStatus.toLowerCase() === paymentStatus.toLowerCase()
          );
        console.log('filteredData 3', filteredData);
        if (filteredData.length > 0) {
          setErrorMessage('');
          filteredValues = filteredData;
          // dispatch({ type: TEMP_SAVE_ORDER, payload: filteredData });
        } else {
          filteredValues = filteredData;
          setErrorMessage('error');
        }
      }
    }
    if (filteredValues && filteredValues.length > 0) {
      setErrorMessage('');
    }
    dispatch({ type: TEMP_SAVE_ORDER, payload: filteredValues });
  }, [
    orderStatus,
    deliveryType,
    orders,
    searchValue,
    startDeliveryDate,
    endDeliveryDate,
    startOrderDate,
    endOrderDate,
    paymentStatus,
  ]);

  const convertDateFormat = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  };

  const handleScroll = () => {
    setLoadingData(true);
    setLoadedData((prev) => prev + 1);
  };
  console.log(startDeliveryDate, 'startDeliveryDate');
  return (
    <>
      <main className="w-[inherit] mt-12 p-4 sm:px-8  bg-white">
        <div className="container grid mx-auto">
          <div className="block md:flex justify-between mb-4">
            <div className="flex flex-col w-80">
              <h2 className="text-lg font-bold">Pending/Draft Orders</h2>
              <span className="text-xs font-semibold">
                Note: All Missing Orders will be shown below*
              </span>
              {/* <form className="w-full" onSubmit={handleSubmit(onSubmits)}>
                <div className="w-full flex">
                  <div className="relative w-full">
                    <input
                      type="text"
                      className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-gray-50 border-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Order ID / Name / Phone / Email"
                      name="searchValue"
                      onChange={SearchhandleChange}
                      ref={valueRef}
                      autoComplete="off"
                    />
                    <button
                      type="submit"
                      disabled={
                        formData.searchKey === 'filterAll' ||
                        (searchPlaceholder && endDate === null)
                      }
                      className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-gray-700 rounded-r-lg border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                    >
                      <AiOutlineSearch size="20" />
                    </button>
                    <span className="text-xs text-gray-800 font-semibold">
                      Click on search for latest 200 Orders*
                    </span>
                  </div>
                </div>
              </form> */}
            </div>

            <div className="flex grid-cols-3 gap-1">
              <div className="mt-2 md:mt-0 items-center justify-center px-5 py-3 text-md font-bold text-gray-600">
                {filteredData &&
                  filteredData.length > 0 &&
                  `Orders Count: ${loading || filterloading ? 'Loading...' : filteredData.length}`}
              </div>
              {/* <div className="mt-2 md:mt-0">
                <button
                  type="submit"
                  className="px-5 py-3 text-xs font-medium text-white  dark:focus:ring-gray-100 bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700"
                  onClick={prepareReportData}
                >
                  {dic.t('Cakery-Download Report')}
                </button>
              </div> */}
            </div>
          </div>
          {/* <div className="flex w-1/3 items-center bg-gray-100 p-3 rounded-lg space-x-4 mb-3">
            <div className="flex-1 w-1/3">
              <label htmlFor="datepicker" className="block text-xs font-bold text-gray-700">
                Delivery Date
              </label>
              <div className="relative flex items-center justify-end">
                <ReactDatePicker
                  value={deliveryDate}
                  id="datepicker"
                  name="searchValue"
                  dateFormat="yyyy-MM-dd"
                  className="p-2.5 text-sm bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-full relative z-10"
                  onChange={(date) => {
                    const [start, end] = date;
                    console.log('DATES', date);
                    if (start !== null && end !== null) {
                      console.log('start date', convertDateFormat(start), convertDateFormat(end));
                      let payload = {
                        searchKey: 'deliveryDate',
                        searchValue: `${convertDateFormat(start)}to${convertDateFormat(end)}`,
                      };
                      dispatch(filterOrders(payload));
                    }
                    setOrderStatus(null);
                    setDeliveryType(null);
                    setPaymentStatusType(null);
                    setStartDeliverydate(start);
                    setEndDeliveryDate(end);

                    setFilter({
                      orderId: '',
                      orderStatus: 'all',
                      deliveryType: 'all',
                      customerName: '',
                      dateRange: null,
                      paymentStatus: 'all',
                    });
                  }}
                  startDate={startDeliveryDate}
                  endDate={endDeliveryDate}
                  placeholderText="YYYY-MM-DD"
                  maxDate={today.setDate(today.getDate() + 180)}
                  autoComplete="off"
                  selectsRange
                  ref={valueRef}
                />
                <div className="absolute top-3 z-10" style={{ right: '10px' }}>
                  <IoMdCalendar className="text-gray-700" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="flex w-100 justify-end space-x-4 ">
            <input
              type="checkbox"
              name="onlyTurkey"
              className=""
              checked={filter.onlyTurkey}
              onChange={handleFilterChangeCheckbox}
            />
            <label>&nbsp;Only Turkey</label>
          </div>
          {loading || filterloading ? (
            <>
              <TableLoader />
            </>
          ) : (
            <div className="w-full overflow-hidden rounded-lg shadow-md border-2 border-gray-300">
              <div className="w-full overflow-x-auto">
                <table className="w-full whitespace-no-wrap table-auto divide-x">
                  <thead className="text-xs font-semibold tracking-wide text-left uppercase border-b bg-gray-50 dark:border-gray-600 dark:text-gray-300 dark:bg-gray-700">
                    <tr>
                      <td className="px-2 py-2 text-center">{dic.t('Cakery-Order ID')}</td>
                      <td className="px-2 py-2 text-center">ORDER {dic.t('Cakery-Status')}</td>
                      <td className="px-2 py-2 text-center">Delivery Type</td>
                      <td className="px-2 py-2 text-center">{dic.t('Cakery-Customer Name')}</td>
                      {/* <td className="px-2 py-2 text-center">{dic.t('Cakery-Order Date')}</td> */}
                      <td className="px-2 py-2 text-center">{dic.t('Cakery-Delivery Date')}</td>
                      <td className="px-2 py-2 text-center">Payment {dic.t('Cakery-Status')}</td>
                      <td className="px-2 py-2 text-center tracking-widest">Cancel</td>
                      <td className="px-2 py-2 text-center">Edit</td>
                      <td className="px-2 py-2 text-center">PDF</td>
                      <td className="px-2 py-2 text-center">Payment Link</td>
                      <td className="px-2 py-2 text-center">View</td>
                    </tr>
                    <tr>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        <input
                          type="text"
                          name="orderId"
                          value={filter.orderId}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        />
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        {/* <select
                          name="orderStatus"
                          value={filter.orderStatus}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        >
                          {orderStatusOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </select> */}
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        <select
                          name="deliveryType"
                          value={filter.deliveryType}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        >
                          {deliveryTypeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        <input
                          type="text"
                          name="customerName"
                          value={filter.customerName}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        />
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      ></td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      >
                        {/* <select
                          name="paymentStatus"
                          value={filter.paymentStatus}
                          onChange={handleFilterChange}
                          className="w-full p-1 rounded-lg border border-gray-300"
                        >
                          {paymentStatusOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </select> */}
                      </td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      ></td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      ></td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      ></td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      ></td>
                      <td
                        scope="col"
                        className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider px-1 py-1"
                      ></td>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y text-gray-700">
                    {filteredData && filteredData.length > 0 && errorMessage === '' ? (
                      filteredData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">{item.orderId}</span>
                            </td>
                            <td className="px-4 py-3 text-center group">
                              <span className="font-semibold inline-flex uppercase rounded text-stone-600">
                                {item.orderStatus === 'PLACED' && (
                                  <>
                                    <BsBasketFill size="20" className="text-gray-500" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      PLACED
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'COOKING' && (
                                  <>
                                    <GiCampCookingPot size="20" className=" text-amber-500" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      COOKING
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'DISPATCH' && (
                                  <>
                                    <MdOutlineDeliveryDining size="20" className=" text-rose-500" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      DISPATCH
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'DELIVERED' && (
                                  <>
                                    <MdOutlineDoneOutline size="25" className=" text-green-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      DELIVERED
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'CANCELLED' && (
                                  <>
                                    <IoCloseCircle size="25" className=" text-red-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      CANCELLED
                                    </span>
                                  </>
                                )}
                                {item.orderStatus === 'DRAFT' && (
                                  <>
                                    <TbShoppingCartX size="25" className=" text-red-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      DRAFT
                                    </span>
                                  </>
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center group">
                              <span className="font-semibold inline-flex uppercase rounded text-stone-600">
                                {item?.deliveryType === 'PICKUP' && (
                                  <>
                                    <RiShoppingBasketLine size="20" className=" text-gray-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      PICKUP
                                    </span>
                                  </>
                                )}
                                {item?.deliveryType === 'DELIVERY' && (
                                  <>
                                    <BiCycling size="20" className=" text-gray-600" />
                                    <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                      DELIVERY
                                    </span>
                                  </>
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <p className="text-sm font-semibold">
                                {item.customerName
                                  ? item.customerName.includes('undefined')
                                    ? item.customerName.replace(/\bundefined\b/g, '').trim()
                                    : item.customerName
                                  : item.customerName}
                              </p>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="text-sm font-semibold">
                                {moment(item.dpDate).format('DD-MM-YYYY')}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center group">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                {item.paymentStatus === 'PAID' && (
                                  <>
                                    <MdMobileFriendly size="20" className="text-green-500" />
                                    <MdRefresh
                                      size="20"
                                      className="mx-2"
                                      style={{ visibility: 'hidden', cursor: 'not-allowed' }}
                                    />
                                  </>
                                )}
                                {item.paymentStatus === 'UNPAID' && (
                                  <>
                                    <MdPhonelinkErase
                                      size="20"
                                      className={`${
                                        item.orderStatus === 'DELIVERED' ||
                                        item.orderStatus === 'CANCELLED'
                                          ? ''
                                          : 'hover:cursor-pointer'
                                      } text-gray-500`}
                                      onClick={
                                        item.orderStatus === 'DELIVERED' ||
                                        item.orderStatus === 'CANCELLED'
                                          ? null
                                          : () => {
                                              onpaymentclick(item);
                                            }
                                      }
                                    />
                                    {item.transactionNumber !== '' ? (
                                      <>
                                        <MdRefresh
                                          size="20"
                                          className="hover:cursor-pointer mx-2"
                                          onClick={() => {
                                            setPaymentModal(true);
                                            dispatch(getNIPayStatusService(item, true));
                                          }}
                                        />
                                        <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                          Reload to check
                                        </span>
                                      </>
                                    ) : (
                                      <MdRefresh
                                        size="20"
                                        className="mx-2"
                                        style={{ visibility: 'hidden', cursor: 'not-allowed' }}
                                      />
                                    )}
                                  </>
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                <BiError
                                  size="20"
                                  className={`${
                                    item.orderStatus === 'DELIVERED' ||
                                    item.orderStatus === 'CANCELLED'
                                      ? 'text-red-600'
                                      : 'text-gray-500 hover:cursor-pointer'
                                  }`}
                                  onClick={
                                    item.orderStatus === 'DELIVERED' ||
                                    item.orderStatus === 'CANCELLED'
                                      ? null
                                      : () => {
                                          oncancelclick(item);
                                        }
                                  }
                                />
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                {item.orderStatus === 'DELIVERED' ||
                                item.orderStatus === 'CANCELLED' ? (
                                  <TbEditOff size="20" className="text-gray-500" />
                                ) : (
                                  <TbEdit
                                    size="20"
                                    className={
                                      item.remarks !== ''
                                        ? 'text-red-500 hover:cursor-pointer'
                                        : 'text-gray-500 hover:cursor-pointer'
                                    }
                                    onClick={() => {
                                      setShowCommentModal(true);
                                      setCommentsModalItem(item);
                                    }}
                                  />
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center">
                              <span className="font-semibold inline-flex py-1 px-1 uppercase rounded text-stone-600">
                                {pdfgenerateLoaderStatus === item.orderId ? (
                                  <ViewLoader />
                                ) : (
                                  <VscFilePdf
                                    size="20"
                                    className="text-gray-500 hover:cursor-pointer"
                                    onClick={() => {
                                      if (item.items) {
                                        onPdfGenrationClick(item);
                                      } else {
                                        setPdfgenerateLoaderStatus(item.orderId);
                                        let payload = {
                                          searchKey: 'order_id',
                                          searchValue: item.orderId,
                                        };
                                        dispatch(viewSingleOrders(payload))
                                          .then((res) => {
                                            if (res.length > 0) {
                                              onPdfGenrationClick(res[0]);
                                            }
                                            setPdfgenerateLoaderStatus('');
                                          })
                                          .catch((err) => {
                                            setPdfgenerateLoaderStatus('');
                                          });
                                      }
                                    }}
                                  />
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-center group">
                              {item.orderStatus === 'DELIVERED' ||
                              item.orderStatus === 'CANCELLED' ||
                              item.paymentStatus === 'PAID' ? (
                                <>
                                  <MdCancelScheduleSend
                                    size="20"
                                    className="text-gray-500 inline-flex "
                                  />
                                  {/* <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                    Payment Link
                                  </span> */}
                                </>
                              ) : (
                                <>
                                  {paymentLinkLoaderStatus === item.orderId ? (
                                    <ViewLoader />
                                  ) : (
                                    <MdSend
                                      size="20"
                                      className="text-gray-500 inline-flex hover:cursor-pointer"
                                      onClick={() => {
                                        if (item.items) {
                                          SendLink(item);
                                          setSelectData(item);
                                        } else {
                                          setPaymentLinkLoaderStatus(item.orderId);
                                          let payload = {
                                            searchKey: 'order_id',
                                            searchValue: item.orderId,
                                          };
                                          dispatch(viewSingleOrders(payload))
                                            .then((res) => {
                                              if (res.length > 0) {
                                                SendLink(res[0]);
                                                setSelectData(res[0]);
                                              }
                                              setPaymentLinkLoaderStatus('');
                                            })
                                            .catch((err) => {
                                              setPaymentLinkLoaderStatus('');
                                            });
                                        }
                                      }}
                                    />
                                  )}
                                  {/* <span className='tooltip-text bg-gray-400 text-white p-2 -mt-10 -ml-6 rounded hidden group-hover:block absolute text-center text-sm z-50"'>
                                    Payment Link
                                  </span> */}
                                </>
                              )}
                            </td>
                            <td className="px-4 py-3 text-center">
                              {viewLoaderStatus === item.orderId ? (
                                <ViewLoader />
                              ) : (
                                <button
                                  className="text-sm font-semibold px-6 outline-none focus:outline-none ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={() => {
                                    if (item.items) {
                                      setSelectData(item);
                                      setShowModal(true);
                                    } else {
                                      setViewLoaderStatus(item.orderId);
                                      let payload = {
                                        searchKey: 'order_id',
                                        searchValue: item.orderId,
                                      };
                                      dispatch(viewSingleOrders(payload))
                                        .then((res) => {
                                          if (res.length > 0) {
                                            setSelectData(res[0]);
                                            setShowModal(true);
                                          }
                                          setViewLoaderStatus('');
                                        })
                                        .catch((err) => {
                                          setViewLoaderStatus('');
                                        });
                                    }
                                  }}
                                >
                                  <AiOutlineEye size="18" />
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <div className="flex items-center justify-center  py-3">
                            {searchValue || endDeliveryDate ? (
                              <p className="text-md text-gray-900 text-center px-4 flex items-center">
                                {dic.t('Cakery-No Order History')} <TbShoppingCartX />
                              </p>
                            ) : (
                              <p className="text-md font-medium capitalize text-gray-900 text-center">
                                Apply filters to retrieve orders
                              </p>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                    {currentItems &&
                      currentItems.length > 0 &&
                      errorMessage === '' &&
                      items &&
                      (searchValue || endDeliveryDate) &&
                      items.length > loadedData * 30 && (
                        <tr>
                          <td colSpan={12}>
                            <div className="flex items-center justify-center text-2xl py-3">
                              <button
                                onClick={() => handleScroll()}
                                className="px-5 py-3 text-xs font-medium text-white  dark:focus:ring-gray-100 bg-gray-600 rounded hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700"
                              >
                                Loading Data...
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    {paymentModal && (
                      <PaymentStatusModal
                        closeModal={(res) => {
                          setPaymentModal(false);
                          if (res === 'CAPTURED') {
                            let payload = {
                              searchKey: 'orderStatus',
                              searchValue: 'DRAFT',
                            };
                            dispatch(filterOrders(payload));
                          }
                        }}
                      />
                    )}
                    {showModal && <OrderModal closeModal={closeModal} data={selectData} />}
                    {confirmModal && (
                      <ConfirmationModal
                        closeModal={closeConfirmModal}
                        item={confirmModalItem}
                        isDraft={true}
                        isDraftClose={draftStatusChangedModal}
                      />
                    )}
                    {sendLinkModal && <OrdersSendLinkModal closeModal={closeSendLinkModal} />}
                    {cancelConfirmModal && (
                      <CancelConfirmModal
                        closeModal={closeCancelConfirmModal}
                        item={cancelconfirmModalItem}
                      />
                    )}
                    {showCommentModal && (
                      <CommentsModal closeModal={closeCommentsModal} item={commentsModalItem} />
                    )}
                    {showAdvanceSearchModal && (
                      <AdvanceSearchModal
                        closeModal={closeAdvanceSearchModal}
                        regionlist={regionlist}
                        afterAdvanceFilterClearFilter={afterAdvanceFilterClearFilter}
                      />
                    )}
                  </tbody>
                </table>
              </div>
              <div className="px-4 py-3 border-t dark:border-gray-600 bg-gray-50 text-gray-500 dark:text-gray-400 dark:bg-gray-600">
                <div className="flex flex-col justify-end text-xs sm:flex-row text-gray-600 dark:text-gray-400"></div>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};
export default Main;
