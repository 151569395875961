import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onInvoicePayment } from '../../../actions/payment/payment.action';
import * as Helpers from '../../../helpers/AdminHelpers';
import * as helpers from '../../../helpers/config';
import { EndUserRoutes } from '../../../constants';
import dic from 'i18next';
import { onAdminOrder } from '../../../actions/admin/AdminOrder.action';
import OrderConfirmModal from '../OrderConfirmModal';
import SendLinkModal from './SendLinkModal';
import { useToasts } from 'react-toast-notifications';

const SummaryRight = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const loggeddetails = helpers.LoggedUserDetails();
  const { customerDeliveryInfo, customerInfo, cartItems } = useSelector(
    (state) => state.callcenter
  );
  const { invoice_loading, invoice_success_message, invoice_order_ref, invoice_ref } = useSelector(
    (state) => state.payment
  );
  let isCustom =
    cartItems.filter((item) => item.cakeType === 'CUSTOMIZED').length > 0 ? true : false;
  const documentupload = useSelector((state) => state.documentupload);
  const [finalcartItems, setfinalcartItems] = useState(cartItems);
  const [confirmModal, setConfirmModal] = useState(false);
  const [sendLinkModal, setSendLinkModal] = useState(false);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };
  const closeSendLinkModal = () => {
    setSendLinkModal(false);
  };

  useEffect(() => {
    setfinalcartItems(cartItems);
  }, [cartItems]);
  useEffect(() => {
    let Price = 0;
    finalcartItems.map((item) => (Price = Price + item.totalPrice));
    setSubTotalPrice(Price);
  }, [finalcartItems]);
  // useEffect(() => {
  //   if (invoice_order_ref !== '') {
  //     let orderPayload = Helpers.getInvoiceOrderPayload(
  //       customerDeliveryInfo,
  //       cartItems,
  //       customerInfo,
  //       subTotalPrice,
  //       loggeddetails,
  //       TotalPayment,
  //       invoice_order_ref
  //     );
  //     let payload = { ...orderPayload };
  //     if (isCustom) {
  //       if (
  //         documentupload &&
  //         documentupload.submitedDocumentID &&
  //         documentupload.submitedDocumentID.length > 0
  //       ) {
  //         payload.items = payload.items.map(
  //           (each, i) =>
  //             (each = { ...each, documentId: [documentupload.submitedDocumentID[i].fielId] })
  //         );
  //       } else {
  //         payload.items = payload.items.map((each, i) => (each = { ...each, documentId: [] }));
  //       }
  //     }
  //     console.log('onAdminOrder(payload)1', payload);
  //     dispatch(onAdminOrder(payload));
  //   }
  // }, [invoice_order_ref]);
  let deliveryprice;

  if (
    customerDeliveryInfo.deliveryAddress &&
    customerDeliveryInfo.deliveryAddress.deliveryPrice !== undefined
  ) {
    deliveryprice =
      customerDeliveryInfo.deliverytype === 'PICKUP'
        ? 0
        : parseFloat(customerDeliveryInfo.deliveryAddress.deliveryPrice);
  } else {
    // Handle the case where deliveryAddress or deliveryPrice is undefined
    deliveryprice = 0; // Provide a default value or handle it as per your requirements
  }
  console.log(deliveryprice, 'deliveryprice');
  let TotalPayment = subTotalPrice + deliveryprice;
  let orderPayload = Helpers.getOrderPayload(
    customerDeliveryInfo,
    cartItems,
    customerInfo,
    subTotalPrice,
    loggeddetails,
    TotalPayment
  );
  const paymentPayload = Helpers.getPaymentPayload(
    TotalPayment,
    customerInfo,
    customerDeliveryInfo,
    EndUserRoutes.redirectUrl
  );
  const InvoicePayload = Helpers.getInvoicePayload(
    customerDeliveryInfo,
    cartItems,
    customerInfo,
    subTotalPrice,
    loggeddetails,
    TotalPayment
  );
  const SaveOrder = () => {
    if (
      customerInfo.CustomerPhoneNumber &&
      customerInfo.CustomerEmail &&
      customerInfo.CustomerFirstName
    ) {
      if (
        customerDeliveryInfo &&
        Object.keys(customerDeliveryInfo).length > 0 &&
        customerDeliveryInfo.deliverytype &&
        customerDeliveryInfo.deliverytype === 'DELIVERY'
      ) {
        if (
          customerDeliveryInfo.deliveryAddress &&
          Object.keys(customerDeliveryInfo.deliveryAddress).length > 0 &&
          customerDeliveryInfo.deliveryAddress.region !== '' &&
          customerDeliveryInfo.deliveryAddress.address !== '' &&
          customerDeliveryInfo.deliveryAddress.deliveryPrice !== ''
        ) {
          setConfirmModal(true);
          let payload = { ...orderPayload };
          if (isCustom) {
            if (
              documentupload &&
              documentupload.submitedDocumentID &&
              documentupload.submitedDocumentID.length > 0
            ) {
              payload.items = payload.items.map(
                (each, i) =>
                  (each = { ...each, documentId: [documentupload.submitedDocumentID[i].fielId] })
              );
            } else {
              payload.items = payload.items.map((each, i) => (each = { ...each, documentId: [] }));
            }
          }
          dispatch(onAdminOrder(payload));
        } else {
          addToast('Please enter valid delivery address');
        }
      } else {
        setConfirmModal(true);
        let payload = { ...orderPayload };
        if (isCustom) {
          if (
            documentupload &&
            documentupload.submitedDocumentID &&
            documentupload.submitedDocumentID.length > 0
          ) {
            payload.items = payload.items.map(
              (each, i) =>
                (each = { ...each, documentId: [documentupload.submitedDocumentID[i].fielId] })
            );
          } else {
            payload.items = payload.items.map((each, i) => (each = { ...each, documentId: [] }));
          }
        }
        dispatch(onAdminOrder(payload));
      }
    } else {
      addToast('Please enter valid Guest Details');
    }
  };
  const SendLink = () => {
    if (
      customerInfo.CustomerPhoneNumber &&
      customerInfo.CustomerEmail &&
      customerInfo.CustomerFirstName
    ) {
      if (
        customerDeliveryInfo &&
        Object.keys(customerDeliveryInfo).length > 0 &&
        customerDeliveryInfo.deliverytype &&
        customerDeliveryInfo.deliverytype === 'DELIVERY'
      ) {
        if (
          customerDeliveryInfo.deliveryAddress &&
          Object.keys(customerDeliveryInfo.deliveryAddress).length > 0 &&
          customerDeliveryInfo.deliveryAddress.region !== '' &&
          customerDeliveryInfo.deliveryAddress.address !== '' &&
          customerDeliveryInfo.deliveryAddress.deliveryPrice !== ''
        ) {
          let orderPayload = Helpers.getInvoiceOrderPayload(
            customerDeliveryInfo,
            cartItems,
            customerInfo,
            subTotalPrice,
            loggeddetails,
            TotalPayment,
            invoice_order_ref,
            invoice_ref
          );
          console.log(orderPayload, 'orderPayload!');
          let payload = { ...orderPayload };
          if (isCustom) {
            if (
              documentupload &&
              documentupload.submitedDocumentID &&
              documentupload.submitedDocumentID.length > 0
            ) {
              payload.items = payload.items.map(
                (each, i) =>
                  (each = { ...each, documentId: [documentupload.submitedDocumentID[i].fielId] })
              );
            } else {
              payload.items = payload.items.map((each, i) => (each = { ...each, documentId: [] }));
            }
          }
          console.log('onAdminOrder(payload)1', payload);
          dispatch(onAdminOrder(payload, InvoicePayload));

          // dispatch(onInvoicePayment(InvoicePayload));
          setSendLinkModal(true);
        } else {
          // alert('Please enter valid delivery address');
          addToast('Please enter valid delivery address');
        }
      } else {
        let orderPayload = Helpers.getInvoiceOrderPayload(
          customerDeliveryInfo,
          cartItems,
          customerInfo,
          subTotalPrice,
          loggeddetails,
          TotalPayment,
          invoice_order_ref,
          invoice_ref
        );
        let payload = { ...orderPayload };
        if (isCustom) {
          if (
            documentupload &&
            documentupload.submitedDocumentID &&
            documentupload.submitedDocumentID.length > 0
          ) {
            payload.items = payload.items.map(
              (each, i) =>
                (each = { ...each, documentId: [documentupload.submitedDocumentID[i].fielId] })
            );
          } else {
            payload.items = payload.items.map((each, i) => (each = { ...each, documentId: [] }));
          }
        }
        console.log('onAdminOrder(payload)1', payload);
        dispatch(onAdminOrder(payload, InvoicePayload));

        // dispatch(onInvoicePayment(InvoicePayload));
        setSendLinkModal(true);
      }
    } else {
      addToast('Please enter valid Guest Details');
    }
  };
  return (
    <Fragment>
      <div className="col-span-12 lg:col-span-4 shadow-md border-2 px-4 py-2 border-gray-200 h-full overflow-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-thumb-full scrollbar-track-rounded-full scrollbar-track-gray-300">
        <div className="card-body">
          <p className="text-base font-bold pb-2 border-b-2 border-gray-400">
            {dic.t('Cakery-Order Summary')}
          </p>
          <div className="flex justify-between pt-2">
            <p className="text-base font-bold mr-2">{dic.t('Cakery-Sub Total')} </p>
            <p className="text-base">
              {dic.t('Cakery-AED')} {subTotalPrice}
            </p>
          </div>
          {customerDeliveryInfo && customerDeliveryInfo.deliverytype === 'DELIVERY' && (
            <div className="flex justify-between mb-3">
              <p className="text-base font-bold mr-2">{dic.t('Cakery-Delivery Charges')} </p>
              <p className="text-base">
                {deliveryprice ? `${dic.t('Cakery-AED')} ${deliveryprice}` : ''}
              </p>
            </div>
          )}
          <hr className="py-2" />
          <div className="flex justify-between mb-3">
            <p className="text-base font-bold mr-2">{dic.t('Cakery-Total Payment')} </p>
            <p className="text-base">
              {dic.t('Cakery-AED')} {TotalPayment}
            </p>
          </div>
        </div>
        <div className="col-span-4">
          <button
            type="submit"
            onClick={SaveOrder}
            className="text-white mt-2 bg-gray-700  hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
          >
            {dic.t('Cakery-Complete Order')}
          </button>
          <button
            type="submit"
            onClick={SendLink}
            className="text-white mt-2 bg-gray-700  hover:bg-gray-500 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center"
          >
            Send Payment Link
          </button>
        </div>
      </div>
      {confirmModal && (
        <OrderConfirmModal closeModal={closeConfirmModal} totalAmount={TotalPayment} />
      )}
      {sendLinkModal && (
        <SendLinkModal closeModal={closeSendLinkModal} totalAmount={TotalPayment} />
      )}
    </Fragment>
  );
};

export default SummaryRight;
