/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeaderModal from './HeaderModal';
import MobileNav from './MobileNav';
import { useDispatch, useSelector } from 'react-redux';
import { onClearLoginState, RevertLoginStatus } from '../../actions/login.action';
import * as helpers from '../../helpers/config';
import { CompleteUserLogout } from '../../helpers/sessionHandler';
import { FiLogIn, FiUser, FiLogOut } from 'react-icons/fi';
import { RiFileList2Line, RiShieldKeyholeFill, RiShieldKeyholeLine } from 'react-icons/ri';
import { AiOutlineSolution } from 'react-icons/ai';
import { FaUserCircle, FaRegUserCircle } from 'react-icons/fa';
import { GiShoppingCart } from 'react-icons/gi';
import { HiShoppingCart } from 'react-icons/hi';
import { onUserCartList, onUserCartStatusModify } from '../../actions/cart/cartlist.action';
import { onCartAction, onCartCount } from '../../actions/cart/addtocart.action';
import { USER_END_PERMISSION, SITECORE_TABS, DEFALUT_PERMISSION } from '../../constants';
import { onErrorClear } from '../../actions/login.action';
import dic from 'i18next';
import whatsappicon from '../../assets/whatsapp-logo.png';
import { useMediaQuery } from 'react-responsive';

function Header(props) {
  const headers_logo = props?.fields?.Logo?.value?.src;
  const primary_nav = props?.fields?.['Primary Navigation'];
  const profile_nav = props?.fields?.['Profile Navigation'];
  const codes = props?.fields?.['Country Codes'];

  const dispatch = useDispatch();
  const gueststatus = useSelector((state) => state.guest.status);
  const successmessage = useSelector((state) => state.login.statuscode);
  const { existcartdata, existcartstatus } = useSelector((state) => state.cartlist);
  const carttotal = useSelector((state) => state.usercart.status);
  const logoutstatus = useSelector((state) => state.login.logoutstatus);
  const [scrollNav, setScrollNav] = useState(false);
  const [ShowNav, setShowNav] = useState(false);
  const [loggedin, setloggedin] = useState('false');
  const [showDropdown, setshowDropdown] = useState(false);
  const islogin = localStorage.getItem('islogin');
  const loggeddetails = helpers.LoggedUserDetails();
  const sessionproducts = localStorage.getItem('cartproducts');
  var get_current_total = JSON.parse(sessionproducts);
  const [Navigation, setNavigation] = useState([]);
  const changeNav = () => {
    if (window !== 'undefined' && window.scrollY >= 60) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const setShowNavs = () => {
    setShowNav(!ShowNav);
  };

  const setshowDropdowns = () => {
    setshowDropdown(!showDropdown);
  };

  const toggleModal = () => {
    dispatch(onErrorClear());
    document.getElementById('modal').classList.toggle('hidden');
  };
  useEffect(() => {
    if (logoutstatus === true) {
      dispatch(RevertLoginStatus());
    }
    AOS.init({
      duration: 2000,
    });
    window !== 'undefined' && window.addEventListener('scroll', changeNav);
  }, []);

  useEffect(() => {
    if (successmessage === 200) {
      dispatch(onUserCartList());
      setloggedin(!loggedin);
    }
  }, [successmessage]);

  const Logout = () => {
    dispatch(onClearLoginState());
    CompleteUserLogout();
    dispatch(onUserCartStatusModify());
    dispatch(onCartAction([]));
    setloggedin(!loggedin);
    setshowDropdowns();
    window.location.href = '/en/home';
  };
  const existCartSessionModify = () => {
    dispatch(onUserCartStatusModify());
  };

  useEffect(() => {
    if (carttotal === 0) {
      setloggedin(!loggedin);
      get_current_total = 0;
    }
  }, [carttotal]);
  const cartproduct = JSON.parse(localStorage.getItem('cartproducts'));
  useEffect(() => {
    dispatch(onCartCount(cartproduct === null ? [] : cartproduct));
  });

  var links = [];
  var userPermission = '';
  if (successmessage === 200 || islogin === 'true') {
    userPermission = loggeddetails?.accessDetails?.permissionsList?.permission;
  } else {
    userPermission = DEFALUT_PERMISSION;
  }

  const Home = userPermission.includes(USER_END_PERMISSION.home);
  const regularCakes = userPermission.includes(USER_END_PERMISSION.regularCakes);
  const customizeCake = userPermission.includes(USER_END_PERMISSION.customizeCake);
  const festiveMenu = userPermission.includes(USER_END_PERMISSION.festiveMenu);
  const cart = userPermission.includes(USER_END_PERMISSION.cart);
  const dashboard = userPermission.includes(USER_END_PERMISSION.dashboard);
  const callcenter = userPermission.includes(USER_END_PERMISSION.callcenter);
  const kitchendisplay = userPermission.includes(USER_END_PERMISSION.kitchendisplay);
  var navigationLinks = primary_nav;
  if (Home) {
    links.push(
      ...navigationLinks.filter((nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.HOME)
    );
  }
  if (regularCakes) {
    links.push(
      ...navigationLinks.filter(
        (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.REGULAR_CAKES
      )
    );
    links.push(
      ...navigationLinks.filter(
        (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.SPECIALCAKES
      )
    );
  }
  if (customizeCake) {
    links.push(
      ...navigationLinks.filter(
        (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.CUSTOMIZE_CAKE
      )
    );
  }
  if (festiveMenu) {
    links.push(
      ...navigationLinks.filter(
        (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.FESTIVEMENU
      )
    );
    links.push(
      ...navigationLinks.filter(
        (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.FRECH_PASTRY
      )
    );
  }

  const loginrole =
    loggeddetails?.accessDetails?.role === undefined ? true : loggeddetails?.accessDetails?.role;
  if (loginrole === 'ADMIN_USER') {
    if (dashboard) {
      links.push(
        ...navigationLinks.filter(
          (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.DASHBOARD
        )
      );
    }
  }
  if (loginrole === 'KITCHEN_USER') {
    if (kitchendisplay) {
      links.push(
        ...navigationLinks.filter(
          (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.KITCHEN_DISPLAY
        )
      );
    }
  }

  if (loginrole === 'CENTER_USER') {
    if (callcenter) {
      links.push(
        ...navigationLinks.filter(
          (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.CALLCENTER
        )
      );
    }
  }

  if (cart) {
    links.push(
      ...navigationLinks.filter(
        (nav) => nav?.fields?.['Page Title']?.value === SITECORE_TABS.ADDTOCART
      )
    );
  }
  navigationLinks = links;
  const current_location_name = typeof window !== 'undefined' && window.location.pathname;
  const geturl = window.location.pathname;
  var adminStatus = '';
  if (geturl.includes('admin')) {
    adminStatus = 'yes';
  } else {
    adminStatus = 'no';
  }
  return (
    <>
      <nav
        className={`${
          scrollNav || isMobile
            ? 'bg-black duration-300 delay-150 backdrop-blur-lg'
            : 'bg-transparent duration-300 delay-50'
        }  fixed w-screen z-20 lg:py-2 lg:px-55 h-14 sm:px-2 px-0 sm:flex justify-between items-center `}
      >
        <div className="flex px-4 relative justify-between sm:py-0">
          <a href="/" className="text-inherit">
            <img src={`${headers_logo}`} alt="Logo" className={'py-1 sm:py-0 h-[2.5rem]'} />
          </a>
          <MobileNav
            setshowDropdowns={setshowDropdowns}
            islogin={islogin}
            navigationLinks={navigationLinks}
            profile_nav={profile_nav}
            toggleModal={toggleModal}
            setShowNavs={setShowNavs}
            showDropdown={showDropdown}
            Logout={Logout}
            get_current_total={get_current_total}
          />
        </div>

        <ul
          className={` sm:flex pt-1 mt-3 sm:mt-0 sm:px-4 px-0 pb-3 sm:pb-0 
          ${ShowNav ? 'bg-black' : 'hidden'}`}
          id="navContent"
        >
          {navigationLinks.map((item, key) => {
            const title = item?.fields?.['Page Title']?.value;
            const CTALink = item?.fields?.['Direct CTA Link']?.value?.href;
            const carticon = item?.fields?.['Menu Background']?.value?.src;
            return (
              <>
                {title === 'Add To Cart' ? (
                  <>
                    <li
                      className="py-4 px-2  uppercase  hidden sm:block border-blue-300   transition duration-200 hover:bg-gray-800 sm:hover:bg-transparent text-white "
                      key={key}
                    >
                      <div className="relative">
                        {carttotal > 0 || carttotal > '0' ? (
                          <>
                            <a href={CTALink}>
                              <HiShoppingCart
                                className={`${
                                  adminStatus === 'no'
                                    ? scrollNav
                                      ? CTALink === current_location_name
                                        ? 'text-white'
                                        : 'text-white'
                                      : CTALink === current_location_name
                                      ? 'text-black'
                                      : 'text-black'
                                    : CTALink === current_location_name
                                    ? 'text-gray-400'
                                    : 'text-white'
                                } `}
                                size={26}
                              />
                              <span className="bg-gray-700 absolute text-white-800 text-xs font-semibold  bottom-[0.9rem] left-[0.8rem] px-2 py-[0.12rem] rounded-full ">
                                {carttotal}
                              </span>
                            </a>
                          </>
                        ) : (
                          <>
                            <div className="relative flex flex-col items-center group">
                              <HiShoppingCart
                                className={`${
                                  adminStatus === 'no'
                                    ? scrollNav
                                      ? CTALink === current_location_name
                                        ? 'text-white'
                                        : 'text-white'
                                      : CTALink === current_location_name
                                      ? 'text-black'
                                      : 'text-black'
                                    : CTALink === current_location_name
                                    ? 'text-gray-400'
                                    : 'text-white'
                                } `}
                                size={26}
                              />
                              {/* <span className="bg-gray-700 absolute text-white-800 text-xs font-semibold  bottom-[0.9rem] left-[0.8rem] px-2 py-[0.12rem] rounded-full ">
                                0
                              </span> */}
                              <div className="absolute shadow-md w-56 my-4 right-[-25px]	top-2	hidden  flex-col items-center  mb-6 group-hover:flex">
                                <div className="w-3 h-3 top-[7px] left-[70px] relative bg-white text-black rotate-45"></div>
                                <div className="bg-white text-black px-6 py-4 hover:cursor-pointer">
                                  <GiShoppingCart size={40} className="text-center mx-auto my-3" />
                                  <p className="text-center font-bold text-lg capitalize py-1">
                                    Your cart is empty
                                  </p>
                                  <p className="text-center font-bold text-sm capitalize py-1">
                                    But it does not have to be.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </li>
                  </>
                ) : title === 'Dashboard' ? (
                  <li
                    key={key}
                    className={`py-3 sm:py-4   uppercase  hover:text-gray-400 text-[14px] sm:text-[12px] lg:text-[16px]  ${
                      adminStatus === 'no'
                        ? scrollNav
                          ? '/en/admin/orders' === current_location_name
                            ? 'text-gray-400'
                            : 'text-white'
                          : '/en/admin/orders' === current_location_name
                          ? 'text-gray-400'
                          : 'text-white sm:text-black'
                        : '/en/admin/orders' === current_location_name
                        ? 'text-gray-400'
                        : 'text-white'
                    } `}
                  >
                    <a
                      // href={CTALink}
                      href={'/en/admin/orders'}
                      className={`px-4 sm:pr-0 lg:px-3 tracking-[0.7px] hover:text-gray-400 hover:cursor-pointer ${
                        CTALink === current_location_name ? 'text-gray-400' : ''
                      }`}
                    >
                      {title}
                    </a>
                  </li>
                ) : (
                  <li
                    key={key}
                    className={`py-3 sm:py-4   uppercase  hover:text-gray-400 text-[14px] sm:text-[12px] lg:text-[16px]  ${
                      adminStatus === 'no'
                        ? scrollNav
                          ? CTALink === current_location_name
                            ? 'text-gray-400'
                            : 'text-white'
                          : CTALink === current_location_name
                          ? 'text-gray-400'
                          : 'text-white sm:text-black'
                        : CTALink === current_location_name
                        ? 'text-gray-400'
                        : 'text-white'
                    } `}
                  >
                    <a
                      href={CTALink}
                      className={`px-4 sm:pr-0 lg:px-3 tracking-[0.7px] hover:text-gray-400 hover:cursor-pointer ${
                        CTALink === current_location_name ? 'text-gray-400' : ''
                      }`}
                    >
                      {title}
                    </a>
                  </li>
                )}
              </>
            );
          })}
          {successmessage !== 200
            ? islogin !== 'true' && (
                <li className="py-4 px-2  uppercase hidden sm:block border-blue-300 transition duration-200     text-white ">
                  <a
                    href="javascript: void(0)"
                    onClick={toggleModal}
                    className="hover:text-gray-700"
                  >
                    <FaRegUserCircle
                      className={scrollNav ? 'text-white' : 'text-black'}
                      size={24}
                    />
                  </a>
                </li>
              )
            : ''}
          {successmessage === 200 || islogin === 'true' ? (
            <li className="py-4 px-2  uppercase hidden sm:block border-blue-300  transition duration-200   text-white ">
              <>
                <button
                  type="button"
                  className="flex relative text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300"
                  id="user-menu-button"
                  aria-expanded="false"
                  data-dropdown-toggle="dropdown"
                  onClick={setshowDropdowns}
                >
                  <span className="sr-only hidden"></span>
                  <FaUserCircle className="text-white" size={24} />
                </button>
                <div
                  className={`${
                    showDropdown ? 'block' : 'hidden'
                  } z-50 my-4 text-base absolute right-8 list-none bg-white rounded divide-y divide-gray-100 shadow block`}
                  id="dropdown"
                  data-popper-placement="bottom"
                >
                  <div className="py-3 bg-slate-700 flex px-4">
                    <span className="mr-2">
                      <FiUser />
                    </span>
                    <span className="block text-sm  font-medium">
                      {loggeddetails?.firstName} {loggeddetails?.lastName}
                    </span>
                  </div>
                  <ul className="py-1" aria-labelledby="dropdown">
                    {profile_nav.map((item, index) => {
                      return item?.fields['Page Title']?.value !== 'Profile' ? (
                        <li key={index}>
                          <a
                            href={item?.fields['Direct CTA Link']?.value.href}
                            className="flex py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            <span className="text-gray-700 mr-2 mt-0.5">
                              {item?.fields['Page Title']?.value === 'Orders' ? (
                                <RiFileList2Line />
                              ) : item?.fields['Page Title']?.value === 'Consent' ? (
                                <AiOutlineSolution />
                              ) : (
                                <RiShieldKeyholeLine />
                              )}
                            </span>
                            {item?.fields['Page Title']?.value}
                          </a>
                        </li>
                      ) : (
                        ''
                      );
                    })}
                    <li onClick={Logout}>
                      <a
                        href="javascript: void(0)"
                        className="flex py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <span className="text-gray-700 mr-2 mt-1">
                          <FiLogOut />
                        </span>
                        {dic.t('Cakery-Log Out')}
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            </li>
          ) : (
            ''
          )}
        </ul>
      </nav>
      <HeaderModal codes={codes} />
      <div className="whats-app-icon fixed bottom-5 left-[1.4rem] z-20 h-12">
        <a
          href="https://api.whatsapp.com/send/?phone=971526801334&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsappicon} className="w-12 inline" />
        </a>
      </div>
    </>
  );
}

export default Header;
