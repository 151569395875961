import { GetGuestDetails, getGuestStatus } from '../helpers/sessionHandler';
export const GUEST_FUNCTION = 'GUEST_FUNCTION';
export const GUEST_DISABLE_FUNCTION = 'GUEST_FUNCTION';

export const onGuest = (data) => async (dispatch) => {
  localStorage.setItem('GuestDetail', JSON.stringify(data));
  localStorage.setItem('guestLoggedIn', true);
  const guest_data = GetGuestDetails();
  const guest_status = getGuestStatus();
  dispatch({ type: GUEST_FUNCTION, payloadstatus: guest_status, payload: guest_data });
};

export const onGuestDisable = () => async (dispatch) => {
  dispatch({ type: GUEST_DISABLE_FUNCTION, payloadstatus: false, payload: '' });
};
