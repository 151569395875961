/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onCartAction } from '../../actions/cart/addtocart.action';
import TopRecommendedCakes from './TopRecommendedCakes';
import TopTrendingCakes from './TopTrendingCakes';
function RecommendedCakes(props) {
  const toprecommend = props?.fields?.['Top Recommended Cakes'];
  const toptrending = props?.fields?.['Top Trending Cakes'];
  return (
    <div>
      <TopRecommendedCakes toprecommend={toprecommend} toptrending={toptrending} />
    </div>
  );
}

export default RecommendedCakes;
