import {
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_FAILURE,
  DOCUMENT_UPLOAD_LOADING,
  SET_UPLOADED_DOCUMENT_ID,
  GET_DOCUMENT_LOADING,
} from '../../actions/admin/DocumentUpload.action';

const initailState = {
  loading: false,
  getdocumentloading: false,
  success: '',
  error: '',
  submitedDocumentID: [],
};

export const documentUploadReducer = (state = initailState, action) => {
  switch (action.type) {
    case DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case DOCUMENT_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_DOCUMENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DOCUMENT_UPLOAD_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_UPLOADED_DOCUMENT_ID:
      return {
        ...state,
        submitedDocumentID: action.payload,
      };
    default:
      return state;
  }
};
