import { MicroRoutes } from '../../constants';
import axios from 'axios';
export const SUCESSS = 'SUCESSS';
export const FAILURE = 'FAILURE';
export const LOADING = 'LOADING';
export const onContactUs = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
    loading: true,
  });
  await axios
    .post(MicroRoutes.ContactUs, data)
    .then((response) => {
      dispatch({
        type: SUCESSS,
        status: response.status,
      });
    })
    .catch((error) => {
      dispatch({
        type: FAILURE,
        status: response.status,
      });
    });
};
