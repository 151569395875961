import React, { useEffect, useState } from 'react';
import { onuserhistory } from '../../actions/admin/UserHistory.action';
import { useSelector, useDispatch } from 'react-redux';
import OrderView from '../OrderView';
import { RiLoader5Line } from 'react-icons/ri';
import * as helpers from '../../helpers/config';
import moment from 'moment';
import dic from 'i18next';

function Order() {
  const dispatch = useDispatch();
  const { loading, orders, errors } = useSelector((state) => state.orderhistory);
  const [getOrders, setOrders] = useState(false);
  const [modelItem, setmodelItem] = useState(false);
  const loggeddetails = helpers.LoggedUserDetails();

  useEffect(() => {
    setOrders(orders);
  }, [orders]);

  useEffect(() => {
    const payload = {
      searchKey: 'phoneNumber',
      searchValue: loggeddetails?.phoneNumber,
    };
    dispatch(onuserhistory(payload));
  }, []);
  useEffect(() => {
    window !== 'undefined' && window.scroll(0, 500);
  }, []);
  return (
    <div className="order-list">
      {loading === true ? (
        <>
          <div className="py-20">
            <RiLoader5Line className="w-12 h-12 mx-auto text-gray-200 animate-spin dark:text-white fill-white" />
            <h4 className="text-white text-center text-xl pt-2">Loading your orders...</h4>
          </div>
        </>
      ) : (
        <>
          <div className="grid sm:grid-cols-2  lg:grid-cols-2 xl:grid-cols-4 gap-3 cursor-pointer sm:px-6 pb-5  ">
            {getOrders ? (
              getOrders.map((item, key) => {
                return (
                  <div
                    className="border-solid border mb-4 mx-2 border-white"
                    onClick={() => setmodelItem(item)}
                    key={key}
                  >
                    <div className="product-image">
                      <img
                        src={item?.items[0]?.itemImageUrl}
                        className="object-cover h-[200px] sm:h-[170px] lg:h-[180px] 2xl:h-[210px] w-full"
                      />
                    </div>
                    <div className="product-details px-6 py-4  sm:text-[12px] lg:text-sm text-white">
                      <p className="py-1">Order ID : {item?.orderId}</p>
                      <p className="py-1">
                        {dic.t('Cakery-Order Date')} :{' '}
                        {moment(item?.orderDate).format('YYYY-MM-DD')}
                      </p>
                      <p className="py-1">
                        {dic.t('Cakery-Order Status')} : {item?.orderStatus}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {' '}
                <p className="text-white text-right  capitalize text-xl">No Orders found.</p>
                <p className="text-white text-left  capitalize text-xl">Make it your first order</p>
              </>
            )}
            {modelItem ? (
              <OrderView item={modelItem} setmodelItem={setmodelItem} checkModal={true} />
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Order;
