import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dic from 'i18next';
import { FaCheck } from 'react-icons/fa';

const SummaryLeft = () => {
  const { customerDeliveryInfo, cartItems, customerInfo } = useSelector(
    (state) => state.callcenter
  );
  const [finalcartItems, setfinalcartItems] = useState(cartItems);
  const [TotalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    setfinalcartItems(cartItems);
  }, [cartItems]);
  useEffect(() => {
    let Price = 0;
    finalcartItems.map((item) => (Price = Price + item.totalPrice));
    setTotalPrice(Price);
  }, [finalcartItems]);
  return (
    <Fragment>
      <div className="col-span-12 lg:col-span-8">
        <div className="grid grid-cols-2 shadow-md p-2 border-2 border-gray-200 h-full">
          <div className="card-body col-span-2 sm:col-span-1">
            <p className="text-base font-bold pb-2 border-b-2 border-gray-400">
              {dic.t('Cakery-Customer Details')}
            </p>
            <div className="flex flex-col mb-2 pt-2 flex-wrap w-full">
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Cakery-Name')}: </p>
                <p className="text-base">
                  {customerInfo.CustomerFirstName} {customerInfo.CustomerLastName}
                </p>
              </div>
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Cakery-Mobile Number')}: </p>
                <p className="text-base">{customerInfo.CustomerPhoneNumber}</p>
              </div>
            </div>
            <div className="flex flex-col mb-2 flex-wrap w-full">
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Cakery-Email')}: </p>
                <p className="text-base">{customerInfo.CustomerEmail}</p>
              </div>
              {customerInfo.StatusOfGuest !== '' && (
                <div className="flex items-center flex-wrap">
                  <p className="text-base font-bold mr-2">{dic.t('Cakery-Status of Guest')}: </p>
                  <p className="text-base">{customerInfo.StatusOfGuest}</p>
                </div>
              )}
              {customerInfo.Remarks !== '' && (
                <div className="flex items-center flex-wrap">
                  <p className="text-base font-bold mr-2">{dic.t('Cakery-Remarks')}: </p>
                  <p className="text-base">{customerInfo.Remarks}</p>
                </div>
              )}
            </div>
          </div>
          <div className="card-body col-span-2 sm:col-span-1">
            <p className="text-base font-bold pb-2 border-b-2 border-gray-400">
              {dic.t('Cakery-Delivery Details')}
            </p>
            {customerDeliveryInfo.deliverytype === 'DELIVERY' && (
              <div className="flex flex-col mb-2 pt-2 flex-wrap">
                <div className="flex items-center flex-wrap">
                  <p className="text-base font-bold mr-2">{dic.t('Cakery-Makani No')}: </p>
                  <p className="text-base">{customerDeliveryInfo.deliveryAddress.makani}</p>
                </div>
                <div className="flex items-center flex-wrap">
                  <p className="text-base font-bold mr-2">{dic.t('Cakery-Region')}: </p>
                  <p className="text-base">{customerDeliveryInfo.deliveryAddress.region}</p>
                </div>
                <div className="flex items-center flex-wrap">
                  <p className="text-base font-bold mr-2">{dic.t('Cakery-Address')}: </p>
                  <p className="text-base">{customerDeliveryInfo.deliveryAddress.address}</p>
                </div>
              </div>
            )}
            <div className="flex flex-col pt-2 flex-wrap">
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Cakery-Delivery Type')}: </p>
                <p className="text-base">{customerDeliveryInfo.deliverytype}</p>
              </div>
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Cakery-Delivery Date')}: </p>
                <p className="text-base">
                  {customerDeliveryInfo && customerDeliveryInfo.dpdate ? (
                    <>
                      {customerDeliveryInfo.dpdate.getDate()}/
                      {customerDeliveryInfo.dpdate.getMonth() + 1}/
                      {customerDeliveryInfo.dpdate.getFullYear()}
                    </>
                  ) : (
                    <span>No delivery date available</span>
                  )}
                </p>
              </div>
              <div className="flex items-center flex-wrap">
                <p className="text-base font-bold mr-2">{dic.t('Cakery-Delivery Time')}: </p>
                <p className="text-base">{customerDeliveryInfo.dptime}</p>
              </div>
            </div>
          </div>
          {finalcartItems && finalcartItems.length > 0 && (
            <div className="flex flex-col justify-center col-span-2">
              <div className="container flex flex-col w-full h-64 px-4 items-center overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-thumb-full scrollbar-track-rounded-full scrollbar-track-gray-300">
                <ul className="flex flex-col w-full divide divide-y">
                  {finalcartItems.map((item, index) => {
                    const itemSupplies =
                      item && item?.cakeSuppliesName ? item.cakeSuppliesName : [];
                    const itemSuppliesPrice = item ? item.cakeSuppliesPrice : [];
                    console.log(item);
                    return (
                      <li className="flex flex-row" key={index}>
                        <div className="select-none cursor-pointer flex flex-1 items-center py-4">
                          <div className="flex flex-col justify-center items-center mr-2">
                            <a href="#" className="block relative">
                              <img
                                alt="cake"
                                src={item.cakeImage}
                                className="mx-auto object-cover rounded h-16 w-20"
                              />
                            </a>
                          </div>
                          <div className="flex-1 pl-1 mr-10">
                            <div className="text-sm font-semibold sm:font-medium">
                              {item.cakeFlavour}
                            </div>
                            {/* {item.cakeBerries !== 0 && (
                              <div className="text-gray-600 text-sm">
                                {` ${item.cakeAddonName} : (${item.cakeBerries})`}
                              </div>
                            )} */}
                            {item.cakeFestiveAddons.length > 0 && (
                              <ul className="festive-addons border-b pb-1 mb-2">
                                <h4 className="text-gray-600 text-xs font-semibold mt-2">Addons</h4>
                                {item &&
                                  item.cakeFestiveAddons.length > 0 &&
                                  item.cakeFestiveAddons.map((val, i) => (
                                    <li
                                      className="addon-item flex items-center text-sm text-muted font-medium space-x-3"
                                      key={i}
                                    >
                                      <h4 className="text-xs flex items-center">
                                        <FaCheck className="h-3 w-3 text-green-500 mr-2" />
                                        {val.Qty} x {val.label}
                                      </h4>
                                      <h4 className="">
                                        {val.value} {dic.t('Cakery-AED')}
                                      </h4>
                                    </li>
                                  ))}
                              </ul>
                            )}
                            {itemSupplies.length > 0 ? (
                              <h6 className="text-xs text-gray-600 font-semibold">Supplies</h6>
                            ) : (
                              ''
                            )}
                            {itemSupplies.length > 0 || itemSupplies !== ''
                              ? itemSupplies.map((items, i) => (
                                  <div className="flex justify-between" key={i}>
                                    <div className="text-gray-600 text-xs w-3/5">
                                      {`${items} : ${itemSuppliesPrice[i]}`}
                                    </div>
                                  </div>
                                ))
                              : ''}
                            <div className="text-gray-600 text-sm">{item.cakeServings}</div>
                            {item.cakeMessage !== '' && (
                              <div className="text-gray-600 text-sm">✉️ {item.cakeMessage}</div>
                            )}
                          </div>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div className="flex text-gray-700 px-1 justify-center text-md font-bold">
                              Qty {item.cakeQuantity}
                            </div>
                            <div className="col-span-1 py-auto text-center">
                              <h2 className="font-bold tracking-widest text-gray-700">
                                {dic.t('Cakery-AED')} {item.totalPrice}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SummaryLeft;
