import { BOOK_PAYMENT } from '../actions/bookpayment.action';
const initailState = {
  payment_loading: false,
  payment_message: false,
  payment_error: false,
};

export const onBookPaymentReducer = (state = initailState, action) => {
  switch (action.type) {
    case BOOK_PAYMENT:
      return {
        ...state,
        payment_loading: action.loading,
        payment_message: action.message,
        payment_error: action.error,
      };
    default:
      return state;
  }
};
